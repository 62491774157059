import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import translation JSON files
import enCcna1Translations from './locales/en/ccna1_translation.json';
import esCcna1Translations from './locales/es/ccna1_translation.json';
import ruCcna1Translations from './locales/ru/ccna1_translation.json';
import zhCcna1Translations from './locales/zh/ccna1_translation.json';
import enCcna2Translations from './locales/en/ccna2_translation.json';
// import esCcna2Translations from './locales/es/ccna2_translation.json';

// Define translation resources
const resources = {
  en: {
     ccna1: enCcna1Translations,
    ccna2: enCcna2Translations,
  },
  es: {
    ccna1: esCcna1Translations,
    // ccna2: esCcna2Translations,
  },
  ru: {
    ccna1: ruCcna1Translations,
    // ccna2: esCcna2Translations,
  },
  zh: {
    ccna1: zhCcna1Translations,
    // ccna2: esCcna2Translations,
  },
};

i18n
  .use(LanguageDetector) // Automatically detect user's language preference
  .use(initReactI18next)  // Connects i18next to React
  .init({
    resources, // Load translations from imported JSON files
    lng: navigator.language.split(/[-_]/)[0] || 'en', // Default to browser language or 'en'
    fallbackLng: 'en', // Fallback language
    debug: true, // Enable debug mode
    ns: ['ccna1', 'ccna2'], // Define namespaces for modules
    defaultNS: 'ccna1', // Set a default namespace/module
    interpolation: {
      escapeValue: false, // React already escapes values
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator'], // Order of language detection
      caches: ['localStorage', 'cookie'], // Cache user language
    },
    react: {
      useSuspense: false // Disable suspense to avoid translation delays
    }
  });

export default i18n;
