import TypeAImg from '../../../../../assets/CCNA2/ch7_SIC_TypeA.png'
import TypeBImg from '../../../../../assets/CCNA2/ch7_SIC_TypeB.png'
import TypeCImg from '../../../../../assets/CCNA2/ch7_SIC_TypeC.png'
import Lab1Img from '../../../../../assets/CCNA2/ch7_SIC_TypeA_Lab 1.png' 
import Lab2Img from '../../../../../assets/CCNA2/ch7_SIC_TypeA_Lab 2.png'
import Research1Img from '../../../../../assets/CCNA2/ch7_SIC_TypeA_Research 1.png'
import Research2Img from '../../../../../assets/CCNA2/ch7_SIC_TypeA_Research 2.png'// import TypeA_2Img from './CCNA1_PTA_2.png'
import Prod1Img from '../../../../../assets/CCNA2/ch7_SIC_TypeA_Prod 1.png'// import TypeBImg from './Exam_B_1.png'
import Prod2Img from '../../../../../assets/CCNA2/ch7_SIC_TypeA_Prod 2.png'// import TypeB_2Img from './Exam_B_2.png'
// import TypeA_124_1 from './CCNA1_PTA_124-1.png'
// import TypeA_124_5 from './CCNA1_PTA_124-5.png'
// import TypeA_214_1 from './CCNA1_PTA_214-1.png'
// import TypeA_Server from './CCNA1_PTA-Server.png'


export const Skills_TabButtonsInfo = {
    Type_A: {
      image: [TypeAImg],
      title: '',
      description:
        'You might be given One (1) of these Three (3) Topology.',

      routerConfig: [
        `<h2>Atom (Router 1) Configuration</h2>`+
        `<p>To configure router Atom follow these steps:</p>`+
`<ol class="skills-list-note">
        <li><b>Note check your configuration requirements before copy and paste the following comand in the CLI.</b></li>
        </ol>`
      ], 
      router2Config: [
        `<h2>Bldg-1, Quest or Branch (Router 2) Configuration</h2>`,
        `<p>To configure router Bldg-1, Quest or Branch follow these steps:</p>`+
`<ol class="skills-list-note">
        <li><b>Note check your configuration requirements before copy and paste the following comand in the CLI.</b></li>
        </ol>`
      ], 
      switch1Config:[
        `<h2>Neutron Switch Configuration</h2>`
      ],  
// This switch 2 is in arry
      switch2Config:[
        `<h2>Proton Switch Configuration</h2>`
      ],
      
      switch3Config:[
        `<h2>Eletron Switch Configuration</h2>`
      ], 
    //  switch2Config:'Admin Switch Configuration', 
    //   endDevice1:'Prof X',
    //   endDevice2:'Prof Y',
    //   endDevice3:'Dean X',
    //   endDevice4:'Dean Y',

  tableData: [
    {
      Device: 'Atom',
      Interface: 'G0/0.20',
      Address: '172.16.20.0/24; last usable address in network',
      // SubnetMask: '255.255.255.224',
      // DefaultGateway: 'N/A',
    },
    {
      Device: 'Atom',
      Interface: "G0/0.40",
      Address: '172.16.40.0/24; last usable address in network',
      // SubnetMask: '',
      // DefaultGateway: 'N/A',
    },
    {
      Device: 'Atom',
      Interface: "G0/0.60",
      Address: '172.16.60.0/24; last usable address in network',
      // SubnetMask: '',
      // DefaultGateway: 'N/A',
    },
    {
      Device: 'Atom',
      Interface: 'G0/0.88',
      Address: '172.16.88.0/24; last usable address in network',
    //   SubnetMask: '255.255.255.240',
    //   DefaultGateway: 'N/A',
    },
    {
      Device: 'Atom',
      Interface: "G0/1.250",
      Address: '172.16.250.0/24; last usable address in network',
      SubnetMask: '',
      DefaultGateway: 'N/A',
    },
    {
      Device: 'Atom',
      Interface: "G0/1.254",
      Address: '172.16.254.0/24, last usable address in network',
      SubnetMask: '',
      DefaultGateway: 'N/A',
    },
    {
      Device: 'Neutron',
      Interface: 'SVI',
      Address: '172.16.88.0/24; any available address in network',
    //   SubnetMask: '255.255.255.240',
    //   DefaultGateway: 'N/A',
    },
    {
      Device: 'Proton',
      Interface: "SVI",
      Address: '172.16.88.0/24; any available address in network',
      SubnetMask: '',
      DefaultGateway: 'N/A',
    },
    {
      Device: 'Electron',
      Interface: "SVI",
      Address: '172.16.88.0/24; any available address in network',
      SubnetMask: '',
      DefaultGateway: 'N/A',
    },
    {
      Device: 'Lab 1',
      Interface: "NIC",
      Address: '172.16.20.0/24; any available address in network',
      SubnetMask: '',
      DefaultGateway: '',
    },
    {
      Device: 'Research 1',
      Interface: "NIC",
      Address: '172.16.40.0/24; any available address in network',
      SubnetMask: '',
      DefaultGateway: '',
    },
    {
      Device: 'Prod 1',
      Interface: "NIC",
      Address: '172.16.60.0/24; any available address in network',
      SubnetMask: '',
      DefaultGateway: '',
    },
    {
      Device: 'Lab 2',
      Interface: 'NIC',
      Address: '172.16.20.0/24; any available address in network',
      SubnetMask: '',
      DefaultGateway: '',
    },
    {
      Device: 'Research 2',
      Interface: 'NIC',
      Address: '172.16.40.0/24; any available address in network',
      SubnetMask: '',
      DefaultGateway: '',
    },
    {
      Device: 'Prod 2',
      Interface: 'NIC',
      Address: '172.16.60.0/24, any available address in network',
      SubnetMask: '',
      DefaultGateway: '',
    },
    {
      Device: 'DNS Server',
      Interface: 'NIC',
      Address: '172.16.254.252',
      SubnetMask: '',
      DefaultGateway: '',
    },

  ],

  vlantable:[
    `<h2>VLAN Assignment Table</h2>`,
    `<table class="addressing-table">
  <thead>
      <tr>
          <th>VLAN Number</th>
          <th>VLAN Name</th>
          <th>Device/Ports</th>
      </tr>
  </thead>
  <tbody>
      <tr>
          <td>20</td>
          <td>Lab</td>
          <td>Proton: Fa0/1 - Fa0/5 </br>Electron: Fa0/1 - Fa0/5</td>

      </tr>
      <tr>
          <td>40</td>
          <td>Research</td>
          <td>Proton: Fa0/6 - Fa0/10 </br>Electron: Fa0/6 - Fa0/10</td>
      </tr>
      <tr>
          <td>60</td>
          <td>Production</td>
          <td>Proton: Fa0/11 - Fa0/15</br> Electron: Fa0/11 - Fa0/15</td>
      </tr>
      <tr>
          <td>88</td>
          <td>NetOps</td>
          <td>Neutron: SVI </br>Proton: SVI</br> Electron: SVI</td>
      </tr>
  </tbody>
</table>`,

  ],

  introduction:[
    `<h2>Introduction</h2>`,
    `<p> In this practice skills assessment, you will configure the Science LLC network with VLANs, Trunking, inter-VLAN routing, and IPv4 standard ACLs. As part of this process, you will perform basic VLAN configuration tasks, address router interfaces and hosts, and implement two ACLs.</p>`,
    `<p><b>You are not required to configure the Quark switch, or any of the servers.</b></p>`,
    `<p><b>Note: In order to keep the assessment activity as brief as possible, you will only be partially configuring some devices, as directed in the instructions. In a real network, all devices would be fully configured to function in the network. You are only responsible for completing the tasks covered in the instructions. Any configuration that you do beyond the requirements will not result in additional credit.</b></p>`,
    `<p><b>All IOS device configurations should be completed from a direct terminal connection to the device console. In addition, many values that are required to complete the configurations have not been given to you. In those cases, create the values that you need to complete the requirements.</b></p>`,
    `<p>You will practice and be assessed on the following skills:</p>`,
    `<ol class="skills-list">
    <li>Configuration of initial device settings</li>
    <li>IPv4 address assignment</li>
    <li>Configuration and addressing of device interfaces</li>
    <li>Configuration of VLANs on three switches</li>
    <li>Configuration of trunking and inter-VLAN routing</li>
    <li>Configuration of ACL to limit device access</li>
  </ol>`,
  `<p>Requirements by device:</p>`,
  `<p><b>Router Atom:</b></p>`,
  `<ol class="skills-list">
    <li>Configuration of initial router settings</li>
    <li>Interface configuration and IPv4 addressing</li>
  </ol>`,
  `<p><b>Switch Proton:</b></p>`,
  `<ol class="skills-list">
    <li>Configuration of VLANs</li>
    <li>Configuration of VLAN trunking</li>
    <li>Configuration of interfaces into VLAN</li>
    <li>Configuration of IP address for the Management VLAN</li>
  </ol>`,
  `<p><b>Switch Electron:</b></p>`,
  `<ol class="skills-list">
    <li>Configuration of VLANs</li>
    <li>Configuration of VLAN trunking</li>
    <li>Configuration of interfaces into VLAN</li>
    <li>Configuration of IP address for the Management VLAN</li>
  </ol>`,
  `<p><b>PC Hosts:</b></p>`,
  `<ol class="skills-list">
   <li>Pv4 full addressing</li>
  </ol>`,
  ],



  instructions: [
    `<h2>Instructions</h2>`,
    `<h2><b>Step 1: Determine Addressing</b></h2>`,

    `<p>Determine the IP addresses that you will use for the required interfaces on the three switch SVIs, and the six LAN hosts. Use the information in the addressing table.</b></p>` ,
      `<ol class="ITN-skills-list">
            <li>Select the switch SVI addresses.</li>
            <li>The interfaces on the <b>Atom</b>router that are used to route the VLANs should be addressed with the last usable IP address in the subnet.</li>
            <li>Assign valid host addresses to the LAN hosts according to the Addressing Table and VLAN assignment</li>
            </ol>`,

      `<h2><b>Step 2: Configure initial device setting on Neutron and Atom</b></h2>`,
      `<p> Configure Neutron and Atom with all initial configurations that you have learned in the course so far:</p>`, 
      `<ol class="ITN-skills-list">
            <li>Configure the switch hostname: Neutron.</li>
            <li>Configure the router hostname: Atom.</b></li>
            <li>Prevent the router from attempting to resolve unrecognized CLI entries as domain names.</li>
            <li>Protect device configurations from unauthorized access with the encrypted password cisco.</li>
            <li>Secure the router and switch console and remote access lines with password cisco.</li>
            <li>Prevent all passwords from being viewed in clear text in device configuration files.</li>
      </ol>`,

      `<h2><b>Step 3: Configure VLANs</b></h2>`,
      `<p>Configure four VLANs on the <b>Neutron</b>, <b>Proton</b>, and <b>Electron</b> switches using the values in the addressing table.</p>`, 
      `<ol class="ITN-skills-list">
            <li>Configure the switch hostname: Neutron.</li>
            <li>Configure the router hostname: Atom.</b></li>
            <li>Prevent the router from attempting to resolve unrecognized CLI entries as domain names.</li>
            <li>Protect device configurations from unauthorized access with the encrypted password cisco.</li>
            <li>Secure the router and switch console and remote access lines with password cisco.</li>
            <li>Prevent all passwords from being viewed in clear text in device configuration files.</li>
      </ol>`,

      `<h2><b>Step 4: Assign Switch Interfaces to VLANs</b></h2>`,
      `<p>Configure FastEthernet interfaces on <b>Proton</b> and <b>Electron</b> according to the following guidelines:</p>`, 
      `<ol class="ITN-skills-list">
            <li>Interfaces Fa0/1 - Fa0/5 are assigned to VLAN 20</li>
            <li>Interfaces Fa0/6 - Fa0/10 are assigned to VLAN 40</li>
            <li>Interfaces Fa0/11 - Fa0/15 are assigned to VLAN 60</li>
      </ol>`,

      `<h2><b>Step 5: Configure the Switches for Remote Management</b></h2>`,
      `<p>Configure the SVIs of the <b>Neutron</b>, <b>Proton</b>, and <b>Electron</b> switches using the information in the VLAN and Addressing Tables. Configure the IP address for the Management VLAN and the default-gateway IP address. Configure the SVIs so that they will be reachable by devices on other networks after the network has been fully configured.</p>`, 

      `<h2><b>Step 6: Configure the Switches for Remote Management</b></h2>`,
      `<p>Configure VLAN trunking between the <b>Neutron</b>, <b>Proton</b>, and <b>Electron</b> switches.</p>`, 
      `<ol class="ITN-skills-list">
            <li><b>Manually</b> configure the VTP domain name to <b>School</b> on the Neutron, Proton, and Electron switches.</li>
            <li><b>Manually</b> configure the trunking ports on the Neutron, Proton, and Electron switches.</li>
      </ol>`,

      `<h2><b>Step 7: Configure inter-VLAN Routing</b></h2>`,
      `<p>Use the information in the Addressing and VLAN tables to configure inter-VLAN routing on Atom according to the following guidelines:</p>`, 
      `<ol class="ITN-skills-list">
            <li>Configure inter-VLAN routing among VLANs 20, 40, 60 and 88 through G0/0 subinterfaces.</li>
            <li>Configure inter-VLAN routing for VLANs 250 and 254 through Gi0/1 subinterfaces.</li>
      </ol>`,
      
      `<h2><b>Step 8: Host Addressing</b></h2>`,
      `<p>Address the hosts according to the values in the addressing table. Use a DNS server address of <b>172.16.254.252.</b> All hosts should be able to reach the <b>www.cisco.com</b> server.</p>`, 

      `<h2><b>Step 9: Configure Access Control Lists</b></h2>`,
      `<p>You will configure two access control lists on the <b>Atom</b> router. The ACL specifications are as follows:</p>`, 
      
            `<p><b>Restrict access to the vty lines on Atom.</b></p>`,

        `<ol class="skills-list">
        <li>Create a numberedstandard ACL using number <b>10</b>. Be sure that you use this number exactly as it appears in these instructions.</li>
        <li>Allow only hosts on the <b>Production</b> VLAN to access the vty lines of <b>Atom</b>.</li>          
        <li>No other internal and Internet hosts should be able to access the vty lines of <b>Atom</b>.</li>
        <li>Your solution should consist of a <b>single ACL statement</b>.</li>
        </ol>`,

        
        `<p><b>Restrict access to the internal web server</b></p>`,
`<ol class="skills-list">
    <li>Create a named standard ACL using the name <b>INT-WEB</b>. Be sure that you use this name exactly as it appears in these instructions.</li>
    <li>Allow hosts on the <b>Research</b> and <b>Production</b> VLANs to reach the internal administrative web server at <b>int.com</b></li>          
    <li>No other internal and Internet hosts should be able to access the internal administrative web server.</li>
    <li>Your solution should consist of two ACL statements.</li>
<ol>`,

`<h2><b>Step 10: Verify Connectivity</b></h2>`,
`<p>Verify your configurations according to the following guidelines:</p>`, 


  `<ol class="skills-list">
  <li>Hosts in VLANs 20, 40, and 60 <b>can</b> ping each other.</li>
  <li>Hosts in VLANs 20, 40, and 60 <b>can</b> visit the external web server <b>www.cisco.com</b>.</li>          
  <li>Hosts in VLAN 40 and 60 can visit the administrative web server <b>www.int.com</b></li>
  <li>Hosts in VLAN 60 <b>can</b> telnet into Atom</li>
    <li>Hosts in VLANs 20 and 40 <b>cannot</b> telnet to <b>Atom</b>.</li>
  </ol>`,
  ],

    
   note: [
          `<h2>NOTE:</h2>`+
          `<ol class="skills-list-note">
              <li><b>Avoid using the browser's Back button or closing/reloading any exam windows during the exam.</b></li>
              <li><b>Do not manually close Packet Tracer; it will shut down on its own when you're finished.</b></li>
              <li><b>Click the "Submit Assessment" button in the browser window to submit your work.</b></li>
            </ol>`,
   ],

   host_Tag:'Hosts Configuration',

   host_124_1: [''],
   host_124_1_Config:[
    `<h2>124-1</h2>`,
    `<p>IPv4 Address: <b>192.168.1.97</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.224</b></p>`+
    `<p>IPv4 Default Gateway: <b>192.168.1.126</b></p>`,
    `<p>IPv6 Address: <b>2001:DB8:ACAD:A::FF/64</b></p>`+
    `<p>IPv6 Default Gateway: <b>FE80::1</b></p>`
   ],

   host_124_5: [''],
   host_124_5_Config:[
    `<h2>124-5</h2>`,
    `<p>IPv4 Address: <b>192.168.1.98</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.224</b></p>`+
    `<p>IPv4 Default Gateway: <b>192.168.1.126</b></p>`,
    `<p>IPv6 Address: <b>2001:DB8:ACAD:A::15/64</b></p>`+
    `<p>IPv6 Default Gateway: <b>FE80::1</b></p>`
   ],

   host_Lab1: [Lab1Img],
   host_Lab1_Config:[
    `<h2>Lab 1</h2>`,
    `<p>IPv4 Address: <b>172.16.20.5</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.0</b></p>`+
    `<p>IPv4 Default Gateway: <b>172.16.20.254</b></p>`+
    `<p>DNS Server: <b>172.16.254.252</b></p>`
   ],

   host_Research1: [Research1Img],
   host_Research1_Config:[
    `<h2>Research 1</h2>`,
    `<p>IPv4 Address: <b>172.16.40.5</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.0</b></p>`+
    `<p>IPv4 Default Gateway: <b>172.16.40.254</b></p>`+
    `<p>DNS Server: <b>172.16.254.252</b></p>`
   ],

   host_Prod1: [Prod1Img],
   host_Prod1_Config:[
    `<h2>Prod 1</h2>`,
    `<p>IPv4 Address: <b>172.16.60.5</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.0</b></p>`+
    `<p>IPv4 Default Gateway: <b>172.16.60.254</b></p>`,
    `<p>IPv4 Default Gateway: <b>172.16.254.252</b></p>`
   ],

   
   host_Lab2: [Lab2Img],
   host_Lab2_Config:[
    `<h2>Lab 2</h2>`,
    `<p>IPv4 Address: <b>172.16.20.10</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.0</b></p>`+
    `<p>IPv4 Default Gateway: <b>172.16.20.254</b></p>`+
    `<p>DNS Server: <b>172.16.254.252</b></p>`
   ],

   host_Research2: [Research2Img],
   host_Research2_Config:[
    `<h2>Research 2</h2>`,
    `<p>IPv4 Address: <b>172.16.40.10</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.0</b></p>`+
    `<p>IPv4 Default Gateway: <b>172.16.40.254</b></p>`+
    `<p>DNS Server: <b>172.16.254.252</b></p>`
   ],

   host_Prod2: [Prod2Img],
   host_Prod2_Config:[
    `<h2>Prod 2</h2>`,
    `<p>IPv4 Address: <b>172.16.60.10</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.0</b></p>`+
    `<p>IPv4 Default Gateway: <b>172.16.60.254</b></p>`+
    `<p>DNS Server: <b>172.16.254.252</b></p>`
   ],

   router:[
    "en",
"config t",

"hostname Atom",
"no ip domain-lookup" ,
"enable secret cisco",

"line console 0",
"password cisco",
"login",
"line vty 0 15",
"password cisco",
"login",
"exit",
"int g0/0.20",
"encapsulation dot1Q 20",
"ip address 172.16.20.254 255.255.255.0",

"int g0/0.40",
"encapsulation dot1Q 40",
"ip address 172.16.40.254 255.255.255.0",
"int g0/0.60",
"encapsulation dot1Q 60",
"ip address 172.16.60.254 255.255.255.0",
"int g0/0.88",
"encapsulation dot1Q 88",
"ip address 172.16.88.254 255.255.255.0",
"int g0/0",
"no shutdown",
"int g0/1.250",
"encapsulation dot1Q 250",
"ip address 172.16.250.254 255.255.255.0",
"int g0/1.254",
"encapsulation dot1Q 254",
"ip address 172.16.254.254 255.255.255.0",

"int g0/1",
"no shutdown", 
"exit",

"access-list 10 permit 172.16.60.0 0.0.0.255",
"ip access-list standard INT-WEB",
"permit 172.16.40.0 0.0.0.255",
"permit 172.16.60.0 0.0.0.255",
"exit",
"line vty 0 15",
"access-class 10 in",
"exit", 

"int g0/1.250",
"ip access-group INT-WEB out",

   ],
   switch1:[
    "en",
"conf t",
"hostname Neutron",
"no ip domain-lookup", 
"enable secret cisco",
"line console 0",
"password cisco",
"login",
"line vty 0 15",
"password cisco",
"login",
"exit", 
"service password-encryption", 
"vlan 20",
"name Lab",
"vlan 40",
"name Research",
"vlan 60",
"name Production",
"vlan 88",
"name NetOps",
"exit",

"int vlan 88",
"ip add 172.16.88.253 255.255.255.0",
"no shutdown", 
"exit", 
"ip default-gateway 172.16.88.254",
"vtp domain School",

"int g0/1",
"switchport mode trunk", 
"int range f0/23-24",
"switchport mode trunk",
   ],

   switch2:[
    "en",
"conf t",
"hostname Proton",
"vlan 20",
"name Lab",
"vlan 40",
"name Research",
"vlan 60",
"name Production",
"vlan 88",
"name NetOps",
"exit",
"int range fastEthernet 0/1-5",
"switchport mode access", 
"switchport access vlan 20",
"int range fastEthernet 0/6-10",
"switchport mode access", 
"switchport access vlan 40",
"int range fastEthernet 0/11-15",
"switchport mode access", 
"switchport access vlan 60",
"exit",
"int vlan 88",
"ip add 172.16.88.252 255.255.255.0",
"no shut",
"no shutdown", 
"exit",
"ip default-gateway 172.16.88.254",
"vtp domain School",
"int f0/23",
"switchport mode trunk", 
   ],

   switch3:[
    "en",
"conf t",
"hostname Electron",
"vlan 20",
"name Lab",
"vlan 40",
"name Research",
"vlan 60",
"name Production",
"vlan 88",
"name NetOps",
"exit",
"int range fa0/1-5",
"switchport mode access", 
"switchport access vlan 20",
"int range fa0/6-10",
"switchport mode access", 
"switchport access vlan 40",
"int range fa0/11-15",
"switchport mode access", 
"switchport access vlan 60",
"exit",
"int vlan 88",
"ip add 172.16.88.251 255.255.255.0",
"no shutdown", 
"exit", 
"ip default-gateway 172.16.88.254",
"vtp domain School",
"int f0/24",
"switchport mode trunk", 
   ],



/*
device2:[
"IP Address: 172.16.5.11",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 172.16.5.1"
],

device3:[
"IP Address: 192.168.5.10",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 192.168.5.1"
],

device4:[
"IP Address: 192.168.5.11",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 192.168.5.1"
]*/
  
  //route:'/ccna/ccna',
    },
    Type_B: {
      image: [TypeBImg],
      title: '',
      description:
        'You might be given One (1) of these Three (3) Topology.',

        routerConfig: [
          `<h2>HQ (Router 1) Configuration</h2>`+
          `<p>To configure router Atom follow these steps:</p>`+
  `<ol class="skills-list-note">
          <li><b>Note check your configuration requirements before copy and paste the following comand in the CLI.</b></li>
          </ol>`
        ], 
        router2Config: [
          `<h2>Bldg-1, Quest or Branch (Router 2) Configuration</h2>`,
          `<p>To configure router Bldg-1, Quest or Branch follow these steps:</p>`+
  `<ol class="skills-list-note">
          <li><b>Note check your configuration requirements before copy and paste the following comand in the CLI.</b></li>
          </ol>`
        ], 
        switch1Config:[
          `<h2>Mgmt Switch Configuration</h2>`
        ],  
  // This switch 2 is in arry
        switch2Config:[
          `<h2>Acct Switch Configuration</h2>`
        ],
        
        switch3Config:[
          `<h2>HR Switch Configuration</h2>`
        ], 
      //  switch2Config:'Admin Switch Configuration', 
      //   endDevice1:'Prof X',
      //   endDevice2:'Prof Y',
      //   endDevice3:'Dean X',
      //   endDevice4:'Dean Y',
  
    tableData: [
      {
        Device: 'Atom',
        Interface: 'G0/0.20',
        Address: '172.16.20.0/24; last usable address in network',
        // SubnetMask: '255.255.255.224',
        // DefaultGateway: 'N/A',
      },
      {
        Device: 'Atom',
        Interface: "G0/0.40",
        Address: '172.16.40.0/24; last usable address in network',
        // SubnetMask: '',
        // DefaultGateway: 'N/A',
      },
      {
        Device: 'Atom',
        Interface: "G0/0.60",
        Address: '172.16.60.0/24; last usable address in network',
        // SubnetMask: '',
        // DefaultGateway: 'N/A',
      },
      {
        Device: 'Atom',
        Interface: 'G0/0.88',
        Address: '172.16.88.0/24; last usable address in network',
      //   SubnetMask: '255.255.255.240',
      //   DefaultGateway: 'N/A',
      },
      {
        Device: 'Atom',
        Interface: "G0/1.250",
        Address: '172.16.250.0/24; last usable address in network',
        SubnetMask: '',
        DefaultGateway: 'N/A',
      },
      {
        Device: 'Atom',
        Interface: "G0/1.254",
        Address: '172.16.254.0/24, last usable address in network',
        SubnetMask: '',
        DefaultGateway: 'N/A',
      },
      {
        Device: 'Neutron',
        Interface: 'SVI',
        Address: '172.16.88.0/24; any available address in network',
      //   SubnetMask: '255.255.255.240',
      //   DefaultGateway: 'N/A',
      },
      {
        Device: 'Proton',
        Interface: "SVI",
        Address: '172.16.88.0/24; any available address in network',
        SubnetMask: '',
        DefaultGateway: 'N/A',
      },
      {
        Device: 'Electron',
        Interface: "SVI",
        Address: '172.16.88.0/24; any available address in network',
        SubnetMask: '',
        DefaultGateway: 'N/A',
      },
      {
        Device: 'Lab 1',
        Interface: "NIC",
        Address: '172.16.20.0/24; any available address in network',
        SubnetMask: '',
        DefaultGateway: '',
      },
      {
        Device: 'Research 1',
        Interface: "NIC",
        Address: '172.16.40.0/24; any available address in network',
        SubnetMask: '',
        DefaultGateway: '',
      },
      {
        Device: 'Prod 1',
        Interface: "NIC",
        Address: '172.16.60.0/24; any available address in network',
        SubnetMask: '',
        DefaultGateway: '',
      },
      {
        Device: 'Lab 2',
        Interface: 'NIC',
        Address: '172.16.20.0/24; any available address in network',
        SubnetMask: '',
        DefaultGateway: '',
      },
      {
        Device: 'Research 2',
        Interface: 'NIC',
        Address: '172.16.40.0/24; any available address in network',
        SubnetMask: '',
        DefaultGateway: '',
      },
      {
        Device: 'Prod 2',
        Interface: 'NIC',
        Address: '172.16.60.0/24, any available address in network',
        SubnetMask: '',
        DefaultGateway: '',
      },
      {
        Device: 'DNS Server',
        Interface: 'NIC',
        Address: '172.16.254.252',
        SubnetMask: '',
        DefaultGateway: '',
      },
  
    ],
  
    vlantable:[
      `<h2>VLAN Assignment Table</h2>`,
      `<table class="addressing-table">
    <thead>
        <tr>
            <th>VLAN Number</th>
            <th>VLAN Name</th>
            <th>Device/Ports</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>20</td>
            <td>Lab</td>
            <td>Proton: Fa0/1 - Fa0/5 </br>Electron: Fa0/1 - Fa0/5</td>
  
        </tr>
        <tr>
            <td>40</td>
            <td>Research</td>
            <td>Proton: Fa0/6 - Fa0/10 </br>Electron: Fa0/6 - Fa0/10</td>
        </tr>
        <tr>
            <td>60</td>
            <td>Production</td>
            <td>Proton: Fa0/11 - Fa0/15</br> Electron: Fa0/11 - Fa0/15</td>
        </tr>
        <tr>
            <td>88</td>
            <td>NetOps</td>
            <td>Neutron: SVI </br>Proton: SVI</br> Electron: SVI</td>
        </tr>
    </tbody>
  </table>`,
  
    ],
  
      
     note: [
            `<h2>NOTE:</h2>`+
            `<ol class="skills-list-note">
                <li><b>Avoid using the browser's Back button or closing/reloading any exam windows during the exam.</b></li>
                <li><b>Do not manually close Packet Tracer; it will shut down on its own when you're finished.</b></li>
                <li><b>Click the "Submit Assessment" button in the browser window to submit your work.</b></li>
              </ol>`,
     ],
  
     host_Tag:'Hosts Configuration',
  
     host_124_1: [''],
     host_124_1_Config:[
      `<h2>124-1</h2>`,
      `<p>IPv4 Address: <b>192.168.1.97</b></p>`+
      `<p>IPv4 Subnet Mask: <b>255.255.255.224</b></p>`+
      `<p>IPv4 Default Gateway: <b>192.168.1.126</b></p>`,
      `<p>IPv6 Address: <b>2001:DB8:ACAD:A::FF/64</b></p>`+
      `<p>IPv6 Default Gateway: <b>FE80::1</b></p>`
     ],
  
     host_124_5: [''],
     host_124_5_Config:[
      `<h2>124-5</h2>`,
      `<p>IPv4 Address: <b>192.168.1.98</b></p>`+
      `<p>IPv4 Subnet Mask: <b>255.255.255.224</b></p>`+
      `<p>IPv4 Default Gateway: <b>192.168.1.126</b></p>`,
      `<p>IPv6 Address: <b>2001:DB8:ACAD:A::15/64</b></p>`+
      `<p>IPv6 Default Gateway: <b>FE80::1</b></p>`
     ],
  
     host_Lab1: [Lab1Img],
     host_Lab1_Config:[
      `<h2>Clerical A</h2>`,
      `<p>IPv4 Address: <b>172.16.20.5</b></p>`+
      `<p>IPv4 Subnet Mask: <b>255.255.255.0</b></p>`+
      `<p>IPv4 Default Gateway: <b>172.16.20.254</b></p>`+
      `<p>DNS Server: <b>172.16.254.252</b></p>`
     ],
  
     host_Research1: [Research1Img],
     host_Research1_Config:[
      `<h2>Acct A</h2>`,
      `<p>IPv4 Address: <b>172.16.40.5</b></p>`+
      `<p>IPv4 Subnet Mask: <b>255.255.255.0</b></p>`+
      `<p>IPv4 Default Gateway: <b>172.16.40.254</b></p>`+
      `<p>DNS Server: <b>172.16.254.252</b></p>`
     ],
  
     host_Prod1: [Prod1Img],
     host_Prod1_Config:[
      `<h2>HR A</h2>`,
      `<p>IPv4 Address: <b>172.16.60.5</b></p>`+
      `<p>IPv4 Subnet Mask: <b>255.255.255.0</b></p>`+
      `<p>IPv4 Default Gateway: <b>172.16.60.254</b></p>`,
      `<p>IPv4 Default Gateway: <b>172.16.254.252</b></p>`
     ],
  
     
     host_Lab2: [Lab2Img],
     host_Lab2_Config:[
      `<h2>Clerical B</h2>`,
      `<p>IPv4 Address: <b>172.16.20.10</b></p>`+
      `<p>IPv4 Subnet Mask: <b>255.255.255.0</b></p>`+
      `<p>IPv4 Default Gateway: <b>172.16.20.254</b></p>`+
      `<p>DNS Server: <b>172.16.254.252</b></p>`
     ],
  
     host_Research2: [Research2Img],
     host_Research2_Config:[
      `<h2>Acct B</h2>`,
      `<p>IPv4 Address: <b>172.16.40.10</b></p>`+
      `<p>IPv4 Subnet Mask: <b>255.255.255.0</b></p>`+
      `<p>IPv4 Default Gateway: <b>172.16.40.254</b></p>`+
      `<p>DNS Server: <b>172.16.254.252</b></p>`
     ],
  
     host_Prod2: [Prod2Img],
     host_Prod2_Config:[
      `<h2>HR B</h2>`,
      `<p>IPv4 Address: <b>172.16.60.10</b></p>`+
      `<p>IPv4 Subnet Mask: <b>255.255.255.0</b></p>`+
      `<p>IPv4 Default Gateway: <b>172.16.60.254</b></p>`+
      `<p>DNS Server: <b>172.16.254.252</b></p>`
     ],
  
     router:[
      "en",
  "config t",
  
  "hostname HQ",
  "no ip domain-lookup" ,
  "enable secret cisco",
  
  "line console 0",
  "password cisco",
  "login",
  "line vty 0 15",
  "password cisco",
  "login",
  "exit",
  "int g0/0.20",
  "encapsulation dot1Q 20",
  "ip address 172.16.20.254 255.255.255.0",
  
  "int g0/0.40",
  "encapsulation dot1Q 40",
  "ip address 172.16.40.254 255.255.255.0",
  "int g0/0.60",
  "encapsulation dot1Q 60",
  "ip address 172.16.60.254 255.255.255.0",
  "int g0/0.88",
  "encapsulation dot1Q 88",
  "ip address 172.16.88.254 255.255.255.0",
  "int g0/0",
  "no shutdown",
  "int g0/1.250",
  "encapsulation dot1Q 250",
  "ip address 172.16.250.254 255.255.255.0",
  "int g0/1.254",
  "encapsulation dot1Q 254",
  "ip address 172.16.254.254 255.255.255.0",
  
  "int g0/1",
  "no shutdown", 
  "exit",
  
  "access-list 10 permit 172.16.60.0 0.0.0.255",
  "ip access-list standard INT-WEB",
  "permit 172.16.40.0 0.0.0.255",
  "permit 172.16.60.0 0.0.0.255",
  "exit",
  "line vty 0 15",
  "access-class 10 in",
  "exit", 
  
  "int g0/1.250",
  "ip access-group INT-WEB out",
  
     ],
     switch1:[
      "en",
  "conf t",
  "hostname Mgmt",
  "no ip domain-lookup", 
  "enable secret cisco",
  "line console 0",
  "password cisco",
  "login",
  "line vty 0 15",
  "password cisco",
  "login",
  "exit", 
  "service password-encryption", 
  "vlan 20",
  "name Clerical",
  "vlan 40",
  "name Acct",
  "vlan 60",
  "name HR",
  "vlan 88",
  "name NetAdmin",
  "exit",
  
  "int vlan 88",
  "ip add 172.16.88.253 255.255.255.0",
  "no shutdown", 
  "exit", 
  "ip default-gateway 172.16.88.254",
  "vtp domain School",
  
  "int g0/1",
  "switchport mode trunk", 
  "int range f0/23-24",
  "switchport mode trunk",
     ],
  
     switch2:[
      "en",
  "conf t",
  "hostname Acct",
  "vlan 20",
  "name Clerical",
  "vlan 40",
  "name Acct",
  "vlan 60",
  "name HR",
  "vlan 88",
  "name NetAdmin",
  "exit",
  "int range fastEthernet 0/1-5",
  "switchport mode access", 
  "switchport access vlan 20",
  "int range fastEthernet 0/6-10",
  "switchport mode access", 
  "switchport access vlan 40",
  "int range fastEthernet 0/11-15",
  "switchport mode access", 
  "switchport access vlan 60",
  "exit",
  "int vlan 88",
  "ip add 172.16.88.252 255.255.255.0",
  "no shut",
  "no shutdown", 
  "exit",
  "ip default-gateway 172.16.88.254",
  "vtp domain School",
  "int f0/23",
  "switchport mode trunk", 
     ],
  
     switch3:[
      "en",
  "conf t",
  "hostname HR",
  "vlan 20",
  "name Clerical",
  "vlan 40",
  "name Acct",
  "vlan 60",
  "name HR",
  "vlan 88",
  "name NetAdmin",
  "exit",
  "int range fa0/1-5",
  "switchport mode access", 
  "switchport access vlan 20",
  "int range fa0/6-10",
  "switchport mode access", 
  "switchport access vlan 40",
  "int range fa0/11-15",
  "switchport mode access", 
  "switchport access vlan 60",
  "exit",
  "int vlan 88",
  "ip add 172.16.88.251 255.255.255.0",
  "no shutdown", 
  "exit", 
  "ip default-gateway 172.16.88.254",
  "vtp domain School",
  "int f0/24",
  "switchport mode trunk", 
     ],
  
/*
device1:[
"IP Address: 172.16.5.10",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 172.16.5.1"
   ],

device2:[
"IP Address: 172.16.5.11",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 172.16.5.1"
],

device3:[
"IP Address: 192.168.5.10",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 192.168.5.1"
],

device4:[
"IP Address: 192.168.5.11",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 192.168.5.1"
]*/
  
  //route:'/ccna/ccna',
    },

    Type_C: {
      image: [TypeCImg],
      title: '',
      description:
        'You might be given One (1) of these Three (3) Topology.',

        routerConfig: [
          `<h2>Main (Router 1) Configuration</h2>`+
          `<p>To configure router Atom follow these steps:</p>`+
  `<ol class="skills-list-note">
          <li><b>Note check your configuration requirements before copy and paste the following comand in the CLI.</b></li>
          </ol>`
        ], 
        router2Config: [
          `<h2>, Quest or Branch (Router 2) Configuration</h2>`,
          `<p>To configure router Bldg-1, Quest or Branch follow these steps:</p>`+
  `<ol class="skills-list-note">
          <li><b>Note check your configuration requirements before copy and paste the following comand in the CLI.</b></li>
          </ol>`
        ], 
        switch1Config:[
          `<h2>ArtBldg Switch Configuration</h2>`
        ],  
  // This switch 2 is in arry
        switch2Config:[
          `<h2>1stF Switch Configuration</h2>`
        ],
        
        switch3Config:[
          `<h2>2ndF Switch Configuration</h2>`
        ], 
      //  switch2Config:'Admin Switch Configuration', 
      //   endDevice1:'Prof X',
      //   endDevice2:'Prof Y',
      //   endDevice3:'Dean X',
      //   endDevice4:'Dean Y',
  
    tableData: [
      {
        Device: 'Atom',
        Interface: 'G0/0.20',
        Address: '172.16.20.0/24; last usable address in network',
        // SubnetMask: '255.255.255.224',
        // DefaultGateway: 'N/A',
      },
      {
        Device: 'Atom',
        Interface: "G0/0.40",
        Address: '172.16.40.0/24; last usable address in network',
        // SubnetMask: '',
        // DefaultGateway: 'N/A',
      },
      {
        Device: 'Atom',
        Interface: "G0/0.60",
        Address: '172.16.60.0/24; last usable address in network',
        // SubnetMask: '',
        // DefaultGateway: 'N/A',
      },
      {
        Device: 'Atom',
        Interface: 'G0/0.88',
        Address: '172.16.88.0/24; last usable address in network',
      //   SubnetMask: '255.255.255.240',
      //   DefaultGateway: 'N/A',
      },
      {
        Device: 'Atom',
        Interface: "G0/1.250",
        Address: '172.16.250.0/24; last usable address in network',
        SubnetMask: '',
        DefaultGateway: 'N/A',
      },
      {
        Device: 'Atom',
        Interface: "G0/1.254",
        Address: '172.16.254.0/24, last usable address in network',
        SubnetMask: '',
        DefaultGateway: 'N/A',
      },
      {
        Device: 'Neutron',
        Interface: 'SVI',
        Address: '172.16.88.0/24; any available address in network',
      //   SubnetMask: '255.255.255.240',
      //   DefaultGateway: 'N/A',
      },
      {
        Device: 'Proton',
        Interface: "SVI",
        Address: '172.16.88.0/24; any available address in network',
        SubnetMask: '',
        DefaultGateway: 'N/A',
      },
      {
        Device: 'Electron',
        Interface: "SVI",
        Address: '172.16.88.0/24; any available address in network',
        SubnetMask: '',
        DefaultGateway: 'N/A',
      },
      {
        Device: 'Lab 1',
        Interface: "NIC",
        Address: '172.16.20.0/24; any available address in network',
        SubnetMask: '',
        DefaultGateway: '',
      },
      {
        Device: 'Research 1',
        Interface: "NIC",
        Address: '172.16.40.0/24; any available address in network',
        SubnetMask: '',
        DefaultGateway: '',
      },
      {
        Device: 'Prod 1',
        Interface: "NIC",
        Address: '172.16.60.0/24; any available address in network',
        SubnetMask: '',
        DefaultGateway: '',
      },
      {
        Device: 'Lab 2',
        Interface: 'NIC',
        Address: '172.16.20.0/24; any available address in network',
        SubnetMask: '',
        DefaultGateway: '',
      },
      {
        Device: 'Research 2',
        Interface: 'NIC',
        Address: '172.16.40.0/24; any available address in network',
        SubnetMask: '',
        DefaultGateway: '',
      },
      {
        Device: 'Prod 2',
        Interface: 'NIC',
        Address: '172.16.60.0/24, any available address in network',
        SubnetMask: '',
        DefaultGateway: '',
      },
      {
        Device: 'DNS Server',
        Interface: 'NIC',
        Address: '172.16.254.252',
        SubnetMask: '',
        DefaultGateway: '',
      },
  
    ],
  
    vlantable:[
      `<h2>VLAN Assignment Table</h2>`,
      `<table class="addressing-table">
    <thead>
        <tr>
            <th>VLAN Number</th>
            <th>VLAN Name</th>
            <th>Device/Ports</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>20</td>
            <td>Lab</td>
            <td>Proton: Fa0/1 - Fa0/5 </br>Electron: Fa0/1 - Fa0/5</td>
  
        </tr>
        <tr>
            <td>40</td>
            <td>Research</td>
            <td>Proton: Fa0/6 - Fa0/10 </br>Electron: Fa0/6 - Fa0/10</td>
        </tr>
        <tr>
            <td>60</td>
            <td>Production</td>
            <td>Proton: Fa0/11 - Fa0/15</br> Electron: Fa0/11 - Fa0/15</td>
        </tr>
        <tr>
            <td>88</td>
            <td>NetOps</td>
            <td>Neutron: SVI </br>Proton: SVI</br> Electron: SVI</td>
        </tr>
    </tbody>
  </table>`,
  
    ],
  
      
     note: [
            `<h2>NOTE:</h2>`+
            `<ol class="skills-list-note">
                <li><b>Avoid using the browser's Back button or closing/reloading any exam windows during the exam.</b></li>
                <li><b>Do not manually close Packet Tracer; it will shut down on its own when you're finished.</b></li>
                <li><b>Click the "Submit Assessment" button in the browser window to submit your work.</b></li>
              </ol>`,
     ],
  
     host_Tag:'Hosts Configuration',
  
     host_124_1: [''],
     host_124_1_Config:[
      `<h2>124-1</h2>`,
      `<p>IPv4 Address: <b>192.168.1.97</b></p>`+
      `<p>IPv4 Subnet Mask: <b>255.255.255.224</b></p>`+
      `<p>IPv4 Default Gateway: <b>192.168.1.126</b></p>`,
      `<p>IPv6 Address: <b>2001:DB8:ACAD:A::FF/64</b></p>`+
      `<p>IPv6 Default Gateway: <b>FE80::1</b></p>`
     ],
  
     host_124_5: [''],
     host_124_5_Config:[
      `<h2>124-5</h2>`,
      `<p>IPv4 Address: <b>192.168.1.98</b></p>`+
      `<p>IPv4 Subnet Mask: <b>255.255.255.224</b></p>`+
      `<p>IPv4 Default Gateway: <b>192.168.1.126</b></p>`,
      `<p>IPv6 Address: <b>2001:DB8:ACAD:A::15/64</b></p>`+
      `<p>IPv6 Default Gateway: <b>FE80::1</b></p>`
     ],
  
     host_Lab1: [Lab1Img],
     host_Lab1_Config:[
      `<h2>Student 1</h2>`,
      `<p>IPv4 Address: <b>172.16.20.5</b></p>`+
      `<p>IPv4 Subnet Mask: <b>255.255.255.0</b></p>`+
      `<p>IPv4 Default Gateway: <b>172.16.20.254</b></p>`+
      `<p>DNS Server: <b>172.16.254.252</b></p>`
     ],
  
     host_Research1: [Research1Img],
     host_Research1_Config:[
      `<h2>Faculty 1</h2>`,
      `<p>IPv4 Address: <b>172.16.40.5</b></p>`+
      `<p>IPv4 Subnet Mask: <b>255.255.255.0</b></p>`+
      `<p>IPv4 Default Gateway: <b>172.16.40.254</b></p>`+
      `<p>DNS Server: <b>172.16.254.252</b></p>`
     ],
  
     host_Prod1: [Prod1Img],
     host_Prod1_Config:[
      `<h2>Admin 1</h2>`,
      `<p>IPv4 Address: <b>172.16.60.5</b></p>`+
      `<p>IPv4 Subnet Mask: <b>255.255.255.0</b></p>`+
      `<p>IPv4 Default Gateway: <b>172.16.60.254</b></p>`,
      `<p>IPv4 Default Gateway: <b>172.16.254.252</b></p>`
     ],
  
     
     host_Lab2: [Lab2Img],
     host_Lab2_Config:[
      `<h2>Student 2</h2>`,
      `<p>IPv4 Address: <b>172.16.20.10</b></p>`+
      `<p>IPv4 Subnet Mask: <b>255.255.255.0</b></p>`+
      `<p>IPv4 Default Gateway: <b>172.16.20.254</b></p>`+
      `<p>DNS Server: <b>172.16.254.252</b></p>`
     ],
  
     host_Research2: [Research2Img],
     host_Research2_Config:[
      `<h2>Faculty 2</h2>`,
      `<p>IPv4 Address: <b>172.16.40.10</b></p>`+
      `<p>IPv4 Subnet Mask: <b>255.255.255.0</b></p>`+
      `<p>IPv4 Default Gateway: <b>172.16.40.254</b></p>`+
      `<p>DNS Server: <b>172.16.254.252</b></p>`
     ],
  
     host_Prod2: [Prod2Img],
     host_Prod2_Config:[
      `<h2>Admin 2</h2>`,
      `<p>IPv4 Address: <b>172.16.60.10</b></p>`+
      `<p>IPv4 Subnet Mask: <b>255.255.255.0</b></p>`+
      `<p>IPv4 Default Gateway: <b>172.16.60.254</b></p>`+
      `<p>DNS Server: <b>172.16.254.252</b></p>`
     ],
  
     router:[
      "en",
  "config t",
  
  "hostname Main",
  "no ip domain-lookup" ,
  "enable secret cisco",
  
  "line console 0",
  "password cisco",
  "login",
  "line vty 0 15",
  "password cisco",
  "login",
  "exit",
  "int g0/0.20",
  "encapsulation dot1Q 20",
  "ip address 172.16.20.254 255.255.255.0",
  
  "int g0/0.40",
  "encapsulation dot1Q 40",
  "ip address 172.16.40.254 255.255.255.0",
  "int g0/0.60",
  "encapsulation dot1Q 60",
  "ip address 172.16.60.254 255.255.255.0",
  "int g0/0.88",
  "encapsulation dot1Q 88",
  "ip address 172.16.88.254 255.255.255.0",
  "int g0/0",
  "no shutdown",
  "int g0/1.250",
  "encapsulation dot1Q 250",
  "ip address 172.16.250.254 255.255.255.0",
  "int g0/1.254",
  "encapsulation dot1Q 254",
  "ip address 172.16.254.254 255.255.255.0",
  
  "int g0/1",
  "no shutdown", 
  "exit",
  
  "access-list 10 permit 172.16.60.0 0.0.0.255",
  "ip access-list standard INT-WEB",
  "permit 172.16.40.0 0.0.0.255",
  "permit 172.16.60.0 0.0.0.255",
  "exit",
  "line vty 0 15",
  "access-class 10 in",
  "exit", 
  
  "int g0/1.250",
  "ip access-group INT-WEB out",
  
     ],
     switch1:[
      "en",
  "conf t",
  "hostname ArtBldg",
  "no ip domain-lookup", 
  "enable secret cisco",
  "line console 0",
  "password cisco",
  "login",
  "line vty 0 15",
  "password cisco",
  "login",
  "exit", 
  "service password-encryption", 
  "vlan 20",
  "name Student",
  "vlan 40",
  "name Faculty",
  "vlan 60",
  "name Admin",
  "vlan 88",
  "name Management",
  "exit",
  
  "int vlan 88",
  "ip add 172.16.88.253 255.255.255.0",
  "no shutdown", 
  "exit", 
  "ip default-gateway 172.16.88.254",
  "vtp domain School",
  
  "int g0/1",
  "switchport mode trunk", 
  "int range f0/23-24",
  "switchport mode trunk",
     ],
  
     switch2:[
      "en",
  "conf t",
  "hostname 1stF",
  "vlan 20",
  "name Student",
  "vlan 40",
  "name Faculty",
  "vlan 60",
  "name Admin",
  "vlan 88",
  "name Management",
  "exit",
  "int range fastEthernet 0/1-5",
  "switchport mode access", 
  "switchport access vlan 20",
  "int range fastEthernet 0/6-10",
  "switchport mode access", 
  "switchport access vlan 40",
  "int range fastEthernet 0/11-15",
  "switchport mode access", 
  "switchport access vlan 60",
  "exit",
  "int vlan 88",
  "ip add 172.16.88.252 255.255.255.0",
  "no shut",
  "no shutdown", 
  "exit",
  "ip default-gateway 172.16.88.254",
  "vtp domain School",
  "int f0/23",
  "switchport mode trunk", 
     ],
  
     switch3:[
      "en",
  "conf t",
  "hostname 2ndF",
  "vlan 20",
  "name Student",
  "vlan 40",
  "name Faculty",
  "vlan 60",
  "name Admin",
  "vlan 88",
  "name Management",
  "exit",
  "int range fa0/1-5",
  "switchport mode access", 
  "switchport access vlan 20",
  "int range fa0/6-10",
  "switchport mode access", 
  "switchport access vlan 40",
  "int range fa0/11-15",
  "switchport mode access", 
  "switchport access vlan 60",
  "exit",
  "int vlan 88",
  "ip add 172.16.88.251 255.255.255.0",
  "no shutdown", 
  "exit", 
  "ip default-gateway 172.16.88.254",
  "vtp domain School",
  "int f0/24",
  "switchport mode trunk", 
     ],
  
/*
device1:[
"IP Address: 172.16.5.10",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 172.16.5.1"
   ],

device2:[
"IP Address: 172.16.5.11",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 172.16.5.1"
],

device3:[
"IP Address: 192.168.5.10",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 192.168.5.1"
],

device4:[
"IP Address: 192.168.5.11",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 192.168.5.1"
]*/
  
  //route:'/ccna/ccna',
    },


}