import React from 'react';
import ReactDOM from 'react-dom/client';
//import './i18n';
import './index.css';
//import './components/Settings/Language/i18n';
import i18n from './components/Settings/i18n';
//import App from './App';
//import './components/Settings/i18n'; // Correct path to i18n.js

import Routepath from './components/route-paths';
import { I18nextProvider } from 'react-i18next';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <I18nextProvider i18n={i18n}>
    < Routepath/>
    </I18nextProvider>
  // </React.StrictMode>
);


