import React, { useMemo, useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from "react-table";
import { CCNA_COMMAND_DATA } from "./CCNA1/ccnaData";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  InputBase,
  IconButton,
  Paper,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import NavigationBar from "./CCNA2/NavigationBar";

const links = ['Home', 'Courses', 'Calculator'];
const chapters = [];

const highlightText = (text, term) => {
  if (!term) return text;
  const regex = new RegExp(`(${term})`, 'gi');
  const parts = text.split(regex);
  return parts.map((part, index) =>
    part.toLowerCase() === term.toLowerCase()
      ? <mark key={index} style={{ backgroundColor: 'yellow', color: 'black' }}>{part}</mark>
      : part
  );
};

export default function CcnaCommand({ showNav = true, searchTerm }) {
  const [loading, setLoading] = useState(true);
  const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm);

  <div>
  <Helmet>
    <title>Cisco Codes and Commands for Switches, Routers, Firewalls - Alpha Centauri Inc.</title>
    <meta
      name="description"
      content="Explore essential Cisco commands for routers, switches, firewalls, and other devices. Learn how to configure and troubleshoot with real-world examples and command syntax. Get the most comprehensive Cisco command reference at Alpha Centauri Inc."
    />
    <meta
      name="keywords"
      content="Cisco commands, Cisco switch commands, router commands, firewall commands, CCNA, CCNP, Cisco device configuration, network commands, Cisco CLI, command syntax, network troubleshooting"
    />
  </Helmet>
      {/* Page content */}
      <h1>Cisco Codes and Commands</h1>
    <p>Learn the most important Cisco commands for configuring and managing routers, switches, firewalls, and other Cisco devices. Whether you're preparing for your CCNA or CCNP exams, or just need a reliable reference for network management, this guide provides real-world examples and usage of essential Cisco commands.</p>
    {/* Add more content here */}
  </div>

  const data = useMemo(() => {
    const fetchedData = CCNA_COMMAND_DATA.flatMap(section =>
      section.commands.map(command => ({
        section: section.section,
        ...command
      }))
    );
    setLoading(false);
    return fetchedData;
  }, []);

  const columns = useMemo(() => [
    { Header: "Section", accessor: "section", sortType: "basic" },
    { Header: "Command", accessor: "command" },
    { Header: "Description", accessor: "description" },
    { Header: "Example", accessor: "example" },
  ], []);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { sortBy: [{ id: "section", desc: false }], pageSize: 50 },
      globalFilter: localSearchTerm,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    state: { pageIndex, pageSize },
    gotoPage,
    setPageSize,
  } = tableInstance;

  useEffect(() => {
    setLocalSearchTerm(searchTerm);
    setGlobalFilter(searchTerm);
  }, [searchTerm, setGlobalFilter]);

  const handleInputChange = (event) => {
    if (event && event.target) {
      setLocalSearchTerm(event.target.value);
      setGlobalFilter(event.target.value);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setGlobalFilter(localSearchTerm);
    }
  };

  return (
    <div>
      {showNav && (
        <NavigationBar 
          links={links} 
          chapters={chapters} 
          onSearch={setGlobalFilter}  
          searchTerm={localSearchTerm} 
          clearSearch={() => setLocalSearchTerm('')} 
          hasChapters={chapters.length > 0} 
        />  
      )}
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#121212' }}>
        <div style={
          {marginBottom:"20px"}
        }>
        <h1>Cisco Codes and Commands</h1>
    <p>Learn the most important Cisco commands for configuring and managing routers, switches, firewalls, and other Cisco devices. Whether you're preparing for your CCNA or CCNP exams, or just need a reliable reference for network management, this guide provides real-world examples and usage of essential Cisco commands.</p>

        </div>
        {/* <Box sx={{ position: 'relative', mb: 2, width: '100%', maxWidth: 400 }}>
          <InputBase
            sx={{
              marginLeft: '60px',
              ml: 1,
              flex: 1,
              color: '#fff',
              '& input': {
                paddingRight: '40px',
                border: 'none',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                borderRadius: '4px',
              },
            }}
            placeholder="Search Commands"
            inputProps={{ 'aria-label': 'search commands' }}
            value={localSearchTerm}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
          />
          <IconButton
            type="button"
            sx={{ p: '10px', position: 'absolute', right: 0 }}
            aria-label="Search"
            onClick={() => setGlobalFilter(localSearchTerm)}
          >
            <SearchIcon sx={{ color: 'white' }} />
          </IconButton>
        </Box> */}
        {loading ? (
          <div className="skeleton-table"></div>
        ) : (
          <TableContainer component={Paper} sx={{ width: '100%', overflowX: 'auto', backgroundColor: '#1e1e1e' }}>
            <Table {...getTableProps()} sx={{ '& thead th': {backgroundColor: 'rgba(31, 38, 135, 0.37)'}, color: '#fff' }}>
              <TableHead>
                {headerGroups.map(headerGroup => (
                  <TableRow {...headerGroup.getHeaderGroupProps()} sx={{ backgroundColor: '#2a2a2a' }}>
                    {headerGroup.headers.map(column => (
                      <TableCell
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        sx={{
                          color: '#fff',
                          borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
                        }}
                      >
                        <TableSortLabel
                          active={column.isSorted}
                          direction={column.isSortedDesc ? 'desc' : 'asc'}
                          sx={{ color: '#fff' }}
                        >
                          {column.render('Header')}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <TableRow {...row.getRowProps()} style={{ backgroundColor: i % 2 === 0 ? "#2a2a2a" : "#1e1e1e" }}>
                      {row.cells.map(cell => (
                        <TableCell
                          {...cell.getCellProps()}
                          sx={{
                            padding: { xs: '4px', sm: '10px' },
                            fontSize: { xs: '0.8rem', sm: '1rem' },
                            color: '#fff',
                          }}
                        >
                          {highlightText(cell.value, localSearchTerm)}
                          {/* {cell.column.id === 'command' || cell.column.id === 'description' || cell.column.id === 'example'
                            ? <span>{highlightText(cell.value, localSearchTerm)}</span>
                            : cell.render('Cell')} */}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[50, 100, 150, 200]}
              component="div"
              count={data.length}
              rowsPerPage={pageSize}
              page={pageIndex}
              onPageChange={(event, newPage) => gotoPage(newPage)}
              onRowsPerPageChange={event => {
                setPageSize(Number(event.target.value));
                gotoPage(0);
              }}
              sx={{ color: '#fff' }}
            />
          </TableContainer>
        )}
      </Box>
    </div>
  );
}
