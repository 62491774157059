import React, { useRef, useEffect } from 'react';

const WatermarkedImagePT = ({ src, watermarkText, style }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const loadImage = (url, callback) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous'; // To handle cross-origin images
      img.onload = () => callback(img);
      img.src = url;
    };

    loadImage(src, (image) => {
      // Set canvas dimensions based on the image size
      canvas.width = image.width;
      canvas.height = image.height;

      // Draw the image on the canvas
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

      // Add watermark
      ctx.font = 'bold 48px Arial';
      ctx.fillStyle = 'rgba(5, 55, 25, 0.1)'; // Semi-transparent white
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';

      // Watermark position
      const x = canvas.width / 2;
      const y = canvas.height / 2;
      ctx.fillText(watermarkText, x, y);
    });
  }, [src, watermarkText]);

  return <canvas ref={canvasRef} style={{ ...style, maxWidth: '100%', height: 'auto' }} />;
};

export default WatermarkedImagePT;
