// src/components/PopularCourses.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { usePageViews } from './PageViewContext';
import CourseCard from './CourseCard';

const PopularCourses = () => {
    const { courses } = usePageViews();
    const navigate = useNavigate();

    if (courses.length === 0) {
        return <div>Loading courses...</div>;
    }

    const handleNavigation = (courseId) => {
        const courseToNavigate = courses.find((course) => course.id === courseId);
        if (courseToNavigate) {
            navigate(courseToNavigate.route);
        }
    };

    const popularCourses = [...courses].sort((a, b) => b.visits - a.visits).slice(0, 3);

    return (
        <div className="popular-courses">
            <h2>Most Popular Courses</h2>
            <div className="courses-container">
                {popularCourses.map((course) => (
                    <CourseCard
                        key={course.id}
                        course={course}
                        onNavigate={handleNavigation}
                    />
                ))}
            </div>
            <button onClick={() => navigate('/addcourse')}>Add New Course</button>
        </div>
    );
};

export default PopularCourses;