// ChapterNavigation.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GrNext } from 'react-icons/gr';
import './ChapterNavigation.css';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Grid2, Select , FormControl, InputLabel} from '@mui/material';
import CoronavirusRoundedIcon from '@mui/icons-material/CoronavirusRounded';
import Stack from '@mui/material/Stack';


const ChapterNavigation = ({ chapters, currentChapter, handleChapterChange, handleNextChapter, handlePreviousChapter }) => {
  const { t } = useTranslation('ccna1');
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (

    <AppBar sx={{ 
      background: 'rgba(17, 14, 14, 0.75)', 
      boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)', 
      backdropFilter: 'blur(2px)', 
      WebkitBackdropFilter: 'blur(2px)', 
      borderRadius: '10px', 
      border: '1px solid rgba(255, 255, 255, 0.18)', 
      position: 'static', 
      width: '100%'
    }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          <Button variant="outlined" onClick={handlePreviousChapter}
            disabled={!currentChapter || currentChapter.key === 'chapter1'}
            style={{
              cursor: !currentChapter || currentChapter.key === 'chapter1' ?  'cursor':'not-allowed',
            }}>
          {t('Previous')}
          </Button>

          
          
          <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
            <InputLabel id="chapter-select-label"
            sx={{  color: '#1976d2',
              // '&.MuiInputLabel-shrink': {
              //   // background: 'rgba(17, 14, 14, 0.75)',
              //   padding: '0 8px', // Adjust this value to increase/decrease space
              // },
            }}>{t('Chapters')}
            
            </InputLabel>
            <Select
              labelId="chapter-select-label"
              id="chapter-select"
              value={currentChapter ? currentChapter.path : ''}
              label={t('Chapters')}
              onChange={handleChapterChange}
              sx={{
                color: '#1976d2',
                '& .MuiSvgIcon-root': {
                  color: '#1976d2',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#1976d2',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#1976d2',
                },

              }}

              MenuProps={{
                PaperProps: {
                  sx: {
                    background: 'rgba(17, 14, 14, 0.75)',
                    boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
                    backdropFilter: 'blur(2px)',
                    WebkitBackdropFilter: 'blur(2px)',
                    borderRadius: '10px',
                    border: '1px solid rgba(255, 255, 255, 0.18)',
                    '& .MuiMenuItem-root': {
                      color: '#1976d2',
                    },
                  },
                },
              }}
            >
              {chapters.map((chapter, index) => (
                <MenuItem key={index} value={chapter.path}>
                  {chapter.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          
          <Button variant="outlined" onClick={handleNextChapter}
          disabled={!currentChapter || currentChapter.key === chapters[chapters.length - 1].key}>
            {t('Next')}
          </Button>
    {/* </Stack> */}     
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ChapterNavigation;
