// src/components/courses/courses/ccna/Calculator/decimal_converter.js 

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Container, Button, Dialog, DialogContent, Alert, AlertTitle, Typography, Grid } from '@mui/material';
import NavigationBar from '../CCNA2/NavigationBar'; // Adjust path as needed
import Utilities from '../../../utilities/utilities';
import { Helmet } from 'react-helmet';

// Convert decimal to binary (improved handling for floating-point numbers)
const decimalToBinary = (decimal) => {
  if (decimal % 1 === 0) {
    return decimal.toString(2); // integer part
  } else {
    let [integerPart, decimalPart] = decimal.toString().split('.');
    let binaryInteger = parseInt(integerPart).toString(2); // integer part to binary
    let binaryDecimal = '.';
    let fraction = parseFloat('0.' + decimalPart);
    
    // Convert decimal part to binary with high precision (up to 32 bits)
    for (let i = 0; i < 32 && fraction !== 0; i++) {
      fraction *= 2;
      binaryDecimal += Math.floor(fraction);
      fraction -= Math.floor(fraction);
    }
    return binaryInteger + binaryDecimal;
  }
};

// Convert decimal to octal
const decimalToOctal = (decimal) => {
  return decimal.toString(8);
};

// Convert decimal to hexadecimal
const decimalToHexadecimal = (decimal) => {
  return decimal.toString(16).toUpperCase();
};

// Convert decimal to IPv4 dotted decimal (integer input only)
const decimalToIPv4 = (decimal) => {
  // Ensure we are working with an integer
  if (!Number.isInteger(decimal) || decimal < 0 || decimal > 4294967295) {
    return 'Invalid IP';
  }
  const binary = decimalToBinary(decimal).padStart(32, '0');
  const octets = [];
  for (let i = 0; i < 32; i += 8) {
    octets.push(parseInt(binary.slice(i, i + 8), 2));
  }
  return octets.join('.');
};

// Convert decimal to IPv6 (first 128 bits)
const decimalToIPv6 = (decimal) => {
  const binary = decimalToBinary(decimal).padStart(128, '0');
  const parts = [];
  for (let i = 0; i < 128; i += 16) {
    const part = binary.slice(i, i + 16);
    parts.push(parseInt(part, 2).toString(16));
  }
  return parts.join(':');
};

// Convert decimal to dotted decimal subnet mask (for integer input)
const decimalToDottedDecimalSubnetMask = (decimal) => {
  const binary = decimalToBinary(decimal).padStart(32, '0');
  const octets = [];
  for (let i = 0; i < 32; i += 8) {
    octets.push(parseInt(binary.slice(i, i + 8), 2));
  }
  return octets.join('.');
};

// Validate decimal input (allowing floating-point numbers)
const isValidDecimal = (input) => {
  return /^(\d+(\.\d+)?|\.\d+)$/.test(input);  // Supports integers and floats
};

const DecimalConverter = () => {
  const [decimalInput, setDecimalInput] = useState('');
  const [conversionResults, setConversionResults] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handleConvert = () => {
    if (isValidDecimal(decimalInput)) {
      const decimal = parseFloat(decimalInput); // Use parseFloat for floating point numbers
      
      // Handle floating-point precision issues by limiting binary precision
      const binary = decimalToBinary(decimal);
      const octal = decimalToOctal(decimal);
      const hexadecimal = decimalToHexadecimal(decimal);
      
      // If input is an integer, convert to IPv4 and subnet mask
      let ipv4 = '';
      let ipv6 = '';
      let subnetMaskDottedDecimal = '';
      
      if (Number.isInteger(decimal)) {
        ipv4 = decimalToIPv4(decimal);
        subnetMaskDottedDecimal = decimalToDottedDecimalSubnetMask(decimal);
      } else {
        ipv4 = 'Invalid for floating-point input';
        subnetMaskDottedDecimal = 'Invalid for floating-point input';
      }
      
      ipv6 = decimalToIPv6(decimal);

      setConversionResults({
        binary,
        octal,
        hexadecimal,
        ipv4,
        ipv6,
        subnetMaskDottedDecimal,
      });
      setErrorMessage('');
    } else {
      setErrorMessage('Please enter a valid decimal number.');
      setConversionResults(null);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent page reload
      handleConvert();
    }
  };

  <div>
    <Helmet>
      <title>Decimal Converter - Convert Decimal to Binary, Octal, Hexadecimal & More | Alpha Centauri Inc.</title>
      <meta
        name="description"
        content="Convert decimal numbers to binary, octal, hexadecimal, and other formats. Use our Decimal Converter for calculations in various number systems, including converting decimal to binary, octal, and hex with examples and detailed explanations."
      />
      <meta
        name="keywords"
        content="decimal converter, decimal to binary, decimal to hexadecimal, decimal to octal, number base conversion, decimal calculator, binary converter, hexadecimal calculator"
      />
      <meta
        name="robots"
        content="index, follow"
      />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content="Decimal Converter - Convert Decimal to Binary, Octal, Hexadecimal & More" />
      <meta property="og:description" content="Convert decimal numbers to binary, octal, hexadecimal, and more. Learn number system conversions and get results instantly." />
      <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
      <meta property="og:url" content="https://alphacentauriinc.com/ccna/Calculator/decimal_converter" />
      <meta property="og:type" content="website" />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:title" content="Decimal Converter - Convert Decimal to Binary, Octal, Hexadecimal & More" />
      <meta name="twitter:description" content="Convert decimal numbers to binary, octal, hexadecimal, and more. Instant conversion with detailed results and examples." />
      <meta name="twitter:image" content="%PUBLIC_URL%/logo192.png" />
      <meta name="twitter:card" content="summary_large_image" />

      {/* Canonical Link */}
      <link rel="canonical" href="https://alphacentauriinc.com/ccna/Calculator/decimal_converter" />

      {/* Structured Data for Article/Tools */}
      <script type="application/ld+json">
        {{
          "@context": "https://schema.org",
          "@type": "WebApplication",
          "name": "Decimal Converter",
          "description": "Convert decimal numbers to binary, octal, hexadecimal, and other formats. Get quick conversions for various number systems.",
          "url": "https://alphacentauriinc.com/ccna/Calculator/decimal_converter",
          "logo": "%PUBLIC_URL%/logo192.png",
          "publisher": {
            "@type": "Organization",
            "name": "Alpha Centauri Inc."
          },
          "mainEntityOfPage": "https://alphacentauriinc.com/ccna/Calculator/decimal_converter"
        }}
      </script>

    </Helmet>

    {/* Page content */}
    <h1>Decimal Converter</h1>
    <p>
      Our Decimal Converter allows you to quickly convert decimal numbers into binary, octal, hexadecimal, and other common number bases. Learn and explore number system conversions with clear results and examples. Ideal for students, developers, and anyone working with different number systems.
    </p>

    {/* Add the content for the Decimal Converter functionality here */}
    {/* (e.g., decimal number input form, conversion options, results display, etc.) */}
  </div>




  return (
    <div>
      <NavigationBar 
        links={['Home', 'Courses','Calculator']} 
        chapters={[]} 
        onSearch={() => {}} 
        searchTerm="" 
        clearSearch={() => {}} 
        hasChapters={false} 
      />   

      <Container 
        sx={{ 
          background: 'rgba(17, 14, 14, 0.6)', 
          padding: 2, 
          borderRadius: 1,
        }}
      >

        <Box sx={{ paddingTop: 4 }}>
          <Typography variant="h4" color="#1976d2" gutterBottom>
            Decimal Converter
          </Typography>
          <p>
      Our Decimal Converter allows you to quickly convert decimal numbers into binary, octal, hexadecimal, ip4 and ipv6 and other common number bases. Learn and explore number system conversions with clear results and examples. Ideal for students, developers, and anyone working with different number systems.
    </p>
    <div style={
          {marginBottom:"20px"}
        }></div>
          {errorMessage && (
            <Dialog open={Boolean(errorMessage)} onClose={() => setErrorMessage('')}>
              <DialogContent>
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {errorMessage}
                </Alert>
              </DialogContent>
            </Dialog>
          )}

          <Box component="form" sx={{ m: 1 }} noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Decimal Input"
                  value={decimalInput}
                  onChange={(e) => setDecimalInput(e.target.value)}
                  onKeyPress={handleKeyPress} // Trigger conversion on Enter key
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    sx: { color: '#1976d2', '& .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                    '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                  },
                    readOnly: false,
                  }}
                  InputLabelProps={{
                    sx: { color: '#1976d2' },
                  }}
                />
              </Grid>
            </Grid>

            <Button variant="contained" color="primary" onClick={handleConvert} sx={{ marginTop: 2 }}>
              Convert
            </Button>
          </Box>

          {conversionResults && (
            <Box sx={{ marginTop: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Binary"
                    value={conversionResults.binary}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Octal"
                    value={conversionResults.octal}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Hexadecimal"
                    value={conversionResults.hexadecimal}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="IPv4 Dotted Decimal"
                    value={conversionResults.ipv4}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="IPv6"
                    value={conversionResults.ipv6}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Subnet Mask (Dotted Decimal)"
                    value={conversionResults.subnetMaskDottedDecimal}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Container>
      <Utilities/>
    </div>
  );
};

export default DecimalConverter;
