// src/components/ImageEnhancer.js
import React, { useState, useEffect } from 'react';
import ReactImageZoom from 'react-image-zoom';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './ImageEnhancer.css';

const ImageEnhancer = ({ src, alt, watermark }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [showWatermark, setShowWatermark] = useState(false);

    const handleImageClick = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
        setShowWatermark(false); // Hide watermark when closing
    };

    const handleContextMenu = (e) => {
        e.preventDefault(); // Prevent the default context menu
        setShowWatermark(true); // Show watermark on right-click
    };

    const handleKeyDown = (e) => {
        // Detect Print Screen key (key code 44)
        if (e.key === 'PrintScreen' || e.keyCode === 44) {
            setShowWatermark(true); // Show watermark when Print Screen is pressed
        }
    };

    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div>
            <div className={`image-container ${isOpen ? 'open' : ''}`} onClick={handleImageClick}>
                <img src={src} alt={alt} className="thumbnail" onContextMenu={handleContextMenu} />
                <div className={`watermark ${showWatermark ? 'visible' : ''}`}>{watermark}</div>
            </div>

            {isOpen && (
                <div className="modal" onClick={handleClose}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <ReactImageZoom
                            img={src}
                            zoomScale={2}
                            width={500}
                            height={500}
                            zoomPosition="original"
                        />
                        <div className={`watermark ${showWatermark ? 'visible' : ''}`}>{watermark}</div>
                        <IconButton className="close-button" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImageEnhancer;