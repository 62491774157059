import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, IconButton, Menu, MenuItem } from '@mui/material';
import TranslateRoundedIcon from '@mui/icons-material/TranslateRounded';

const Language = () => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
    handleClose();
  };

  const languages = [
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Español' },
    { code: 'ru', name: 'Русский' },
    { code: 'zh', name: '中文' },
  ];

  return (
    <>
      <Tooltip title={t('Language')}>
        <IconButton
          onClick={handleClick}
          sx={{ color: 'var(--text-color)' }}
          aria-label={t('Language')}
        >
          <TranslateRoundedIcon/>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{ 
          sx: { 
            background: 'rgba(17, 14, 14, 0.75)', 
            boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)', 
            backdropFilter: 'blur(2px)', 
            WebkitBackdropFilter: 'blur(2px)', 
            borderRadius: '10px', 
            border: '1px solid rgba(255, 255, 255, 0.18)', 
            color: 'white' 
          } 
        }}
      >
        {languages.map((lang) => (
          <MenuItem key={lang.code} onClick={() => handleLanguageChange(lang.code)}>
            {t(lang.name)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default Language;