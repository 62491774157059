import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import WatermarkedImage from '../../../WatermarkedImage';
import StudyRobot from '../../../StudyRobot';
import '../CCNA1/CcnaQuestionCard.css';
import CCNA2_Quiz_Bank from '../../../Settings/locales/en/ccna2_translation.json'
import { useTranslation } from 'react-i18next';
import ChapterNavigation from '../CCNA1/ChapterNav';
import Q38 from '../CCNA1/chapter-2/ccna1_chapter2_assets/Q13.jpg';
import Q1V7 from '../../../../assets/CCNA2ExamVersion7/Q1.jpg';
import Q2V7 from '../../../../assets/CCNA2ExamVersion7/Q2.png';
import Q3V7 from '../../../../assets/CCNA2ExamVersion7/Q3.png';
import Q5V7 from '../../../../assets/CCNA2ExamVersion7/Q5.png';
import Q9V7 from '../../../../assets/CCNA2ExamVersion7/Q9.png'; 
import Q14V7 from '../../../../assets/CCNA2ExamVersion7/Q14.png';
import Q17V7 from '../../../../assets/CCNA2ExamVersion7/Q17.png';
import Q21V7 from '../../../../assets/CCNA2ExamVersion7/Q21.png';
import Q23V7 from '../../../../assets/CCNA2ExamVersion7/Q23.png';
import Q25V7 from '../../../../assets/CCNA2ExamVersion7/Q25.png';
import Q29V7 from '../../../../assets/CCNA2ExamVersion7/Q29.png';
import Q32V7 from '../../../../assets/CCNA2ExamVersion7/Q32.png';
import Q42V7 from '../../../../assets/CCNA2ExamVersion7/Q42.png';
import Q44V7 from '../../../../assets/CCNA2ExamVersion7/Q44.png';
import Q53V7 from '../../../../assets/CCNA2ExamVersion7/Q53.png';
import Q54V7 from '../../../../assets/CCNA2ExamVersion7/Q54.png';
import Q56V7 from '../../../../assets/CCNA2ExamVersion7/Q56.png';
import Q57V7 from '../../../../assets/CCNA2ExamVersion7/Q57.png';
import Q61V7 from '../../../../assets/CCNA2ExamVersion7/Q61.png';
import Q64V7 from '../../../../assets/CCNA2ExamVersion7/Q64.png';
import Q66V7 from '../../../../assets/CCNA2ExamVersion7/Q66.png';
import Q67V7 from '../../../../assets/CCNA2ExamVersion7/Q67.png';
import Q74V7 from '../../../../assets/CCNA2ExamVersion7/Q74.png';
import Q87V7 from '../../../../assets/CCNA2ExamVersion7/Q87.png';
import Q91V7 from '../../../../assets/CCNA2ExamVersion7/Q91.png';
import Q95V7 from '../../../../assets/CCNA2ExamVersion7/Q95.png';
import Q96V7 from '../../../../assets/CCNA2ExamVersion7/Q96.png';
import Q99V7 from '../../../../assets/CCNA2ExamVersion7/Q99.png';
import Q112V7 from '../../../../assets/CCNA2ExamVersion7/Q112.png';
import Q115V7 from '../../../../assets/CCNA2ExamVersion7/Q115.png';
import Q118V7 from '../../../../assets/CCNA2ExamVersion7/Q118.png';
import Q124V7 from '../../../../assets/CCNA2ExamVersion7/Q124.png';
import Q127V7 from '../../../../assets/CCNA2ExamVersion7/Q127.png';
import Q130V7 from '../../../../assets/CCNA2ExamVersion7/Q130.png';
import Q141V7 from '../../../../assets/CCNA2ExamVersion7/Q141.png';
import Q146V7 from '../../../../assets/CCNA2ExamVersion7/Q146.png';
import NavigationBar from './NavigationBar';
import CircularWithValueLabel from '../../../Loader';
import ImageViewer from '../../../ImageViewer';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import ImageEnhancer from '../../../ImageEnhancer';
import { Helmet } from 'react-helmet';

const optamized = () => (

  <div>
    <Helmet>
      <title>CCNA 2 Switching, Routing, and Wireless Essentials (SWRE) - Chapter Answers, Configurations, and Exam Quiz | Alpha Centauri Inc.</title>
      <meta
        name="description"
        content="Complete study guide for CCNA 2 Switching, Routing, and Wireless Essentials (SWRE). Includes Chapter 2, Chapter 7 answers, PSA PT answers, device configurations, and exam quiz solutions for Version 7. Enhance your CCNA knowledge with detailed explanations and examples."
      />
      <meta
        name="keywords"
        content="CCNA 2, Switching Routing Wireless Essentials, SWRE, Chapter 2 answers, Chapter 7 answers, CCNA exam quiz, PSA PT answers, CCNA 2 device configurations, CCNA 2 quiz, CCNA version 7, Cisco exam, CCNA certification, routing, switching, wireless, CCNA practice test"
      />
      <meta
        name="robots"
        content="index, follow"
      />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content="CCNA 2 Switching, Routing, and Wireless Essentials (SWRE) - Chapter Answers, Configurations, and Exam Quiz" />
      <meta property="og:description" content="Complete study guide for CCNA 2 SWRE. Chapter 2, Chapter 7 answers, device configurations, and exam quiz solutions for Version 7. Master the concepts and pass the CCNA exam." />
      <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
      <meta property="og:url" content="https://alphacentauriinc.com/ccna/CCNA2/chapter-1" />
      <meta property="og:type" content="website" />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:title" content="CCNA 2 Switching, Routing, and Wireless Essentials (SWRE) - Chapter Answers, Configurations, and Exam Quiz" />
      <meta name="twitter:description" content="Complete study guide for CCNA 2 SWRE, including Chapter 2 and 7 answers, device configurations, and exam quiz solutions for Version 7. Boost your CCNA skills." />
      <meta name="twitter:image" content="%PUBLIC_URL%/logo192.png" />
      <meta name="twitter:card" content="summary_large_image" />

      {/* Canonical Link */}
      <link rel="canonical" href="https://alphacentauriinc.com/ccna/CCNA2/chapter-1" />

      {/* Structured Data for Article/Tools */}
      <script type="application/ld+json">
        {{
          "@context": "https://schema.org",
          "@type": "WebApplication",
          "name": "CCNA 2 Switching, Routing, and Wireless Essentials",
          "description": "A comprehensive guide for CCNA 2 SWRE with answers for Chapter 2 and Chapter 7, device configurations, and solutions for PSA PT and Version 7 quizzes. Perfect for students preparing for the CCNA certification exam.",
          "url": "https://alphacentauriinc.com/ccna/CCNA2/chapter-1",
          "logo": "%PUBLIC_URL%/logo192.png",
          "publisher": {
            "@type": "Organization",
            "name": "Alpha Centauri Inc."
          },
          "mainEntityOfPage": "https://alphacentauriinc.com/ccna/CCNA2/chapter-1"
        }}
      </script>

    </Helmet>

    {/* Page content */}
    <h1>CCNA 2 Switching, Routing, and Wireless Essentials (SWRE)</h1>
    <p>
      Welcome to our comprehensive study guide for **CCNA 2 Switching, Routing, and Wireless Essentials (SWRE)**. This guide includes detailed answers and solutions for **Chapter 2** and **Chapter 7**, practical **device configurations**, **PSA PT answers**, and **CCNA exam quizzes** for **Version 7**. 
    </p>

    <h2>Chapters Covered:</h2>
    <ul>
      <li><strong>Chapter 2:</strong> Switching Concepts and VLAN Configuration</li>
      <li><strong>Chapter 7:</strong> Wireless Networking Basics</li>
    </ul>

    <h2>Key Features:</h2>
    <ul>
      <li><strong>PSA PT Answers:</strong> Walkthroughs and solutions to Packet Tracer labs for real-world networking scenarios.</li>
      <li><strong>Device Configurations:</strong> Step-by-step guide on configuring routers, switches, and wireless devices to align with CCNA standards.</li>
      <li><strong>CCNA Exam Quiz Answers:</strong> Practice quizzes for each chapter with detailed explanations and step-by-step solutions.</li>
      <li><strong>Version 7 Updates:</strong> All answers and solutions updated for **CCNA 2 Version 7** to reflect the latest exam standards and curriculum.</li>
    </ul>

    <p>
      Whether you're preparing for the **CCNA 2 exam**, improving your networking knowledge, or seeking real-world configurations, this guide is designed to provide in-depth explanations, examples, and tools to help you succeed. Dive into each chapter and master the content with confidence.
    </p>

    <p>
      <strong>Start your CCNA journey with Alpha Centauri Inc. and pass your certification exam with ease!</strong>
    </p>

    {/* Add the content for the CCNA 2 Chapters, Quizzes, and Device Configurations here */}
    {/* (e.g., chapter answers, quiz questions, configuration examples, etc.) */}
  </div>
)

const images = {
Q1V7, Q2V7, Q3V7, Q5V7, Q9V7, Q14V7, Q17V7, Q21V7, Q23V7, Q25V7, Q29V7, Q32V7, Q42V7,
Q44V7, Q53V7, Q54V7, Q56V7, Q57V7, Q61V7, Q64V7, Q66V7, Q67V7, Q74V7, Q87V7, Q91V7,
Q95V7, Q96V7, Q99V7, Q112V7, Q115V7, Q118V7, Q124V7, Q127V7, Q130V7, Q141V7, Q146V7
  // Add more images here as needed
};

const linksArray = ["Courses", "Calculator"];

const QuestionCard = ({ questionData, searchTerm }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation('ccna2');

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 3000); // Simulate loading
  }, []);

  const highlightText = (text, term) => {
    if (!term) return text;
    const regex = new RegExp(`(${term})`, 'gi');
    return text.replace(regex, (match) => `<mark style="background-color: yellow; color: black;">${match}</mark>`);
  };

  const renderMatchingQuestion = (question) => {
    return (
      <div className="matching-question">
        <div className="matching-container">
          <div  className="matching-items">
            {question.items.map((item) => (
              <div key={item.id} className="matching-item">
                <span>{item.content}</span>
              </div>
            ))}
          </div>
          <div className="matching-options">
            {question.options.map((option) => (
              <div style={{background: 'rgba( 58, 1, 114, 0.7 )'}} key={option.id} className="matching-option">
                <span>{option.content}</span>
              </div>
            ))}
          </div>
        </div>
        <h4>Correct Matches:</h4>
<div className="correct-matches">
  {question.items.map((item) => (
    <div key={item.id} className="correct-match-item">
      <div className="match-content">
        <span className="item-content">{item.content}</span>
        <span className="match-arrow">→</span>
        <div className="matched-options">
          {(() => {
            const correctMatch = question.correctMatches[item.id];
            if (Array.isArray(correctMatch)) {
              return correctMatch.map((optionId) => {
                const option = question.options.find(opt => opt.id === optionId);
                return (
                  <div key={optionId} className="correct-option">
                    {option.content} ✔
                  </div>
                );
              });
            } else if (typeof correctMatch === 'string') {
              const option = question.options.find(opt => opt.id === correctMatch);
              return (
                <div key={correctMatch} className="correct-option">
                  {option.content} ✔
                </div>
              );
            } else {
              return null;
            }
          })()}
        </div>
      </div>
      <div className="match-line"></div>
    </div>
  ))}
</div>
</div>
    );
  };

  return (
    <React.Fragment>
      
      <CardContent sx={{width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
        <div className="card">
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
              <CircularWithValueLabel />
            </Box>
          ) : (
            <>
              <h3>{t('Question')} {questionData.id}</h3>
              <p
                style={{ fontWeight: 'bold' }}
                dangerouslySetInnerHTML={{
                  __html: highlightText(questionData.question, searchTerm)
                }}
              />
              {questionData.image && images[questionData.image] && (
                <ImageEnhancer
                  src={images[questionData.image]}
                  watermarkText="STUDYHUB.COM"
                  maxWidth={400}
                />
              )}
              {questionData.type === 'multiple-choice' ? (
                <ol>
                  {questionData.answers.map((answer, index) => (
                    <li key={index} className={answer.isCorrect ? 'correct' : ''}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: highlightText(answer.text, searchTerm)
                        }}
                      />
                      {answer.isCorrect && <span className="checkmark">✔</span>}
                    </li>
                  ))}
                </ol>
              ) : questionData.type === 'matching' ? (
                renderMatchingQuestion(questionData)
              ) : (
                <p>Unsupported question type: {questionData.type}</p>
              )}
              <div className="reason">
                <p>{t('Explanation')}: {questionData.reason}</p>
              </div>
            </>
          )}
        </div>
      </CardContent>
    </React.Fragment>
  );
};

const QuestionList = () => {
  const { t } = useTranslation('ccna2');
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const chapters = [
    { name: t('Chapter 1'), path: '/ccna/CCNA2/chapter-1', key: 'chapter1' },
    { name: t('Chapter 2'), path: '/ccna/CCNA2/chapter-2', key: 'chapter2' },
    { name: t('Chapter 2 SIC_PSA_PT'), path: '/ccna/CCNA2/Chapter2_SIC_PracticeSkills_Assessment/SIC_PSA_PT' },
    { name: t('Chapter 3'), path: '/ccna/CCNA2/chapter-3', key: 'chapter3' },
    { name: t('Chapter 4'), path: '/ccna/CCNA2/chapter-4', key: 'chapter4' },
    { name: t('Chapter 5'), path: '/ccna/CCNA2/chapter-5', key: 'chapter5' },
    { name: t('Chapter 6'), path: '/ccna/CCNA2/chapter-6', key: 'chapter6' },
    { name: t('Chapter 7'), path: '/ccna/CCNA2/chapter-7', key: 'chapter7' },
    { name: t('Chapter 7 SIC_PSA_PT'), path: '/ccna/CCNA2/Chapter7_SIC_PracticeSkills_Assessment/SIC_PSA_PT' },
    { name: t('Chapter 8'), path: '/ccna/CCNA2/chapter-8', key: 'chapter8' },
    { name: t('Chapter 9'), path: '/ccna/CCNA2/chapter-9', key: 'chapter9' },
    { name: t('Chapter 10'), path: '/ccna/CCNA2/chapter-10', key: 'chapter10' },
    { name: t('Chapter 10 Exam'), path: '/ccna/CCNA2/chapter-10-Exam', key: 'chapter10Exam' },
    // { name: t('CCNA2 Exam'), path: '/ccna/CCNA2/chapter-10-Exam', key: 'CCNA2Exam' },
    { name: t('Exam'), path: '/ccna/CCNA2/Exam', key: 'CCNA2Exam' },
    { name: t('Exam Version 7'), path: '/ccna/CCNA2/Exam-Version-7', key: 'CCNA2Exam2024' },
    { name: t('Quiz'), path: '/ccna/ccna2/ccna2_quiz/QuizApp' }
  ];

  const clearSearch = () => {
    setSearchTerm('');
  };

  const currentChapter = chapters.find(
    chapter => chapter.path === location.pathname
  );

  const currentChapterName = currentChapter ? currentChapter.name : '';

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleChapterChange = (event) => {
    const selectedChapter = event.target.value;
    navigate(selectedChapter);
    clearSearch();
  };

  const handleNextChapter = () => {
    const currentIndex = chapters.findIndex(chapter => chapter.key === currentChapter?.key);
    if (currentIndex < chapters.length - 1 && currentIndex >= 0) {
      navigate(chapters[currentIndex + 1].path);
    }
  };

  const handlePreviousChapter = () => {
    const currentIndex = chapters.findIndex(chapter => chapter.key === currentChapter?.key);
    if (currentIndex > 0) {
      navigate(chapters[currentIndex - 1].path);
    }
  };

  // Access the existing translation file
  const currentQuestions = CCNA2_Quiz_Bank[currentChapter?.key] || [];

  return (
    <div>
      <NavigationBar 
        links={linksArray} 
        chapters={chapters} 
        onSearch={handleSearch}
        currentChapter={currentChapter}
        currentChapterName={currentChapterName}
        searchTerm={searchTerm}
        clearSearch={clearSearch}
      />
      <div className="current-chapter-container">
                <h1
                    style={{
                        color: 'white', // Change text color
                        fontSize: '24px', // Set font size
                        fontWeight: 'bold', // Make it bold
                        textAlign: 'center', // Center the text
                        margin: '10px 0', // Add margin for spacing
                    }}
                >
                    {currentChapter ? (
                        <span className="current-chapter">
                            CCNA 2: {typeof currentChapter === 'string' ? currentChapter : currentChapter.name}
                        </span>
                    ) : null}
                </h1>
            </div>
      {currentQuestions.length > 0 ? (
        currentQuestions
          .filter((qa) => qa.question.toLowerCase().includes(searchTerm.toLowerCase()))
          .map((qa) => (
            <QuestionCard 
              key={qa.id} 
              questionData={qa}
              searchTerm={searchTerm}
            />
          ))
      ) : (
        <p>No questions found for this chapter.</p>
      )}
      <ChapterNavigation
        chapters={chapters}
        currentChapter={currentChapter}
        handleChapterChange={handleChapterChange}
        handleNextChapter={handleNextChapter}
        handlePreviousChapter={handlePreviousChapter}
      />
      <StudyRobot />
    </div>
  );
};

export default QuestionList;