import React, { useRef, useEffect } from 'react';

const WatermarkedImage = ({ src, watermarkText, maxWidth, style }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const loadImage = (url, callback) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous'; // To handle cross-origin images
      img.onload = () => callback(img);
      img.src = url;
    };

    loadImage(src, (image) => {
      const aspectRatio = image.width / image.height;
      // Calculate canvas dimensions based on maxWidth
      canvas.width = maxWidth * 2; // Double the width for HD
      canvas.height = (maxWidth * 2) / aspectRatio; // Adjust height accordingly

      // Draw the image on the canvas
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

      // Add watermark
      ctx.font = 'bold 48px Arial'; // Larger font for HD
      ctx.fillStyle = 'rgba(0, 0, 0, 0.4)'; // White with some opacity
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';

      // Watermark position
      const x = canvas.width / 2.3;
      const y = canvas.height / 2;
      ctx.fillText(watermarkText, x, y);
    });
  }, [src, watermarkText, maxWidth]);

  return <canvas ref={canvasRef} style={{ maxWidth: '100%' }} />;
};

export default WatermarkedImage;