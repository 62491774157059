import React, { useState, useEffect } from 'react';
import './UpdateNotification.css';

// Assuming the version is fetched from version.json
const CURRENT_APP_VERSION = process.env.REACT_APP_VERSION || '1.0.0';  // Fallback version

const UpdateNotification = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);

  useEffect(() => {
    const fetchVersion = async () => {
      try {
        const response = await fetch('/version.json');  // Fetch the version.json file
        const data = await response.json();
        const latestVersion = data.version;

        // Compare with the current app version
        if (latestVersion !== CURRENT_APP_VERSION) {
          setNewVersionAvailable(true);  // New version is available
        }
      } catch (error) {
        console.error('Failed to fetch version info:', error);
      }
    };

    // Fetch version on component mount
    fetchVersion();

    // Listen to messages for updates (could be from service workers, etc.)
    const handleMessage = (event) => {
      if (event.data === 'updateAvailable') {
        setIsVisible(true);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const handleUpdate = () => {
    window.location.reload();  // Trigger the page refresh to get the latest version
  };

  const handleDismiss = () => {
    setIsVisible(false);
  };

  if (!isVisible || !newVersionAvailable) return null;  // Hide if no update is available

  return (
    <div className="notification update-notification">
      <p>A new update is available!</p>
      <div className="notification-actions">
        <button onClick={handleUpdate}>Update Now</button>
        <button onClick={handleDismiss}>Dismiss</button>
      </div>
    </div>
  );
};

export default UpdateNotification;
