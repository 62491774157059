// path/to/your/file
import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid'; // Import Grid from Material-UI
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation
import NavigationBar from '../courses/ccna/CCNA2/NavigationBar';
import Jsx_ui from '../../assets/jsx-ui.png';
import Config from '../../assets/config.png';
import IPV4 from '../../assets/ipv41.png';
import IPV6 from '../../assets/ipv6.png';
import Binary from '../../assets/binary.png';
import DEC from '../../assets/dec.png';
import HEX from '../../assets/hex.png';
import OCT from '../../assets/oct.png';

const links = ['Home','Courses'];
const chapters = []; // or some array of chapter objects if applicable
const onSearch = (searchTerm) => {
  // Handle search logic
};

const Utilities = ({ showNav = false }) => {
  const location = useLocation(); // Get the current location
  const utilityPaths = [
    '/ccna/Calculator/IP_Calculator',
    '/ccna/Calculator/ipv6_calculator',
    '/ccna/Calculator/binary_converter',
    '/ccna/Calculator/hexadecimal_converter',
    '/ccna/Calculator/octal_converter',
    '/ccna/Calculator/decimal_converter',
  ];

  // Check if the current path matches any of the utility paths
  const isUtilityPath = utilityPaths.includes(location.pathname);

  return (
    <div>
      {showNav && (
        <NavigationBar 
          links={links} 
          chapters={chapters} // Pass an empty array if no chapters
          onSearch={onSearch} 
          searchTerm="" 
          clearSearch={() => {}} 
          hasChapters={chapters.length > 0} // Optional: pass this prop if needed
        />  
      )}
      <Container sx={{ backgroundColor: 'rgba(17, 14, 14, 0.6)', padding: '16px', borderRadius: '8px' }}>
        <Typography variant="h4" color="#1976d2" gutterBottom>
          {isUtilityPath ? 'Other Utilities' : 'Utilities'}
        </Typography>
        <Grid container spacing={2}>
          {/* Conditionally render the Ipv4 Calculator card */}
          {location.pathname !== '/ccna/Calculator/IP_Calculator' && (
          <Grid item xs={12} sm={6} md={4}>
            <Card variant="outlined" sx={{ borderColor: '#1976d2', backgroundColor: 'rgba(17, 14, 14, 0.6)', '&:hover': { opacity: 0.8 } }}>
              <Link to="/ccna/Calculator/IP_Calculator" style={{ textDecoration: 'none', color: 'inherit' }}>
                <AspectRatio sx={{ backgroundColor: 'rgba(17, 14, 14, 0.6)', '& .MuiAspectRatio-content': { backgroundColor: 'rgba(17, 14, 14, 0.6)' } }}>
                  <img src={IPV4} alt="IPv4 Calculator" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </AspectRatio>
                <div>
                  <Typography level="title-md" sx={{ color: '#1976d2' }}>IPv4 Calculator</Typography>
                  <Typography level="body-sm" sx={{ color: '#1976d2' }}>Description of the IPv4 Calculator.</Typography>
                </div>
              </Link>
            </Card>
          </Grid>
          )}

          {/* Conditionally render the Ipv6 Calculator card */}
          {location.pathname !== '/ccna/Calculator/ipv6_calculator' && (
          <Grid item xs={12} sm={6} md={4}>
            <Card variant="outlined" sx={{ borderColor: '#1976d2', backgroundColor: 'rgba(17, 14, 14, 0.6)', '&:hover': { opacity: 0.8 } }}>
              <Link to="/ccna/Calculator/ipv6_calculator" style={{ textDecoration: 'none', color: 'inherit' }}>
                <AspectRatio sx={{ backgroundColor: 'rgba(17, 14, 14, 0.6)', '& .MuiAspectRatio-content': { backgroundColor: 'rgba(17, 14, 14, 0.6)' } }}>
                  <img src={IPV6} alt="IPv6 Calculator" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </AspectRatio>
                <div>
                  <Typography level="title-md" sx={{ color: '#1976d2' }}>IPv6 Calculator</Typography>
                  <Typography level="body-sm" sx={{ color: '#1976d2' }}>Description of the IPv6 Calculator.</Typography>
                </div>
              </Link>
            </Card>
          </Grid>
          )}

        {/* Conditionally render the Binary Converter card */}
        {location.pathname !== '/ccna/Calculator/binary_converter' && (
              <Grid item xs={12} sm={6} md={4}>
            <Card variant="outlined" sx={{ borderColor: '#1976d2', backgroundColor: 'rgba(17, 14, 14, 0.6)', '&:hover': { opacity: 0.8 } }}>
              <Link to="/ccna/Calculator/binary_converter" style={{ textDecoration: 'none', color: 'inherit' }}>
              <AspectRatio sx={{ backgroundColor: 'rgba(17, 14, 14, 0.6)', '& .MuiAspectRatio-content': { backgroundColor: 'rgba(17, 14, 14, 0.6)' } }}>
              <img src={Binary} alt="Binary Converter" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </AspectRatio>
                <div>
                  <Typography level="title-md" sx={{ color: '#1976d2' }}>Binary Converter</Typography>
                  <Typography level="body-sm" sx={{ color: '#1976d2' }}>Description of the Binary Converter.</Typography>
                </div>
              </Link>
            </Card>
          </Grid>
        )}
          {/* Conditionally render the Hexadecimal Converter card */}
          {location.pathname !== '/ccna/Calculator/hexadecimal_converter' && (
            <Grid item xs={12} sm={6} md={4}>
              <Card variant="outlined" sx={{ borderColor: '#1976d2', backgroundColor: 'rgba(17, 14, 14, 0.6)', '&:hover': { opacity: 0.8 } }}>
                <Link to="/ccna/Calculator/hexadecimal_converter" style={{ textDecoration: 'none', color: 'inherit' }}>
                <AspectRatio sx={{ backgroundColor: 'rgba(17, 14, 14, 0.6)', '& .MuiAspectRatio-content': { backgroundColor: 'rgba(17, 14, 14, 0.6)' } }}>
                    <img src={HEX} alt="Hexadecimal Converter" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                  </AspectRatio>
                  <div>
                    <Typography level="title-md" sx={{ color: '#1976d2' }}>Hexadecimal Converter</Typography>
                    <Typography level="body-sm" sx={{ color: '#1976d2' }}>Description of the Hexadecimal Converter.</Typography>
                  </div>
                </Link>
              </Card>
            </Grid>
          )}

          {/* Conditionally render the Octal Converter card */}
          {location.pathname !== '/ccna/Calculator/octal_converter' && (
          <Grid item xs={12} sm={6} md={4}>
            <Card variant="outlined" sx={{ borderColor: '#1976d2', backgroundColor: 'rgba(17, 14, 14, 0.6)', '&:hover': { opacity: 0.8 } }}>
              <Link to="/ccna/Calculator/octal_converter" style={{ textDecoration: 'none', color: 'inherit' }}>
              <AspectRatio sx={{ backgroundColor: 'rgba(17, 14, 14, 0.6)', '& .MuiAspectRatio-content': { backgroundColor: 'rgba(17, 14, 14, 0.6)' } }}>
              <img src={OCT} alt="Octal Converter" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </AspectRatio>
                <div>
                  <Typography level="title-md" sx={{ color: '#1976d2' }}>Octal Converter</Typography>
                  <Typography level="body-sm" sx={{ color: '#1976d2' }}>Description of the Octal Converter.</Typography>
                </div>
              </Link>
            </Card>
          </Grid>
          )}
          {/* Conditionally render the Decimal Converter card */}
          {location.pathname !== '/ccna/Calculator/decimal_converter' && (
          <Grid item xs={12} sm={6} md={4}>
            <Card variant="outlined" sx={{ borderColor: '#1976d2', backgroundColor: 'rgba(17, 14, 14, 0.6)', '&:hover': { opacity: 0.8 } }}>
              <Link to="/ccna/Calculator/decimal_converter" style={{ textDecoration: 'none', color: 'inherit' }}>
              <AspectRatio sx={{ backgroundColor: 'rgba(17, 14, 14, 0.6)', '& .MuiAspectRatio-content': { backgroundColor: 'rgba(17, 14, 14, 0.6)' } }}>
              <img src={DEC} alt="Decimal Converter" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </AspectRatio>
                <div>
                  <Typography level="title-md" sx={{ color: '#1976d2' }}>Decimal Converter</Typography>
                  <Typography level="body-sm" sx={{ color: '#1976d2' }}>Description of the Decimal Converter.</Typography>
                </div>
              </Link>
            </Card>
          </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default Utilities;