import React from 'react';
import { useState , useRef} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import TabButtons from '../../../../TabButtons';
import { Skills_TabButtonsInfo } from './ITN_PSA_Instruc';
import WatermarkedImagePT from '../../../../WatermarkedImagePT';
// import CCNA1Navbar from '../CCNA1_Navbar';
import NavigationBar from '../../CCNA2/NavigationBar';
import '../../CCNA1/FooterNav.css';
import StudyRobot from '../../../../StudyRobot';
import './ITN.css'
import ChapterNavigation from '../ChapterNav';
import { useTranslation } from 'react-i18next';
import CopyComponent from '../../../../copy';
import { Box, useMediaQuery, useTheme } from '@mui/material';


// Fetching the URL for files.
const PSA_URL = 'http://localhost:3000/1.3.6-packet-tracer-configure-ssh.pka'
const linksArray = ["Home","Courses", "Calculator"];

function ITN_PSA_PT() {
    const {t} = useTranslation();
    const [selectedTopic, setSelectedTopic] = useState('Exam_A');
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const location = useLocation();

    const theme = useTheme();
 // Media Queries for different breakpoints
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // XS & SM (phones, small tablets)
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md')); // MD (tablets, small laptops)
    const routerRef = useRef(null);
    const switch1Ref = useRef(null);
    const switch2Ref = useRef(null);
    const switch3Ref = useRef(null);

    // Ensure topics exists before joining ONLY need to be used if have the raw HTML Tags in the imported component
    const introduction = Skills_TabButtonsInfo[selectedTopic]?.introduction?.join('<br/>') || '';
    const instructions = Skills_TabButtonsInfo[selectedTopic]?.instructions?.join('<br/>') || '';
    const note = Skills_TabButtonsInfo[selectedTopic]?.note?.join('<br/>') || '';
    const requirments = Skills_TabButtonsInfo[selectedTopic]?.requirments?.join('<br/>') || '';
    const host_124_1_Config=Skills_TabButtonsInfo[selectedTopic]?.host_124_1_Config?.join('<br/>') || '';
    const host_124_5_Config=Skills_TabButtonsInfo[selectedTopic]?.host_124_5_Config?.join('<br/>') || '';
    const host_214_1_Config=Skills_TabButtonsInfo[selectedTopic]?.host_214_1_Config?.join('<br/>') || '';
    const host_Server_Config=Skills_TabButtonsInfo[selectedTopic]?.host_Server_Config?.join('<br/>') || '';
    const routerConfig=Skills_TabButtonsInfo[selectedTopic]?.routerConfig?.join('<br/>') || '';
    const switch1Config=Skills_TabButtonsInfo[selectedTopic]?.switch1Config?.join('<br/>') || '';
    //const Logo192 = `${process.env.PUBLIC_URL}/logo192.png`;
    //const PSA_down = `${process.env.PUBLIC_URL}/1.3.6-packet-tracer---configure-ssh.pka`;

    // Download from URL 
    const downloadFileAtURL=(url)=>{
        fetch (url).then(response=>response.blob()).then(blob=>{
          const blobURL = window.URL.createObjectURL(new Blob([blob]))  
          const fileName = url.split('/').pop();
          const aTag = document.createElement('a');
          aTag.href=blobURL;
          aTag.setAttribute('download',fileName);
          document.body.appendChild(aTag);
          aTag.click();
          aTag.remove();
        })
    };


    const chapters = [
        { name: t('Chapter 1'), path: '/ccna/CCNA1/chapter-1', key: 'chapter1' }, // i18n change
        { name: t('Chapter 2'), path: '/ccna/CCNA1/chapter-2', key: 'chapter2' }, // i18n change
        { name: t('Chapter 3'), path: '/ccna/ccna1/chapter-3', key: 'chapter3' }, // i18n change
        { name: t('Chapter 4'), path: '/ccna/ccna1/chapter-4', key: 'chapter4' }, // i18n change
        { name: t('Chapter 5'), path: '/ccna/ccna1/chapter-5', key: 'chapter5' }, // i18n change
        { name: t('Chapter 6'), path: '/ccna/ccna1/chapter-6', key: 'chapter6' }, // i18n change
        { name: t('Chapter 6 Skill PT'), path: '/ccna/ccna1/chapter-6/ch6_skills_PT' , key: 'chapter6SkillPT'}, // i18n change
        { name: t('Chapter 7'), path: '/ccna/ccna1/chapter-7', key: 'chapter7' }, // i18n change
        { name: t('Chapter 8'), path: '/ccna/ccna1/chapter-8', key: 'chapter8' }, // i18n change
        { name: t('Chapter 9'), path: '/ccna/ccna1/chapter-9', key: 'chapter9' }, // i18n change
        { name: t('Chapter 10'), path: '/ccna/ccna1/chapter-10', key: 'chapter10' }, // i18n change
        { name: t('Chapter 11'), path: '/ccna/ccna1/chapter-11', key: 'chapter11' }, // i18n change
        { name: t('ITN PSA–PT'), path: '/ccna/ccna1/Practice-PTA/ITN_PSA_PT', key: 'itnpsapt' }, // i18n change
        { name: t('Quiz'), path: '/ccna/ccna1/ccna1_quiz/QuizApp', key: 'quizapp' }, // i18n change
    ];

    const currentChapter = chapters.find(
        chapter => chapter.path === location.pathname
    );

    const handleSearch = (term) => {
        setSearchTerm(term);
    };

    const handleChapterChange = (event) => {
        const selectedChapter = event.target.value;
        navigate(selectedChapter);
    };

    const handleNextChapter = () => {
        const currentIndex = chapters.findIndex(chapter => chapter.key === currentChapter?.key);
        if (currentIndex < chapters.length - 1 && currentIndex >= 0) {
            navigate(chapters[currentIndex + 1].path);
        }
    };

    const handlePreviousChapter = () => {
        const currentIndex = chapters.findIndex(chapter => chapter.key === currentChapter?.key);
        if (currentIndex > 0) {
            navigate(chapters[currentIndex - 1].path);
        }
    };

    function selectHandler(selectedButton) {
        setSelectedTopic(selectedButton);
    }

    const tableStyle = {
        width: '100%',
        borderCollapse: 'collapse',
    };

    const thStyle = {
        border: '1px solid white',
        padding: '8px',
        backgroundColor: 'purple',
    };

    const tdStyle = {
        border: '1px solid White',
        padding: '8px',
        textAlign: 'center'
    };
    //const mergedRowSpan = 2; // Number of rows to span for "CS Department"

    // const groupedData = Skills_TabButtonsInfo[selectedTopic].tableData.reduce((acc, row) => {
    //     if (!acc[row.Device]) {
    //         acc[row.Device] = [];
    //     }
    //     acc[row.Device].push(row);
    //     return acc;
    // }, {});
    const groupedData = Skills_TabButtonsInfo[selectedTopic].tableData.reduce((acc, row) => {
    const { Device, Interface } = row;   
    // Group by device, then by interface within each device
    if (!acc[Device]) acc[Device] = {};
    if (!acc[Device][Interface]) acc[Device][Interface] = [];
    
    acc[Device][Interface].push(row);
    return acc;
}, {});

    // CSS Style to hide the bottom border of specific cells
const style = `
td.no-bottom-cell-border {
  border-bottom: none !important;
}
`;
const getArrayOrString = (key) => {
    const value = t(key, { returnObjects: true });
    return Array.isArray(value) ? value : [value];  // Return as an array if it's not already
};
    return (
        <div>
            <style>{style}</style> {/* Include the style tag with custom styles */}
            {/* <CCNA1Navbar chapters={chapters} onSearch={handleSearch} /> */}
            <NavigationBar 
            links={linksArray} 
            chapters={chapters} 
            onSearch={handleSearch} 
            currentChapter={currentChapter}
            searchTerm={searchTerm} 
            clearSearch={setSearchTerm} 
        />
            <main>
                <section id='tab-sections'>
                    <h1 style={{marginBottom: '50px'}}>ITN ( Version 7.00) Practice PT Skills Assessment (PTSA) Exam</h1>
                    <section>
                        <div id='tab-content' style={{color:'Red'}} dangerouslySetInnerHTML={{ __html: note }} />     
                        </section>
                    <menu>
                        <TabButtons isSelected={selectedTopic === 'Exam_A'} onSelect={() => selectHandler('Exam_A')}>Exam-A</TabButtons>
                        <TabButtons isSelected={selectedTopic === 'Exam_B'} onSelect={() => selectHandler('Exam_B')}>Exam-B</TabButtons>
                    </menu>
                    <div id='tab-content'>
                    <h5>{Skills_TabButtonsInfo[selectedTopic].title}</h5>
                        {Skills_TabButtonsInfo[selectedTopic].image.map((image, index) => (
                            <WatermarkedImagePT
                                key={index}
                                src={image}
                                watermarkText="AC Studies"
                                style={{
                                    width: 'auto',
                                    height: '500px',
                                    animation: 'fadeIn 3s ease-in-out',
                                    marginBottom: '20px', // Add space between images
                                }}
                            />
                        ))}
                        </div>
                        </section>
                        

                        <section>
                            <div id='tab-content' dangerouslySetInnerHTML={{ __html: introduction }} />
                        </section>
                        <section id='tab-sections'>
                    <h3>Requirments</h3>
                    
                    <div id='tab-content' dangerouslySetInnerHTML={{ __html: requirments }} />
                    
                </section>
                        <h1 style={{fontSize:'22px'}}>Address Table</h1>
                        <div>
      <Box
        id="tab-content"
        sx={{
          overflowX: 'auto',
          maxWidth: '100%',
          maxHeight: '800px',
          padding: '10px', // Smaller padding on mobile
          boxSizing: 'border-box',
          fontSize: isSmallScreen ? '0.8rem' : '1rem', // Smaller text size on small screens
        }}
      >
        {/* <div id='tab-content'> */}
                        <table style={{ ...tableStyle, width: '100%', tableLayout: 'auto' }}>
                            <thead>
                                <tr>
                                    <th style={thStyle}>Device</th>
                                    <th style={thStyle}>Interface</th>
                                    <th style={thStyle}>Address</th>
                                    <th style={thStyle}>Subnet Mask</th>
                                    <th style={thStyle}>Default Gateway</th>
                                </tr>
                            </thead>
                            <tbody>
                 {Object.entries(groupedData).map(([device, interfaces], deviceIndex) => (
                    Object.entries(interfaces).map(([interfaceName, rows], interfaceIndex) => (
                     rows.map((row, rowIndex) => {
                    // Apply the 'no-bottom-cell-border' class conditionally to hide the bottom border
                    const isDeviceFirstRow = interfaceIndex === 0 && rowIndex === 0;
                    const isInterfaceFirstRow = rowIndex === 0;
    
                                        
                        return (
                            <tr key={`${deviceIndex}-${rowIndex}`}>
                        {isDeviceFirstRow && (
                          <td style={tdStyle} rowSpan={Object.keys(interfaces).length * rows.length}>
                            {device}
                            </td>
                        )}
                        {isInterfaceFirstRow && (
                            <td style={tdStyle} rowSpan={rows.length}>{interfaceName}</td>
                        )}
                        <td style={tdStyle}>{row.Address}</td>
                        <td style={tdStyle}>{row.SubnetMask}</td>
                        <td style={tdStyle}>{row.DefaultGateway}</td>

                     </tr>
                                        );
                                    })
                                ))
                            ))}
                            </tbody>
                        </table>
                        </Box>
                    </div>
                <section id='tab-sections'>
                <div id='tab-content' className= "instruction" dangerouslySetInnerHTML={{ __html: instructions }} />
                </section>
                <section id='tab-sections'>
                <h3>{Skills_TabButtonsInfo[selectedTopic].host_Tag}</h3>
    <div id='tab-content' style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        {Skills_TabButtonsInfo[selectedTopic].host_124_1.map((host_124_1, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                <WatermarkedImagePT
                    src={host_124_1}
                    watermarkText="AC Studies"
                    style={{
                        width: '300px',
                        height: 'auto',
                        animation: 'fadeIn 8s ease-in-out',
                        marginRight: '20px', // Add space between image and content
                    }}
                />
                <div dangerouslySetInnerHTML={{ __html: host_124_1_Config }} />
            </div>
        ))}
    
        {Skills_TabButtonsInfo[selectedTopic].host_124_5.map((host_124_5, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                <WatermarkedImagePT
                    src={host_124_5}
                    watermarkText="AC Studies"
                    style={{
                        width: '300px',
                        height: 'auto',
                        animation: 'fadeIn 8s ease-in-out',
                        marginRight: '20px', // Add space between image and content
                    }}
                />
                <div dangerouslySetInnerHTML={{ __html: host_124_5_Config }} />
            </div>
        ))}
        {Skills_TabButtonsInfo[selectedTopic].host_214_1.map((host_214_1, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                <WatermarkedImagePT
                    src={host_214_1}
                    watermarkText="AC Studies"
                    style={{
                        width: '300px',
                        height: 'auto',
                        animation: 'fadeIn 8s ease-in-out',
                        marginRight: '20px', // Add space between image and content
                    }}
                />
                <div dangerouslySetInnerHTML={{ __html: host_214_1_Config }} />
            </div>
        ))}
        {Skills_TabButtonsInfo[selectedTopic].host_Server.map((host_Server, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                <WatermarkedImagePT
                    src={host_Server}
                    watermarkText="AC Studies"
                    style={{
                        width: '300px',
                        height: 'auto',
                        animation: 'fadeIn 8s ease-in-out',
                        marginRight: '20px', // Add space between image and content
                    }}
                />
                <div dangerouslySetInnerHTML={{ __html: host_Server_Config }} />
            </div>
        ))}
    </div>
</section>

                <section id='tab-sections'style={{ position: 'relative' }}>
                <div dangerouslySetInnerHTML={{ __html: routerConfig }} />
                    <div id='tab-content' style={{ position: 'relative', padding: '20px' }}>
                        <pre>
                        <code>
                    <ul style={{ listStyleType: 'none', paddingLeft: '20px', fontFamily:'Roboto Condensed'}} ref={routerRef}>
                            {Skills_TabButtonsInfo[selectedTopic].router.map((router, index) => (
                                <li key={index}>{router}</li>
                            ))}
                            <div style={{ position: 'absolute', top: '-160px', right: '0' }}>
                            <CopyComponent ref={routerRef} />
                            </div>

                        </ul>
                        </code>
                        </pre>
                    </div>
                </section>

                <section id='tab-sections' style={{ position: 'relative' }}>
                <div dangerouslySetInnerHTML={{ __html: switch1Config }} />
                <div id='tab-content' style={{ position: 'relative', padding: '20px' }}>
                        <pre>
                        <code>
                    <ul style={{ listStyleType: 'none', paddingLeft: '20px', fontFamily:'Roboto Condensed'}} ref={switch1Ref}>
                            {Skills_TabButtonsInfo[selectedTopic].switch1.map((switch1, index) => (
                                <li key={index}>{switch1}</li>
                            ))}
                             <div style={{ position: 'absolute', top: '-160px', right: '0' }}>
                            <CopyComponent ref={switch1Ref} />
                            </div>
                        </ul>
                        </code>
                        </pre>
                    </div>
                </section>

            {/* Add a link image to download a file */}
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <a href="#!" onClick={()=>downloadFileAtURL(PSA_URL)}>
            <img 
              src="/packet tracer logo.png"
              alt="Download File" 
              className="colored-image bounce-on-click"
              style={{ width: '50px', height: '50px', cursor: 'pointer' }} 
            />
          </a>
        </div>
      </main>
            
      <ChapterNavigation 
      
      chapters={chapters}
      currentChapter={currentChapter}
      handleChapterChange={handleChapterChange}
      handleNextChapter={handleNextChapter}
      handlePreviousChapter={handlePreviousChapter}
      />
    <StudyRobot />
        </div>
    );
}
export default ITN_PSA_PT;
