import React, { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import TikTok from '../assets/TikTok1.png';
import styles from './share.module.css.css'
import { ThemeContext } from './Settings/Theme';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  EmailShareButton,
  FacebookMessengerShareButton,
  TelegramShareButton,
  FacebookIcon,
  XIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  LinkedinIcon,
  EmailIcon,
  FacebookMessengerIcon,
} from 'react-share';
import { IconButton, Box, Fade } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share'; // MUI Share Icon

const ShareComponent = () => {
  const { theme } = useContext(ThemeContext);
  const location = useLocation();
  const shareUrl = `https://alphacentauriinc.com${location.pathname}`;
  const title = 'Check out this amazing Learning Platform';
  
  const [hovered, setHovered] = useState(false);

  // TikTok share function
  const shareToTikTok = () => {
    const tiktokUrl = `https://www.tiktok.com/share?url=${encodeURIComponent(shareUrl)}`;
    window.open(tiktokUrl, '');
  };

  // Gmail share function
  const shareToGmail = () => {
    const email = ''; // Replace with the recipient's email
    const subject = encodeURIComponent('Check this out!');
    const body = encodeURIComponent(`Hey, check out this link: ${shareUrl}`);
    window.open(`mailto:${email}?subject=${subject}&body=${body}`, '_blank');
  };

  // Render the component only if not on the homepage
  if (location.pathname === '/') {
    return null; // Do not render on homepage
  }
  const iconStyle = {
    position: 'relative',
    top: 0,
    transition: 'transform 0.3s ease, opacity 0.3s ease',
    transform: hovered ? 'scale(1.2) rotate(5deg)' : 'scale(1) rotate(0deg)',
    opacity: hovered ? 1 : 0.7, // Slight transparency effect
  }

  const iconFillColor = theme ===  'dark' || theme === 'system'? 'black' : '';
  const iconSize = theme === 'default' || theme === 'light' ? 28  : 32;
  const iconLeft = theme === 'default' || theme === 'light' ? -200  : -230;
  return (
    <Box 
      onMouseEnter={() => setHovered(true)} 
      onMouseLeave={() => setHovered(false)} 
      sx={{ position: 'relative', display: 'inline-block' }}
    >
      <IconButton>
        <ShareIcon sx={{color:'white'}}/>
      </IconButton>

      <Fade in={hovered} timeout={300}>
        <Box
          sx={{
            position: 'absolute',
            left: iconLeft, // Adjust as needed
            top: 8,
            display: 'flex',
            flexDirection: 'row',
            transition: 'transform 0.1s ease',
            transform: hovered ? 'translateX(0)' : 'translateX(0%)',
            zIndex: 1,
          }}
        >
          <FacebookShareButton url={shareUrl} quote={title}>
            <FacebookIcon size={iconSize} round  bgStyle={{ fill: iconFillColor }}/>
          </FacebookShareButton>
          <TwitterShareButton url={shareUrl} title={title}>
            <XIcon size={iconSize} round />
          </TwitterShareButton>
          <WhatsappShareButton url={shareUrl} title={title}>
            <WhatsappIcon size={iconSize} round bgStyle={{ fill: iconFillColor }}/>
          </WhatsappShareButton>
          <LinkedinShareButton url={shareUrl} title={title}>
            <LinkedinIcon size={iconSize} round bgStyle={{ fill: iconFillColor }}/>
          </LinkedinShareButton>
          <EmailShareButton url={shareUrl} subject={title}>
            <EmailIcon size={iconSize} round bgStyle={{ fill: iconFillColor }}/>
          </EmailShareButton>
          <FacebookMessengerShareButton url={shareUrl} title={title}>
            <FacebookMessengerIcon size={iconSize} round bgStyle={{ fill: iconFillColor }}/>
          </FacebookMessengerShareButton>
          <TelegramShareButton url={shareUrl} title={title}>
            <TelegramIcon size={iconSize} round bgStyle={{ fill: iconFillColor}}/>
          </TelegramShareButton>
          {/* <button onClick={shareToGmail}>
            Share via Gmail
          </button> */}
          {/* <button onClick={shareToTikTok} style={{background:'transparent', border:'none', cursor: 'pointer'}}>
            <img src={TikTok} alt="Share on TikTok" style={{ width: '48px', height: '48px', mixBlendMode: 'color-burn', 
                padding:0, backgroundColor: 'transparent', margin: 0, justifySelf:'left' }} round />
          </button> */}

        </Box>
      </Fade>
    </Box>
  );
};

export default ShareComponent;
