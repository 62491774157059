import TypeAImg from './chapter-6/ch6_skillPT.png'
import TypeBImg from './chapter-6/ch6_TypeB.png'
import TypeCImg from './chapter-6/ch6_TypeC.png'
import TypeCImg2 from './chapter-6/ch6_TypeC2.png'

export const Skills_TabButtonsInfo = {
    TypeA: {
      image: [TypeAImg],
      title: 'Type A',
      description:
        'You might be given One (1) of these Three (3) Topology.',
      routerConfig: 'College Router Configuration', 
      switch1Config:'Faculty Switch Configuration', 
      switch2Config:'Admin Switch Configuration', 
      endDevice1:'Prof X',
      endDevice2:'Prof Y',
      endDevice3:'Dean X',
      endDevice4:'Dean Y',

  tableData: [
    {
      Device: 'College',
      Interface: 'G0/0',
      Address: '172.16.5.1',
      SubnetMask: '255.255.255.0',
      DefaultGateway: 'N/A',
      
    },
    {
      Device: 'College',
      Interface: 'G0/1',
      Address: '192.168.5.1',
      SubnetMask: '255.255.255.0',
      DefaultGateway: 'N/A',
    },
    {
      Device: 'Faculty',
      Interface: 'VLAN 1',
      Address: '172.16.5.2',
      SubnetMask: '255.255.255.0',
      DefaultGateway: '172.16.5.1',
    },
    {
      Device: 'Admin',
      Interface: 'VLAN 1',
      Address: '192.168.5.252',
      SubnetMask: '255.255.255.0',
      DefaultGateway: '192.168.5.1',
    },
    {
      Device: 'Prof X',
      Interface: 'NIC',
      Address: '172.16.5.10',
      SubnetMask: '255.255.255.0',
      DefaultGateway: '172.16.5.1',
    },
    {
      Device: 'Prof Y',
      Interface: 'NIC',
      Address: '172.16.5.11',
      SubnetMask: '255.255.255.0',
      DefaultGateway: '172.16.5.1',
    },
    {
      Device: 'Dean X',
      Interface: 'NIC',
      Address: '192.168.5.10',
      SubnetMask: '255.255.255.0',
      DefaultGateway: '192.168.5.1',
    },
    {
      Device: 'Dean Y',
      Interface: 'NIC',
      Address: '192.168.5.11',
      SubnetMask: '255.255.255.0',
      DefaultGateway: '192.168.5.1',
    },
  ],

  objectives:[
            "Perform basic device configuration tasks on a router and a switch",
            "Configure IP addressing settings on network devices",
            "Verify Layer 3 connectivity and troubleshoot connectivity issues"
  ],

  scenario:'You are working on a network that has already been partially configured In this assessment you will complete a set of requirements by configure some additional settings on the Faculty switch and the router. Use the knowledge that you have gained in the curriculum and labs to fulfill the requirements below',
   
  requirments: [
    "Determine the values that are missing from the Addressing Table",
    "Configure all devices with the missing default gateway values",
    "Name the Faculty switch Faculty Your configuration must match this value exactly",
    "Secure access to all configuration lines of the Faculty switch",
    "Secure access to the device configurations of the Faculty switch using the encrypted password",
    "Ensure that all plain text passwords on the Faculty switch are encrypted",
    "Configure an appropriate banner on the Faculty switch",
    "Configure addressing for all devices according to the Addressing Table Most values are provided in the table, others you must determine",
    "Document interfaces with descnptions on the College router interfaces and the Faculty switch virtual interface",
    "Save your configurations",
    "Verify connectivity between all devices All devices should be able to ping all of the other devices",
    "Troubleshoot any connectivity problems",
  ],
    
   note: "All devices should be able to ping one another when you have successfully completed the activity.",

   router:[
    "en",
"config t",

"hostname College",

"enable secret cisco",

"line console 0",
"password cisco",
"login",
"exit",

"line vty 0 4",
"password cisco",
"login",
"exit",

"line aux 0",
"password cisco",
"login",
"exit",

"service password-encryption",

"banner motd $Authorized Users Only$",

"interface g0/0",
"ip address 172.16.5.1 255.255.255.0",
"no shut",
"description Faculty LAN",
"exit",

"interface g0/1",
"ip address 192.168.5.1 255.255.255.0",
"no shut",
"description Admin LAN",
"end",

"write"
   ],

   switch1:[
    "en",
"config t",

"hostname Faculty",

"enable secret class",

"line console 0",
"password cisco",
"login",
"exit",

"line vty 0 4",
"password cisco",
"login",
"exit",

"service password-encryption",

"banner motd $Authorized Users Only$",

"interface vlan 1",
"ip address 172.16.5.2 255.255.255.0",
"no shut",
"description Faculty – College LAN",
"exit",

"ip default-gateway 172.16.5.1",

"end",
"write"
   ],

   switch2:[
    "enable",
"config t",

"hostname Admin",

"enable secret class",

"line console 0",
"password cisco",
"login",
"exit",

"line vty 0 4",
"password cisco",
"login",
"exit",

"service password-encryption",

"banner motd $Authorized Users Only$",

"interface vlan 1",
"ip address 192.168.5.252 255.255.255.0",
"no shut",
"description Admin – College LAN",
"exit",

"ip default-gateway 192.168.5.1",
"end",

"write"
   ],

device1:[
"IP Address: 172.16.5.10",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 172.16.5.1"
   ],

device2:[
"IP Address: 172.16.5.11",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 172.16.5.1"
],

device3:[
"IP Address: 192.168.5.10",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 192.168.5.1"
],

device4:[
"IP Address: 192.168.5.11",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 192.168.5.1"
]
  
  //route:'/ccna/ccna',
    },
    TypeB: {
        image: [TypeBImg],
      title: 'Type B',
      description:
        'You might be given One (1) of these Three (3) Topology.',
      routerConfig: 'Housing Router Configuration', 
      switch1Config:'Bldg1 Switch Configuration', 
      switch2Config:'Bldg2 Switch Configuration', 
      endDevice1:'RA1-1',
      endDevice2:'RA1-2',
      endDevice3:'RA2-1',
      endDevice4:'RA2-2',

    tableData: [
    {
      Device: 'Housing',
      Interface: 'G0/0',
      Address: '172.16.5.1',
      SubnetMask: '255.255.255.0',
      DefaultGateway: 'N/A',
      
    },
    {
      Device: 'Housing',
      Interface: 'G0/1',
      Address: '192.168.5.1',
      SubnetMask: '255.255.255.0',
      DefaultGateway: 'N/A',
    },
    {
      Device: 'Bldg1',
      Interface: 'VLAN 1',
      Address: '172.16.5.2',
      SubnetMask: '255.255.255.0',
      DefaultGateway: '172.16.5.1',
    },
    {
      Device: 'Bldg2',
      Interface: 'VLAN 1',
      Address: '192.168.5.252',
      SubnetMask: '255.255.255.0',
      DefaultGateway: '192.168.5.1',
    },
    {
      Device: 'RA1-1',
      Interface: 'NIC',
      Address: '172.16.5.10',
      SubnetMask: '255.255.255.0',
      DefaultGateway: '172.16.5.1',
    },
    {
      Device: 'RA1-2',
      Interface: 'NIC',
      Address: '172.16.5.11',
      SubnetMask: '255.255.255.0',
      DefaultGateway: '172.16.5.1',
    },
    {
      Device: 'RA2-1',
      Interface: 'NIC',
      Address: '192.168.5.10',
      SubnetMask: '255.255.255.0',
      DefaultGateway: '192.168.5.1',
    },
    {
      Device: 'RA2-2',
      Interface: 'NIC',
      Address: '192.168.5.11',
      SubnetMask: '255.255.255.0',
      DefaultGateway: '192.168.5.1',
    },
],

    objectives:[
        "Perform basic device configuration tasks on a router and a switch",
        "Configure IP addressing settings on network devices",
        "Verify Layer 3 connectivity and troubleshoot connectivity issues"
],

scenario:'You are working on a network that has already been partially configured In this assessment you will complete a set of requirements by configunng some additional settings on the Faculty switch and the router. Use the knowledge that you have gained in the curnculum and labs to fulfill the requirements below',

requirments: [
"Determine the values that are missing from the Addressing Table",
"Configure all devices with the missing default gateway values",
"Name the Faculty switch Faculty Your configuration must match this value exactly",
"Secure access to all configuration lines of the Faculty switch",
"Secure access to the device configurations of the Faculty switch using the encrypted password",
"Ensure that all plain text passwords on the Faculty switch are encrypted",
"Configure an appropriate banner on the Faculty switch",
"Configure addressing for all devices according to the Addressing Table Most values are provided in the table, others you must determine",
"Document interfaces with descnptions on the College router interfaces and the Faculty switch virtual interface",
"Save your configurations",
"Verify connectivity between all devices All devices should be able to ping all of the other devices",
"Troubleshoot any connectivity problems",
],

note: "All devices should be able to ping one another when you have successfully completed the activity.",

router:[
"en",
"config t",

"hostname Housing",

"enable secret cisco",

"line console 0",
"password cisco",
"login",
"exit",

"line vty 0 4",
"password cisco",
"login",
"exit",

"line aux 0",
"password cisco",
"login",
"exit",

"service password-encryption",

"banner motd $Authorized Users Only$",

"interface g0/0",
"ip address 172.16.5.1 255.255.255.0",
"no shut",
"description Bldg1 LAN",
"exit",

"interface g0/1",
"ip address 192.168.5.1 255.255.255.0",
"no shut",
"description Bldg2 LAN",
"end",

"write"
],

switch1:[
"en",
"config t",

"hostname Bldg1",

"enable secret class",

"line console 0",
"password cisco",
"login",
"exit",

"line vty 0 4",
"password cisco",
"login",
"exit",

"service password-encryption",

"banner motd $Authorized Users Only$",

"interface vlan 1",
"ip address 172.16.5.2 255.255.255.0",
"no shut",
"description Bldg1 – Housing LAN",
"exit",

"ip default-gateway 172.16.5.1",

"end",
"write"
],

switch2:[
"enable",
"config t",

"hostname Bldg2",

"enable secret class",

"line console 0",
"password cisco",
"login",
"exit",

"line vty 0 4",
"password cisco",
"login",
"exit",

"service password-encryption",

"banner motd $Authorized Users Only$",

"interface vlan 1",
"ip address 192.168.5.252 255.255.255.0",
"no shut",
"description Bldg2 – Housing LAN",
"exit",

"ip default-gateway 192.168.5.1",
"end",

"write"
],

device1:[
"IP Address: 172.16.5.10",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 172.16.5.1"
],

device2:[
"IP Address: 172.16.5.11",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 172.16.5.1"
],

device3:[
"IP Address: 192.168.5.10",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 192.168.5.1"
],

device4:[
"IP Address: 192.168.5.11",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 192.168.5.1"
]

      //  route:'/math',
    },
    TypeC: {
        image: [TypeCImg,TypeCImg2],
        title: 'Type C',
        description:
          'You might be given One (1) of these Three (3) Topology.',
        routerConfig: 'Science Router Configuration', 
        switch1Config:'Bio Switch Configuration', 
        switch2Config:'Phys Switch Configuration', 
        endDevice1:'Bio 1',
        endDevice2:'Bio 2',
        endDevice3:'Phys 1',
        endDevice4:'Phys 2',
  
    tableData: [
      {
        Device: 'Science',
        Interface: 'G0/0',
        Address: '172.16.5.1',
        SubnetMask: '255.255.255.0',
        DefaultGateway: 'N/A',
        
      },
      {
        Device: 'Science',
        Interface: 'G0/1',
        Address: '192.168.5.1',
        SubnetMask: '255.255.255.0',
        DefaultGateway: 'N/A',
      },
      {
        Device: 'Bio',
        Interface: 'VLAN 1',
        Address: '172.16.5.2',
        SubnetMask: '255.255.255.0',
        DefaultGateway: '172.16.5.1',
      },
      {
        Device: 'Phys',
        Interface: 'VLAN 1',
        Address: '192.168.5.252',
        SubnetMask: '255.255.255.0',
        DefaultGateway: '192.168.5.1',
      },
      {
        Device: 'Bio-1',
        Interface: 'NIC',
        Address: '172.16.5.10',
        SubnetMask: '255.255.255.0',
        DefaultGateway: '172.16.5.1',
      },
      {
        Device: 'Bio-2',
        Interface: 'NIC',
        Address: '172.16.5.11',
        SubnetMask: '255.255.255.0',
        DefaultGateway: '172.16.5.1',
      },
      {
        Device: 'Phys-1',
        Interface: 'NIC',
        Address: '192.168.5.10',
        SubnetMask: '255.255.255.0',
        DefaultGateway: '192.168.5.1',
      },
      {
        Device: 'Phys-2',
        Interface: 'NIC',
        Address: '192.168.5.11',
        SubnetMask: '255.255.255.0',
        DefaultGateway: '192.168.5.1',
      },
    ],
  
    objectives:[
              "Perform basic device configuration tasks on a router and a switch",
              "Configure IP addressing settings on network devices",
              "Verify Layer 3 connectivity and troubleshoot connectivity issues"
    ],
  
    scenario:'You are working on a network that has already been partially configured In this assessment you will complete a set of requirements by configuring some additional settings on the Faculty switch and the router. Use the knowledge that you have gained in the curriculum and labs to fulfill the requirements below',
     
    requirments: [
      "Determine the values that are missing from the Addressing Table",
      "Configure all devices with the missing default gateway values",
      "Name the Faculty switch Faculty Your configuration must match this value exactly",
      "Secure access to all configuration lines of the Faculty switch",
      "Secure access to the device configurations of the Faculty switch using the encrypted password",
      "Ensure that all plain text passwords on the Faculty switch are encrypted",
      "Configure an appropriate banner on the Faculty switch",
      "Configure addressing for all devices according to the Addressing Table Most values are provided in the table, others you must determine",
      "Document interfaces with descnptions on the College router interfaces and the Faculty switch virtual interface",
      "Save your configurations",
      "Verify connectivity between all devices All devices should be able to ping all of the other devices",
      "Troubleshoot any connectivity problems",
    ],
      
     note: "All devices should be able to ping one another when you have successfully completed the activity.",
  
     router:[
      "en",
  "config t",
  
  "hostname Science",
  
  "enable secret cisco",
  
  "line console 0",
  "password cisco",
  "login",
  "exit",
  
  "line vty 0 4",
  "password cisco",
  "login",
  "exit",
  
  "line aux 0",
  "password cisco",
  "login",
  "exit",
  
  "service password-encryption",
  
  "banner motd $Authorized Users Only$",
  
  "interface g0/0",
  "ip address 172.16.5.1 255.255.255.0",
  "no shut",
  "description Bio LAN",
  "exit",
  
  "interface g0/1",
  "ip address 192.168.5.1 255.255.255.0",
  "no shut",
  "description Phys LAN",
  "end",
  
  "write"
     ],
  
     switch1:[
      "en",
  "config t",
  
  "hostname Bio",
  
  "enable secret class",
  
  "line console 0",
  "password cisco",
  "login",
  "exit",
  
  "line vty 0 4",
  "password cisco",
  "login",
  "exit",
  
  "service password-encryption",
  
  "banner motd $Authorized Users Only$",
  
  "interface vlan 1",
  "ip address 172.16.5.2 255.255.255.0",
  "no shut",
  "description Bio – Science LAN",
  "exit",
  
  "ip default-gateway 172.16.5.1",
  
  "end",
  "write"
     ],
  
     switch2:[
      "enable",
  "config t",
  
  "hostname Phys",
  
  "enable secret class",
  
  "line console 0",
  "password cisco",
  "login",
  "exit",
  
  "line vty 0 4",
  "password cisco",
  "login",
  "exit",
  
  "service password-encryption",
  
  "banner motd $Authorized Users Only$",
  
  "interface vlan 1",
  "ip address 192.168.5.252 255.255.255.0",
  "no shut",
  "description Phys – Science LAN",
  "exit",
  
  "ip default-gateway 192.168.5.1",
  "end",
  
  "write"
     ],
  
  device1:[
  "IP Address: 172.16.5.10",
  "Subnet Mask: 255.255.255.0",
  "Default Gateway: 172.16.5.1"
     ],
  
  device2:[
  "IP Address: 172.16.5.11",
  "Subnet Mask: 255.255.255.0",
  "Default Gateway: 172.16.5.1"
  ],
  
  device3:[
  "IP Address: 192.168.5.10",
  "Subnet Mask: 255.255.255.0",
  "Default Gateway: 192.168.5.1"
  ],
  
  device4:[
  "IP Address: 192.168.5.11",
  "Subnet Mask: 255.255.255.0",
  "Default Gateway: 192.168.5.1"
  ]
  
  }
}