import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './MatchingComponent.css';

const MatchingQuestion = ({ question, onSelectAnswer, selectedAnswers }) => {
  const [matches, setMatches] = useState(selectedAnswers || {});

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;
    const newMatches = { ...matches };

    // Remove the option from its previous match if it exists
    Object.keys(newMatches).forEach(key => {
      if (Array.isArray(newMatches[key])) {
        newMatches[key] = newMatches[key].filter(id => id !== result.draggableId);
        if (newMatches[key].length === 0) delete newMatches[key];
      } else if (newMatches[key] === result.draggableId) {
        delete newMatches[key];
      }
    });

    // Add new match
    if (destination.droppableId !== 'options') {
      const correctMatchesForItem = question.correctMatches[destination.droppableId];
      if (correctMatchesForItem.length > 1) {
        if (!newMatches[destination.droppableId]) {
          newMatches[destination.droppableId] = [];
        }
        if (newMatches[destination.droppableId].length < correctMatchesForItem.length) {
          newMatches[destination.droppableId].push(result.draggableId);
        }
      } else {
        newMatches[destination.droppableId] = result.draggableId;
      }
    }

    setMatches(newMatches);
    onSelectAnswer(question.id, newMatches);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="matching-question">
        {/* <h3>{question.question}</h3> */}
        <div className="matching-container">
          <div style={{color: 'white'}} className="matching-items">
            {question.items.map((item) => (
              <Droppable key={item.id} droppableId={item.id}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={`matching-item ${snapshot.isDraggingOver ? 'dragging-over' : ''}`}
                  >
                    <span>{item.content}</span>
                    {matches[item.id] && (
                      Array.isArray(matches[item.id]) ? (
                        matches[item.id].map((optionId, index) => (
                          <Draggable key={optionId} draggableId={optionId} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`matching-option ${snapshot.isDragging ? 'dragging' : ''}`}
                              >
                                {question.options.find((opt) => opt.id === optionId)?.content}
                              </div>
                            )}
                          </Draggable>
                        ))
                      ) : (
                        <Draggable key={matches[item.id]} draggableId={matches[item.id]} index={0}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={`matching-option ${snapshot.isDragging ? 'dragging' : ''}`}
                            >
                              {question.options.find((opt) => opt.id === matches[item.id])?.content}
                            </div>
                          )}
                        </Draggable>
                      )
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
          </div>
          <Droppable droppableId="options">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="matching-options"
              >
                {question.options
                  .filter((option) => {
                    const matchedOptions = Object.values(matches).flat();
                    return !matchedOptions.includes(option.id);
                  })
                  .map((option, index) => (
                    <Draggable key={option.id} draggableId={option.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`matching-option ${snapshot.isDragging ? 'dragging' : ''}`}
                        >
                          {option.content}
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </DragDropContext>
  );
};

export default MatchingQuestion;