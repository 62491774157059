import React, { useContext, useState, useEffect, useRef } from 'react';
import { ThemeContext } from './Theme';
import { useTranslation } from 'react-i18next';
import { FaCog } from 'react-icons/fa';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { Tooltip } from '@mui/material';


import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';



import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import AutoModeRoundedIcon from '@mui/icons-material/AutoModeRounded';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';




const Settings = () => {
  const { setTheme } = useContext(ThemeContext);
  // const { theme, setTheme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [selectedDevice, setSelectedDevice] = useState(''); // Default to 'light'

  const handleDeviceChange = (event, newDevice) => {
    if (newDevice) {
      setSelectedDevice(newDevice);
      handleThemeChange(newDevice);
    }
  };


  // const [devices, setDevices] = React.useState(() => ['']);

// const handleDevices = (event, newDevices) => {
//   if (newDevices.length) {
//     setDevices(newDevices);
//   }
// };
const handleThemeChange = (theme) => {
  setTheme(theme);
  setIsDropdownOpen(false);
};

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);


  // const toggleDropdown = () => {
  //   setIsDropdownOpen(!isDropdownOpen);
  // };

  return (

      <MenuList ref={dropdownRef}>
        <MenuItem>
        <Stack direction="row" spacing={4}>
        <ToggleButtonGroup
        value={selectedDevice}
        exclusive
        onChange={handleDeviceChange}
        aria-label="device"
      >
        <Tooltip title={t('Light Mode')}>
        <ToggleButton sx={{background: 'rgba(213, 211, 211, 0.15)', 
                    backdropFilter: 'blur(0.5px)', WebkitBackdropFilter: 'blur(0.5px)',
                    borderRadius: '10px', border: '1px solid rgba(127, 173, 229, 0.5)', color: 'white' , padding: '5px'} } value="light" aria-label="light" 
                    onClick={() => handleThemeChange('light')}>
          <LightModeRoundedIcon sx={{color:'#1976d2'}} />
        </ToggleButton>
        </Tooltip>
        <Tooltip title={t('System Mode')}>
        <ToggleButton sx={{ background: 'rgba(213, 211, 211, 0.15)', 
                    backdropFilter: 'blur(0.5px)', WebkitBackdropFilter: 'blur(0.5px)', 
                    borderRadius: '10px', border: '1px solid rgba(127, 173, 229, 0.5)', color: 'white', padding: '5px' } } value="system" aria-label="system"
                    onClick={() => handleThemeChange('system')}>
          <SettingsBrightnessIcon sx={{color: '#1976d2'}} />
        </ToggleButton>
        </Tooltip>
        <Tooltip title={t('Dark Mode')}>
        <ToggleButton sx={{background: 'rgba(213, 211, 211, 0.15)', 
                    backdropFilter: 'blur(0.5px)', WebkitBackdropFilter: 'blur(0.5px)',
                    borderRadius: '10px', border: '1px solid rgba(127, 173, 229, 0.5)', color: 'white', padding: '5px' } } value="dark" aria-label="dark"
                    onClick={() => handleThemeChange('dark')}>
          <DarkModeOutlinedIcon sx={{color: '#1976d2'}} 
          />
        </ToggleButton>
        </Tooltip>
        <Tooltip title={t('Default Mode')}>
        <ToggleButton sx={{ background: 'rgba(213, 211, 211, 0.15)', 
                    backdropFilter: 'blur(0.5px)', WebkitBackdropFilter: 'blur(0.5px)',
                    borderRadius: '10px', border: '1px solid rgba(127, 173, 229, 0.5)', color: 'white', padding: '5px' } } value="default" aria-label="default"
                    onClick={() => handleThemeChange('default')}>
          <AutoModeRoundedIcon sx={{color: '#1976d2'}} />
        </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
          </Stack>
          </MenuItem>
          </MenuList>
  );
}



//     <div style={containerStyle}>
//       <Tooltip title="Settings">
//       <SettingsRoundedIcon
//         onClick={toggleDropdown}
//         style={iconStyle}
//         aria-label={t('Settings')}
//         />
//       </Tooltip>
//       {isDropdownOpen && (
//         <div ref={dropdownRef} style={dropdownStyle}>
//           <div style={optionStyle} onClick={() => handleThemeChange('light')}>
//             {t('Light Mode')}
//           </div>
//           <div style={optionStyle} onClick={() => handleThemeChange('dark')}>
//             {t('Dark Mode')}
//           </div>
//           <div style={optionStyle} onClick={() => handleThemeChange('system')}>
//             {t('System Mode')}
//           </div>
//           <div style={optionStyle} onClick={() => handleThemeChange('default')}>
//             {t('Default Mode')}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// Styles (reuse the same styles)
// const containerStyle = {

// };

// const iconStyle = {
//   fontSize: '24px',
//   cursor: 'pointer',
//   color: 'var(--text-color)',
// };

// const dropdownStyle = {
//   position: 'absolute',
//   top: '30px',
//   right: '0',
//   backgroundColor: '#333',
//   border: '1px solid #ddd',
//   boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
//   zIndex: 1000,
//   width: '150px',
//   borderRadius: '4px',
// };

// const optionStyle = {
//   padding: '10px',
//   cursor: 'pointer',
//   borderBottom: '1px solid #ddd',
//   transition: 'background-color 0.2s ease',
//   display: 'flex',
//   alignItems: 'center',
// };

export default Settings;
