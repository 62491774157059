import React, { useState, useContext } from 'react';
import {
    Drawer,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Box,
    Fade
} from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate and useLocation
import Settings from '../../../Settings/Setting';
import Language from '../../../Settings/Language';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  EmailShareButton,
  FacebookMessengerShareButton,
  TelegramShareButton,
  FacebookIcon,
  XIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  LinkedinIcon,
  EmailIcon,
  FacebookMessengerIcon,
} from 'react-share';
import ShareIcon from '@mui/icons-material/Share'; // MUI Share Icon
import { ThemeContext } from '../../../Settings/Theme';

const DrawerComp = ({ links, chapters = [] }) => {
    const [open, setOpen] = useState(false);
    const [showChapters, setShowChapters] = useState(false); // State for showing chapters
    const [showShare, setShowShare] = useState(false); // State for showing share buttons
    const navigate = useNavigate(); // Initialize useNavigate
    const location = useLocation(); // Get current location for share URL
    const { theme } = useContext(ThemeContext);


    const shareUrl = `https://alphacentauriinc.com/${location.pathname}`;
    const title = 'Check out this amazing Learning Platform';

    // Function to handle navigation based on link index
    const handleNavigation = (index) => {
        setOpen(false); // Close the drawer
        if (index === 0) {
            navigate('/'); // Navigate to CCNA
        } else if (index === 1) {
            navigate('/ccna/ccna'); // Navigate to IP Calculator
        } else if (index === 2) {
            navigate('/ccna/Calculator/IP_Calculator');
        } else if (index === 3) {
            navigate('/ccna/ccna1/ccna1_quiz/QuizApp');
        }
    };

    // Function to toggle chapter visibility
    const handleToggleChapters = () => {
        setShowChapters(!showChapters);
    };

    const handleChapterSelect = (chapter) => {
        navigate(chapter.path);
        setOpen(false); // Close the drawer after selection
        setShowChapters(false); // Close chapters menu if it's open
    };

    const handleToggleShare = () => {
        setShowShare(!showShare); // Toggle share button visibility
    };

    // const iconSize = 28;
    const iconFillColor = theme ===  'dark' || theme === 'system'? 'black' : '';
    const iconSize = theme === 'default' || theme === 'light' ? 28  : 32;
    const iconLeft = theme === 'default' || theme === 'light' ? -200  : -230;

    return (
        <>
            <Drawer
                PaperProps={{ sx: { background: 'rgba( 255, 255, 255, 0.05 )', boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )', 
                backdropFilter: 'blur(2px)', WebkitBackdropFilter: 'blur(2px)', borderRadius: '10px', border: '1px solid rgba( 255, 255, 255, 0.18 )' } }}
                anchor='left'
                open={open}
                onClose={() => setOpen(false)}
            >
                <List>
                    {links.map((link, index) => (
                        <ListItemButton
                            onClick={() => handleNavigation(index)} // Call the navigation function
                            key={index}
                            divider
                        >
                            <ListItemText sx={{ color: 'white' }}>
                                {link}
                            </ListItemText>
                        </ListItemButton>
                    ))}
                    <Divider sx={{ backgroundColor: 'white' }} />
                    <ListItemButton onClick={handleToggleChapters}>
                        <ListItemText sx={{ color: 'white' }} primary="Chapters" />
                    </ListItemButton>
                    {showChapters && (
                        <List>
                            {chapters.map((chapter, index) => (
                                <ListItemButton
                                    key={index}
                                    onClick={() => handleChapterSelect(chapter)}
                                    sx={{
                                        paddingLeft: 4, // Indent the chapters
                                    }}
                                >
                                    <ListItemText sx={{ color: 'white' }}>
                                        {chapter.name}
                                    </ListItemText>
                                </ListItemButton>
                            ))}
                        </List>
                    )}
                    <Divider sx={{ backgroundColor: 'white' }} /> {/* Divider before Settings */}
                    <ListItemButton>
                        <ListItemText sx={{ color: 'white' }} primary="Settings" />
                    </ListItemButton>
                    <Settings sx={{ background: 'white' }} />
                    <Divider sx={{ backgroundColor: 'white' }} /> {/* Divider before Language */}
                    <ListItemButton>
                        <ListItemText sx={{ color: 'white' }} primary="Language" />
                    </ListItemButton>
                    <Language sx={{ background: '' }} />
                    <Divider sx={{ backgroundColor: 'white' }} /> {/* Divider before Share */}
                    <ListItemButton onClick={handleToggleShare}>
                        <ListItemText sx={{ color: 'white' }} primary="Share" />
                    </ListItemButton>
                    {showShare && (
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <FacebookShareButton url={shareUrl} quote={title}>
                                <FacebookIcon size={iconSize} round bgStyle={{ fill: iconFillColor }} />
                            </FacebookShareButton>
                            <TwitterShareButton url={shareUrl} title={title}>
                                <XIcon size={iconSize} round />
                            </TwitterShareButton>
                            <WhatsappShareButton url={shareUrl} title={title}>
                                <WhatsappIcon size={iconSize} round bgStyle={{ fill: iconFillColor }} />
                            </WhatsappShareButton>
                            <LinkedinShareButton url={shareUrl} title={title}>
                                <LinkedinIcon size={iconSize} round bgStyle={{ fill: iconFillColor }} />
                            </LinkedinShareButton>
                            <EmailShareButton url={shareUrl} subject={title}>
                                <EmailIcon size={iconSize} round bgStyle={{ fill: iconFillColor }} />
                            </EmailShareButton>
                            <FacebookMessengerShareButton url={shareUrl} title={title}>
                                <FacebookMessengerIcon size={iconSize} round bgStyle={{ fill: iconFillColor }} />
                            </FacebookMessengerShareButton>
                            <TelegramShareButton url={shareUrl} title={title}>
                                <TelegramIcon size={iconSize} round bgStyle={{ fill: iconFillColor }} />
                            </TelegramShareButton>
                        </Box>
                    )}
                    <Divider sx={{ backgroundColor: 'white' }} />
                </List>
            </Drawer>
            <IconButton sx={{ marginLeft: 'auto', color: 'white' }} onClick={() => setOpen(!open)}>
                <MenuRoundedIcon />
            </IconButton>
        </>
    );
};

export default DrawerComp;
