import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Container, FormControl, IconButton, InputLabel, Select, MenuItem, Grid, Dialog, DialogContent } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import NavigationBar from '../CCNA2/NavigationBar';
import Utilities from '../../../utilities/utilities';
import { Helmet } from 'react-helmet';

const IP_Calculator = () => {
  const [ipParts, setIpParts] = useState(['', '', '', '']);
  const [classType, setClassType] = useState('');
  const [specialPurpose, setSpecialPurpose] = useState('');
  const [prefix, setPrefix] = useState('');
  const [subnetMask, setSubnetMask] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [networkAddress, setNetworkAddress] = useState('');
  const [hostRange, setHostRange] = useState('');
  const [numberOfHosts, setNumberOfHosts] = useState('');
  const [broadcastAddress, setBroadcastAddress] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const [isPeerToPeer, setIsPeerToPeer] = useState(false);
  const [totalIPs, setTotalIPs] = useState('');
  const [subnetBinary, setSubnetBinary] = useState('');
  const [ipType, setIpType] = useState('');
  const [wildcardMask, setWildcardMask] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [ipRepresentations, setIpRepresentations] = useState({
    decimal: '',
    hexadecimal: '',
    binary: '',
    octal: ''
  });

  <div>
    <Helmet>
      <title>IPv4 Address Calculator - Convert IPv4 to IPv6, Binary, Decimal & More | Alpha Centauri Inc.</title>
      <meta
        name="description"
        content="Use the IPv4 Calculator to convert IPv4 addresses into IPv6, binary, decimal, octal, hexadecimal. Get detailed IP information including subnet mask, network range, broadcast, host count, wildcard, and more."
      />
      <meta
        name="keywords"
        content="IPv4 calculator, IPv4 to IPv6, binary conversion, subnet mask, IP address calculator, convert IPv4 to binary, network address, host range, wildcard mask, peer-to-peer IP, IPv4 subnetting"
      />
      <meta
        name="robots"
        content="index, follow"
      />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content="IPv4 Address Calculator - Convert IPv4 to IPv6, Binary, Decimal & More" />
      <meta property="og:description" content="Convert IPv4 addresses into IPv6, binary, octal, hexadecimal, and decimal. Get detailed network information including subnet mask, network range, and host address range." />
      <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
      <meta property="og:url" content="https://alphacentauriinc.com/ccna/Calculator/IP_Calculator" />
      <meta property="og:type" content="website" />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:title" content="IPv4 Address Calculator - Convert IPv4 to IPv6, Binary, Decimal & More" />
      <meta name="twitter:description" content="Use our IPv4 calculator to convert addresses into IPv6, binary, and other formats. Get network details like host count, subnet mask, broadcast range, and more." />
      <meta name="twitter:image" content="%PUBLIC_URL%/logo192.png" />
      <meta name="twitter:card" content="summary_large_image" />

      {/* Canonical Link */}
      <link rel="canonical" href="https://alphacentauriinc.com/ccna/Calculator/IP_Calculator" />

      {/* Structured Data for Article/Tools */}
      <script type="application/ld+json">
        {{
          "@context": "https://schema.org",
          "@type": "WebApplication",
          "name": "IPv4 Address Calculator",
          "description": "Convert IPv4 addresses into IPv6, binary, decimal, octal, hexadecimal, and more. View network and broadcast details, subnet mask, host range, and more.",
          "url": "https://alphacentauriinc.com/ccna/Calculator/IP_Calculator",
          "logo": "%PUBLIC_URL%/logo192.png",
          "publisher": {
            "@type": "Organization",
            "name": "Alpha Centauri Inc."
          },
          "mainEntityOfPage": "https://alphacentauriinc.com/ccna/Calculator/IP_Calculator"
        }}
      </script>

    </Helmet>

    {/* Page content */}
    <h1>IPv4 Address Calculator</h1>
    <p>Our IPv4 Address Calculator helps you convert IPv4 addresses into IPv6, binary, decimal, octal, and hexadecimal formats. Additionally, it provides in-depth network details such as the number of hosts, network and broadcast addresses, subnet mask, wildcard mask, and more.</p>

    {/* Add the content for the calculator and its features here */}
    {/* (e.g., IPv4 Address input form, conversion options, results display, etc.) */}
  </div>

  const links = ['Home','Courses', 'Calculator'];
  const chapters = []; // or some array of chapter objects if applicable
  const onSearch = (searchTerm) => {
    // Handle search logic
  };

  const subnetMasksByClass = {
    'Class A': [
      { prefix: 8, mask: '255.0.0.0' },
      { prefix: 9, mask: '255.128.0.0' },
      { prefix: 10, mask: '255.192.0.0' },
      { prefix: 11, mask: '255.224.0.0' },
      { prefix: 12, mask: '255.240.0.0' },
      { prefix: 13, mask: '255.248.0.0' },
      { prefix: 14, mask: '255.252.0.0' },
      { prefix: 15, mask: '255.254.0.0' },
      { prefix: 16, mask: '255.255.0.0' },
      { prefix: 17, mask: '255.255.128.0' },
      { prefix: 18, mask: '255.255.192.0' },
      { prefix: 19, mask: '255.255.224.0' },
      { prefix: 20, mask: '255.255.240.0' },
      { prefix: 21, mask: '255.255.248.0' },
      { prefix: 22, mask: '255.255.252.0' },
      { prefix: 23, mask: '255.255.254.0' },
      { prefix: 24, mask: '255.255.255.0' }
    ],
    'Class B': [
      { prefix: 16, mask: '255.255.0.0' },
      { prefix: 17, mask: '255.255.128.0' },
      { prefix: 18, mask: '255.255.192.0' },
      { prefix: 19, mask: '255.255.224.0' },
      { prefix: 20, mask: '255.255.240.0' },
      { prefix: 21, mask: '255.255.248.0' },
      { prefix: 22, mask: '255.255.252.0' },
      { prefix: 23, mask: '255.255.254.0' },
      { prefix: 24, mask: '255.255.255.0' }
    ],
    'Class C': [
      { prefix: 24, mask: '255.255.255.0' },
      { prefix: 25, mask: '255.255.255.128' },
      { prefix: 26, mask: '255.255.255.192' },
      { prefix: 27, mask: '255.255.255.224' },
      { prefix: 28, mask: '255.255.255.240' },
      { prefix: 29, mask: '255.255.255.248' },
      { prefix: 30, mask: '255.255.255.252' },
      { prefix: 31, mask: '255.255.255.254' },
      { prefix: 32, mask: '255.255.255.255' }
    ]
  };

  const classInfo = {
    'Class A': 'Used for large networks, supports 16 million hosts on each of 128 networks.',
    'Class B': 'Used for medium-sized networks, supports 65,000 hosts on each of 16,000 networks.',
    'Class C': 'Used for small networks, supports 254 hosts on each of 2 million networks.',
    'Class D': 'Reserved for multicast groups.',
    'Class E': 'Reserved for experimental purposes.'
  };

  const specialPurposes = {
    'Class A': 'Large Networks',
    'Class B': 'Medium Networks',
    'Class C': 'Small Networks',
    'Class D': 'Multicast',
    'Class E': 'Experimental'
  };

  const calculateClassType = (firstOctet) => {
    if (firstOctet >= 1 && firstOctet <= 126) return 'Class A';
    if (firstOctet >= 128 && firstOctet <= 191) return 'Class B';
    if (firstOctet >= 192 && firstOctet <= 223) return 'Class C';
    if (firstOctet >= 224 && firstOctet <= 239) return 'Class D';
    if (firstOctet >= 240 && firstOctet <= 255) return 'Class E';
    return '';
  };

  const handleIpPartChange = (index, value) => {
    const parts = [...ipParts];
    value = value.replace(/[^0-9]/g, ''); // Accept only numbers
    if (value.length > 3) return; // Prevent more than 3 digits per octet

    if (parseInt(value, 10) > 255) { 
      setErrorMessage('Each octet must be between 0 and 255.');
    } else {
      setErrorMessage('');
      parts[index] = value;
      setIpParts(parts);
      if (value.length === 3 && parseInt(value, 10) <= 255 && index < 3) {
        document.getElementById(`octet-${index + 1}`).focus(); // Move to the next octet
      }
    }

    const firstOctet = parseInt(parts[0], 10);
    if (!isNaN(firstOctet)) {
      const classType = calculateClassType(firstOctet);
      setClassType(classType);
      setSpecialPurpose(specialPurposes[classType] || '');

      if (classType === 'Class D' || classType === 'Class E') {
        setSubnetMask('');
        setPrefix('');
        setNetworkAddress('N/A');
        setHostRange('N/A');
        setNumberOfHosts('N/A');
        setBroadcastAddress('N/A');
      } else {
        setSubnetMask('');
        setPrefix('');
      }
    }
  };

  const handlePrefixChange = (event) => {
    const selectedPrefix = event.target.value;
    setPrefix(selectedPrefix);
    const matchingMask = subnetMasksByClass[classType]?.find(
      (mask) => mask.prefix === parseInt(selectedPrefix, 10)
    );
    if (matchingMask) {
      setSubnetMask(matchingMask.mask);
      calculateAddresses(ipParts.join('.'), selectedPrefix);
    }
  };

  const handleSubnetMaskChange = (event) => {
    const selectedMask = event.target.value;
    setSubnetMask(selectedMask);
    const matchingPrefix = subnetMasksByClass[classType]?.find(
      (mask) => mask.mask === selectedMask
    );
    if (matchingPrefix) {
      setPrefix(matchingPrefix.prefix);
      calculateAddresses(ipParts.join('.'), matchingPrefix.prefix);
    }
  };

  const calculateAddresses = (ip, prefix) => {
    const ipParts = ip.split('.').map(Number);
    const prefixNumber = parseInt(prefix, 10);

    const mask = (0xffffffff << (32 - prefixNumber)) >>> 0;
    const subnetMaskParts = [
      (mask >>> 24) & 255,
      (mask >>> 16) & 255,
      (mask >>> 8) & 255,
      mask & 255
    ];
    const networkParts = [
      ipParts[0] & subnetMaskParts[0],
      ipParts[1] & subnetMaskParts[1],
      ipParts[2] & subnetMaskParts[2],
      ipParts[3] & subnetMaskParts[3]
    ];

    const broadcastParts = [
      networkParts[0] | (~subnetMaskParts[0] & 255),
      networkParts[1] | (~subnetMaskParts[1] & 255),
      networkParts[2] | (~subnetMaskParts[2] & 255),
      networkParts[3] | (~subnetMaskParts[3] & 255)
    ];

    setNetworkAddress(networkParts.join('.'));
    setBroadcastAddress(broadcastParts.join('.'));
    const numberOfHosts = prefixNumber === 32 ? 0 : Math.pow(2, 32 - prefixNumber) - 2;
    setNumberOfHosts(numberOfHosts);
    setHostRange(
      prefixNumber === 32
        ? 'N/A'
        : `${networkParts[0]}.${networkParts[1]}.${networkParts[2]}.${networkParts[3] + 1} - ${broadcastParts[0]}.${broadcastParts[1]}.${broadcastParts[2]}.${broadcastParts[3] - 1}`
    );

    setTotalIPs(Math.pow(2, 32 - prefixNumber));
    setIsPeerToPeer(prefixNumber === 31 || prefixNumber === 32);

    const binaryMask = mask.toString(2).padStart(32, '0').match(/.{1,8}/g).join('.');
    setSubnetBinary(binaryMask);

    setIpType(determineIpType(ipParts));
    setWildcardMask(
      subnetMaskParts.map((part) => 255 - part).join('.')
    );

    // Calculate IP representations
    // const ipDecimal = ipParts.join('.');
    const ipDecimal = ipParts.reduce((acc, part, index) => {
      return acc + (parseInt(part, 10) * Math.pow(256, 3 - index));
  }, 0); // Convert to base 10
    const ipHexadecimal = ipParts.map(part => parseInt(part, 10).toString(16).padStart(2, '0')).join('.');
    const ipBinary = ipParts.map(part => parseInt(part, 10).toString(2).padStart(8, '0')).join('.');
    const ipOctal = ipParts.map(part => parseInt(part, 10).toString(8).padStart(3, '0')).join('.');

    setIpRepresentations({
      decimal: ipDecimal,
      hexadecimal: ipHexadecimal,
      binary: ipBinary,
      octal: ipOctal
    });

    if (isPeerToPeer) setShowDialog(true);
  };

  const determineIpType = (ipParts) => {
    const [firstOctet, secondOctet] = ipParts;
    if (
      (firstOctet === 10) ||
      (firstOctet === 172 && secondOctet >= 16 && secondOctet <= 31) ||
      (firstOctet === 192 && secondOctet === 168)
    ) {
      return 'Private';
    }
    return 'Public';
  };

  const handleInfoClick = () => {
    setShowTooltip(true);
  };

  const handleTooltipClose = () => {
    setShowTooltip(false);
  };

  useEffect(() => {
    if (isPeerToPeer) {
      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        PaperProps={{
          sx: {
            background: 'rgba(17, 14, 14, 0.6)', // Match the container background
            maxWidth: '500px',
            margin: 'auto', // Center the dialog
          },
        }}
      >
        <DialogContent>
          <Alert severity="info" sx={{ color: '#1976d2', backgroundColor: 'rgba(17, 14, 14, 0.6)', }}>
            <AlertTitle style={{ color: '#1976d2' }}>Alert</AlertTitle>
            This is a point-to-point link (/31 or /32).
            <Box display="flex" justifyContent="center" mt={2}>
              <Button onClick={() => setShowDialog(false)} sx={{ color: '#1976d2' }}>
                Close
              </Button>
            </Box>
          </Alert>
        </DialogContent>
      </Dialog>
    }
  }, [isPeerToPeer]);

  return (
    <div>
      <NavigationBar 
        links={links} 
        chapters={chapters} // Pass an empty array if no chapters
        onSearch={onSearch} 
        searchTerm="" 
        clearSearch={() => {}} 
        hasChapters={chapters.length > 0} // Optional: pass this prop if needed
      />    
      <Container 
        sx={{ 
          background: 'rgba(17, 14, 14, 0.6)', 
          padding: 2, 
          borderRadius: 1,
          Top: 0
        }}
      >

        <Typography variant="h1" className='cal' sx={{ color: '#1976d2', marginBottom: 2, fontSize:'24px', justifySelf: 'center' }}>
          IPv4 Calculator
        </Typography>
        <p>Our IPv4 Address Calculator helps you convert IPv4 addresses into IPv6, binary, decimal, octal, and hexadecimal formats. Additionally, it provides in-depth network details such as the number of hosts, network and broadcast addresses, subnet mask, wildcard mask, and more.</p>
        <div style={
          {marginBottom:"20px"}
        }></div>
        <div className="input-group">
          <Typography variant="body1" sx={{ color: '#1976d2' }}>
            IP Address:
          </Typography>
          <Box
            component="form"
            sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
            noValidate
            autoComplete="off"
          >
            {ipParts.map((part, index) => (
              <TextField 
                fontSize="17px"
                label="Octet" 
                variant="outlined"
                key={index}
                id={`octet-${index}`}
                className="ip-octet"
                type="text"
                value={part}
                onChange={(e) => handleIpPartChange(index, e.target.value)}
                maxLength="3"
                placeholder={index < 3 ? `Octet ${index + 1}` : 'Octet 4'}
                InputProps={{
                  sx: {
                    color: '#1976d2',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#1976d2',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#1976d2',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                    '&::placeholder': {
                      color: '#1976d2',
                    },
                  },
                }}
                InputLabelProps={{
                  sx: { color: '#1976d2' },
                }}
              />
            ))}
          </Box>

          <div>
          <Grid container spacing={2} alignItems="center">
    <Grid item xs={10} sm={4.93}>
        <Box 
            display="flex" 
            alignItems="center" 
            justifyContent="flex-start" 
            sx={{ 
                width: 'auto', 
                maxWidth: '200px', 
                color: '#1976d2', 
            }}
        >
<style>
                {`
                    /* For Firefox */
                    * {
                        scrollbar-width: thin; /* Options: auto, thin */
                        scrollbar-color: #1976d2 transparent; /* thumb color and track color */
                    }

                    /* For WebKit browsers (Chrome, Safari) */
                    ::-webkit-scrollbar {
                        width: 8px; /* Width of the scrollbar */
                    }

                    ::-webkit-scrollbar-track {
                        background: transparent; /* Background of the scrollbar track */
                    }

                    ::-webkit-scrollbar-thumb {
                        background: #1976d2; /* Color of the scrollbar thumb */
                        border-radius: 10px; /* Optional: round the corners of the thumb */
                    }

                    ::-webkit-scrollbar-thumb:hover {
                        background: #155a8a; /* Optional: darker shade on hover */
                    }
                `}
            </style>

            <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                <InputLabel id="prefix-select-label" sx={{ color: '#1976d2' }}>
                    Select Prefix
                </InputLabel>
                <Select
                    labelId="prefix-select-label"
                    id="prefix-select"
                    value={prefix}
                    label="Select Prefix"
                    onChange={handlePrefixChange}
                    sx={{
                        color: '#1976d2',
                        '& .MuiSvgIcon-root': {
                            color: '#1976d2',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#1976d2',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#1976d2',
                        },
                    }}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                background: 'rgba(17, 14, 14, 0.6)',
                                boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
                                backdropFilter: 'blur(2px)',
                                WebkitBackdropFilter: 'blur(2px)',
                                borderRadius: '10px',
                                border: '1px solid rgba(255, 255, 255, 0.18)',
                                '& .MuiMenuItem-root': {
                                    color: '#1976d2',
                                },
                            },
                        },
                    }}
                >
                    <MenuItem value="">Select Prefix</MenuItem>
                    {subnetMasksByClass[classType]?.map((mask) => (
                        <MenuItem key={mask.prefix} value={mask.prefix}>{`/${mask.prefix}`}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <IconButton onClick={handleInfoClick} sx={{ ml: 0, padding: 0 }}>
                <InfoRoundedIcon sx={{ padding: 0,
                    color: '#1976d2',
                    '& .MuiSvgIcon-root': {
                        color: '#1976d2',
                    },
                }} />
            </IconButton>
        </Box>
    </Grid>

              <Grid item xs={10} sm={4}>
                <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                  <InputLabel id="subnet-mask-select-label" sx={{ color: '#1976d2' }}>
                    Subnet Mask
                  </InputLabel>
                  <Select
                    labelId="subnet-mask-select-label"
                    id="subnet-mask-select"
                    value={subnetMask}
                    label="Subnet Mask"
                    onChange={handleSubnetMaskChange}
                    sx={{
                      color: '#1976d2',
                      '& .MuiSvgIcon-root': {
                        color: '#1976d2',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#1976d2',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#1976d2',
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          background: 'rgba(17, 14, 14, 0.6)',
                          boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
                          backdropFilter: 'blur(2px)',
                          WebkitBackdropFilter: 'blur(2px)',
                          borderRadius: '10px',
                          border: '1px solid rgba(255, 255, 255, 0.18)',
                          '& .MuiMenuItem-root': {
                            color: '#1976d2',
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value="">Select Subnet Mask</MenuItem>
                    {subnetMasksByClass[classType]?.map((mask) => (
                      <MenuItem key={mask.mask} value={mask.mask}>{mask.mask}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        </div>

        <Box component="form" sx={{ m: 1 }} noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="class-type"
                label="Class"
                value={classType}
                variant="outlined"
                fullWidth
                InputProps={{
                  readOnly: true,
                  sx: { color: '#1976d2', 
                    '& .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                    '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                  },
                }}
                InputLabelProps={{
                  sx: { color: '#1976d2' },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="special-purpose"
                label="Purpose"
                value={specialPurpose}
                variant="outlined"
                fullWidth
                InputProps={{
                  readOnly: true,
                  sx: { color: '#1976d2', 
                    '& .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                    '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                  },
                }}
                InputLabelProps={{
                  sx: { color: '#1976d2' },
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <div>
          {subnetMask && prefix && (
            <Box component="form" sx={{ m: 1 }} noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="network-address"
                    label="Network Address"
                    value={networkAddress}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      sx: { color: '#1976d2', 
                        '& .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                      },
                    }}
                    InputLabelProps={{
                      sx: { color: '#1976d2' },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="broadcast-address"
                    label="Broadcast Address"
                    value={broadcastAddress}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      sx: { color: '#1976d2', 
                        '& .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                      },
                    }}
                    InputLabelProps={{
                      sx: { color: '#1976d2' },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="total-ip-addresses"
                    label="Total IP Addresses"
                    value={totalIPs}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      sx: { color: '#1976d2',
                         '& .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                         '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                        },
                    }}
                    InputLabelProps={{
                      sx: { color: '#1976d2' },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="usable-hosts"
                    label="# of usable Hosts"
                    value={numberOfHosts}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      sx: { color: '#1976d2', 
                        '& .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                      },
                    }}
                    InputLabelProps={{
                      sx: { color: '#1976d2' },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="host-range"
                    label="Host Range"
                    value={hostRange}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      sx: { color: '#1976d2',
                         '& .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                         '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                        },
                    }}
                    InputLabelProps={{
                      sx: { color: '#1976d2' },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="subnet-binary"
                    label="Subnet Binary"
                    value={subnetBinary}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      sx: { color: '#1976d2', 
                        '& .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                      },
                    }}
                    InputLabelProps={{
                      sx: { color: '#1976d2' },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="ip-type"
                    label="IP Type"
                    value={ipType}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      sx: { color: '#1976d2', 
                        '& .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                      },
                    }}
                    InputLabelProps={{
                      sx: { color: '#1976d2' },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="wildcard-mask"
                    label="Wildcard Mask"
                    value={wildcardMask}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      sx: { color: '#1976d2', 
                        '& .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                      },
                    }}
                    InputLabelProps={{
                      sx: { color: '#1976d2' },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="ip-decimal"
                    label="Decimal"
                    value={ipRepresentations.decimal}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      sx: { color: '#1976d2', 
                        '& .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                      },
                    }}
                    InputLabelProps={{
                      sx: { color: '#1976d2' },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="ip-hexadecimal"
                    label="Hexadecimal"
                    value={ipRepresentations.hexadecimal}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      sx: { color: '#1976d2', 
                        '& .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                      },
                    }}
                    InputLabelProps={{
                      sx: { color: '#1976d2' },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="ip-binary"
                    label="Binary"
                    value={ipRepresentations.binary}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      sx: { color: '#1976d2',
                         '& .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' } ,
                         '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                        },
                    }}
                    InputLabelProps={{
                      sx: { color: '#1976d2' },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="ip-octal"
                    label="Octal"
                    value={ipRepresentations.octal}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      sx: { color: '#1976d2',
                         '& .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                         '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                        },
                    }}
                    InputLabelProps={{
                      sx: { color: '#1976d2' },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </div>

        {networkAddress && (
          <Card sx={{ minWidth: 275, backgroundColor: 'rgba(17, 14, 14, 0.6)',  }}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" sx={{ color: '#1976d2' }}>
                Summary
              </Typography>
              <Typography variant="body2" sx={{ color: '#1976d2' }}>
                <strong>Network Address:</strong> {networkAddress}
                <br />
                <strong>Broadcast Address:</strong> {broadcastAddress}
                <br />
                <strong>Host Range:</strong> {hostRange}
                <br />
                <strong>Usable Hosts:</strong> {numberOfHosts}
                <br />
                {isPeerToPeer && (
                  <Typography className="peer-to-peer" sx={{ color: '#1976d2' }}>
                    This is a point-to-point link (/31 or /32).
                  </Typography>
                )}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" sx={{ color: '#1976d2' }}>Learn More</Button>
            </CardActions>
          </Card>
        )}

        <div className="input-group">
          {showTooltip && (
            <>
              <IconButton onClick={() => setShowTooltip(true)} sx={{ ml: 0, padding: 0 }}>
                <InfoRoundedIcon sx={{ padding: 0 }} />
              </IconButton>

              <Dialog
                open={showTooltip}
                onClose={() => setShowTooltip(false)}
                PaperProps={{
                  sx: {
                    background: 'rgba(17, 14, 14, 0.6)', // Match the container background
                    maxWidth: '500px',
                    margin: 'auto', // Center the dialog
                  },
                }}
              >
                <DialogContent>
                  <Box>
                    <Typography variant="h6" sx={{ color: '#1976d2', textAlign: 'center' }}>
                      Class Information
                    </Typography>
                    <Typography sx={{ color: '#1976d2', textAlign: 'center' }}>
                      {classInfo[classType]}
                    </Typography>
                    <Box display="flex" justifyContent="center" mt={2}>
                      <Button onClick={() => setShowTooltip(false)} sx={{ color: '#1976d2' }}>
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </DialogContent>
              </Dialog>
            </>
          )}
        </div>
        {errorMessage && <p className="error" style={{ color: '#C80000' }}>{errorMessage}</p>}
        {showDialog && (
          <Dialog
            open={showDialog}
            onClose={() => setShowDialog(false)}
            PaperProps={{
              sx: {
                background: 'rgba(17, 14, 14, 0.6)', // Match the container background
                maxWidth: '500px',
                margin: 'auto', // Center the dialog
              },
            }}
          >
            <DialogContent>
              <Alert severity="info" sx={{ color: '#1976d2', backgroundColor: 'rgba(17, 14, 14, 0.6)', }}>
                <AlertTitle style={{ color: '#1976d2' }}>Alert</AlertTitle>
                This is a point-to-point link (/31 or /32).
                <Box display="flex" justifyContent="center" mt={2}>
                  <Button onClick={() => setShowDialog(false)} sx={{ color: '#1976d2' }}>
                    Close
                  </Button>
                </Box>
              </Alert>
            </DialogContent>
          </Dialog>
        )}
      </Container>
      <Utilities />
    </div>
  );
};

export default IP_Calculator;