import React from "react";
import { Link } from "react-router-dom";
import CodeComandConcept from "./CodeCommandConcept";
import { CODE_AND_COMMAND_CONCEPTS } from "../data";
import { Container, Grid, Card, Typography, AspectRatio} from '@mui/joy'; // Adjusted to use MUI Joy

const CodeComandConcepts = () => {
  return (
    <Container sx={{ backgroundColor: 'rgba(17, 14, 14, 0.6)', padding: '16px', borderRadius: '8px', marginTop: '20px' }}>
      <Typography variant="h4" color="#1976d2" gutterBottom>
        Code and Commands
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        {CODE_AND_COMMAND_CONCEPTS.map((concept, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              variant="outlined"
              sx={{ borderColor: '#1976d2', backgroundColor: 'rgba(17, 14, 14, 0.6)', '&:hover': { opacity: 0.8 } }}
            >
              <Link to={concept.route} style={{ textDecoration: 'none', color: 'inherit' }}>
              <AspectRatio sx={{ backgroundColor: 'rgba(17, 14, 14, 0.6)', '& .MuiAspectRatio-content': { backgroundColor: 'rgba(17, 14, 14, 0.6)' } }}>
                  <CodeComandConcept {...concept} />
                </AspectRatio>
                <Typography level="title-md" sx={{ color: '#1976d2', padding: '8px' }}>
                  {concept.title} {/* Assuming each concept has a title */}
                </Typography>
                <Typography level="body-sm" sx={{ color: '#1976d2', padding: '8px' }}>
                  {concept.description} {/* Assuming each concept has a description */}
                </Typography>
              </Link>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default CodeComandConcepts;
