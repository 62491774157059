// // QuizApp.jsx
// import ChapterQuiz from './ChapterQuiz';
// import ScrollToTop from '../../../../../ScrolltopPage';
// import ExamQuiz from './ExamQuiz';
// import { QUIZ_CONCEPTS } from './Concept_Data'; // Importing the quiz concepts array
// import './QuizConcept.css'; // Importing the CSS for styling
// import React, { useState, useEffect } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// //import QuizNavbar from './QuizNavbar';
// import { useTranslation } from 'react-i18next';


// const QuizApp = () => {
//   const {t} = useTranslation();
//   const [quizMode, setQuizMode] = useState(null); // To track the selected quiz mode
//   const [searchTerm, setSearchTerm] = useState('');
//   const navigate = useNavigate();
//   const location = useLocation();

//   // List of chapters for the navbar
//   const chapters = [
//     { name: t('Chapter 1'), path: '/ccna/CCNA2/chapter-1', key: 'chapter1' },
//     { name: t('Chapter 2'), path: '/ccna/CCNA2/chapter-2', key: 'chapter2' },
//     { name: t('Chapter 3'), path: '/ccna/CCNA2/chapter-3', key: 'chapter3' },
//     { name: t('Chapter 4'), path: '/ccna/CCNA2/chapter-4', key: 'chapter4' },
//     { name: t('Chapter 5'), path: '/ccna/CCNA2/chapter-5', key: 'chapter5' },
//     { name: t('Chapter 6'), path: '/ccna/CCNA2/chapter-6', key: 'chapter6' },
//     { name: t('Chapter 7'), path: '/ccna/CCNA2/chapter-7', key: 'chapter7' },
//     { name: t('Chapter 8'), path: '/ccna/CCNA2/chapter-8', key: 'chapter8' },
//     { name: t('Chapter 9'), path: '/ccna/CCNA2/chapter-9', key: 'chapter9' },
//     { name: t('Chapter 10'), path: '/ccna/CCNA2/chapter-10', key: 'chapter10' },
//     { name: t('Chapter 10 Exam'), path: '/ccna/CCNA2/chapter-10-Exam', key: 'chapter10Exam' },
//     { name: t('Exam'), path: '/ccna/CCNA2/Exam', key: 'CCNA2Exam' },
//     { name: t('Exam Version 7'), path: '/ccna/CCNA2/Exam-Version-7', key: 'CCNA2Exam2024' },
//   ];


//   const currentChapter = chapters.find((chapter) => chapter.path === location.pathname);

//   const handleSearch = (term) => {
//     setSearchTerm(term);
//   };

//   const handleChapterChange = (event) => {
//     const selectedChapter = event.target.value;
//     navigate(selectedChapter);
//   };

//   const handleNextChapter = () => {
//     const currentIndex = chapters.findIndex((chapter) => chapter.key === currentChapter?.key);
//     if (currentIndex < chapters.length - 1 && currentIndex >= 0) {
//       navigate(chapters[currentIndex + 1].path);
//     }
//   };

//   const handlePreviousChapter = () => {
//     const currentIndex = chapters.findIndex((chapter) => chapter.key === currentChapter?.key);
//     if (currentIndex > 0) {
//       navigate(chapters[currentIndex - 1].path);
//     }
//   };

//   const handleModeSelection = (mode) => {
//     setQuizMode(mode);
//    // navigate(mode); // Navigate to the selected quiz mode
//   };

//   const getArrayOrString = (key) => {
//     const value = t(key, { returnObjects: true });
//     return Array.isArray(value) ? value : [value];  // Return as an array if it's not already
// };

//   return (
//     <div>
//       <ScrollToTop />
//       {/* <QuizNavbar
//         chapters={chapters}
//         onSearch={handleSearch}
//         onChapterChange={handleChapterChange}
//         onNextChapter={handleNextChapter}
//         onPreviousChapter={handlePreviousChapter}
//       /> */}
//       <div className="quiz-app"><h1>CCNA1 Quiz</h1></div>
//       {!quizMode ? (
//         <div className="mode-selection">
//           {QUIZ_CONCEPTS.map((concept, index) => (
//             <div key={index} className="quiz-concept-card" onClick={() => handleModeSelection(concept.route)}>
//               <img src={concept.image} alt={concept.title} className="card-image" />
//               <h2 className="card-title">{concept.title}</h2>
//               <p className="card-description">{concept.description}</p>
//             </div>
//           ))}
//         </div>
//       ) : (
//         <div>
//           {quizMode === 'ccna/CCNA1/ccna1_quiz/ExamMode' && <ExamQuiz />}
//           {quizMode === 'ccna/CCNA1/ccna1_quiz/ChapterQuiz' && <ChapterQuiz />}
//         </div>
//       )}
//     </div>
//   );
// };

// export default QuizApp;



import ChapterQuiz from './ChapterQuiz';
import ScrollToTop from '../../../../../ScrolltopPage';
import ExamQuiz from './ExamQuiz';
import { QUIZ_CONCEPTS } from './Concept_Data'; // Importing the quiz concepts array
import './QuizConcept.css'; // Importing the CSS for styling
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // useNavigate for navigation
import NavigationBar from '../NavigationBar';
import { useTranslation } from 'react-i18next';

const QuizApp = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const linksArray = ["Home", "Courses", "Calculator"];

  // List of chapters for the navbar
  const chapters = [
    { name: t('Chapter 1'), path: '/ccna/CCNA2/chapter-1', key: 'chapter1' },
    { name: t('Chapter 2'), path: '/ccna/CCNA2/chapter-2', key: 'chapter2' },
    { name: t('Chapter 2 SIC_PSA_PT'), path: '/ccna/CCNA2/Chapter2_SIC_PracticeSkills_Assessment/SIC_PSA_PT' },
    { name: t('Chapter 3'), path: '/ccna/CCNA2/chapter-3', key: 'chapter3' },
    { name: t('Chapter 4'), path: '/ccna/CCNA2/chapter-4', key: 'chapter4' },
    { name: t('Chapter 5'), path: '/ccna/CCNA2/chapter-5', key: 'chapter5' },
    { name: t('Chapter 6'), path: '/ccna/CCNA2/chapter-6', key: 'chapter6' },
    { name: t('Chapter 7'), path: '/ccna/CCNA2/chapter-7', key: 'chapter7' },
    { name: t('Chapter 7 SIC_PSA_PT'), path: '/ccna/CCNA2/Chapter7_SIC_PracticeSkills_Assessment/SIC_PSA_PT' },
    { name: t('Chapter 8'), path: '/ccna/CCNA2/chapter-8', key: 'chapter8' },
    { name: t('Chapter 9'), path: '/ccna/CCNA2/chapter-9', key: 'chapter9' },
    { name: t('Chapter 10'), path: '/ccna/CCNA2/chapter-10', key: 'chapter10' },
    { name: t('Chapter 10 Exam'), path: '/ccna/CCNA2/chapter-10-Exam', key: 'chapter10Exam' },
    // { name: t('CCNA2 Exam'), path: '/ccna/CCNA2/chapter-10-Exam', key: 'CCNA2Exam' },
    { name: t('Exam'), path: '/ccna/CCNA2/Exam', key: 'CCNA2Exam' },
    { name: t('Exam Version 7'), path: '/ccna/CCNA2/Exam-Version-7', key: 'CCNA2Exam2024' },
    { name: t('Quiz'), path: '/ccna/ccna2/ccna2_quiz/QuizApp' }  ];

  const currentChapter = chapters.find((chapter) => chapter.path === location.pathname);

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleChapterChange = (event) => {
    const selectedChapter = event.target.value;
    navigate(selectedChapter);
  };

  const handleNextChapter = () => {
    const currentIndex = chapters.findIndex((chapter) => chapter.key === currentChapter?.key);
    if (currentIndex < chapters.length - 1 && currentIndex >= 0) {
      navigate(chapters[currentIndex + 1].path);
    }
  };

  const handlePreviousChapter = () => {
    const currentIndex = chapters.findIndex((chapter) => chapter.key === currentChapter?.key);
    if (currentIndex > 0) {
      navigate(chapters[currentIndex - 1].path);
    }
  };

  // Ensure the route passed to navigate is an absolute path
  const handleModeSelection = (mode) => {
    if (!mode.startsWith('/')) {
      mode = '/' + mode;
    }
    navigate(mode); // Navigate to the selected quiz mode route
  };

  return (
    <div>
      <ScrollToTop />
      <NavigationBar links={linksArray}  />
      <div className="quiz-app"><h1>CCNA1 Quiz</h1></div>
      <div className="mode-selection">
        {QUIZ_CONCEPTS.map((concept, index) => (
          <div
            key={index}
            className="quiz-concept-card"
            onClick={() => handleModeSelection(concept.route)} // Navigate on click
          >
            <img src={concept.image} alt={concept.title} className="card-image" />
            <h2 className="card-title">{concept.title}</h2>
            <p className="card-description">{concept.description}</p>
          </div>
        ))}
        
      </div>
              {/* Add margin to the Utilities component */}
              <div style={{ marginTop: '60px' , marginBottom: '60px'}}></div>

    </div>
  );
};

export default QuizApp;

