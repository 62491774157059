import React, { useEffect, useState } from 'react';
import Logo from '../../../../assets/Alpha logo1 sm.png'
import {
    AppBar,
    Box,
    Tab,
    Tabs,
    Toolbar,
    Typography,
    Menu,
    MenuItem,
    useTheme,
    useMediaQuery,
    Paper,
    Grid2,
    InputBase,
    IconButton,
    Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SchoolSharpIcon from '@mui/icons-material/SchoolSharp';
import DrawerComp from './DrawerComp';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import Language from '../../../Settings/Language';
import Settings from '../../../Settings/Setting';
import ShareComponent from '../../../share';

// Styled components for custom Tabs
const StyledTabs = styled(Tabs)(({ theme }) => ({
    '& .MuiTabs-flexContainer': {
        justifyContent: 'space-between',
        marginRight: '0px',
        marginLeft: '20px',
    },
}));

const NavigationBar = ({ links, chapters = [], onSearch, currentChapter, searchTerm, clearSearch}) => {
    const location = useLocation();
    const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm);

    useEffect(() => {
        setLocalSearchTerm(searchTerm);
    }, [searchTerm]);

    const handleSearch = () => {
        onSearch(localSearchTerm);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    };

    const handleInputChange = (e) => {
        setLocalSearchTerm(e.target.value);
        onSearch(e.target.value);
    };

    const getPlaceholder = () => {
        if (location.pathname.includes('/ccna/ccna_command')) {
            return "Search Command";
        } else if (/\/ccna\/CCNA[123]\//.test(location.pathname)) {
            return "Search Questions";
        } else {
            return "Search";
        }
    };

    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down('sm'));
    const isXsMatch = useMediaQuery(theme.breakpoints.down('xs'));
    const [value, setValue] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();

    const exceptionPaths = ['/ccna/ccna1/Practice-PTA/ITN_PSA_PT', '/ccna/ccna1/chapter-6/ch6_skills_PT']; // Add your exception paths here

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 0) {
            navigate('/');
        } else if (newValue === 1) {
            navigate('/ccna/ccna');
        } else if (newValue === 2) {
            navigate('/ccna/Calculator/calculators');
        } else if (newValue === 3) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleChapterSelect = (chapter) => {
        navigate(chapter.path);
        setLocalSearchTerm('');
        onSearch('');
        handleCloseMenu();
    };

  // Define restricted paths
  const restrictedPaths = ['QuizApp', 'ccna1_quiz/ExamQuiz','/ccna/Calculator']; // Add your restricted paths here

  // Determine if the current path matches any restricted paths
  const isRestrictedPath = location.pathname === '/' || location.pathname === '/ccna/ccna' || location.pathname ==='/ccna/CCNA1/ccna1_quiz/ChapterQuiz' || restrictedPaths.some(path => location.pathname.match(path));

  // Render the search bar if it's not a restricted path
  const renderSearchBar = () => {
      return (
          <Paper
              component="form"
              sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  background: 'rgba(17, 14, 14, 0.75)',
                  backdropFilter: 'blur(2px)',
                  borderRadius: '25px',
                  border: '1px solid rgba(255, 255, 255, 0.18)',
                  width: '100%',
                  ...(isMatch && {
                      marginLeft: '30px',
                      marginRight: '20px',
                  }),
                  ...(isXsMatch && {
                      marginLeft: '30px',
                      marginRight: '20px',
                  }),
              }}
          >
              <InputBase
                  sx={{
                      marginLeft: '60px',
                      ml: 1,
                      flex: 1,
                      color: '#ffff',
                      '& input': {
                          paddingRight: '40px',
                          border: 'none'
                      }
                  }}
                  placeholder={getPlaceholder()}
                  inputProps={{ 'aria-label': 'search' }}
                  value={localSearchTerm}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                  endAdornment={
                      <IconButton
                          type="button"
                          sx={{ p: '10px', position: 'absolute', right: 0 }}
                          aria-label="Search"
                          onClick={handleSearch}
                      >
                          <SearchIcon sx={{ color: 'white' }} />
                      </IconButton>
                  }
              />
          </Paper>
      );
  };

  return (
      <>
          <AppBar sx={{
              background: 'rgba(17, 14, 14, 0.75)',
              boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
              backdropFilter: 'blur(2px)',
              position: 'fixed',
              top: -50,
              left: 0,
              right: 0,
          }}>
              <Toolbar disableGutters>
                  {isXsMatch ? (
                      <>
                          <Typography sx={{ paddingRight: '10px' }}></Typography>
                          {!isRestrictedPath && renderSearchBar()} {/* Only render search bar if not restricted */}
                          <DrawerComp links={links} chapters={chapters} />
                      </>
                  ) : isMatch ? (
                      <>
                          <Typography></Typography>
                          {!isRestrictedPath && renderSearchBar()} {/* Only render search bar if not restricted */}
                          <DrawerComp links={links} chapters={chapters} />
                      </>
                  ) : (
                      <>
                          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                              <Grid2 container spacing={2} alignItems="center">
                                  <Grid2 xs={1}>
                                      <Typography>
                                          <Link to="/" style={{ textDecoration: 'none' }}>
                                          {/* <img src={Logo} alt="Alpha Logo" /> */}
                                              <SchoolSharpIcon style={{ cursor: 'pointer' }} />
                                          </Link>
                                      </Typography>
                                  </Grid2>
                                  <Grid2 xs={5}>
                                      <StyledTabs
                                          indicatorColor="secondary"
                                          textColor='inherit'
                                          value={value}
                                          onChange={handleTabChange}
                                      >
                                          <Tab label="Home" onClick={(event) => handleTabChange(event, 0)} />
                                          <Tab label="Courses" onClick={(event) => handleTabChange(event, 1)} />
                                          <Tab label="Calculators" onClick={(event) => handleTabChange(event, 2)} />
                                          {(chapters.length > 0 || exceptionPaths.includes(location.pathname)) && (
                                              <Tab label="Chapters" onClick={(event) => handleTabChange(event, 3)} />
                                          )}
                                      </StyledTabs>
                                  </Grid2>
                              </Grid2>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Grid2 xs={4}>
                                  {!isRestrictedPath && renderSearchBar()} {/* Only render search bar if not restricted */}
                              </Grid2>
                          </Box>

                          <Box
                              sx={{
                                  position: 'absolute',
                                  bottom: '-50px',
                                  right: '-12px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  background: 'Transparent',
                                  padding: '0px 0px',
                                  borderRadius: '8px',
                              }}
                          >
                              <ShareComponent sx={{ marginRight: '8px' }} />
                              {/* <Language sx={{ marginRight: '8px' }} /> */}
                              <Settings />
                          </Box>
                      </>
                  )}
              </Toolbar>
              <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseMenu}
                  PaperProps={{
                      sx: {
                          background: 'rgba(17, 14, 14, 0.75)',
                          boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
                          backdropFilter: 'blur(2px)',
                          borderRadius: '10px',
                          border: '1px solid rgba(255, 255, 255, 0.18)',
                          color: 'white'
                      }
                  }}
              >
                  {chapters.map((chapter, index) => (
                      <MenuItem key={index} onClick={() => handleChapterSelect(chapter)}>
                          {chapter.name}
                      </MenuItem>
                  ))}
              </Menu>
          </AppBar>

          {/* Add spacing below the AppBar */}
          <Box sx={{ height: '100px' }} /> {/* Adjust height as necessary for spacing */}
      </>
  );
};




export default NavigationBar;
