import TypeAImg from '../../../../../assets/CCNA2/ch2_SIC_TypeA.png'
import HostAImg from '../../../../../assets/CCNA2/ch2_SIC_TypeA_Host_A.png' 
import HostBImg from '../../../../../assets/CCNA2/ch2_SIC_TypeA_Host_B.png'
import TypeBImg from '../../../../../assets/CCNA2/ch2_SIC_TypeB.png'
import TypeCImg from '../../../../../assets/CCNA2/ch2_SIC_TypeC.png'
// import TypeA_2Img from './CCNA1_PTA_2.png'
// import TypeBImg from './Exam_B_1.png'
// import TypeB_2Img from './Exam_B_2.png'
// import TypeA_124_1 from './CCNA1_PTA_124-1.png'
// import TypeA_124_5 from './CCNA1_PTA_124-5.png'
// import TypeA_214_1 from './CCNA1_PTA_214-1.png'
// import TypeA_Server from './CCNA1_PTA-Server.png'


export const Skills_TabButtonsInfo = {
    Type_A: {
      image: [TypeAImg],
      title: '',
      description:
        'You might be given One (1) of these Three (3) Topology.',

      routerConfig: [
        `<h2>Main, Central or HQ (Router 1) Configuration</h2>`,
        `<p>To configure router Main, Central or HQ follow these steps:</p>`+
`<ol class="skills-list-note">
        <li><b>Note check your configuration requirements before copy and paste the following comand in the CLI.</b></li>
        </ol>`
      ], 
      router2Config: [
        `<h2>Bldg-1, Quest or Branch (Router 2) Configuration</h2>`,
        `<p>To configure router Bldg-1, Quest or Branch follow these steps:</p>`+
`<ol class="skills-list-note">
        <li><b>Note check your configuration requirements before copy and paste the following comand in the CLI.</b></li>
        </ol>`
      ], 
      switch1Config:[
        `<h2>LAB 214-A Switch Configuration</h2>`+
        `<p>To connect to the <b>214-1</b> device to the <b>LAB 214-A Switch </b>router using the <b>line console</b>, follow these steps:</p>`+
        `<ol class="num-list">
        <li>On the <b>214-1</b> device, navigate to the "Desktop" tab.</li>
        <li>Select <b>"Terminal"</b> from the available options.</li>
        </ol>`
      ],  
// This switch 2 is in arry
      switch2Config:[
        `<h2>Switch Configuration</h2>`+
        `<p>The following command can be used for the approprate switches in this PTA activities; however:</p>`+
        `<ol class="skills-list-note">
        <li>Ensure you use the correct hostname and ip addresses before you copy and paste this command directly into the switch CLI</li>
        </ol>`
      ],  
    //  switch2Config:'Admin Switch Configuration', 
    //   endDevice1:'Prof X',
    //   endDevice2:'Prof Y',
    //   endDevice3:'Dean X',
    //   endDevice4:'Dean Y',

  tableData: [
    {
      Device: 'HQ / Main / Central',
      Interface: 'S0/0/0',
      Address: '128.107.0.1/30',
      // SubnetMask: '255.255.255.224',
      // DefaultGateway: 'N/A',
    },
    {
      Device: 'HQ / Main / Central',
      Interface: "S0/0/0",
      Address: '2001:DB8:2:1::1/64',
      // SubnetMask: '',
      // DefaultGateway: 'N/A',
    },
    {
      Device: 'HQ / Main / Central',
      Interface: "S0/0/0",
      Address: 'fe80::1',
      // SubnetMask: '',
      // DefaultGateway: 'N/A',
    },
    {
      Device: 'HQ / Main / Central',
      Interface: 'S0/0/1',
      Address: '128.107.0.5/30',
    //   SubnetMask: '255.255.255.240',
    //   DefaultGateway: 'N/A',
    },
    {
      Device: 'HQ / Main / Central',
      Interface: "S0/0/1",
      Address: '2001:DB8:3:1::1/64',
      SubnetMask: '',
      DefaultGateway: 'N/A',
    },
    {
      Device: 'HQ / Main / Central',
      Interface: "S0/0/1",
      Address: 'fe80::1',
      SubnetMask: '',
      DefaultGateway: 'N/A',
    },
    {
      Device: 'HQ / Main / Central',
      Interface: 'S0/1/1',
      Address: '10.10.20.1/30',
    //   SubnetMask: '255.255.255.240',
    //   DefaultGateway: 'N/A',
    },
    {
      Device: 'HQ / Main / Central',
      Interface: "S0/1/1",
      Address: '2001:DB8:1:1::1/64',
      SubnetMask: '',
      DefaultGateway: 'N/A',
    },
    {
      Device: 'HQ / Main / Central',
      Interface: "S0/1/1",
      Address: 'fe80::1',
      SubnetMask: '',
      DefaultGateway: 'N/A',
    },
    {
      Device: 'Bldg-1 / Branch',
      Interface: "S0/0/0",
      Address: '10.10.20.2/30',
      SubnetMask: '',
      DefaultGateway: '',
    },
    {
      Device: 'Bldg-1 / Branch',
      Interface: "S0/0/0",
      Address: '2001:DB8:1:1::2/64',
      SubnetMask: '',
      DefaultGateway: '',
    },
    {
      Device: 'Bldg-1 / Branch',
      Interface: "S0/0/0",
      Address: 'fe80::2',
      SubnetMask: '',
      DefaultGateway: '',
    },
    {
      Device: 'Bldg-1 / Branch',
      Interface: 'G0/0',
      Address: '10.10.1.254/24',
      SubnetMask: '',
      DefaultGateway: '',
    },
    {
      Device: 'Bldg-1 / Branch',
      Interface: 'G0/0',
      Address: '2001:DB8:1:A::1/64',
      SubnetMask: '',
      DefaultGateway: '',
    },
    {
      Device: 'Bldg-1 / Branch',
      Interface: 'G0/0',
      Address: 'fe80::2',
      SubnetMask: '',
      DefaultGateway: '',
    },
    {
      Device: 'Bldg-1 / Branch',
      Interface: 'G0/1',
      Address: '10.10.2.254/24',
      SubnetMask: '',
      DefaultGateway: '',
    },
    {
      Device: 'Bldg-1 / Branch',
      Interface: 'G0/1',
      Address: '2001:DB8:1:B::1/64',
      SubnetMask: '',
      DefaultGateway: '',
    },
    {
      Device: 'Bldg-1 / Branch',
      Interface: 'G0/1',
      Address: 'fe80::2',
      SubnetMask: '',
      DefaultGateway: '',
    },
    {
      Device: 'Web DNS Server',
      Interface: 'NIC',
      Address: '64.100.100.10/24',
      SubnetMask: '',
      DefaultGateway: '',
    },
    {
      Device: 'Web DNS Server',
      Interface: 'NIC',
      Address: '2001:DB8:FF:F::10/64',
      SubnetMask: '',
      DefaultGateway: '',
    },
    {
      Device: 'PC1',
      Interface: 'NIC',
      Address: '10.10.1.0/24, any available address',
      SubnetMask: '',
      DefaultGateway: '',
    },
    {
      Device: 'PC1',
      Interface: 'NIC',
      Address: '2001:DB8:1:A:: A/64',
      SubnetMask: '',
      DefaultGateway: '',
    },
    {
      Device: 'PC2',
      Interface: 'NIC',
      Address: '10.10.2.0/24, any available address',
      SubnetMask: '',
      DefaultGateway: '',
    },
    {
      Device: 'PC2',
      Interface: 'NIC',
      Address: '2001:DB8:1:B:: A/64',
      SubnetMask: '',
      DefaultGateway: '',
    },
  
  ],

  instructions: [
    `<h2>Instructions</h2>`,
    `<h2><b>Step 1: Basic Device Configuration</b></h2>`,

    `<p> Perform a basic device configuration on <b>Main.</b></p>` ,
      `<ol class="ITN-skills-list">
            <li>Configure the router <b>hostname</b> to the value in the addressing table.</li>
            <li>Prevent the router from attempting to resolve unrecognized CLI entries as domain names.</li>
            <li>Protect device configurations from unauthorized access with the encrypted secret password of <b>cisco.</b></li>
            <li>Secure the router <b>console</b> and <b>vty</b> lines with the password <b>class.</b></li>
            <li>Prevent all passwords from being viewed in clear text in device configuration files.</li>
            <li>Add a message-of-the-day banner of your choice.</li>
            <li>Provide descriptions on all active interfaces.</li>
            </ol>`,
            `<h2><b>Step 2: Configure Default, Static, and Floating Static Routes.</b></h2>`,
    
      `<p> Configure static, default, and floating static routes on the routers.</p>`, 
      `<p> <b>Main:</b></p>`,
      `<ol class="ITN-skills-list">
            <li>Configure interfaces of the <b>Main</b> router with IPv4 and IPv6 addressing based on the <b>Addressing Table.</b></li>
            <li>Configure <b>two directly connected static routes</b> on <b>Main</b> to reach the two IPv4 LAN networks on router <b>Bldg-1.</b></li>
            <li>Configure <b>two directly connected static routes</b> on <b>Main</b> to reach the IPv6 LAN networks on router <b>Bldg-1.</b></li>
            <li>Configure <b>directly connected IPv4</b> default static routes to reach hosts outside of the network.</b></li>
            </ol>`,
            `<ol class="skills-list">
            <li>Configure the primary path through Serial0/0/0.</li>
            <li>Configure a floating default static route for the backup path through Serial0/0/1 with a metric of <b>2.</b></li>
            </ol>`,

            `<ol class="custom-list-letters">
            <li>Configure <b>directly connected IPv6</b> default static routes to reach hosts outside of the network.</li>
            </ol>`+
            `<ol class="skills-list">
            <li>Configure the primary path through Serial0/0/0.</li>
            <li>Configure a floating default static route for the backup path through Serial0/0/1 with a metric of <b>2.</b></li>
            </ol>`,

            `<p> <b>Bldg-1:</b></p>`,
            `<ol class="ITN-skills-list">
                  <li>Configure interfaces of the <b>Bldg-1</b> router with IPv4 and IPv6 addressing based on the <b>Addressing Table.</b></li>
                  <li>Configure <b>directly connected IPv4 default static route</b> on <b>Bldg-1</b> to reach the Internet</li>
                  <li>Configure <b>directly connected IPv6 default static route</b> on <b>Bldg-1</b> to reach the Internet</li>
                  </ol>`,
      

            `<h2><b>Step 3: Configure Host Addressing</b></h2>`+

    `<ol class="ITN-skills-list">
            <li>Configure the <b>Host A</b> and <b>Host B</b> hosts with both IPv4 and IPv6 addresses based on the <b>Addressing Table.</b></li>

            <li>IPv6 default gateway addresses should be the link local address of the LAN router interface.</li>
            <li>Configure the DNS server address for both IPv4 and IPv6.</li>
            <li>All PC hosts should be able to reach the Internet server.</li>
            </ol>`
  ],

    
   note: [
          `<h2>NOTE:</h2>`+
          `<ol class="skills-list-note">
              <li><b>Avoid using the browser's Back button or closing/reloading any exam windows during the exam.</b></li>
              <li><b>Do not manually close Packet Tracer; it will shut down on its own when you're finished.</b></li>
              <li><b>Click the "Submit Assessment" button in the browser window to submit your work.</b></li>
            </ol>`,
   ],

   host_Tag:'Hosts Configuration',

   host_124_1: [''],
   host_124_1_Config:[
    `<h2>124-1</h2>`,
    `<p>IPv4 Address: <b>192.168.1.97</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.224</b></p>`+
    `<p>IPv4 Default Gateway: <b>192.168.1.126</b></p>`,
    `<p>IPv6 Address: <b>2001:DB8:ACAD:A::FF/64</b></p>`+
    `<p>IPv6 Default Gateway: <b>FE80::1</b></p>`
   ],

   host_124_5: [''],
   host_124_5_Config:[
    `<h2>124-5</h2>`,
    `<p>IPv4 Address: <b>192.168.1.98</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.224</b></p>`+
    `<p>IPv4 Default Gateway: <b>192.168.1.126</b></p>`,
    `<p>IPv6 Address: <b>2001:DB8:ACAD:A::15/64</b></p>`+
    `<p>IPv6 Default Gateway: <b>FE80::1</b></p>`
   ],

   host_Host_A: [HostAImg],
   host_Host_A_Config:[
    `<h2>Host-A</h2>`,
    `<p>IPv4 Address: <b>10.10.1.10</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.0</b></p>`+
    `<p>IPv4 Default Gateway: <b>10.10.1.254</b></p>`+
    `<p>DNS Server: <b>64.100.100.10</b></p>`,
    `<p>IPv6 Address: <b>2001:DB8:1:A::A/64</b></p>`+
    `<p>IPv6 Default Gateway: <b>FE80::2</b></p>`+
    `<p>IPv6 DNS Server: <b>2001:DB8:FF:F::10</b></p>`
   ],

   host_Host_B: [HostBImg],
   host_Host_B_Config:[
    `<h2>Host-B</h2>`,
    `<p>IPv4 Address: <b>10.10.2.10</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.0</b></p>`+
    `<p>IPv4 Default Gateway: <b>10.10.2.254</b></p>`+
    `<p>DNS Server: <b>64.100.100.10</b></p>`,
    `<p>IPv6 Address: <b>2001:DB8:1:B::A/64</b></p>`+
    `<p>IPv6 Default Gateway: <b>FE80::2</b></p>`+
    `<p>IPv6 DNS Server: <b>2001:DB8:FF:F::10</b></p>`
   ],

   host_Server: [],
   host_Server_Config:[
    `<h2>Server</h2>`,
    `<p>IPv4 Address: <b>192.168.1.146</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.240</b></p>`+
    `<p>IPv4 Default Gateway: <b>192.168.1.158</b></p>`,
    `<p>IPv6 Address: <b>2001:DB8:ACAD:B::15/64</b></p>`+
    `<p>IPv6 Default Gateway: <b>FE80::1</b></p>`
   ],


   router:[
    "en",
"config t",

"hostname Main",
"no ip domain-lookup" ,
"enable secret cisco",

"line console 0",
"password class",
"login",
"line vty 0 15",
"password cisco",
"login",
"exit",
"service password-encryption", 
"banner motd $cisco.com$",

"int s0/0/0",
"description PRIMARY CONNECTION",
"ip address 128.107.0.1 255.255.255.252",
"ipv6 address 2001:DB8:2:1::1/64",
"ipv6 address FE80::1 link-local", 
"no shutdown", 

"int s0/0/1",
"description BACKUP CONNECTION",
"ip address 128.107.0.5 255.255.255.252",
"ipv6 address 2001:DB8:3:1::1/64",
"ipv6 address FE80::1 link-local", 
"no shutdown",

"int s0/1/1",
"description Connect to Bldg-1",
"ip address 10.10.20.1 255.255.255.252",
"ipv6 address 2001:DB8:1:1::1/64",
"ipv6 address FE80::1 link-local",
"no shutdown", 
"exit",

"ip route 10.10.1.0 255.255.255.0 s0/1/1",

"ip route 10.10.2.0 255.255.255.0 s0/1/1",

"ipv6 route 2001:DB8:1:A::/64 s0/1/1",
"ipv6 route 2001:DB8:1:B::/64 s0/1/1",

"ip route 0.0.0.0 0.0.0.0 s0/0/0",
"ip route 0.0.0.0 0.0.0.0 s0/0/1 2",
"ipv6 route ::/0 s0/0/0",
"ipv6 route ::/0 s0/0/1 2",
"ipv6 unicast-routing",

   ],

   router2:[
    "en",
"config t",

"int s0/0/0",
"ip address 10.10.20.2 255.255.255.252",
"ipv6 address 2001:DB8:1:1::2/64",
"ipv6 address FE80::2 link-local", 
"no shutdown", 

"int g0/0",
"ip address 10.10.1.254 255.255.255.0",
"ipv6 address 2001:DB8:1:A::1/64",
"ipv6 address FE80::2 link-local", 
"no shutdown",

"int g0/1",
"ip address 10.10.2.254 255.255.255.0",
"ipv6 address 2001:DB8:1:B::1/64",
"ipv6 address FE80::2 link-local",
"no shutdown",
"exit", 

"int s0/0/0",
"description Connection to Main",
"int g0/0",
"description LAN A",
"int g0/1",
"description LAN B",
"exit",

"ip route 0.0.0.0 0.0.0.0 s0/0/0",

"ipv6 route ::/0 s0/0/0",

"ipv6 unicast-routing",

   ],
   switch2:[
    "enable",
"config t",

"hostname SW-1",

"enable secret class",

"line console 0",
"password cisco",
"login",
"exit",
"service password-encryption",
"banner motd $Authorized Users Only$",
"interface vlan1",
"ip address 10.10.10.100 255.255.255.0",
"no shut",
"exit",
"ip default-gateway 10.10.10.1",
"ip domain-name cisco.com",
"crypto key generate rsa",
"1024",
"ip ssh version 2",
"username admin secret ccna",
"line vty 0 15",
"login local",
"transport input ssh",
"exit",
"int fa0/1",
"no shutdown",
"int range fa0/2-24",
"shutdown",
"int g0/2",
"shutdown",
"int range fa0/1-24",
"switchport mode access",
"switchport port-security",
"switchport port-security mac-address sticky",
"switchport port-security maximum 2",
"switchport port-security violation shutdown",
"int range g0/1-2",
"switchport mode access",
"switchport port-security",
"switchport port-security mac-address sticky",
"switchport port-security maximum 2",
"switchport port-security violation shutdown",



"end",

"write"
   ],

/*
device2:[
"IP Address: 172.16.5.11",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 172.16.5.1"
],

device3:[
"IP Address: 192.168.5.10",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 192.168.5.1"
],

device4:[
"IP Address: 192.168.5.11",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 192.168.5.1"
]*/
  
  //route:'/ccna/ccna',
    },
    Type_B: {
      image: [TypeBImg],
      title: '',
      description:
        'You might be given One (1) of these Three (3) Topology.',

      routerConfig: [
        `<h2>Main, Central or HQ (Router 1) Configuration</h2>`,
        `<p>To configure router Main, Central or HQ follow these steps:</p>`+
`<ol class="skills-list-note">
        <li><b>Note check your configuration requirements before copy and paste the following comand in the CLI.</b></li>
        </ol>`
      ], 
      router2Config: [
        `<h2>Bldg-1, Quest or Branch (Router 2) Configuration</h2>`,
        `<p>To configure router Bldg-1, Quest or Branch follow these steps:</p>`+
`<ol class="skills-list-note">
        <li><b>Note check your configuration requirements before copy and paste the following comand in the CLI.</b></li>
        </ol>`
      ], 
      switch1Config:[
        `<h2>LAB 214-A Switch Configuration</h2>`+
        `<p>To connect to the <b>214-1</b> device to the <b>LAB 214-A Switch </b>router using the <b>line console</b>, follow these steps:</p>`+
        `<ol class="num-list">
        <li>On the <b>214-1</b> device, navigate to the "Desktop" tab.</li>
        <li>Select <b>"Terminal"</b> from the available options.</li>
        </ol>`
      ], 
      
      // This switch 2 is in arry
      switch2Config:[
       `<h2>Switch Configuration</h2>`+
        `<p>The following command can be used for the approprate switches in this PTA activities; however:</p>`+
        `<ol class="skills-list-note">
        <li>Ensure you use the correct hostname and ip addresses before you copy and paste this command directly into the switch CLI</li>
        </ol>`
      ],  

    //  switch2Config:'Admin Switch Configuration', 
    //   endDevice1:'Prof X',
    //   endDevice2:'Prof Y',
    //   endDevice3:'Dean X',
    //   endDevice4:'Dean Y',

  tableData: [
    {
      Device: 'Town Hall',
      Interface: 'G0/0',
      Address: '2001:DB8:ACAD:A::1/64',
      // SubnetMask: '255.255.255.224',
      // DefaultGateway: 'N/A',
    },
    {
      Device: 'Town Hall',
      Interface: "G0/1",
      Address: '2001:DB8:ACAD:B::1/64',
      // SubnetMask: '',
      // DefaultGateway: 'N/A',
    },
    {
      Device: 'Town Hall',
      Interface: "Link Local",
      Address: 'fe80::1',
      // SubnetMask: '',
      // DefaultGateway: 'N/A',
    },
    {
      Device: 'Administration Switch',
      Interface: 'Vlan 1',
      Address: 'N/A',
    //   SubnetMask: '255.255.255.240',
    //   DefaultGateway: 'N/A',
    },
    {
      Device: 'Reception Host',
      Interface: "NIC",
      Address: '2001:DB8:ACAD:A::FF',
      // SubnetMask: '',
      // DefaultGateway: 'N/A',
    },
    {
      Device: 'Operation Host',
      Interface: "NIC",
      Address: '2001:DB8:ACAD:A::15',
      // SubnetMask: '',
      // DefaultGateway: 'N/A',
    },
    {
      Device: 'IT Host',
      Interface: "NIC",
      Address: '2001:DB8:ACAD:B::FF',
      // SubnetMask: '',
      // DefaultGateway: 'N/A',
    },
      
  ],

  instructions: [
    `<h2>Instructions</h2>`,
    `<h2><b>Step 1: Determine the IP Addressing Scheme.</b></h2>`,
    `<p> Design an IPv4 addressing scheme and complete the Addressing Table based on the following requirements. Use the table to help you organize your work.</p>` ,
    `<table class="addressing-table">
    <thead>
        <tr>
            <th>Subnet Number</th>
            <th>Beginning Address</th>
            <th>Ending Address</th>
           <th>Mask</th>
            <th>Assignment</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>1</td>
            <td>192.168.1.0</td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td>2</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>

        </tr>
        <tr>
            <td>3</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td>4</td>
            <td></td>
            <td></td>
            <td></td>
            <td>IT Department LAN Subnet</td>
        </tr>
        <tr>
            <td>5</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td>6</td>
            <td></td>
            <td></td>
            <td></td>
            <td>Administration LAN Subnet</td>
        </tr>

    </tbody>
</table>`,
  `<ol class="ITN-skills-list">
            <li>Subnet the 192.168.1.0/24 network to provide 30 host addresses per subnet while wasting the fewest addresses.</li>
            <li>Assign the fourth subnet to the IT Department LAN.</li>
            <li>Assign the last network host address (the highest) in this subnet to the G0/0 interface on Town Hall.</li>
            <li>Starting with the fifth subnet, subnet the network again so that the new subnets will provide 14 host addresses per subnet while wasting the fewest addresses.</li>
            <li> Assign the second of these new 14-host subnets to the Administration LAN.</li>
            <li>Assign the last network host address (the highest) in the Administration LAN subnet to the G0/1 interface of the Town Hall router.</li>
            <li>Assign the second to the last address (the second highest) in this subnet to the VLAN 1 interface of the Administration Switch.</li>
            <li>Configure addresses on the hosts using any of the remaining addresses in their respective subnets.</li>
            </ol>`,
            `<h2><b>Step 2: Configure the Town Hall Router.</b></h2>`,
    
      `<p> Configure the Town Hall router with all initial configurations that you have learned in the course so far:</p>`, 
      `<ol class="skills-list">
            <li>Configure the router hostname:<b>Middle</b> router with IPv4 and IPv6 addressing based on the <b>Addressing Table.</b></li>
            <li>Protect device configurations from unauthorized access with the encrypted privileged exec password.</li>
            <li>Secure all access lines into the router using methods covered in the course and labs.</li>
            <li>Require newly-entered passwords must have a minimum length of 10 characters.</li>
            <li>Prevent all passwords from being viewed in clear text in device configuration files.</li>
            <li>Configure the router to only accept in-band management connections over the protocol that is more secure than Telnet, as was done in the labs.  Use the value <b>1024</b> for encryption key strength.</li>
            <li>Configure local user authentication for in-band management connections. Create a user with the name <b>netadmin</b> and a secret password of <b>Cisco_CCNA5</b>  Give the user the highest administrative privileges. Your answer must match these values exactly.</li>
            </ol>`,
            
            `<p> b. Configure the two Gigabit Ethernet interfaces using the IPv4 addressing values you calculated and the IPv6 values provided in the addressing table.</p>`,
            
            `<ol class="skills-list">
            <li>Reconfigure the link local addresses to the value shown in the table.</li>
            Document the interfaces in the configuration file.
            </ol>`,

           `<h2>Step 3: Configure the Administration Switch.</h2>`,
           `<p>Configure Administration Switch for remote management over Telnet.</p>`,
           
           `<h2>Step 4: Configure and Verify Host Addressing.</h2>`,

           `<ol class="num-list">
            <li>Use the IPv4 addressing from Step 1 and the IPv6 addressing values provided in the addressing table to configure all host PCs with the correct addressing.</li>
            <li>Use the router interface link-local address as the IPv6 default gateways on the hosts.</li>
            <li>Complete the configuration of the TFTP server using the IPv4 addressing  values from Step 1 and the values in the addressing table.</li>
            </ol>`,

  ],

  introduction:[
    `<h2>Introduction</h2>`,
    `<p> In this assessment, you will configure devices in an IPv4/IPv6 network. For the sake of time, you will not be asked to perform all configurations on all network devices as you may be required to do in a real network or other assessment. Instead, you will use the skills and knowledge that you have learned in the labs in this course to configure the Town Hall router. In addition, you will address the hosts on two LANs with IPv4 and IPv6 addresses, activate and address the management interface of the Administration Switch, and back up a device configuration to a TFTP server.</p>`,
    `<p>You will receive one of several topologies.</p>`,
    `<p><b>You are not required to configure the IT Department Switch, and you will not be able to access it in this practice skills assessment activity.</b></p>`,
    `<p><b></b>All IOS device configurations should be completed from a direct terminal connection to the device console. In addition, many values that are required to complete the configurations have not been given to you. In those cases, create the values that you need to complete the requirements. For values that have been supplied to you, they must be entered exactly as they appear in order for you to get full credit for your configuration.</p>`,
    `<p>You will practice and be assessed on the following skills:</p>`,
    
    `<ol class="skills-list">
    <li>Configuration of initial IOS device settings</li>
    <li>Design and calculation of IPv4 addressing</li>
    <li>Configuration of IOS device interfaces including IPv4 and IPv6 addressing when appropriate</li>
    <li>Addressing of network hosts with IPv4 and IPv6 addresses</li>
    <li>Enhancing device security, including configuration of the secure transport protocol for remote device configuration</li>
    <li>Configuration of a switch management interface</li>
  </ol>`,
  ],
  requirements:[
    `<h2>Requirements</h2>`,
    `<h3><b>Requirements by device:</b></h3>`,
    `<h3><b>Town Hall Router:</b></h3>`,
    `<ol class="skills-list">
    <li>Configuration of initial router settings</li>
    <li>Interface configuration and IPv4 and IPv6 addressing</li>
    <li>Device security enhancement or device hardening</li>
    <li>Secure transport for remote configuration connections as covered in the labs</li>
    <li>Backup of the configuration file to a TFTP server</li>
  </ol>`,

   `<h3><b>Administration Switch:</b></h3>`, 
    `<ol class="skills-list">
    <li>Enabling basic remote management by Telnet</li>
    </ol>`,
    `<h3><b>PC and Server hosts:</b></h3>`,
    `<ol class="skills-list">
    <li>IPv4 full addressing</li>
    <li>IPv6 addressing</li>
  </ol>`,
  ],
    
   note: [
          `<h2>NOTE:</h2>`+
          `<ol class="skills-list-note">
              <li><b>Avoid using the browser's Back button or closing/reloading any exam windows during the exam.</b></li>
              <li><b>Do not manually close Packet Tracer; it will shut down on its own when you're finished.</b></li>
              <li><b>Click the "Submit Assessment" button in the browser window to submit your work.</b></li>
            </ol>`,
   ],

   host_Tag:'Hosts Configuration',

   host_124_1: [''],
   host_124_1_Config:[
    `<h2>124-1</h2>`,
    `<p>IPv4 Address: <b>192.168.1.97</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.224</b></p>`+
    `<p>IPv4 Default Gateway: <b>192.168.1.126</b></p>`,
    `<p>IPv6 Address: <b>2001:DB8:ACAD:A::FF/64</b></p>`+
    `<p>IPv6 Default Gateway: <b>FE80::1</b></p>`
   ],

   host_124_5: [''],
   host_124_5_Config:[
    `<h2>124-5</h2>`,
    `<p>IPv4 Address: <b>192.168.1.98</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.224</b></p>`+
    `<p>IPv4 Default Gateway: <b>192.168.1.126</b></p>`,
    `<p>IPv6 Address: <b>2001:DB8:ACAD:A::15/64</b></p>`+
    `<p>IPv6 Default Gateway: <b>FE80::1</b></p>`
   ],

   host_Host_A: [HostAImg],
   host_Host_A_Config:[
    `<h2>Host-A</h2>`,
    `<p>IPv4 Address: <b>10.10.1.10</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.0</b></p>`+
    `<p>IPv4 Default Gateway: <b>10.10.1.254</b></p>`+
    `<p>DNS Server: <b>64.100.100.10</b></p>`,
    `<p>IPv6 Address: <b>2001:DB8:1:A::A/64</b></p>`+
    `<p>IPv6 Default Gateway: <b>FE80::2</b></p>`+
    `<p>IPv6 DNS Server: <b>2001:DB8:FF:F::10</b></p>`
   ],

   host_Host_B: [HostBImg],
   host_Host_B_Config:[
    `<h2>Host-B</h2>`,
    `<p>IPv4 Address: <b>10.10.2.10</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.0</b></p>`+
    `<p>IPv4 Default Gateway: <b>10.10.2.254</b></p>`+
    `<p>DNS Server: <b>64.100.100.10</b></p>`,
    `<p>IPv6 Address: <b>2001:DB8:1:B::A/64</b></p>`+
    `<p>IPv6 Default Gateway: <b>FE80::2</b></p>`+
    `<p>IPv6 DNS Server: <b>2001:DB8:FF:F::10</b></p>`
   ],

   host_Server: [],
   host_Server_Config:[
    `<h2>Server</h2>`,
    `<p>IPv4 Address: <b>192.168.1.146</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.240</b></p>`+
    `<p>IPv4 Default Gateway: <b>192.168.1.158</b></p>`,
    `<p>IPv6 Address: <b>2001:DB8:ACAD:B::15/64</b></p>`+
    `<p>IPv6 Default Gateway: <b>FE80::1</b></p>`
   ],


   router:[
    "en",
"config t",

"hostname Main",
"no ip domain-lookup" ,
"enable secret cisco",

"line console 0",
"password class",
"login",
"line vty 0 15",
"password cisco",
"login",
"exit",
"service password-encryption", 
"banner motd $cisco.com$",

"int s0/0/0",
"description PRIMARY CONNECTION",
"ip address 128.107.0.1 255.255.255.252",
"ipv6 address 2001:DB8:2:1::1/64",
"ipv6 address FE80::1 link-local", 
"no shutdown", 

"int s0/0/1",
"description BACKUP CONNECTION",
"ip address 128.107.0.5 255.255.255.252",
"ipv6 address 2001:DB8:3:1::1/64",
"ipv6 address FE80::1 link-local", 
"no shutdown",

"int s0/1/1",
"description Connect to Bldg-1",
"ip address 10.10.20.1 255.255.255.252",
"ipv6 address 2001:DB8:1:1::1/64",
"ipv6 address FE80::1 link-local",
"no shutdown", 
"exit",

"ip route 10.10.1.0 255.255.255.0 s0/1/1",

"ip route 10.10.2.0 255.255.255.0 s0/1/1",

"ipv6 route 2001:DB8:1:A::/64 s0/1/1",
"ipv6 route 2001:DB8:1:B::/64 s0/1/1",

"ip route 0.0.0.0 0.0.0.0 s0/0/0",
"ip route 0.0.0.0 0.0.0.0 s0/0/1 2",
"ipv6 route ::/0 s0/0/0",
"ipv6 route ::/0 s0/0/1 2",
"ipv6 unicast-routing",

   ],

   router2:[
    "en",
"config t",

"int s0/0/0",
"ip address 10.10.20.2 255.255.255.252",
"ipv6 address 2001:DB8:1:1::2/64",
"ipv6 address FE80::2 link-local", 
"no shutdown", 

"int g0/0",
"ip address 10.10.1.254 255.255.255.0",
"ipv6 address 2001:DB8:1:A::1/64",
"ipv6 address FE80::2 link-local", 
"no shutdown",

"int g0/1",
"ip address 10.10.2.254 255.255.255.0",
"ipv6 address 2001:DB8:1:B::1/64",
"ipv6 address FE80::2 link-local",
"no shutdown",
"exit", 

"int s0/0/0",
"description Connection to Main",
"int g0/0",
"description LAN A",
"int g0/1",
"description LAN B",
"exit",

"ip route 0.0.0.0 0.0.0.0 s0/0/0",

"ipv6 route ::/0 s0/0/0",

"ipv6 unicast-routing",

   ],
   switch2:[
    "enable",
    "config t",
    
    "hostname SW-1",
    
    "enable secret class",
    
    "line console 0",
    "password cisco",
    "login",
    "exit",
    "service password-encryption",
    "banner motd $Authorized Users Only$",
    "interface vlan1",
    "ip address 10.10.10.100 255.255.255.0",
    "no shut",
    "exit",
    "ip default-gateway 10.10.10.1",
    "ip domain-name cisco.com",
    "crypto key generate rsa",
    "1024",
    "ip ssh version 2",
    "username admin secret ccna",
    "line vty 0 15",
    "login local",
    "transport input ssh",
    "exit",
    "int fa0/1",
    "no shutdown",
    "int range fa0/2-24",
    "shutdown",
    "int g0/2",
    "shutdown",
    "int range fa0/1-24",
    "switchport mode access",
    "switchport port-security",
    "switchport port-security mac-address sticky",
    "switchport port-security maximum 2",
    "switchport port-security violation shutdown",
    "int range g0/1-2",
    "switchport mode access",
    "switchport port-security",
    "switchport port-security mac-address sticky",
    "switchport port-security maximum 2",
    "switchport port-security violation shutdown",
    
    
    
    "end",
    
    "write"
    
   ],

/*
device1:[
"IP Address: 172.16.5.10",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 172.16.5.1"
   ],

device2:[
"IP Address: 172.16.5.11",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 172.16.5.1"
],

device3:[
"IP Address: 192.168.5.10",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 192.168.5.1"
],

device4:[
"IP Address: 192.168.5.11",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 192.168.5.1"
]*/
  
  //route:'/ccna/ccna',
    },

    Type_C: {
      image: [TypeCImg],
      title: '',
      description:
        'You might be given One (1) of these Three (3) Topology.',

      routerConfig: [
        `<h2>Main, Central or HQ (Router 1) Configuration</h2>`,
        `<p>To configure router Main, Central or HQ follow these steps:</p>`+
`<ol class="skills-list-note">
        <li><b>Note check your configuration requirements before copy and paste the following comand in the CLI.</b></li>
        </ol>`
      ], 
      router2Config: [
        `<h2>Bldg-1, Quest or Branch (Router 2) Configuration</h2>`,
        `<p>To configure router Bldg-1, Quest or Branch follow these steps:</p>`+
`<ol class="skills-list-note">
        <li><b>Note check your configuration requirements before copy and paste the following comand in the CLI.</b></li>
        </ol>`
      ], 
      switch1Config:[
        `<h2>LAB 214-A Switch Configuration</h2>`+
        `<p>To connect to the <b>214-1</b> device to the <b>LAB 214-A Switch </b>router using the <b>line console</b>, follow these steps:</p>`+
        `<ol class="num-list">
        <li>On the <b>214-1</b> device, navigate to the "Desktop" tab.</li>
        <li>Select <b>"Terminal"</b> from the available options.</li>
        </ol>`
      ],
      // This switch 2 is in arry
      switch2Config:[
`<h2>Switch Configuration</h2>`+
        `<p>The following command can be used for the approprate switches in this PTA activities; however:</p>`+
        `<ol class="skills-list-note">
        <li>Ensure you use the correct hostname and ip addresses before you copy and paste this command directly into the switch CLI</li>
        </ol>`
      ],  
  
    //  switch2Config:'Admin Switch Configuration', 
    //   endDevice1:'Prof X',
    //   endDevice2:'Prof Y',
    //   endDevice3:'Dean X',
    //   endDevice4:'Dean Y',

  tableData: [
    {
      Device: 'SW-1',
      Interface: 'VLAN 1',
      Address: '10.10.10.100',
      SubnetMask: '255.255.255.0',
      // DefaultGateway: 'N/A',
    },
    {
      Device: 'PC-1',
      Interface: "NIC",
      Address: '10.10.10.10',
      SubnetMask: '255.255.255.0',
      // DefaultGateway: 'N/A',
    },
    {
      Device: 'PC-2',
      Interface: "NIC",
      Address: '192.168.2.1',
      SubnetMask: '255.255.255.0',
      // DefaultGateway: 'N/A',
    },      
  ],

//   instructions: [
//     `<h2>Instructions</h2>`,
//     `<h2><b>Step 1: Determine the IP Addressing Scheme.</b></h2>`,
//     `<p> Design an IPv4 addressing scheme and complete the Addressing Table based on the following requirements. Use the table to help you organize your work.</p>` ,
//     `<table class="addressing-table">
//     <thead>
//         <tr>
//             <th>Subnet Number</th>
//             <th>Beginning Address</th>
//             <th>Ending Address</th>
//            <th>Mask</th>
//             <th>Assignment</th>
//         </tr>
//     </thead>
//     <tbody>
//         <tr>
//             <td>1</td>
//             <td>192.168.1.0</td>
//             <td></td>
//             <td></td>
//             <td></td>
//         </tr>
//         <tr>
//             <td>2</td>
//             <td></td>
//             <td></td>
//             <td></td>
//             <td></td>

//         </tr>
//         <tr>
//             <td>3</td>
//             <td></td>
//             <td></td>
//             <td></td>
//             <td></td>
//         </tr>
//         <tr>
//             <td>4</td>
//             <td></td>
//             <td></td>
//             <td></td>
//             <td>IT Department LAN Subnet</td>
//         </tr>
//         <tr>
//             <td>5</td>
//             <td></td>
//             <td></td>
//             <td></td>
//             <td></td>
//         </tr>
//         <tr>
//             <td>6</td>
//             <td></td>
//             <td></td>
//             <td></td>
//             <td>Administration LAN Subnet</td>
//         </tr>

//     </tbody>
// </table>`,
//   `<ol class="ITN-skills-list">
//             <li>Subnet the 192.168.1.0/24 network to provide 30 host addresses per subnet while wasting the fewest addresses.</li>
//             <li>Assign the fourth subnet to the IT Department LAN.</li>
//             <li>Assign the last network host address (the highest) in this subnet to the G0/0 interface on Town Hall.</li>
//             <li>Starting with the fifth subnet, subnet the network again so that the new subnets will provide 14 host addresses per subnet while wasting the fewest addresses.</li>
//             <li> Assign the second of these new 14-host subnets to the Administration LAN.</li>
//             <li>Assign the last network host address (the highest) in the Administration LAN subnet to the G0/1 interface of the Town Hall router.</li>
//             <li>Assign the second to the last address (the second highest) in this subnet to the VLAN 1 interface of the Administration Switch.</li>
//             <li>Configure addresses on the hosts using any of the remaining addresses in their respective subnets.</li>
//             </ol>`,
//             `<h2><b>Step 2: Configure the Town Hall Router.</b></h2>`,
    
//       `<p> Configure the Town Hall router with all initial configurations that you have learned in the course so far:</p>`, 
//       `<ol class="skills-list">
//             <li>Configure the router hostname:<b>Middle</b> router with IPv4 and IPv6 addressing based on the <b>Addressing Table.</b></li>
//             <li>Protect device configurations from unauthorized access with the encrypted privileged exec password.</li>
//             <li>Secure all access lines into the router using methods covered in the course and labs.</li>
//             <li>Require newly-entered passwords must have a minimum length of 10 characters.</li>
//             <li>Prevent all passwords from being viewed in clear text in device configuration files.</li>
//             <li>Configure the router to only accept in-band management connections over the protocol that is more secure than Telnet, as was done in the labs.  Use the value <b>1024</b> for encryption key strength.</li>
//             <li>Configure local user authentication for in-band management connections. Create a user with the name <b>netadmin</b> and a secret password of <b>Cisco_CCNA5</b>  Give the user the highest administrative privileges. Your answer must match these values exactly.</li>
//             </ol>`,
            
//             `<p> b. Configure the two Gigabit Ethernet interfaces using the IPv4 addressing values you calculated and the IPv6 values provided in the addressing table.</p>`,
            
//             `<ol class="skills-list">
//             <li>Reconfigure the link local addresses to the value shown in the table.</li>
//             Document the interfaces in the configuration file.
//             </ol>`,

//            `<h2>Step 3: Configure the Administration Switch.</h2>`,
//            `<p>Configure Administration Switch for remote management over Telnet.</p>`,
           
//            `<h2>Step 4: Configure and Verify Host Addressing.</h2>`,

//            `<ol class="num-list">
//             <li>Use the IPv4 addressing from Step 1 and the IPv6 addressing values provided in the addressing table to configure all host PCs with the correct addressing.</li>
//             <li>Use the router interface link-local address as the IPv6 default gateways on the hosts.</li>
//             <li>Complete the configuration of the TFTP server using the IPv4 addressing  values from Step 1 and the values in the addressing table.</li>
//             </ol>`,

//   ],

scenario:[
  `<h2>Scenario</h2>`,
  `<p>The network administrator has asked you to configure a new switch. In this activity, you will use a list of requirements to configure the new switch with initial settings, SSH, and port security.</p>`,
  `<p><b>Configure SW-1 with the following initial settings:</b></p>`,
  `<ol class="skills-list">
  <li>Configure the switch with the hostname value from the addressing table. Your configured value must match the value in the addressing table exactly.</li>
  <li>Configure a banner message-of-the-day.</li>
  <li>Enable access to the device console with the password <b>cisco</b>.</li>
  <li>Create an MD5 encrypted enable password of class.</li>
  <li>Encrypt all plain text passwords.</li>
  <li>Management SVI addressing.</li>
  <li>Address the default management interface.</li>
  <li>The switch should be reachable over the network from <b>PC-1</b> and <b>PC-2</b>.</li>
  </ol>`,
  `<p><b>Configure SSH to secure remote access with the following settings:</b></p>`,
  `<ol class="skills-list">
  <li>A domain name of <b>cisco.com</b></li>
  <li>RSA key-pair parameters to support SSH version 2. Use a modulus of 1024.</li>
  <li>Set SSH to version 2.</li>
  <li>Create a user <b>admin</b> with password <b>ccna</b></li>
  <li>Configure vty lines to only accept SSH connections.</li>
  <li>Require the user created above to supply the user name and password in order to login over SSH.</li>
  </ol>`,
  `<p><b>Configure the port security feature to restrict network access:</b></p>`,
  `<ol class="skills-list">
  <li>Disable all unused ports.</b></li>
  <li>Set all Fast Ethernet ports to access ports.</li>
  <li>Enable port security to allow only two hosts per port.</li>
  <li>Enable the MAC addresses of hosts that have connected to the switch ports to be recorded in the configuration file.</li>
  <li>Configure vty lines to only accept SSH connections.</li>
  <li>Ensure that port violations disable ports.</li>
  </ol>`,
],

  introduction:[
    `<h2>Introduction</h2>`,
    `<p>In this practice skills assessment, you will configure <b>SW-1</b> with an initial configuration, SSH, and port security.</p>`,
    `<p>You are only required to configure <b>SW-1</b> in this assessment.</p>`,
    `<p><b>All IOS device configurations should be completed from a direct terminal connection to the device console.</b></p>`,
    `<p><b>It is possible that information that is required to complete the configurations has not been given to you. In that case, create the values that you need to complete the requirements. These values may include certain IP addresses, passwords, interface descriptions, banner text, and other values. You should always use the values that are provided in the instructions in any case.</b></p>`,
    `<p>You will practice and be assessed on the following skills:</p>`,
    
    `<ol class="skills-list">
    <li>Configuration of initial device settings</li>
    <li>Configuration of switch ports</li>
    <li>Configuration and addressing of the switch management interface (SVI)</li>
    <li>Configuration of the SSH protocol for remote switch access.</li>
    <li>Enhancing device security, including configuration of the secure transport protocol for remote device configuration</li>
    <li>Configuration of port security features.</li>
  </ol>`,
  ],
  requirements:[
    `<h2>Requirements</h2>`,
    `<h3><b>Requirements by device:</b></h3>`,
    `<h3><b>Town Hall Router:</b></h3>`,
    `<ol class="skills-list">
    <li>Configuration of initial router settings</li>
    <li>Interface configuration and IPv4 and IPv6 addressing</li>
    <li>Device security enhancement or device hardening</li>
    <li>Secure transport for remote configuration connections as covered in the labs</li>
    <li>Backup of the configuration file to a TFTP server</li>
  </ol>`,

   `<h3><b>Administration Switch:</b></h3>`, 
    `<ol class="skills-list">
    <li>Enabling basic remote management by Telnet</li>
    </ol>`,
    `<h3><b>PC and Server hosts:</b></h3>`,
    `<ol class="skills-list">
    <li>IPv4 full addressing</li>
    <li>IPv6 addressing</li>
  </ol>`,
  ],
    
   note: [
          `<h2>NOTE:</h2>`+
          `<ol class="skills-list-note">
              <li><b>Avoid using the browser's Back button or closing/reloading any exam windows during the exam.</b></li>
              <li><b>Do not manually close Packet Tracer; it will shut down on its own when you're finished.</b></li>
              <li><b>Click the "Submit Assessment" button in the browser window to submit your work.</b></li>
            </ol>`,
   ],

   host_Tag:'Hosts Configuration',

   host_124_1: [''],
   host_124_1_Config:[
    `<h2>124-1</h2>`,
    `<p>IPv4 Address: <b>192.168.1.97</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.224</b></p>`+
    `<p>IPv4 Default Gateway: <b>192.168.1.126</b></p>`,
    `<p>IPv6 Address: <b>2001:DB8:ACAD:A::FF/64</b></p>`+
    `<p>IPv6 Default Gateway: <b>FE80::1</b></p>`
   ],

   host_124_5: [''],
   host_124_5_Config:[
    `<h2>124-5</h2>`,
    `<p>IPv4 Address: <b>192.168.1.98</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.224</b></p>`+
    `<p>IPv4 Default Gateway: <b>192.168.1.126</b></p>`,
    `<p>IPv6 Address: <b>2001:DB8:ACAD:A::15/64</b></p>`+
    `<p>IPv6 Default Gateway: <b>FE80::1</b></p>`
   ],

   host_Host_A: [HostAImg],
   host_Host_A_Config:[
    `<h2>Host-A</h2>`,
    `<p>IPv4 Address: <b>10.10.1.10</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.0</b></p>`+
    `<p>IPv4 Default Gateway: <b>10.10.1.254</b></p>`+
    `<p>DNS Server: <b>64.100.100.10</b></p>`,
    `<p>IPv6 Address: <b>2001:DB8:1:A::A/64</b></p>`+
    `<p>IPv6 Default Gateway: <b>FE80::2</b></p>`+
    `<p>IPv6 DNS Server: <b>2001:DB8:FF:F::10</b></p>`
   ],

   host_Host_B: [HostBImg],
   host_Host_B_Config:[
    `<h2>Host-B</h2>`,
    `<p>IPv4 Address: <b>10.10.2.10</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.0</b></p>`+
    `<p>IPv4 Default Gateway: <b>10.10.2.254</b></p>`+
    `<p>DNS Server: <b>64.100.100.10</b></p>`,
    `<p>IPv6 Address: <b>2001:DB8:1:B::A/64</b></p>`+
    `<p>IPv6 Default Gateway: <b>FE80::2</b></p>`+
    `<p>IPv6 DNS Server: <b>2001:DB8:FF:F::10</b></p>`
   ],

   host_Server: [],
   host_Server_Config:[
    `<h2>Server</h2>`,
    `<p>IPv4 Address: <b>192.168.1.146</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.240</b></p>`+
    `<p>IPv4 Default Gateway: <b>192.168.1.158</b></p>`,
    `<p>IPv6 Address: <b>2001:DB8:ACAD:B::15/64</b></p>`+
    `<p>IPv6 Default Gateway: <b>FE80::1</b></p>`
   ],


   router:[
    "en",
"config t",

"hostname Main",
"no ip domain-lookup" ,
"enable secret cisco",

"line console 0",
"password class",
"login",
"line vty 0 15",
"password cisco",
"login",
"exit",
"service password-encryption", 
"banner motd $cisco.com$",

"int s0/0/0",
"description PRIMARY CONNECTION",
"ip address 128.107.0.1 255.255.255.252",
"ipv6 address 2001:DB8:2:1::1/64",
"ipv6 address FE80::1 link-local", 
"no shutdown", 

"int s0/0/1",
"description BACKUP CONNECTION",
"ip address 128.107.0.5 255.255.255.252",
"ipv6 address 2001:DB8:3:1::1/64",
"ipv6 address FE80::1 link-local", 
"no shutdown",

"int s0/1/1",
"description Connect to Bldg-1",
"ip address 10.10.20.1 255.255.255.252",
"ipv6 address 2001:DB8:1:1::1/64",
"ipv6 address FE80::1 link-local",
"no shutdown", 
"exit",

"ip route 10.10.1.0 255.255.255.0 s0/1/1",

"ip route 10.10.2.0 255.255.255.0 s0/1/1",

"ipv6 route 2001:DB8:1:A::/64 s0/1/1",
"ipv6 route 2001:DB8:1:B::/64 s0/1/1",

"ip route 0.0.0.0 0.0.0.0 s0/0/0",
"ip route 0.0.0.0 0.0.0.0 s0/0/1 2",
"ipv6 route ::/0 s0/0/0",
"ipv6 route ::/0 s0/0/1 2",
"ipv6 unicast-routing",

   ],

   router2:[
    "en",
"config t",

"int s0/0/0",
"ip address 10.10.20.2 255.255.255.252",
"ipv6 address 2001:DB8:1:1::2/64",
"ipv6 address FE80::2 link-local", 
"no shutdown", 

"int g0/0",
"ip address 10.10.1.254 255.255.255.0",
"ipv6 address 2001:DB8:1:A::1/64",
"ipv6 address FE80::2 link-local", 
"no shutdown",

"int g0/1",
"ip address 10.10.2.254 255.255.255.0",
"ipv6 address 2001:DB8:1:B::1/64",
"ipv6 address FE80::2 link-local",
"no shutdown",
"exit", 

"int s0/0/0",
"description Connection to Main",
"int g0/0",
"description LAN A",
"int g0/1",
"description LAN B",
"exit",

"ip route 0.0.0.0 0.0.0.0 s0/0/0",

"ipv6 route ::/0 s0/0/0",

"ipv6 unicast-routing",

   ],
   switch2:[
    "enable",
"config t",

"hostname SW-1",

"enable secret class",

"line console 0",
"password cisco",
"login",
"exit",



"service password-encryption",

"banner motd $Authorized Users Only$",

"interface vlan1",
"ip address 10.10.10.100 255.255.255.0",
"no shut",
"exit",
"ip default-gateway 10.10.10.1",

"ip domain-name cisco.com",
"crypto key generate rsa",
"1024",
"ip ssh version 2",
"username admin secret ccna",
"line vty 0 15",
"login local",
"transport input ssh",
"exit",
"int fa0/1",
"no shutdown",
"int range fa0/2-24",
"shutdown",
"int g0/2",
"shutdown",
"int range fa0/1-24",
"switchport mode access",
"switchport port-security",
"switchport port-security mac-address sticky",
"switchport port-security maximum 2",
"switchport port-security violation shutdown",
"int range g0/1-2",
"switchport mode access",
"switchport port-security",
"switchport port-security mac-address sticky",
"switchport port-security maximum 2",
"switchport port-security violation shutdown",



"end",

"write"
   ],
/*
device1:[
"IP Address: 172.16.5.10",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 172.16.5.1"
   ],

device2:[
"IP Address: 172.16.5.11",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 172.16.5.1"
],

device3:[
"IP Address: 192.168.5.10",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 192.168.5.1"
],

device4:[
"IP Address: 192.168.5.11",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 192.168.5.1"
]*/
  
  //route:'/ccna/ccna',
    },


}