// src/components/CourseCard.jsx
import React from 'react';
import './CourseCard.css'; // Import your CSS file for styling

const CourseCard = ({ course, onNavigate }) => {
  console.log('Rendering course:', course); // Log the course being rendered
  return (
    <div className="course-card" onClick={() => onNavigate(course.id)}>
      <img src={course.image} alt={course.title} className="course-card-image" />
      <div className="course-card-content">
        <h3>{course.title}</h3>
        <p>{course.description}</p>
        <p><strong>Visited: {course.visits}</strong> times</p>
      </div>
    </div>
  );
};

export default CourseCard;
