import React, { useState } from 'react';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import AlertTitle from '@mui/material/AlertTitle';
import Alert from '@mui/material/Alert';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Zoom from '@mui/material/Zoom';

const CopyComponent = React.forwardRef((props, ref) => {
const [alertVisible, setAlertVisible] = useState(false);
const CodeTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

    const handleCopy = () => {
        if (ref.current) {
            const textToCopy = ref.current.innerText; // Get text content from the section
            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    setAlertVisible(true);
                    setTimeout(() => {
                        setAlertVisible(false);
                    }, 3000); // Hide alert after 2 seconds
                })
                .catch(err => {
                    console.error('Failed to copy: ', err);
                });
        }
    };

    return (
        <Box 
        position="absolute"
        top = "150px"
        right= "10px"
        zIndex={1000}
        >
            {alertVisible && (
                <Alert severity="success" style={{ position: 'absolute', top: '0px', right: '0' }}
                sx={{backgroundColor:'rgba(0,0,0,0.6)'}}>
                    <AlertTitle sx={{color: "green"}}>Copied</AlertTitle>
                    
                </Alert>
            )}
            <CodeTooltip TransitionComponent={Zoom} title="Copy" slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }} arrow
    //    sx={{
    //             '& .MuiTooltip-tooltip': {
    //                 backgroundColor: 'transparent', // Replace with your desired color
    //                 color: '#fff', // Optional: text color
    //             },
    //         }}
            >
                <button
                    onClick={handleCopy}
                    style={{
                        backgroundColor: 'transparent',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px',
                        padding: '8px 12px',
                        cursor: 'pointer',
                        position: 'absolute',
                        top: '35px',
                        right: '10px',
                    }}
                >
                    <ContentCopyRoundedIcon />
                </button>
            </CodeTooltip>
        </Box>
    );
});

export default CopyComponent;
