//import CCNAImg from './assets/components.png';
import propsImg from './assets/config.png';
import jsxImg from './assets/jsx-ui.png';
import stateImg from './assets/state-mgmt.png';
import CCImg from './assets/cc.png';
import CCNAImg from './assets/ccna.png';
import DatabaseImg from './assets/database.png';
import QuizImg from './assets/quiz.png';
import ExamImg from './assets/exam.png';
import SecImg from './assets/secplus.png';
import MathImg from './assets/math.png';



//import ccna from './components/pages/ccna';

export const CODE_AND_COMMAND_CONCEPTS = [
    {
      image: CCImg,
      title: 'CCNA',
     // description:
     // 'The core Networking building block for all Networking students.',
      route:'/ccna/ccna_command',
    },
   // {
    //   image: jsxImg,
    //   title: 'Maths',
    // /* description:
    //  'Return (potentially dynamic) HTML(ish) code to define the actual markup that will be rendered.',*/
    //  route:'/securityplus',
    // },

    // {
    //   image: stateImg,
    //   title: 'Security+',
    //   /*description:
    //   'Make components configurable (and therefore reusable) by passing input data to them.',*/
    //   route:'/security+/securityplus_commands',
    // },
    // {
    //   image: propsImg,
    //   title: 'Info-Tech',
    //   /*description:
    //     'React-managed data which, when changed, causes the component to re-render & the UI to update.',*/
    //     route:'/securityplus',
    // },
    // {
    //     image: stateImg,
    //   title: 'Database',
    //   /*description:
    //   'Return (potentially dynamic) HTML(ish) code to define the actual markup that will be rendered.',*/
    //   route:'/database/database_commands',
    // },
  ];
  export const STUDY_CONCEPTS = [
  {
    image: CCNAImg,
    title: 'CCNA',
   // description:
    //  'The core Networking building block for all Networking students.',
  },
  // {
  //   image: jsxImg,
  //   title: 'JSX',
  //  // description:
  //  //   'Return (potentially dynamic) HTML(ish) code to define the actual markup that will be rendered.',
  // },
  // {
  //   image: propsImg,
  //   title: 'Props',
  //   //description:
  //   //  'Make components configurable (and therefore reusable) by passing input data to them.',
  // },
  // {
  //   image: stateImg,
  //   title: 'State',
  //   //description:
  //   //  'React-managed data which, when changed, causes the component to re-render & the UI to update.',
  // },
];

  export const TabButtonsInfo = {
    CCNA: {
      image: CCNAImg,
      title: 'CCNA',
      description:
        'Cisco Certified Network Associate consists of several levels that cover various aspects of networking. Each level builds upon the previous one, providing a comprehensive understanding of network fundamentals.',
      code: `
  Alpha> enable
  Alpha# configure terminal
  Alpha(config)# banner motd #Welcome to ACS#
  Alpha(config)# exit
  Alpha# write memory`,
  
    route:'/ccna/ccna',
    },
    Math: {
        image: MathImg,
      title: 'Math',
      description:
        'Math is life',
      code: `
  <div>
    <h1>Welcome {userName}</h1>
    <p>Time to learn React!</p>
  </div>`,
        route:'/math',
    },
    SecurityPlus: {
        image: SecImg,
      title: 'Security+',
      description:
        'Components accept arbitrary inputs called props. They are like function arguments.',
      code: `
  function Welcome(props) {
    return <h1>Hello, {props.name}</h1>;
  }`,
  route:'/securityplus',
    },
    InfoTech: {
        image: CCImg,
      title: 'Info-Tech',
      description:
        'Components accept arbitrary inputs called props. They are like function arguments.',
      code: `
      function Counter() {
        const [isVisible, setIsVisible] = useState(false);
      
        function handleClick() {
          setIsVisible(true);
        }
      
        return (
          <div>
            <button onClick={handleClick}>Show Details</button>
            {isVisible && <p>Amazing details!</p>}
          </div>
        );
      }`,
      route:'/infotech',
    },
    Database: {
        image: DatabaseImg,
      title: 'Database',
      description:
        'Managing Data.',
      code:`SELECT c.Fst_Nme + c.Lst_Nme as username, a.Acc_Num, t.Acc_Typ, 
            a.Acc_Bal FROM Customers c
            INNER JOIN Accounts a on c.Cus_ID = a.Cus_ID
            INNER JOIN Account_Type t on a.Acc_Typ_ID = t.Acc_Typ_ID`,
        route:'/database',
    },
  };
