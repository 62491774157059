import React, { useState, useEffect } from 'react';
import './StudyRobot.css';

const motivationalSpeeches = [
 "Keep going! You're doing great!",
  "Don't stop now, success is near!",
  "Believe in yourself, you've got this!",
  "Stay focused, and keep pushing forward!",
  "Every step counts, keep moving!",
  // 'Hi Chantoya',
  // 'I love you',
  // 'Forever'
];

const StudyRobot = () => {
  const [isActive, setIsActive] = useState(true);
  const [speech, setSpeech] = useState('');
  const [robotPosition, setRobotPosition] = useState('bottom-right');

  const resetActivity = () => {
    setIsActive(true);
    setRobotPosition('bottom-right');
    setSpeech('');
  };

  const handleInactivity = () => {
    setIsActive(false);
    setSpeech(motivationalSpeeches[Math.floor(Math.random() * motivationalSpeeches.length)]);
    setRobotPosition('top-card');
  };

  useEffect(() => {
    const activityEvents = ['mousemove', 'keydown', 'scroll', 'click'];

    const resetTimeout = () => {
      resetActivity();
      clearTimeout(activityTimeout);
      activityTimeout = setTimeout(handleInactivity, 1800000); // 1 minute
    };

    let activityTimeout = setTimeout(handleInactivity, 1800000); // 1 minute

    activityEvents.forEach((event) => {
      window.addEventListener(event, resetTimeout);
    });

    return () => {
      clearTimeout(activityTimeout);
      activityEvents.forEach((event) => {
        window.removeEventListener(event, resetTimeout);
      });
    };
  }, []);

  return (
    <div className={`robot ${robotPosition}`}>
      🤖
      {!isActive && <div className="robot-speech">{speech}</div>}
    </div>
  );
};

export default StudyRobot;
