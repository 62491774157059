// src/components/courses/ccna/Calculator/ipv6_calculator.js 

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Container, Button, Dialog, DialogContent, Alert, AlertTitle, Typography, Grid } from '@mui/material';
import NavigationBar from '../CCNA2/NavigationBar'; // Adjust path as needed
import Utilities from '../../../utilities/utilities';
import { Helmet } from 'react-helmet';
// Ensure BigInt is defined
const BigInt = window.BigInt || global.BigInt; // Ensure BigInt is defined

// Convert IPv6 to binary
const ipv6ToBinary = (ipv6) => {
  return ipv6.split(':').map(part => {
    const binary = parseInt(part, 16).toString(2).padStart(16, '0');
    return binary;
  }).join('');
};

// Convert binary back to IPv6
const binaryToIPv6 = (binary) => {
  let ipv6 = '';
  for (let i = 0; i < 128; i += 16) {
    const part = binary.slice(i, i + 16);
    ipv6 += parseInt(part, 2).toString(16) + ':';
  }
  return ipv6.slice(0, -1); // Remove trailing colon
};

// Convert IPv6 to Octal
const ipv6ToOctal = (ipv6) => {
  return ipv6.split(':').map(part => parseInt(part, 16).toString(8)).join(':');
};

// Calculate IPv4 alternative (if any)
const ipv6ToIPv4 = (ipv6) => {
  const parts = ipv6.split(':');
  if (parts.length < 8) {
    // Assuming last part is in IPv4-mapped format
    const lastPart = parts[5] || '0';
    const [a, b, c, d] = lastPart.split('.').map(Number);
    return `${a}.${b}.${c}.${d}`;
  }
  return 'Not available';
};

const links = ['Home','Courses', 'Calculator'];
const chapters = []; // or some array of chapter objects if applicable
const onSearch = (searchTerm) => {
  // Handle search logic
};

// Calculate the number of total and usable addresses
const calculateTotalAndUsableAddresses = (cidr) => {
  const totalAddresses = BigInt(2 ** (128 - cidr));
  const usableAddresses = cidr === 128 ? 1n : totalAddresses - 1n;
  return { totalAddresses, usableAddresses };
};

// Convert IPv6 to decimal
const ipv6ToDecimal = (ipv6) => {
  return ipv6.split(':').map(part => parseInt(part, 16)).reduce((acc, val) => acc * BigInt(65536) + BigInt(val), 0n).toString();
};

// Calculate IPv6 network details based on input and CIDR
const calculateIPv6Details = (ipv6, cidr) => {
  const binaryAddress = ipv6ToBinary(ipv6);

  // Calculate network address
  const networkBinary = binaryAddress.slice(0, cidr).padEnd(128, '0');
  const networkAddress = binaryToIPv6(networkBinary);

  // Calculate first usable address
  const startRangeBinary = binaryAddress.slice(0, cidr).padEnd(128, '0');
  const startRange = binaryToIPv6(startRangeBinary);

  // Calculate last usable address
  const endRangeBinary = binaryAddress.slice(0, cidr).padEnd(128, '1');
  const endRange = binaryToIPv6(endRangeBinary);

  // Number of usable hosts
  const { totalAddresses, usableAddresses } = calculateTotalAndUsableAddresses(cidr);

  return {
    networkAddress,
    addressRangeStart: startRange,
    addressRangeEnd: endRange,
    totalAddresses: totalAddresses.toString(),
    usableAddresses: usableAddresses.toString(),
    binaryRepresentation: binaryAddress,
    decimalRepresentation: ipv6ToDecimal(ipv6),
    hexadecimalRepresentation: ipv6,
    octalRepresentation: ipv6ToOctal(ipv6),
    ipv4Alternative: ipv6ToIPv4(ipv6),
    ipType: 'Public', // Placeholder, you can adjust this based on real logic
  };
};

const isValidIPv6 = (address) => {

  const ipv6Regex = /^(([0-9a-fA-F]{1,4}:){1,7}([0-9a-fA-F]{1,4})?)$/;
  const compressedIpv6Regex = /^(([0-9a-fA-F]{1,4}:){0,7}:([0-9a-fA-F]{1,4}:){0,7}[0-9a-fA-F]{1,4})$/;
  return ipv6Regex.test(address) || compressedIpv6Regex.test(address);
  // Basic IPv6 validation, can be expanded if needed
  // const ipv6Regex = /^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/;
  // return ipv6Regex.test(address);
};

// Expand compressed IPv6 address to full format
const expandIPv6 = (address) => {
  if (address.includes('::')) {
    const parts = address.split('::');
    const left = parts[0].split(':').filter(Boolean);
    const right = (parts[1] || '').split(':').filter(Boolean);
    const missingZeroGroups = 8 - (left.length + right.length);
    const expandedAddress = [
      ...left,
      ...Array(missingZeroGroups).fill('0000'),
      ...right,
    ];
    return expandedAddress.map(part => part.padStart(4, '0')).join(':');
  }
  return address.split(':').map(part => part.padStart(4, '0')).join(':');
};

const IPv6_Calculator = () => {

  <Helmet>
      <title>IPv6 Address Calculator - Convert IPv6 to IPv4, Binary, Decimal & More | Alpha Centauri Inc.</title>
      <meta
        name="description"
        content="Use the IPv6 Calculator to convert IPv6 addresses into IPv4, binary, decimal, octal, hexadecimal. Get detailed network information including subnet mask, network range, broadcast, host count, wildcard, and more."
      />
      <meta
        name="keywords"
        content="IPv6 calculator, IPv6 to IPv4, binary conversion, subnet mask, IP address calculator, convert IPv6 to binary, network address, host range, wildcard mask, IPv6 subnetting"
      />
      <meta
        name="robots"
        content="index, follow"
      />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content="IPv6 Address Calculator - Convert IPv6 to IPv4, Binary, Decimal & More" />
      <meta property="og:description" content="Convert IPv6 addresses into IPv4, binary, octal, hexadecimal, and decimal. Get detailed network information including subnet mask, network range, and host address range." />
      <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
      <meta property="og:url" content="https://alphacentauriinc.com/ccna/Calculator/ipv6_calculator" />
      <meta property="og:type" content="website" />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:title" content="IPv6 Address Calculator - Convert IPv6 to IPv4, Binary, Decimal & More" />
      <meta name="twitter:description" content="Use our IPv6 calculator to convert addresses into IPv4, binary, and other formats. Get network details like host count, subnet mask, broadcast range, and more." />
      <meta name="twitter:image" content="%PUBLIC_URL%/logo192.png" />
      <meta name="twitter:card" content="summary_large_image" />

      {/* Canonical Link */}
      <link rel="canonical" href="https://alphacentauriinc.com/ccna/Calculator/ipv6_calculator" />

      {/* Structured Data for Article/Tools */}
      <script type="application/ld+json">
        {{
          "@context": "https://schema.org",
          "@type": "WebApplication",
          "name": "IPv6 Address Calculator",
          "description": "Convert IPv6 addresses into IPv4, binary, decimal, octal, hexadecimal, and more. View network and broadcast details, subnet mask, host range, and more.",
          "url": "https://alphacentauriinc.com/ccna/Calculator/ipv6_calculator",
          "logo": "%PUBLIC_URL%/logo192.png",
          "publisher": {
            "@type": "Organization",
            "name": "Alpha Centauri Inc."
          },
          "mainEntityOfPage": "https://alphacentauriinc.com/ccna/Calculator/ipv6_calculator"
        }}
      </script>

    </Helmet>

   
  const [ipv6Input, setIPv6Input] = useState('2001:0000:130F:0000::'); // Default IPv6 input for demonstration
  const [cidr, setCidr] = useState(64);
  const [ipv6Details, setIPv6Details] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  // const handleCalculate = () => {
  //   if (isValidIPv6(ipv6Input)) {
  //     const details = calculateIPv6Details(ipv6Input, cidr);
  //     setIPv6Details(details);
  //     setErrorMessage('');
  //   } else {
  //     setErrorMessage('Invalid IPv6 address. Please enter a valid one.');
  //     setIPv6Details(null);
  //   }
  // };
  const handleCalculate = () => {
    if (isValidIPv6(ipv6Input)) {
      const fullIPv6 = expandIPv6(ipv6Input);
      const details = calculateIPv6Details(fullIPv6, cidr);
      setIPv6Details(details);
      setErrorMessage('');
    } else {
      setErrorMessage('Invalid IPv6 address. Please enter a valid one.');
      setIPv6Details(null);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent page reload
      handleCalculate(); // Trigger calculation
    }
  };

  return (
    <div>
      <NavigationBar 
        links={links} 
        chapters={chapters} // Pass an empty array if no chapters
        onSearch={onSearch} 
        searchTerm="" 
        clearSearch={() => {}} 
        hasChapters={chapters.length > 0} // Optional: pass this prop if needed
      /> 
  

      <Container 
      
        sx={{ 
          background: 'rgba(17, 14, 14, 0.6)', 
          padding: 2, 
          borderRadius: 1,
          Top: 0
        }}
      >
        <Box sx={{ paddingTop: 4 }}>
          <Typography variant="h4" color="#1976d2" gutterBottom>
            Advanced IPv6 Calculator
          </Typography>
          <div> {/* Page content */}
    <p>Our IPv6 Address Calculator helps you convert IPv6 addresses into IPv4, binary, decimal, octal, hexadecimal, and more. Additionally, it provides in-depth network details such as subnet mask, network range, broadcast, host count, wildcard mask, and more.</p>

    {/* Add the content for the calculator and its features here */}
    {/* (e.g., IPv6 Address input form, conversion options, results display, etc.) */}
  </div>
  <div style={
          {marginBottom:"20px"}
        }></div>
          {errorMessage && (
            <Dialog open={Boolean(errorMessage)} onClose={() => setErrorMessage('')}>
              <DialogContent>
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {errorMessage}
                </Alert>
              </DialogContent>
            </Dialog>
          )}

          <Box component="form" sx={{ m: 1 }} noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
              <style>
        {`
            /* For Firefox */
            * {
                scrollbar-width: thin; /* Options: auto, thin */
                scrollbar-color: #1976d2 transparent; /* thumb color and track color */
            }

            /* For WebKit browsers (Chrome, Safari) */
            ::-webkit-scrollbar {
                width: 8px; /* Width of the scrollbar */
            }

            ::-webkit-scrollbar-track {
                background: transparent; /* Background of the scrollbar track */
            }

            ::-webkit-scrollbar-thumb {
                background: #1976d2; /* Color of the scrollbar thumb */
                border-radius: 10px; /* Optional: round the corners of the thumb */
            }

            ::-webkit-scrollbar-thumb:hover {
                background: #155a8a; /* Darker shade on hover */
            }
        `}
    </style>
                <TextField
                  label="IPv6 Address"
                  value={ipv6Input}
                  onChange={(e) => setIPv6Input(e.target.value)}
                  onKeyPress={handleKeyPress} // Trigger calculation on Enter key
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    sx: { color: '#1976d2', '& .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                    '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                  },
                    readOnly: false,
                  }}
                  InputLabelProps={{
                    sx: { color: '#1976d2' },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="CIDR Notation"
                  type="number"
                  value={cidr}
                  onChange={(e) => setCidr(e.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    sx: {
                      color: '#1976d2', // Change the text color
                      '& .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                      '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                    },
                  }}
                  InputLabelProps={{
                    sx: { color: '#1976d2' },
                  }}
                />
              </Grid>
            </Grid>

            <Button variant="contained" color="primary" onClick={handleCalculate} sx={{ marginTop: 2 }}>
              Calculate
            </Button>
          </Box>

          {ipv6Details && (
            <Box sx={{ marginTop: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Network Address"
                    value={ipv6Details.networkAddress}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Address Range Start"
                    value={ipv6Details.addressRangeStart}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Address Range End"
                    value={ipv6Details.addressRangeEnd}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Total Addresses"
                    value={ipv6Details.totalAddresses}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Usable Addresses"
                    value={ipv6Details.usableAddresses}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="IPv4 Alternative"
                    value={ipv6Details.ipv4Alternative}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Decimal Representation"
                    value={ipv6Details.decimalRepresentation}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Hexadecimal Representation"
                    value={ipv6Details.hexadecimalRepresentation}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Octal Representation"
                    value={ipv6Details.octalRepresentation}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Binary Representation"
                    value={ipv6Details.binaryRepresentation}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Container>
      <Utilities />
    </div>
  );
};

export default IPv6_Calculator;