import TypeAImg from './CCNA1_PTA_1.png'
import TypeA_2Img from './CCNA1_PTA_2.png'
import TypeBImg from './Exam_B_1.png'
import TypeB_2Img from './Exam_B_2.png'
import TypeA_124_1 from './CCNA1_PTA_124-1.png'
import TypeA_124_5 from './CCNA1_PTA_124-5.png'
import TypeA_214_1 from './CCNA1_PTA_214-1.png'
import TypeA_Server from './CCNA1_PTA-Server.png'


export const Skills_TabButtonsInfo = {
    Exam_A: {
      image: [TypeAImg, TypeA_2Img],
      title: 'ID:12, ID:32',
     /* description:
        'You might be given One (1) of these Three (3) Topology.',*/
      routerConfig: [
        `<h1>CS Department Router Configuration</h1>`+
        `<p>To connect to the <b>124-1</b> and <b>CS Department </b>router using the <b>line console</b>, follow these steps:</p>`+
        `<ol class="num-list">
        <li>Open the <b>"Desktop"</b> tab on the 124-1 device.</li>
        <li>Select <b>"Terminal"</b> from the options provided.</li>
        </ol>`
      ], 
      switch1Config:[
        `<h1>LAB 214-A Switch Configuration</h1>`+
        `<p>To connect to the <b>214-1</b> device to the <b>LAB 214-A Switch </b>router using the <b>line console</b>, follow these steps:</p>`+
        `<ol class="num-list">
        <li>On the <b>214-1</b> device, navigate to the "Desktop" tab.</li>
        <li>Select <b>"Terminal"</b> from the available options.</li>
        </ol>`
      ],  
     /* switch2Config:'Admin Switch Configuration', 
      endDevice1:'Prof X',
      endDevice2:'Prof Y',
      endDevice3:'Dean X',
      endDevice4:'Dean Y',*/

  tableData: [
    {
      Device: 'CS Department',
      Interface: 'G0/0',
      Address: '192.168.1.126/27',
      SubnetMask: '255.255.255.224',
      DefaultGateway: 'N/A',
    },
    {
      Device: 'CS Department',
      Interface: "G0/0",
      Address: '2001:db8:acad:a::1/64',
      SubnetMask: '',
      DefaultGateway: 'N/A',
    },

    {
      Device: 'CS Department',
      Interface: 'G0/1',
      Address: '192.168.1.158/28',
      SubnetMask: '255.255.255.240',
      DefaultGateway: 'N/A',
    },
    {
      Device: 'CS Department',
      Interface: "G0/1",
      Address: '2001:db8:acad:b::1/64',
      SubnetMask: '',
      DefaultGateway: 'N/A',
    },
    {
      Device: 'CS Department',
      Interface: "G0/1",
      Address: 'fe80::1',
      SubnetMask: '',
      DefaultGateway: 'N/A',
    },
    {
      Device: 'CS Department',
      Interface: "G0/0",
      Address: 'fe80::1',
      SubnetMask: '',
      DefaultGateway: 'N/A',
    },
    {
      Device: 'LAB 214-A Switch',
      Interface: 'SVI',
      Address: '192.168.1.157/28',
      SubnetMask: '255.255.255.240',
      DefaultGateway: '192.168.1.158',
    },
    {
      Device: '124-1',
      Interface: 'NIC',
      Address: '192.168.1.97/27',
      SubnetMask: '255.255.255.224',
      DefaultGateway: '192.168.1.126',
    },
    {
      Device: '124-1',
      Interface: 'NIC',
      Address: '2001:db8:acad:a::ff/64',
      SubnetMask: '',
      DefaultGateway: 'fe80::1',
    },
    {
      Device: '124-5',
      Interface: 'NIC',
      Address: '192.168.1.98/27',
      SubnetMask: '255.255.255.224',
      DefaultGateway: '192.168.1.126',
    },
    {
      Device: '124-5',
      Interface: 'NIC',
      Address: '2001:db8:acad:a::15/64',
      SubnetMask: '',
      DefaultGateway: 'fe80::1',
    },
    {
      Device: '214-1',
      Interface: 'NIC',
      Address: '192.168.1.145/28',
      SubnetMask: '255.255.255.240',
      DefaultGateway: '192.168.1.158',
    },
    {
      Device: '214-1',
      Interface: 'NIC',
      Address: '2001:db8:acad:b::ff/64',
      SubnetMask: '',
      DefaultGateway: 'fe80::1',
    },
    {
      Device: 'Server',
      Interface: 'NIC',
      Address: '192.168.1.146/28',
      SubnetMask: '255.255.255.240',
      DefaultGateway: '192.168.1.158',
    },
    {
      Device: 'Server',
      Interface: 'NIC',
      Address: '2001:db8:acad:b::15/64',
      SubnetMask: '',
      DefaultGateway: 'fe80::1',
    },
  
  ],

  instructions: [
    `<h1>Instructions</h1>`,
    `<h1><b>Step 1: Determine the IP Addressing Scheme.</b></h1>`+

    `<p> Design an IPv4 addressing scheme and complete the Addressing Table based on the following requirements.</p>` +
      `<ol class="ITN-skills-list">
            <li>Subnet the 192.168.1.0/24 network to provide 30 host addresses per subnet while wasting the fewest addresses.</li>
            <li>Assign the fourth subnet to the <b>LAB 124-C LAN</b>.</li>
            <li>Assign the last network host address (the highest) in this subnet to the G0/0 interface on <b>CS Department</b>.</li>
            <li><b>Addressing of network hosts with <b>(IPv4 and IPv6)</b> addresses</b></li>
            <li>Starting with the fifth subnet, subnet the network again so that the new subnets will provide 14 host addresses per subnet while wasting the fewest addresses.</li>
            <li>Assign the second of these new 14-host subnets to the <b>LAB 214-A LAN</b>.</li>
            <li>Assign the last network host address (the highest) in the <b>LAB 214-A LAN</b> subnet to the G0/1 interface of the <b>CS Department</b> router.</li>
            <li>Assign the second to the last address (the second highest) in this subnet to the VLAN 1 interface of the <b>LAB 214-A Switch</b>.</li>
            <li>Configure addresses on the hosts using any of the remaining addresses in their respective subnets.</li>
            </ol>`,
            `<h1><b>Step 2: Configure Host Addressing</b></h1>`+

    `<ol class="ITN-skills-list">
            <li>Use the IPv4 addressing from Step 1 and the IPv6 addressing values provided in the Addressing Table to configure all host PCs with the correct addressing.</li>
            <li>Use the router interface link-local address as the IPv6 default gateways on the hosts.</li>
            <li>Complete the configuration of the server using the IPv4 addressing values from Step 1 and the values in the addressing table</li>
            </ol>`,

            `<h1><b>Step 3: Configure the CS Department Router.</b></h1>`+

    `<ol class="ITN-skills-list">
            <li>Configure the <b>CS Department router</b> with all initial configurations that you have learned in the course so far:</li>
            </ol>`+
            `<ol class="skills-list">
            <li>Configure the router hostname: <b>CS-Department</b></li>
            <li>Secure all access lines into the router using methods covered in the course and labs.</li>
            <li>Require newly-entered passwords to have a minimum length of 10 characters.</li>
            <li>Prevent all passwords from being viewed in clear text in device configuration files.</li>
            <li>Configure the router to only accept in-band management connections over the protocol that is more secure than Telnet, as was done in the labs and PT activities. Use the value <b>1024</b> for encryption key strength.</li>
            <li>Configure local user authentication for in-band management connections. Create a user with the name <b>netadmin</b> and a secret password of <b>Cisco_CCNA7</b>.</li>
            </ol>`+

            `<ol class="custom-list">
            <li>Configure the two Gigabit Ethernet interfaces using the IPv4 addressing values that you calculated and the IPv6 values provided in the addressing table.</li>
            </ol>`+
            `<ol class="skills-list">
            <li>Reconfigure the link local addresses to the value shown in the table.</li>
            <li>Document the interfaces in the configuration file.</li>
            <li>Require newly-entered passwords to have a minimum length of 10 characters.</li>
            </ol>`,
            `<h1><b>Step 4: Configure the LAB 214-A Switch</b></h1>`+
              `<p><b>Configure LAB 214-A Switch for remote management over Telnet.</b></p>`+
            `<ol class="ITN-skills-list">
                    <li>Configure VLAN 1 as the SVI.</li>
                    <li>Configure IPv4 addressing according to your work in Step 1.</li>
                    <li>Be sure that the switch is able to accept connections from hosts on other networks.</li>
                    </ol>`,
  ],

  objectives:[
            "Perform basic device configuration tasks on a router and a switch",
            "Configure IP addressing settings on network devices",
            "Verify Layer 3 connectivity and troubleshoot connectivity issues"
  ],

  //scenario:'You are working on a network that has already been partially configured In this assessment you will complete a set of requirements by configunng some additional settings on the Faculty switch and the router. Use the knowledge that you have gained in the curnculum and labs to fulfill the requirements below',
   
  requirments: [
    `<h3>CS Department router:</h3>`+
    `<ol class="skills-list">
            <li>Configuration of initial router settings</li>
            <li>Interface configuration and IPv4 and IPv6 addressing</li>
            <li>Device security enhancement, or device hardening</li>
            <li>Secure transport for remote configuration connections as covered in the labs and Packet Tracers in the course.</li>
            </ol>`+
            `<h3>LAB 214-A Switch:</h3>`+
            `<p> Enabling basic remote management by Telnet</p>`+ 
            `<p> PC and Server hosts:</p>`+  
    `<ol class="skills-list">
            <li>IPv4 full addressing</li>
            <li>IPv6 addressing</li>
            </ol>`
  ],

  introduction: [
    `<h2>Introduction</h2>`+
    `<p class="line-spacing">In this assessment, you will configure devices in an IPv4/IPv6 network. For the sake of time, you will not be asked to perform all configurations on all network devices as you may be required to do in a real network or other assessment.
    Instead, you will use the skills and knowledge that you have learned in the labs and packet tracers in this course to configure the <b>CS Department</b> router. In addition, you will address the hosts on two LANs with IPv4 and IPv6 addresses and activate and address the management interface of the <b>LAB 214-A Switch</b>.
    You will receive one of several topologies.
    You are not required to configure the <b>LAB 124-C Switch</b>, and you will not be able to access it in this practice skills assessment activity.
    All IOS device configurations should be completed from a direct terminal connection to the device console. In addition, many values that are required to complete the configurations have not been given to you. In those cases, create the values that you need to complete the requirements. For values that have been supplied to you, they must be entered exactly as they appear in order for you to get full credit for your configuration.</br>
    <p><b>You will practice and be assessed on the following skills:</b></p>` +
      `<ol class="skills-list">
            <li><b>Configuration of initial IOS device settings</b></li>
            <li><b>Design and calculation of IPv4 addressing</b></li>
            <li><b>Configuration of IOS device interfaces including <b>(IPv4 and IPv6)</b> addressing when appropriate</b></li>
            <li><b>Addressing of network hosts with <b>(IPv4 and IPv6)</b> addresses</b></li>
            <li><b>Enhancing device security, including configuration of the secure transport protocol for remote device management</b></li>
            <li><b>Configuration of a switch virtual management interface</b></li>
            </ol>`
  ],

    
   note: [
          `<h1>NOTE:</h1>`+
          `<ol class="skills-list-note">
              <li><b>Avoid using the browser's Back button or closing/reloading any exam windows during the exam.</b></li>
              <li><b>Do not manually close Packet Tracer; it will shut down on its own when you're finished.</b></li>
              <li><b>Click the "Submit Assessment" button in the browser window to submit your work.</b></li>
            </ol>`,
   ],

   host_Tag:'Hosts Configuration',

   host_124_1: [TypeA_124_1],
   host_124_1_Config:[
    `<h1>124-1</h1>`,
    `<p>IPv4 Address: <b>192.168.1.97</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.224</b></p>`+
    `<p>IPv4 Default Gateway: <b>192.168.1.126</b></p>`,
    `<p>IPv6 Address: <b>2001:DB8:ACAD:A::FF/64</b></p>`+
    `<p>IPv6 Default Gateway: <b>FE80::1</b></p>`
   ],

   host_124_5: [TypeA_124_5],
   host_124_5_Config:[
    `<h1>124-5</h1>`,
    `<p>IPv4 Address: <b>192.168.1.98</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.224</b></p>`+
    `<p>IPv4 Default Gateway: <b>192.168.1.126</b></p>`,
    `<p>IPv6 Address: <b>2001:DB8:ACAD:A::15/64</b></p>`+
    `<p>IPv6 Default Gateway: <b>FE80::1</b></p>`
   ],

   host_214_1: [TypeA_214_1],
   host_214_1_Config:[
    `<h1>214-1</h1>`,
    `<p>IPv4 Address: <b>192.168.1.145</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.240</b></p>`+
    `<p>IPv4 Default Gateway: <b>192.168.1.158</b></p>`,
    `<p>IPv6 Address: <b>2001:DB8:ACAD:B::FF/64</b></p>`+
    `<p>IPv6 Default Gateway: <b>FE80::1</b></p>`
   ],

   host_Server: [TypeA_Server],
   host_Server_Config:[
    `<h1>Server</h1>`,
    `<p>IPv4 Address: <b>192.168.1.146</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.240</b></p>`+
    `<p>IPv4 Default Gateway: <b>192.168.1.158</b></p>`,
    `<p>IPv6 Address: <b>2001:DB8:ACAD:B::15/64</b></p>`+
    `<p>IPv6 Default Gateway: <b>FE80::1</b></p>`
   ],


   router:[
    "en",
"config t",

"hostname CS-Department",

"enable secret class12345",

"service password-encryption",

"banner motd $Authorized Users Only$",

"security passwords min-length 10",

"login block-for 120 attempts 2 within 30",

"no ip domain-lookup",

"ip domain-name studyhub.com",

"crypto key generate rsa",
"1024",

"line console 0",
"password cisco12345",
"login",
"logging synchronous",
"exec-timeout 60",
"exit",

"line vty 0 4",
"password cisco12345",
"transport input ssh",
"login local",
"logging synchronous",
"exec-timeout 60",
"exit",

"line aux 0",
"password cisco12345",
"login",
"logging synchronous",
"exec-timeout 60",
"exit",

"ip ssh version 2",
"ip ssh time-out 120",

"username netadmin privilege 15 secret Cisco_CCNA7",

"interface g0/0",
"ip address 192.168.1.126 255.255.255.224",
"description LAB 124-C LAN",
"ipv6 address 2001:DB8:ACAD:A::1/64",
"ipv6 address fe80::1 link-local",
"no shut",
"exit",

"interface g0/1",
"ip address 192.168.1.158 255.255.255.240",
"description LAB 214-A LAN",
"ipv6 address 2001:DB8:ACAD:B::1/64",
"ipv6 address fe80::1 link-local",
"no shut",
"exit",

"ipv6 unicast-routing",

"end",

"write"

   ],

   switch1:[
  "en",
"conf t",

"enable secret class12345",

"service password-encryption",

"banner motd $LAB 214-A Switch$",

"no ip domain-lookup",

"line console 0",
"password cisco12345",
"login",
"logging synchronous",
"exec-timeout 60",
"exit",

"line vty 0 15",
"password cisco12345",
"login",
"logging synchronous",
"exec-timeout 60",
"exit",

"interface vlan 1",
"ip address 192.168.1.157 255.255.255.240",
"no shut",
"exit",

"ip default-gateway 192.168.1.158",

"end",

"write"

   ],

 /*  switch2:[
    "enable",
"config t",

"hostname Admin",

"enable secret class",

"line console 0",
"password cisco",
"login",
"exit",

"line vty 0 4",
"password cisco",
"login",
"exit",

"service password-encryption",

"banner motd $Authorized Users Only$",

"interface vlan 1",
"ip address 192.168.5.252 255.255.255.0",
"no shut",
"description Admin – College LAN",
"exit",

"ip default-gateway 192.168.5.1",
"end",

"write"
   ],

device1:[
"IP Address: 172.16.5.10",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 172.16.5.1"
   ],

device2:[
"IP Address: 172.16.5.11",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 172.16.5.1"
],

device3:[
"IP Address: 192.168.5.10",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 192.168.5.1"
],

device4:[
"IP Address: 192.168.5.11",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 192.168.5.1"
]*/
  
  //route:'/ccna/ccna',
    },
    Exam_B: {
      image: [TypeBImg , TypeB_2Img],
      title: 'ID:21, ID:31',
     /* description:
        'You might be given One (1) of these Three (3) Topology.',*/
      routerConfig: [
        `<h1>Building 1 Router Configuration</h1>`+
        `<p>To connect to the <b>Host 1</b> and <b>Building 1 </b>router using the <b>line console</b>, follow these steps:</p>`+
        `<ol class="num-list">
        <li>Open the <b>"Desktop"</b> tab on the Host 1 device.</li>
        <li>Select <b>"Terminal"</b> from the options provided.</li>
        </ol>`
      ], 
      switch1Config:[
        `<h1>Second Floor Switch Configuration</h1>`+
        `<p>To connect to the <b>Host 3</b> device to the <b>Second Floor Switch </b>router using the <b>line console</b>, follow these steps:</p>`+
        `<ol class="num-list">
        <li>On the <b>Host 3</b> device, navigate to the "Desktop" tab.</li>
        <li>Select <b>"Terminal"</b> from the available options.</li>
        </ol>`
      ],  
     /* switch2Config:'Admin Switch Configuration', 
      endDevice1:'Prof X',
      endDevice2:'Prof Y',
      endDevice3:'Dean X',
      endDevice4:'Dean Y',*/

  tableData: [
    {
      Device: 'Building 1',
      Interface: 'G0/0',
      Address: '192.168.1.126/27',
      SubnetMask: '255.255.255.224',
      DefaultGateway: 'N/A',
    },
    {
      Device: 'Building 1',
      Interface: "G0/0",
      Address: '2001:db8:acad:a::1/64',
      SubnetMask: '',
      DefaultGateway: 'N/A',
    },
    {
      Device: 'Building 1',
      Interface: "G0/0",
      Address: 'fe80::1',
      SubnetMask: '',
      DefaultGateway: 'N/A',
    },
    {
      Device: 'Building 1',
      Interface: 'G0/1',
      Address: '192.168.1.158/28',
      SubnetMask: '255.255.255.240',
      DefaultGateway: 'N/A',
    },
    {
      Device: 'Building 1',
      Interface: "G0/1",
      Address: '2001:db8:acad:a::1/64',
      SubnetMask: '',
      DefaultGateway: 'N/A',
    },
    {
      Device: 'Building 1',
      Interface: "G0/1",
      Address: 'fe80::1',
      SubnetMask: '',
      DefaultGateway: 'N/A',
    },
    {
      Device: 'Second Floor Switch',
      Interface: 'SVI',
      Address: '192.168.1.157/28',
      SubnetMask: '255.255.255.240',
      DefaultGateway: '192.168.1.158',
    },
    {
      Device: 'Host 1',
      Interface: 'NIC',
      Address: '192.168.1.97/27',
      SubnetMask: '255.255.255.224',
      DefaultGateway: '192.168.1.126',
    },
    {
      Device: 'Host 1',
      Interface: 'NIC',
      Address: '2001:db8:acad:a::ff/64',
      SubnetMask: '',
      DefaultGateway: 'fe80::1',
    },
    {
      Device: 'Host 2',
      Interface: 'NIC',
      Address: '192.168.1.98/27',
      SubnetMask: '255.255.255.224',
      DefaultGateway: '192.168.1.126',
    },
    {
      Device: 'Host 2',
      Interface: 'NIC',
      Address: '2001:db8:acad:a::15/64',
      SubnetMask: '',
      DefaultGateway: 'fe80::1',
    },
    {
      Device: 'Host 3',
      Interface: 'NIC',
      Address: '192.168.1.145/28',
      SubnetMask: '255.255.255.240',
      DefaultGateway: '192.168.1.158',
    },
    {
      Device: 'Host 3',
      Interface: 'NIC',
      Address: '2001:db8:acad:b::ff/64',
      SubnetMask: '',
      DefaultGateway: 'fe80::1',
    },
    {
      Device: 'Server',
      Interface: 'NIC',
      Address: '192.168.1.146/28',
      SubnetMask: '255.255.255.240',
      DefaultGateway: '192.168.1.158',
    },
    {
      Device: 'Server',
      Interface: 'NIC',
      Address: '2001:db8:acad:b::15/64',
      SubnetMask: '',
      DefaultGateway: 'fe80::1',
    },
  
  ],

  instructions: [
    `<h1>Instructions</h1>`,
    `<h1><b>Step 1: Determine the IP Addressing Scheme.</b></h1>`+

    `<p> Design an IPv4 addressing scheme and complete the Addressing Table based on the following requirements.</p>` +
      `<ol class="ITN-skills-list">
            <li>Subnet the 192.168.1.0/24 network to provide 30 host addresses per subnet while wasting the fewest addresses.</li>
            <li>Assign the fourth subnet to the <b>First Floor LAN</b>.</li>
            <li>Assign the last network host address (the highest) in this subnet to the G0/0 interface on <b>Building 1</b>.</li>
            <li>Starting with the fifth subnet, subnet the network again so that the new subnets will provide 14 host addresses per subnet while wasting the fewest addresses.</li>
            <li>Assign the second of these new 14-host subnets to the <b>Second Floor LAN</b>.</li>
            <li>Assign the last network host address (the highest) in the <b>Second Floor LAN</b> subnet to the G0/1 interface of the <b>Building 1</b> router.</li>
            <li>Assign the second to the last address (the second highest) in this subnet to the VLAN 1 interface of the <b>Second Floor Switch</b>.</li>
            <li>Configure addresses on the hosts using any of the remaining addresses in their respective subnets.</li>
            </ol>`,
            `<h1><b>Step 2: Configure Host Addressing</b></h1>`+

    `<ol class="ITN-skills-list">
            <li>Use the IPv4 addressing from Step 1 and the IPv6 addressing values provided in the Addressing Table to configure all host PCs with the correct addressing.</li>
            <li>Use the router interface link-local address as the IPv6 default gateways on the hosts.</li>
            <li>Complete the configuration of the server using the IPv4 addressing values from Step 1 and the values in the addressing table</li>
            </ol>`,

            `<h1><b>Step 3: Configure the Building 1 Router.</b></h1>`+

    `<ol class="ITN-skills-list">
            <li>Configure the <b>Building 1 router</b> with all initial configurations that you have learned in the course so far:</li>
            </ol>`+
            `<ol class="skills-list">
            <li>Configure the router hostname: <b>Building-1</b></li>
            <li>Protect device configurations from unauthorized access with the encrypted privileged exec password.</li>
            <li>Secure all access lines into the router using methods covered in the course and labs.</li>
            <li>Require newly-entered passwords to have a minimum length of 10 characters.</li>
            <li>Prevent all passwords from being viewed in clear text in device configuration files.</li>
            <li>Configure the router to only accept in-band management connections over the protocol that is more secure than Telnet, as was done in the labs and PT activities. Use the value <b>1024</b> for encryption key strength.</li>
            <li>Configure local user authentication for in-band management connections. Create a user with the name <b>netadmin</b> and a secret password of <b>Cisco_CCNA7</b>.</li>
            </ol>`+

            `<ol class="custom-list">
            <li>Configure the two Gigabit Ethernet interfaces using the IPv4 addressing values that you calculated and the IPv6 values provided in the addressing table.</li>
            </ol>`+
            `<ol class="skills-list">
            <li>Reconfigure the link local addresses to the value shown in the table.</li>
            <li>Document the interfaces in the configuration file.</li>
            <li>Require newly-entered passwords to have a minimum length of 10 characters.</li>
            </ol>`,
            `<h1><b>Step 4: Configure the Second Floor Switch</b></h1>`+
              `<p><b>Configure Second Floor Switch for remote management over Telnet.</b></p>`+
            `<ol class="ITN-skills-list">
                    <li>Configure VLAN 1 as the SVI.</li>
                    <li>Configure IPv4 addressing according to your work in Step 1.</li>
                    <li>Be sure that the switch is able to accept connections from hosts on other networks.</li>
                    </ol>`,
  ],

 /* objectives:[
            "Perform basic device configuration tasks on a router and a switch",
            "Configure IP addressing settings on network devices",
            "Verify Layer 3 connectivity and troubleshoot connectivity issues"
  ],*/

  //scenario:'You are working on a network that has already been partially configured In this assessment you will complete a set of requirements by configunng some additional settings on the Faculty switch and the router. Use the knowledge that you have gained in the curnculum and labs to fulfill the requirements below',
   
  requirments: [
    `<h3>Building 1 router:</h3>`+
    `<ol class="skills-list">
            <li>Configuration of initial router settings</li>
            <li>Interface configuration and IPv4 and IPv6 addressing</li>
            <li>Device security enhancement, or device hardening</li>
            <li>Secure transport for remote configuration connections as covered in the labs and Packet Tracers in the course.</li>
            </ol>`+
            `<h3>Second Floor Switch:</h3>`+
            `<p> Enabling basic remote management by Telnet</p>`+ 
            `<p> PC and Server hosts:</p>`+  
    `<ol class="skills-list">
            <li>IPv4 full addressing</li>
            <li>IPv6 addressing</li>
            </ol>`
  ],

  introduction: [
    `<h2>Introduction</h2>`+
    `<p class="line-spacing">In this assessment, you will configure devices in an IPv4/IPv6 network. For the sake of time, you will not be asked to perform all configurations on all network devices as you may be required to do in a real network or other assessment.
    Instead, you will use the skills and knowledge that you have learned in the labs and packet tracers in this course to configure the <b>Building 1</b> router. In addition, you will address the hosts on two LANs with IPv4 and IPv6 addresses and activate and address the management interface of the <b>Second Floor Switch</b>.
    You will receive one of several topologies.
    You are not required to configure the <b>First Floor Switch</b>, and you will not be able to access it in this practice skills assessment activity.
    All IOS device configurations should be completed from a direct terminal connection to the device console. In addition, many values that are required to complete the configurations have not been given to you. In those cases, create the values that you need to complete the requirements. For values that have been supplied to you, they must be entered exactly as they appear in order for you to get full credit for your configuration.</br>
    <p><b>You will practice and be assessed on the following skills:</b></p>` +
      `<ol class="skills-list">
            <li><b>Configuration of initial IOS device settings</b></li>
            <li><b>Design and calculation of IPv4 addressing</b></li>
            <li><b>Configuration of IOS device interfaces including <b>(IPv4 and IPv6)</b> addressing when appropriate</b></li>
            <li><b>Addressing of network hosts with <b>(IPv4 and IPv6)</b> addresses</b></li>
            <li><b>Enhancing device security, including configuration of the secure transport protocol for remote device management</b></li>
            <li><b>Configuration of a switch virtual management interface</b></li>
            </ol>`
  ],

    
   note: [
          `<h1>NOTE:</h1>`+
          `<ol class="skills-list-note">
              <li><b>Avoid using the browser's Back button or closing/reloading any exam windows during the exam.</b></li>
              <li><b>Do not manually close Packet Tracer; it will shut down on its own when you're finished.</b></li>
              <li><b>Click the "Submit Assessment" button in the browser window to submit your work.</b></li>
            </ol>`,
   ],

   host_Tag:'Hosts Configuration',

   host_124_1: [TypeA_124_1],
   host_124_1_Config:[
    `<h1>Host 1</h1>`,
    `<p>IPv4 Address: <b>192.168.1.97</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.224</b></p>`+
    `<p>IPv4 Default Gateway: <b>192.168.1.126</b></p>`,
    `<p>IPv6 Address: <b>2001:DB8:ACAD:A::FF/64</b></p>`+
    `<p>IPv6 Default Gateway: <b>FE80::1</b></p>`
   ],

   host_124_5: [TypeA_124_5],
   host_124_5_Config:[
    `<h1>Host 2</h1>`,
    `<p>IPv4 Address: <b>192.168.1.98</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.224</b></p>`+
    `<p>IPv4 Default Gateway: <b>192.168.1.126</b></p>`,
    `<p>IPv6 Address: <b>2001:DB8:ACAD:A::15/64</b></p>`+
    `<p>IPv6 Default Gateway: <b>FE80::1</b></p>`
   ],

   host_214_1: [TypeA_214_1],
   host_214_1_Config:[
    `<h1>Host 3</h1>`,
    `<p>IPv4 Address: <b>192.168.1.145</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.240</b></p>`+
    `<p>IPv4 Default Gateway: <b>192.168.1.158</b></p>`,
    `<p>IPv6 Address: <b>2001:DB8:ACAD:B::FF/64</b></p>`+
    `<p>IPv6 Default Gateway: <b>FE80::1</b></p>`
   ],

   host_Server: [TypeA_Server],
   host_Server_Config:[
    `<h1>Server</h1>`,
    `<p>IPv4 Address: <b>192.168.1.146</b></p>`+
    `<p>IPv4 Subnet Mask: <b>255.255.255.240</b></p>`+
    `<p>IPv4 Default Gateway: <b>192.168.1.158</b></p>`,
    `<p>IPv6 Address: <b>2001:DB8:ACAD:B::15/64</b></p>`+
    `<p>IPv6 Default Gateway: <b>FE80::1</b></p>`
   ],


   router:[
    "en",
"config t",

"hostname Building-1",

"enable secret class12345",

"service password-encryption",

"banner motd $Authorized Users Only$",

"security passwords min-length 10",

"login block-for 120 attempts 2 within 30",

"no ip domain-lookup",

"ip domain-name studyhub.com",

"crypto key generate rsa",
"1024",

"line console 0",
"password cisco12345",
"login",
"logging synchronous",
"exec-timeout 60",
"exit",

"line vty 0 4",
"password cisco12345",
"transport input ssh",
"login local",
"logging synchronous",
"exec-timeout 60",
"exit",

"line aux 0",
"password cisco12345",
"login",
"logging synchronous",
"exec-timeout 60",
"exit",

"ip ssh version 2",
"ip ssh time-out 120",

"username netadmin privilege 15 secret Cisco_CCNA7",

"interface g0/0",
"ip address 192.168.1.126 255.255.255.224",
"description First Floor LAN",
"ipv6 address 2001:DB8:ACAD:A::1/64",
"ipv6 address fe80::1 link-local",
"no shut",
"exit",

"interface g0/1",
"ip address 192.168.1.158 255.255.255.240",
"description Second Floor LAN",
"ipv6 address 2001:DB8:ACAD:B::1/64",
"ipv6 address fe80::1 link-local",
"no shut",
"exit",

"ipv6 unicast-routing",

"end",

"copy run start"

   ],

   switch1:[
  "en",
"conf t",

"enable secret class12345",

"service password-encryption",

"banner motd $Second Floor Switch$",

"no ip domain-lookup",

"line console 0",
"password cisco12345",
"login",
"logging synchronous",
"exec-timeout 60",
"exit",

"line vty 0 15",
"password cisco12345",
"login",
"logging synchronous",
"exec-timeout 60",
"exit",

"interface vlan 1",
"ip address 192.168.1.157 255.255.255.240",
"no shut",
"exit",

"ip default-gateway 192.168.1.158",

"end",

"copy run start"

   ],

 /*  switch2:[
    "enable",
"config t",

"hostname Admin",

"enable secret class",

"line console 0",
"password cisco",
"login",
"exit",

"line vty 0 4",
"password cisco",
"login",
"exit",

"service password-encryption",

"banner motd $Authorized Users Only$",

"interface vlan 1",
"ip address 192.168.5.252 255.255.255.0",
"no shut",
"description Admin – College LAN",
"exit",

"ip default-gateway 192.168.5.1",
"end",

"write"
   ],

device1:[
"IP Address: 172.16.5.10",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 172.16.5.1"
   ],

device2:[
"IP Address: 172.16.5.11",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 172.16.5.1"
],

device3:[
"IP Address: 192.168.5.10",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 192.168.5.1"
],

device4:[
"IP Address: 192.168.5.11",
"Subnet Mask: 255.255.255.0",
"Default Gateway: 192.168.5.1"
]*/
  
  //route:'/ccna/ccna',
    },

}