import React, { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import Confetti from "react-confetti";
import Fireworks from "fireworks-js";
import "./Quiz.css";

// Register necessary components for Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

const Result = ({ questions, selectedAnswers }) => {
  const [showAnimations, setShowAnimations] = useState(false);

  const calculateScore = () => {
    let score = 0;

    questions.forEach((question) => {
      if (question.type === "multiple-choice") {
        const correctAnswers = question.answers
          .map((answer, index) => (answer.isCorrect ? index : null))
          .filter((index) => index !== null);
        const selectedAnswerIndices = selectedAnswers[question.id] || [];

        const correctSelections = selectedAnswerIndices.filter((index) => correctAnswers.includes(index)).length;
        if (correctSelections === correctAnswers.length) {
          score += correctAnswers.length;
        } else {
          score += correctSelections;
        }
      } else if (question.type === "matching") {
        const userMatches = selectedAnswers[question.id] || {};
        const correctMatches = question.correctMatches;
        
        Object.keys(correctMatches).forEach(itemId => {
          const userMatch = userMatches[itemId];
          if (Array.isArray(userMatch)) {
            const correctOptions = correctMatches[itemId];
            const correctCount = userMatch.filter(option => correctOptions.includes(option)).length;
            score += correctCount;
          } else if (correctMatches[itemId].includes(userMatch)) {
            score += 1;
          }
        });
      }
    });

    return score;
  };

  const calculateQuestionScore = (question) => {
    if (question.type === "multiple-choice") {
      const correctAnswers = question.answers
        .map((answer, index) => (answer.isCorrect ? index : null))
        .filter((index) => index !== null);
      const selectedAnswerIndices = selectedAnswers[question.id] || [];

      const correctSelections = selectedAnswerIndices.filter((index) => correctAnswers.includes(index)).length;
      return { earned: correctSelections, total: correctAnswers.length };
    } else if (question.type === "matching") {
      const userMatches = selectedAnswers[question.id] || {};
      const correctMatches = question.correctMatches;
      let earned = 0;
      let total = 0;

      Object.keys(correctMatches).forEach(itemId => {
        const userMatch = userMatches[itemId];
        const correctOptions = correctMatches[itemId];
        total += correctOptions.length;

        if (Array.isArray(userMatch)) {
          earned += userMatch.filter(option => correctOptions.includes(option)).length;
        } else if (correctOptions.includes(userMatch)) {
          earned += 1;
        }
      });

      return { earned, total };
    }
  };

  const totalPoints = questions.reduce((total, question) => {
    if (question.type === "multiple-choice") {
      return total + question.answers.filter((answer) => answer.isCorrect).length;
    } else if (question.type === "matching") {
      return total + Object.values(question.correctMatches).flat().length;
    }
    return total;
  }, 0);

  const percentage = ((calculateScore() / totalPoints) * 100).toFixed(2);

  const totalQuestions = questions.length;
  const correctCount = calculateScore();
  const unansweredCount = totalQuestions - Object.keys(selectedAnswers).length;
  const wrongCount = totalQuestions - unansweredCount - correctCount;

  const pieData = {
    labels: ["Correct", "Wrong", "Skipped/Unanswered"],
    datasets: [
      {
        data: [correctCount, wrongCount, unansweredCount],
        backgroundColor: ["#4CAF50", "#FF5252", "#FFC107"],
      },
    ],
  };

  // Manage the visibility of animations based on percentage
  useEffect(() => {
    if (percentage >= 70) {
      setShowAnimations(true);
      const timer = setTimeout(() => {
        setShowAnimations(false);
      }, 20000);

      return () => clearTimeout(timer);
    }
  }, [percentage]);

  // Initialize fireworks animation
  useEffect(() => {
    if (showAnimations) {
      const container = document.getElementById('fireworks-container');
      const fireworks = new Fireworks(container, {
        // Fireworks configuration options
        speed: 3,
        acceleration: 1.05,
        friction: 0.98,
        gravity: 1,
        particles: 50,
      });
      fireworks.start();

      return () => fireworks.stop();
    }
  }, [showAnimations]);

  return (
    <div className="result-wrapper">
      {showAnimations && (
        <div className="animation-overlay">
          <Confetti />
          <div id="fireworks-container"></div>
        </div>
      )}

      <div className="result-container">
        <div className="content">
          <div className="header-card">
            <h2>Your Score: {calculateScore()} points out of {totalPoints} points ({percentage}%)</h2>
            <div className="result-stats">
              <p>Correct: {correctCount}</p>
              <p>Wrong: {wrongCount}</p>
              <p>Skipped/Unanswered: {unansweredCount}</p>
            </div>
            <div className="chart-container">
              <Pie data={pieData} options={{ responsive: true, maintainAspectRatio: false }} />
            </div>
          </div>
          <div className="questions-container">
            {questions.map((question, index) => {
              const { earned, total } = calculateQuestionScore(question);
              return (
                <div key={index} className="question-card-result">
                  <div className="question-header">
                    <h3>Question {index + 1}: {question.question}</h3>
                    <span className="question-points">{earned}/{total} pts</span>
                  </div>
                  {question.image && <img src={question.image} alt="Question Image" className="result-image" />}
                  {question.type === "multiple-choice" && (
                    <div className="answers-result">
                      {question.answers.map((answer, idx) => (
                        <div
                          key={idx}
                          className={`answer-result ${answer.isCorrect ? "correct" : ""} ${
                            selectedAnswers[question.id] && selectedAnswers[question.id].includes(idx)
                              ? answer.isCorrect
                                ? "user-correct"
                                : "user-wrong"
                              : ""
                          }`}
                        >
                          {answer.text}
                          {selectedAnswers[question.id] && selectedAnswers[question.id].includes(idx) ? (
                            answer.isCorrect ? (
                              <span className="check-mark">✔️</span>
                            ) : (
                              <span className="cross-mark">❌</span>
                            )
                          ) : null}
                        </div>
                      ))}
                    </div>
                  )}
                  {question.type === "matching" && (
                    <div className="matching-result">
                      {question.items.map((item) => (
                        <div key={item.id} className="matching-item-result">
                          <span>{item.content}</span>
                          <div className="matching-options-result">
                            {(selectedAnswers[question.id] && selectedAnswers[question.id][item.id]) ? (
                              Array.isArray(selectedAnswers[question.id][item.id]) ? (
                                selectedAnswers[question.id][item.id].map((optionId) => (
                                  <span key={optionId} className={question.correctMatches[item.id].includes(optionId) ? "correct" : "incorrect"}>
                                    {question.options.find(opt => opt.id === optionId).content}
                                    {question.correctMatches[item.id].includes(optionId) ? " ✔️" : " ❌"}
                                  </span>
                                ))
                              ) : (
                                <span className={question.correctMatches[item.id].includes(selectedAnswers[question.id][item.id]) ? "correct" : "incorrect"}>
                                  {question.options.find(opt => opt.id === selectedAnswers[question.id][item.id]).content}
                                  {question.correctMatches[item.id].includes(selectedAnswers[question.id][item.id]) ? " ✔️" : " ❌"}
                                </span>
                              )
                            ) : (
                              <span className="unanswered">Unanswered</span>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Result;