import React from "react";
import { useTable, useSortBy, useFilters, useGlobalFilter } from "react-table";
import './database_Commands.css'; // CSS file for custom styling

function Database_Commands() {

  const data = React.useMemo(
    () => Array.from({ length: 20 }, (_, index) => ({
      col1: `Command ${index + 1}`,
      col2: `Description ${index + 1}`,
      col3: `Example ${index + 1}`,
    })),
    []
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'Command',
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: 'Description',
        accessor: 'col2',
      },
      {
        Header: 'Example',
        accessor: 'col3',
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state,
  } = useTable({ columns, data }, useFilters, useGlobalFilter, useSortBy);

  return (
    <div>
      <h1>Datab Commands Table</h1>
      <input
        value={state.globalFilter || ''}
        onChange={(e) => setGlobalFilter(e.target.value)}
        placeholder="Search..."
        style={{ marginBottom: "10px", padding: "8px", borderRadius: "5px" }}
      />
      <table {...getTableProps()} className="ccna-table">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Database_Commands;
