import ChapterQuiz from './ChapterQuiz';
import ScrollToTop from '../../../../../ScrolltopPage';
import ExamQuiz from './ExamQuiz';
import { QUIZ_CONCEPTS } from './Concept_Data'; // Importing the quiz concepts array
import './QuizConcept.css'; // Importing the CSS for styling
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // useNavigate for navigation
import NavigationBar from '../../CCNA2/NavigationBar';
import { useTranslation } from 'react-i18next';

const QuizApp = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const linksArray = ["Home", "Courses", "Calculator"];

  // List of chapters for the navbar
  const chapters = [
    { name: t('Chapter 1'), path: '/ccna/CCNA1/chapter-1', key: 'chapter1' },
    { name: t('Chapter 2'), path: '/ccna/CCNA1/chapter-2', key: 'chapter2' },
    { name: t('Chapter 3'), path: '/ccna/ccna1/chapter-3', key: 'chapter3' },
    { name: t('Chapter 4'), path: '/ccna/ccna1/chapter-4', key: 'chapter4' },
    { name: t('Chapter 5'), path: '/ccna/ccna1/chapter-5', key: 'chapter5' },
    { name: t('Chapter 6'), path: '/ccna/ccna1/chapter-6', key: 'chapter6' },
    { name: t('Chapter 6 Skill PT'), path: '/ccna/ccna1/chapter-6/ch6_skills_PT', key: 'chapter6SkillPT' },
    { name: t('Chapter 7'), path: '/ccna/ccna1/chapter-7', key: 'chapter7' },
    { name: t('Chapter 8'), path: '/ccna/ccna1/chapter-8', key: 'chapter8' },
    { name: t('Chapter 9'), path: '/ccna/ccna1/chapter-9', key: 'chapter9' },
    { name: t('Chapter 10'), path: '/ccna/ccna1/chapter-10', key: 'chapter10' },
    { name: t('Chapter 11'), path: '/ccna/ccna1/chapter-11', key: 'chapter11' },
    { name: t('ITN PSA–PT'), path: '/ccna/ccna1/Practice-PTA/ITN_PSA_PT', key: 'itnpsapt' },
    { name: t('Quiz'), path: '/ccna/ccna1/ccna1_quiz/QuizApp', key: 'quizapp' },
  ];

  const currentChapter = chapters.find((chapter) => chapter.path === location.pathname);

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleChapterChange = (event) => {
    const selectedChapter = event.target.value;
    navigate(selectedChapter);
  };

  const handleNextChapter = () => {
    const currentIndex = chapters.findIndex((chapter) => chapter.key === currentChapter?.key);
    if (currentIndex < chapters.length - 1 && currentIndex >= 0) {
      navigate(chapters[currentIndex + 1].path);
    }
  };

  const handlePreviousChapter = () => {
    const currentIndex = chapters.findIndex((chapter) => chapter.key === currentChapter?.key);
    if (currentIndex > 0) {
      navigate(chapters[currentIndex - 1].path);
    }
  };

  // Ensure the route passed to navigate is an absolute path
  const handleModeSelection = (mode) => {
    if (!mode.startsWith('/')) {
      mode = '/' + mode;
    }
    navigate(mode); // Navigate to the selected quiz mode route
  };

  return (
    <div>
      <ScrollToTop />
      <NavigationBar links={linksArray}  />
      <div className="quiz-app"><h1>CCNA1 Quiz</h1></div>
      <div className="mode-selection">
        {QUIZ_CONCEPTS.map((concept, index) => (
          <div
            key={index}
            className="quiz-concept-card"
            onClick={() => handleModeSelection(concept.route)} // Navigate on click
          >
            <img src={concept.image} alt={concept.title} className="card-image" />
            <h2 className="card-title">{concept.title}</h2>
            <p className="card-description">{concept.description}</p>
          </div>
        ))}
        
      </div>
              {/* Add margin to the Utilities component */}
              <div style={{ marginTop: '60px' , marginBottom: '60px'}}></div>

    </div>
  );
};

export default QuizApp;
