import React from 'react';
import { useState, useRef} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TabButtons from '../../../../TabButtons';
import { Skills_TabButtonsInfo } from '../skills_PT';
import WatermarkedImagePT from '../../../../WatermarkedImagePT';
// import CCNA1Navbar from '../CCNA1_Navbar';
import NavigationBar from '../../CCNA2/NavigationBar';
import '../../CCNA1/FooterNav.css';
import StudyRobot from '../../../../StudyRobot';
import './ch6_skills_PT.css';
import ChapterNavigation from '../ChapterNav';
import CopyComponent from '../../../../copy';

const linksArray = ["Home", "Courses", "Calculator"];

function Ch6_skills_PT() {
    const {t} = useTranslation();
    const [selectedTopic, setSelectedTopic] = useState('TypeA');
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const location = useLocation();

    const routerRef = useRef(null);
    const switch1Ref = useRef(null);
    const switch2Ref = useRef(null);
    const switch3Ref = useRef(null);


    const chapters = [
        { name: t('Chapter 1'), path: '/ccna/CCNA1/chapter-1', key: 'chapter1' }, // i18n change
        { name: t('Chapter 2'), path: '/ccna/CCNA1/chapter-2', key: 'chapter2' }, // i18n change
        { name: t('Chapter 3'), path: '/ccna/ccna1/chapter-3', key: 'chapter3' }, // i18n change
        { name: t('Chapter 4'), path: '/ccna/ccna1/chapter-4', key: 'chapter4' }, // i18n change
        { name: t('Chapter 5'), path: '/ccna/ccna1/chapter-5', key: 'chapter5' }, // i18n change
        { name: t('Chapter 6'), path: '/ccna/ccna1/chapter-6', key: 'chapter6' }, // i18n change
        { name: t('Chapter 6 Skill PT'), path: '/ccna/ccna1/chapter-6/ch6_skills_PT' , key: 'chapter6SkillPT'}, // i18n change
        { name: t('Chapter 7'), path: '/ccna/ccna1/chapter-7', key: 'chapter7' }, // i18n change
        { name: t('Chapter 8'), path: '/ccna/ccna1/chapter-8', key: 'chapter8' }, // i18n change
        { name: t('Chapter 9'), path: '/ccna/ccna1/chapter-9', key: 'chapter9' }, // i18n change
        { name: t('Chapter 10'), path: '/ccna/ccna1/chapter-10', key: 'chapter10' }, // i18n change
        { name: t('Chapter 11'), path: '/ccna/ccna1/chapter-11', key: 'chapter11' }, // i18n change
        { name: t('ITN PSA–PT'), path: '/ccna/ccna1/Practice-PTA/ITN_PSA_PT', key: 'itnpsapt' }, // i18n change
        { name: t('Quiz'), path: '/ccna/ccna1/ccna1_quiz/QuizApp', key: 'quizapp' }, // i18n change
    ];

    const currentChapter = chapters.find(
        chapter => chapter.path === location.pathname
    );

    const handleSearch = (term) => {
        setSearchTerm(term);
    };

    const handleChapterChange = (event) => {
        const selectedChapter = event.target.value;
        navigate(selectedChapter);
    };

    const handleNextChapter = () => {
        const currentIndex = chapters.findIndex(chapter => chapter.key === currentChapter?.key);
        if (currentIndex < chapters.length - 1 && currentIndex >= 0) {
            navigate(chapters[currentIndex + 1].path);
        }
    };

    const handlePreviousChapter = () => {
        const currentIndex = chapters.findIndex(chapter => chapter.key === currentChapter?.key);
        if (currentIndex > 0) {
            navigate(chapters[currentIndex - 1].path);
        }
    };

    function selectHandler(selectedButton) {
        setSelectedTopic(selectedButton);
    }

    const tableStyle = {
        // width: '',
        borderCollapse: 'collapse',
    };

    const thStyle = {
        border: '1px solid white',
        padding: '8px',
        backgroundColor: 'purple',
    };

    const tdStyle = {
        border: '1px solid White',
        padding: '8px',
        textAlign: 'center'
    };
    const mergedRowSpan = 2; // Number of rows to span for College device

 // Helper function to ensure the objectives/requirments are arrays
 const getArrayOrString = (key) => {
    const value = t(key, { returnObjects: true });
    return Array.isArray(value) ? value : [value];  // Return as an array if it's not already
};
    return (
        <div>
            {/* <CCNA1Navbar chapters={chapters} onSearch={handleSearch} /> */}
            <NavigationBar 
            links={linksArray} 
            chapters={chapters} 
            onSearch={handleSearch} 
            currentChapter={currentChapter}
            searchTerm={searchTerm} 
            clearSearch={setSearchTerm} 
        />
            <main>
                <section id='tab-sections'>
                    <h2>{t('Chapter 6 Skills PT')}</h2>
                    <menu>
                    <TabButtons isSelected={selectedTopic === 'TypeA'} onSelect={() => selectHandler('TypeA')}>{t('Type A')}</TabButtons> {/* i18n change */}
                    <TabButtons isSelected={selectedTopic === 'TypeB'} onSelect={() => selectHandler('TypeB')}>{t('Type B')}</TabButtons> {/* i18n change */}
                    <TabButtons isSelected={selectedTopic === 'TypeC'} onSelect={() => selectHandler('TypeC')}>{t('Type C')}</TabButtons> {/* i18n change */}
                    </menu>
                    <h4>{t(`Skills_TabButtonsInfo.${selectedTopic}.description`)}</h4> {/* i18n change */}
                    <div id='tab-content'>  
                    {Skills_TabButtonsInfo[selectedTopic].image.map((image, index) => (
                            <WatermarkedImagePT
                                key={index}
                                src={image}
                                watermarkText="AC Studies"
                                style={{
                                    width: 'auto',
                                    height: '500px',
                                    animation: 'fadeIn 3s ease-in-out',
                                    marginBottom: '20px', // Add space between images
                                }}
                            />
                        ))}
                        <h4>Address {t('Table')}</h4>
                        <table style={tableStyle}>
                            <thead>
                                <tr>
                                <th style={thStyle}>{t('Device')}</th> {/* i18n change */}
                                    <th style={thStyle}>Interface</th>
                                    <th style={thStyle}>Address</th>
                                    <th style={thStyle}>Subnet Mask</th>
                                    <th style={thStyle}>Default Gateway</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Skills_TabButtonsInfo[selectedTopic].tableData.map((row, index) => {
                                    // Render the first "College" row with a rowspan of 2
                                    if (row.Device === 'College' ,'Housing','Science' && index === 0) {
                                        return (
                                            <tr key={index}>
                                                         <td style={tdStyle} rowSpan={mergedRowSpan}>{t(row.Device)}</td> {/* i18n change */}
                                                         <td style={tdStyle}>{t(row.Interface)}</td> {/* i18n change */}
                                                <td style={tdStyle}>{row.Address}</td>
                                                <td style={tdStyle}>{row.SubnetMask}</td>
                                                <td style={tdStyle}>{row.DefaultGateway}</td>
                                            </tr>
                                        );
                                    }

                                    // Skip the second "College" row (index 1) since it is merged
                                    if (row.Device === 'College','Housing','Science' && index === 1) {
                                        return (
                                            <tr key={index}>
                                                <td style={tdStyle}>{row.Interface}</td>
                                                <td style={tdStyle}>{row.Address}</td>
                                                <td style={tdStyle}>{row.SubnetMask}</td>
                                                <td style={tdStyle}>{row.DefaultGateway}</td>
                                            </tr>
                                        );
                                    }

                                    // Render all other rows normally
                                    return (
                                        <tr key={index}>
                                            <td style={tdStyle}>{row.Device}</td>
                                            <td style={tdStyle}>{row.Interface}</td>
                                            <td style={tdStyle}>{row.Address}</td>
                                            <td style={tdStyle}>{row.SubnetMask}</td>
                                            <td style={tdStyle}>{row.DefaultGateway}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </section>
                <section id='tab-sections'>
                <h3>{t('Objectives')}</h3>
                    <div id='tab-content'>
                    <ul style={{ listStyleType: 'number', paddingLeft: '20px' }}>
                    {getArrayOrString(`Skills_TabButtonsInfo.${selectedTopic}.objectives`).map((objectives, index) => (
                    <li key={index}>{objectives}</li>
                            ))}
                        </ul>
                    </div>
                </section>
                <section id='tab-sections'>
                <h3>{t('Scenario')}</h3>
                    <div id='tab-content'>
                    <p>{t(`Skills_TabButtonsInfo.${selectedTopic}.scenario`)}</p>
                            
                    </div>
                </section>
                
                <section id='tab-sections'>
                    <h3>{t('Requirements')}</h3>
                    <div id='tab-content'>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                    {getArrayOrString(`Skills_TabButtonsInfo.${selectedTopic}.requirments`).map((requirements, index) => (
                    <li key={index}>{requirements}</li>
                            ))}
                        </ul>
                        <p style={{fontWeight:'bolder'}}>{t(`Skills_TabButtonsInfo.${selectedTopic}.note`)}</p>     
                    </div>
                </section>

                <section id='tab-sections' style={{ position: 'relative' }} >
                    <h3>{t(`Skills_TabButtonsInfo.${selectedTopic}.routerConfig`)}</h3>
                    <div id='tab-content'style={{ position: 'relative', padding: '20px' }}>
                        <pre>
                        <code>
                    <ul style={{ listStyleType: 'none', paddingLeft: '20px', fontFamily:' Roboto Condensed'}} ref={routerRef}>
                            {Skills_TabButtonsInfo[selectedTopic].router.map((router, index) => (
                                <li key={index}>{router}</li>
                            ))}
                            <div style={{ position: 'absolute', top: '-160px', right: '0' }}>
                            <CopyComponent ref={routerRef} />
                            </div>
                        </ul>
                        </code>
                        </pre>
                    </div>
                </section>

                <section id='tab-sections' style={{position: 'relative'}}>
                <h3>{t(`Skills_TabButtonsInfo.${selectedTopic}.switch1Config`)}</h3>
                    <div id='tab-content'style={{ position: 'relative', padding: '20px' }}>
                        <pre>
                        <code>
                    <ul style={{ listStyleType: 'none', paddingLeft: '20px', fontFamily:'Roboto Condensed'}} ref={switch1Ref}>
                            {Skills_TabButtonsInfo[selectedTopic].switch1.map((switch1, index) => (
                                <li key={index}>{switch1}</li>
                            ))}
                            <div style={{ position: 'absolute', top: '-160px', right: '0' }}>
                            <CopyComponent ref={switch1Ref} />
                            </div>
                        </ul>
                        </code>
                        </pre>
                    </div>
                </section>

                <section id='tab-sections'>
                <h3>{t(`Skills_TabButtonsInfo.${selectedTopic}.switch2Config`)}</h3>
                    <div id='tab-content'style={{ position: 'relative', padding: '20px' }}>
                        <pre>
                        <code>
                    <ul style={{ listStyleType: 'none', paddingLeft: '20px', fontFamily:'Roboto Condensed'}} ref={switch3Ref}>
                            {Skills_TabButtonsInfo[selectedTopic].switch2.map((switch2, index) => (
                                <li key={index}>{switch2}</li>
                            ))}
                            <div style={{ position: 'absolute', top: '-160px', right: '0' }}>
                            <CopyComponent ref={switch3Ref} />
                            </div>
                        </ul>
                        </code>
                        </pre>
                    </div>
                </section>


                <section id='tab-sections'>
                <h3>{Skills_TabButtonsInfo[selectedTopic].endDevice3}</h3>
                    <div id='tab-content'>
                        <pre>
                        <code>
                    <ul style={{ listStyleType: 'none', paddingLeft: '20px', fontFamily:'Roboto Condensed'}}>
                            {Skills_TabButtonsInfo[selectedTopic].device3.map((device3, index) => (
                                <li key={index}>{device3}</li>
                            ))}
                        </ul>
                        </code>
                        </pre>
                    </div>
                </section>

                <section id='tab-sections'>
                <h3>{Skills_TabButtonsInfo[selectedTopic].endDevice4}</h3>
                    <div id='tab-content'>
                        <pre>
                        <code>
                    <ul style={{ listStyleType: 'none', paddingLeft: '20px', fontFamily:'Roboto Condensed'}}>
                            {Skills_TabButtonsInfo[selectedTopic].device4.map((device4, index) => (
                                <li key={index}>{device4}</li>
                            ))}
                        </ul>
                        </code>
                        </pre>
                    </div>
                </section>

                <section id='tab-sections'>
                <h3>{Skills_TabButtonsInfo[selectedTopic].endDevice1}</h3>
                    <div id='tab-content'>
                        <pre>
                        <code>
                    <ul style={{ listStyleType: 'none', paddingLeft: '20px', fontFamily:'Roboto Condensed'}}>
                            {Skills_TabButtonsInfo[selectedTopic].device1.map((device1, index) => (
                                <li key={index}>{device1}</li>
                            ))}
                        </ul>
                        </code>
                        </pre>
                    </div>
                </section>

                <section id='tab-sections'>
                <h3>{Skills_TabButtonsInfo[selectedTopic].endDevice2}</h3>
                    <div id='tab-content'>
                        <pre>
                        <code>
                    <ul style={{ listStyleType: 'none', paddingLeft: '20px', fontFamily:'Roboto Condensed'}}>
                            {Skills_TabButtonsInfo[selectedTopic].device2.map((device2, index) => (
                                <li key={index}>{device2}</li>
                            ))}
                        </ul>
                        </code>
                        </pre>
                    </div>
                </section>
            </main>
            <ChapterNavigation 
      
      chapters={chapters}
      currentChapter={currentChapter}
      handleChapterChange={handleChapterChange}
      handleNextChapter={handleNextChapter}
      handlePreviousChapter={handlePreviousChapter}
      />
    <StudyRobot />
        </div>
    );
}
export default Ch6_skills_PT;
