import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Card, Typography } from '@mui/material';
import NavigationBar from './CCNA2/NavigationBar';
import Utilities from '../../utilities/utilities';
import CodeComandConcepts from '../../CodeCommandConcepts';
import BookImg from '../../../assets/book.png'

const linksArray = ["Home","Courses", "Calculator"];

const Ccna = () => {
  const navigate = useNavigate();

  const handleChapterClick = (chapter) => {
    switch (chapter) {
      case 'CCNA1':
        navigate('/ccna/CCNA1/chapter-1');
        break;
      case 'CCNA2':
        navigate('/ccna/CCNA2/chapter-1');
        break;
      case 'CCNA3':
        navigate('/ccna3');
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <NavigationBar links={linksArray}  />

      <Container sx={{ backgroundColor: 'rgba(17, 14, 14, 0.6)', padding: '16px', borderRadius: '8px' }}>
        <Typography variant="h4" color="#1976d2" gutterBottom>
          Welcome to CCNA
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={6} md={4}>
            <Card
              variant="outlined"
              sx={{ borderColor: '#1976d2', backgroundColor: 'rgba(17, 14, 14, 0.6)', '&:hover': { opacity: 0.8 } }}
              onClick={() => handleChapterClick('CCNA1')}
              style={{ cursor: 'pointer', padding: '16px' }}
            >
              <Typography level="title-md" sx={{ color: '#1976d2', fontSize: '2rem', textAlign: 'center' }}><img src={BookImg} alt="CCNA 1" /> CCNA 1</Typography>
              <Typography level="body-sm" sx={{ color: '#1976d2', textAlign: 'center', marginTop: '8px' }}>
                Introduction to Networking Concepts and Protocols.
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              variant="outlined"
              sx={{ borderColor: '#1976d2', backgroundColor: 'rgba(17, 14, 14, 0.6)', '&:hover': { opacity: 0.8 } }}
              onClick={() => handleChapterClick('CCNA2')}
              style={{ cursor: 'pointer', padding: '16px' }}
            >
              <Typography level="title-md" sx={{ color: '#1976d2', fontSize: '2rem', textAlign: 'center' }}><img src={BookImg} alt="CCNA 2" /> CCNA 2</Typography>
              <Typography level="body-sm" sx={{ color: '#1976d2', textAlign: 'center', marginTop: '8px' }}>
                Deep Dive into Network Access and Configuration.
              </Typography>
            </Card>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4}>
            <Card
              variant="outlined"
              sx={{ borderColor: '#1976d2', backgroundColor: 'rgba(17, 14, 14, 0.6)', '&:hover': { opacity: 0.8 } }}
              onClick={() => handleChapterClick('CCNA3')}
              style={{ cursor: 'pointer', padding: '16px' }}
            >
              <Typography level="title-md" sx={{ color: '#1976d2', fontSize: '2rem', textAlign: 'center' }}><img src={BookImg} alt="CCNA 3" /> CCNA 3</Typography>
              <Typography level="body-sm" sx={{ color: '#1976d2', textAlign: 'center', marginTop: '8px' }}>
                Advanced Networking Concepts and Implementation.
              </Typography>
            </Card>
          </Grid> */}
        </Grid>
      </Container>
<CodeComandConcepts/>
      {/* Add margin to the Utilities component */}
      <div style={{ marginTop: '20px' , marginBottom: '20px'}}>
        <Utilities />
      </div>
    </div>
  );
};

export default Ccna;
