import studyImg from '../../assets/react-core-concepts.png';
import LogoImg from '../../assets/Alpha logo1.png';
//import LogoImg from '../../assets/ipv4.png';
import "./Header.css"
const reactDescriptions = ['Fundamental', 'Crucial', 'Core'];

function genRandomInt(max) {
  return Math.floor(Math.random() * (max + 1));
}

export default function Header() {

    const ranword = reactDescriptions[genRandomInt(2)];
    return (
      <header>
        <img src={LogoImg} alt="Alpha Centauri" />
        {/* <h1>Study Hub</h1> */}
        <h1>Alpha Centauri Study</h1>
        <p>
          {ranword} Learning Platform Essential for Success in Any Field of Study!
        </p>
      </header>
    );
  }