import React from "react";
import "./Quiz.css";
import MatchingQuestion from "../../../../MatchingComponent";

const Question = ({ question, onSelectAnswer, selectedAnswers, questionNumber }) => {
    const handleAnswerClick = (index) => {
        onSelectAnswer(question.id, index);
    };
    if (question.type === "multiple-choice") {
        return (
            <div className="question-card">
                <div className="question-header">
                <h4 className="question-number">Question {questionNumber}: {question.question}</h4>
                <span className="question-points">{`/${question.points}pt`}</span>
                </div>
                {/* <h4 className="question-text">{question.question}</h4> */}
                {question.image && <img src={question.image} alt="Question Image" className="question-image" />}
                
                <div className="answers">
    <ol>
        {question.answers.map((answer, index) => (
            <li
                key={index}
                className={`answer-option ${selectedAnswers.includes(index) ? "selected" : ""}`}
                onClick={() => handleAnswerClick(index)}
            >
                {answer.text}
            </li>
        ))}
    </ol>
</div>

                {/* {question.answers.map((answer, index) => (
                    <div key={index} className="answer">
                        <input
                            type="checkbox"
                            id={`q${question.id}-a${index}`}
                            checked={selectedAnswers.includes(index)}
                            onChange={() => onSelectAnswer(question.id, index)}
                        />
                        <label htmlFor={`q${question.id}-a${index}`}>{answer.text}</label>
                    </div>
                ))} */}
            </div>
        );
    } else if (question.type === "matching") {
        return (
            <div className="question-card">
                <div className="question-header">
                <h3 className="question-number">Question {questionNumber}: {question.question}</h3>
                <span className="question-points">{`/${question.points}pt`}</span>
                </div>
                {/* <h4 className="question-text">{question.question}</h4> */}
            {question.image && <img src={question.image} alt="Question Image" className="question-image" />}

                {/* {question.items.map((item) => (
                    <div key={item.id} className="matching-item">
                        <span>{item.content}</span>
                        <select
                            value={selectedAnswers[item.id] || ""}
                            onChange={(e) => onSelectAnswer(question.id, { [item.id]: e.target.value })}
                        >
                            <option value="">Select an option</option>
                            {question.options.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.content}
                                </option>
                            ))}
                        </select>
                    </div>
                ))} */}
                <MatchingQuestion
          question={question}
          onSelectAnswer={onSelectAnswer}
          selectedAnswers={selectedAnswers}
        />
            </div>
        );
    }
    return null;
};

export default Question;
