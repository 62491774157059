
import React from 'react';
import './CodeCommandConcept.css';
<meta name='viewport'content='width=device-width, initial-scale=1.0, maximum-scale=2.0'></meta>

export default function CodeCommandConcept(props){
    return( 
    <div className='card-content'>
      <img src={props.image} alt= {props.title} className="card-image"/>
      <h3 className='card-title'>{props.title}</h3>
      <p className='card-description'>{props.description}</p>
    </div>
    );
  }
  