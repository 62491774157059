import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from '../ScrolltopPage';
import Ccna from './courses/ccna/ccna'; // Import the page component
import Database from './courses/database/database';
import DatabaseCommands from './courses/database/database_commands';
import IT from './courses/info-tech/infotech';
import Maths from './courses/maths/math';
import Securityplus from './courses/security+/securityplus';
import CcnaCommand from './courses/ccna/ccna_command';
//import CCNA1Exam from './courses/ccna/CCNA1/ccna1_quiz/ExamMode';
import SecurityplusCommands from './courses/security+/securityplus_commands';
import App from '../App';
import Sitemap from './SiteMap';
import Footer from './Footer/Footer2';
import CCNAChapter1 from './courses/ccna/CCNA1/CcnaQuestionCard';
import CCNAChapter2 from './courses/ccna/CCNA1/CcnaQuestionCard';
import CCNAChapter3 from './courses/ccna/CCNA1/CcnaQuestionCard';
import CCNAChapter4 from './courses/ccna/CCNA1/CcnaQuestionCard';
import CCNAChapter5 from './courses/ccna/CCNA1/CcnaQuestionCard';
import CCNAChapter6 from './courses/ccna/CCNA1/CcnaQuestionCard';
import CCNAChapter6SPT from './courses/ccna/CCNA1/chapter-6/ch6_skills_PT';
import CCNAChapter7 from './courses/ccna/CCNA1/CcnaQuestionCard';
import CCNAChapter8 from './courses/ccna/CCNA1/CcnaQuestionCard';
import CCNAChapter9 from './courses/ccna/CCNA1/CcnaQuestionCard';
import CCNAChapter10 from './courses/ccna/CCNA1/CcnaQuestionCard';
import CCNAChapter11 from './courses/ccna/CCNA1/CcnaQuestionCard';
import CCNAITNPSA from './courses/ccna/CCNA1/Practice-PTA/ITN_PSA_PT';
import PracticeExam from './courses/ccna/CCNA1/CcnaQuestionCard';
import Exam from './courses/ccna/CCNA1/CcnaQuestionCard';
import CCNA1Quiz from './courses/ccna/CCNA1/ccna1_quiz/QuizApp';
import CCNA1ChapterQuiz from './courses/ccna/CCNA1/ccna1_quiz/ChapterQuiz';
import CCNA1ExamQuiz from './courses/ccna/CCNA1/ccna1_quiz/ExamQuiz';

import CCNA2Chapter1 from './courses/ccna/CCNA2/CcnaQuestionCard';
import CCNA2Chapter2 from './courses/ccna/CCNA2/CcnaQuestionCard';
import SIC_PSA_PT from './courses/ccna/CCNA2/Chapter2_SIC_Practice_Skills_Assessment/SIC_PSA_PT';
import CCNA2Chapter3 from './courses/ccna/CCNA2/CcnaQuestionCard';
import CCNA2Chapter4 from './courses/ccna/CCNA2/CcnaQuestionCard';
import CCNA2Chapter5 from './courses/ccna/CCNA2/CcnaQuestionCard';
import CCNA2Chapter6 from './courses/ccna/CCNA2/CcnaQuestionCard';
import CCNA2Chapter7 from './courses/ccna/CCNA2/CcnaQuestionCard';
// import CH7_SIC_PSA_PT from './courses/ccna/CCNA2/Chapter7_SIC_Practice_Skills_Assessment/SIC_PSA_PT';
import CH7_SIC_PSA_PT from './courses/ccna/CCNA2/Chapter7_SIC_Practice_Skills_Assessment/SIC_PSA_PT'
import CCNA2Chapter8 from './courses/ccna/CCNA2/CcnaQuestionCard';
import CCNA2Chapter9 from './courses/ccna/CCNA2/CcnaQuestionCard';
import CCNA2Chapter10 from './courses/ccna/CCNA2/CcnaQuestionCard';
import CCNA2Chapter10Exam from './courses/ccna/CCNA2/CcnaQuestionCard';
import CCNA2Exam from './courses/ccna/CCNA2/CcnaQuestionCard';
import CCNA2Exam2024 from './courses/ccna/CCNA2/CcnaQuestionCard';
import CCNA2Quiz from './courses/ccna/CCNA2/ccna2_quiz/QuizApp';
import CCNA2ChapterQuiz from './courses/ccna/CCNA2/ccna2_quiz/ChapterQuiz';
import CCNA2ExamQuiz from './courses/ccna/CCNA2/ccna2_quiz/ExamQuiz';









import Calculators from './courses/ccna/Calculator/calculators';
import IP_Calculator from './courses/ccna/Calculator/IP_Calculator';
import IPv6_Calculator from './courses/ccna/Calculator/ipv6_calculator';
import BinaryConverter from './courses/ccna/Calculator/binary_converter';
import OctalConverter from './courses/ccna/Calculator/octal_converter';
import HexadecimalConverter from './courses/ccna/Calculator/hexadecimal_converter';
import DecimalConverter from './courses/ccna/Calculator/decimal_converter';
import PopularCourses from './Course_Card/PopularCourses';
//import { PageViewProvider } from './Course_Card/PageViewContext';
import TrackingComponent from './Course_Card/TrackingComponent';
import TotalVisits from './Course_Card/TotalVisits';
import Settings from './Settings/Setting';
import ThemeProvider from './Settings/Theme';
import Language from './Settings/Language';
import './Settings/i18n';
import BackToTop from './Settings/Back_To_Top';
import Top from './TopPage';
import NewFooter from './Footer/NewFooter';
import PageNotFound from './PageNotFound';
import AddCourse from './addcourse';
import UpdateNotification from './UpdateNotification/UpdateNotification';




function Routepath() {
    return (
      <Router>
         {/* <PageViewProvider> */}
          {/* <TrackingComponent />  */}
          <ThemeProvider>
          {/* <Settings /> */}
          {/* <Language /> */}
          {/* <BackToTop /> */}
          
        <ScrollToTop />
        <Top/>
        <Routes>
        <Route path="/" element={<App />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/courses/PopularCourses" element={<PopularCourses />} />
          <Route path="/ccna/ccna" element={<Ccna />} />
          <Route path='/ccna/ccna_command' element={<CcnaCommand />} />
          <Route path="/database" element={<Database />} />
          <Route path="/database/database_commands" element={<DatabaseCommands />} />
          <Route path="/infotech" element={<IT />} />
          <Route path="/math" element={<Maths />} />
          <Route path="/security+/securityplus_commands" element={<Securityplus />} />
          <Route path="/security+/securityplus_commands" element={<SecurityplusCommands />} />
          <Route path="/ccna/CCNA1/chapter-1" element={<CCNAChapter1 />} />  
          <Route path="/ccna/CCNA1/chapter-2" element={<CCNAChapter2 />} />
          <Route path="/ccna/CCNA1/chapter-3" element={<CCNAChapter3 />} />
          <Route path="/ccna/CCNA1/chapter-4" element={<CCNAChapter4 />} />
          <Route path="/ccna/CCNA1/chapter-5" element={<CCNAChapter5 />} /> 
          <Route path="/ccna/CCNA1/chapter-6" element={<CCNAChapter6 />} /> 
          <Route path="/ccna/CCNA1/chapter-6/ch6_skills_PT" element={<CCNAChapter6SPT />} /> 
          <Route path="/ccna/CCNA1/chapter-7" element={<CCNAChapter7 />} /> 
          <Route path="/ccna/CCNA1/chapter-8" element={<CCNAChapter8 />} /> 
          <Route path="/ccna/CCNA1/chapter-9" element={<CCNAChapter9 />} /> 
          <Route path="/ccna/CCNA1/chapter-10" element={<CCNAChapter10 />} /> 
          <Route path="/ccna/CCNA1/chapter-11" element={<CCNAChapter11 />} /> 
          <Route path="/ccna/CCNA1/Practice-PTA/ITN_PSA_PT" element={<CCNAITNPSA />} />
          <Route path="/ccna/CCNA1/practice_exam" element={<PracticeExam />} />
          <Route path="/ccna/CCNA1/exam" element={<Exam />} />
          <Route path="/ccna/CCNA1/ccna1_quiz/QuizApp" element={<CCNA1Quiz />} />
           <Route path="/ccna/CCNA1/ccna1_quiz/ChapterQuiz" element={<CCNA1ChapterQuiz />} />
          <Route path="/ccna/CCNA1/ccna1_quiz/ExamQuiz" element={<CCNA1ExamQuiz />} /> 

          <Route path="/ccna/CCNA2/chapter-1" element={<CCNA2Chapter1 />} />  
          <Route path="/ccna/CCNA2/chapter-2" element={<CCNA2Chapter2 />} />  
          <Route path="ccna/CCNA2/Chapter2_SIC_PracticeSkills_Assessment/SIC_PSA_PT" element={<SIC_PSA_PT />} />  
          <Route path="/ccna/CCNA2/chapter-3" element={<CCNA2Chapter3 />} />  
          <Route path="/ccna/CCNA2/chapter-4" element={<CCNA2Chapter4 />} />  
          <Route path="/ccna/CCNA2/chapter-5" element={<CCNA2Chapter5 />} />  
          <Route path="/ccna/CCNA2/chapter-6" element={<CCNA2Chapter6 />} />  
          <Route path="/ccna/CCNA2/chapter-7" element={<CCNA2Chapter7 />} />
          <Route path="ccna/CCNA2/Chapter7_SIC_PracticeSkills_Assessment/SIC_PSA_PT" element={<CH7_SIC_PSA_PT />} />  
          <Route path="/ccna/CCNA2/chapter-8" element={<CCNA2Chapter8 />} />
          <Route path="/ccna/CCNA2/chapter-9" element={<CCNA2Chapter9 />} />
          <Route path="/ccna/CCNA2/chapter-10" element={<CCNA2Chapter10 />} />
          <Route path="/ccna/CCNA2/chapter-10-Exam" element={<CCNA2Chapter10Exam />} />
          <Route path="/ccna/CCNA2/Exam" element={<CCNA2Exam />} />
          <Route path="/ccna/CCNA2/Exam-Version-7" element={<CCNA2Exam2024 />} />
          <Route path="/ccna/CCNA2/ccna2_quiz/QuizApp" element={<CCNA2Quiz />} />
          <Route path="/ccna/CCNA2/ccna2_quiz/ChapterQuiz" element={<CCNA2ChapterQuiz />} />
          <Route path="/ccna/CCNA2/ccna2_quiz/ExamQuiz" element={<CCNA2ExamQuiz />} /> 



          <Route path="/ccna/Calculator/calculators" element={<Calculators />} />
          <Route path="/ccna/Calculator/IP_Calculator" element={<IP_Calculator />} />
          <Route path="/ccna/Calculator/ipv6_calculator" element={<IPv6_Calculator />} />
          <Route path="/ccna/Calculator/binary_converter" element={<BinaryConverter />} />
          <Route path="/ccna/Calculator/octal_converter" element={<OctalConverter />} />
          <Route path="/ccna/Calculator/hexadecimal_converter" element={<HexadecimalConverter />} />
          <Route path="/ccna/Calculator/decimal_converter" element={<DecimalConverter />} />
          {/* <Route path="/admin_add_course" element={<AdminAddCourse/>}/> */}
          {/* <Route path="/admin_add_course" components={AdminAddCourse} /> */}
          {/* <Route path="/components/sitemap" component={Sitemap} /> */}
          <Route path='/SiteMap' element={<Sitemap />}/>
          {/* <Route path="/src/translate_smart" element={<Translate_Smart/>}/> */}


           <Route path="/addcourse" component={AddCourse} />

          {/* Add other routes here */}
        </Routes>
        {/* <TotalVisits />
        </PageViewProvider> */}
        <NewFooter></NewFooter>
        <UpdateNotification/>
        </ThemeProvider>
        {/* </PageViewProvider> */}
      </Router>
    );
  }
  
  export default Routepath;