// ccnaData.jsx

export const CCNA_COMMAND_DATA = [
    {
        section: "Interface Commands",
        commands: [
          {
            command: 'show ip interface brief',
      description: 'Displays a brief summary of IP interfaces.',
      example: 'Router# show ip interface brief'
    },
    {
      command: 'show ip route',
      description: 'Shows the routing table.',
      example: 'Router# show ip route'
    },
    {
      command: 'show vlan brief',
      description: 'Lists existing VLANs, their names, and the ports assigned to them.',
      example: 'Switch# show vlan brief'
    },
    {
      command: 'show controllers serial x/x/x',
      description: 'Indicates whether a DCE or DTE is connected and whether a clock rate is present.',
      example: 'Router# show controllers serial 0/0/0'
    },
    {
      command: 'show interface trunk',
      description: 'Shows which ports are trunking, the native VLAN, and the allowed VLANs.',
      example: 'Switch# show interface trunk'
    },
    {
      command: 'show running-config',
      description: 'Displays the current active configuration.',
      example: 'Router# show running-config'
    },
    {
      command: 'show startup-config',
      description: 'Shows the configuration that will be loaded at startup.',
      example: 'Router# show startup-config'
    },
    {
      command: 'show ip protocol',
      description: 'Provides details about routing protocols, including the networks they cover, passive interfaces, and neighbors.',
      example: 'Router# show ip protocol'
    },
    {
      command: 'show cdp neighbors',
      description: 'Lists directly connected Cisco devices.',
      example: 'Router# show cdp neighbors'
    },
    {
      command: 'show cdp neighbors detail',
      description: 'Includes the IP addresses of directly connected Cisco devices.',
      example: 'Router# show cdp neighbors detail'
    },
    {
      command: 'show cdp interface',
      description: 'Shows which interfaces are running CDP (Cisco Discovery Protocol).',
      example: 'Router# show cdp interface'
    },
    {
      command: 'show interface serial x/x/x',
      description: 'Displays encapsulation type, IP address, and counters for a serial interface.',
      example: 'Router# show interface serial 0/0/0'
    },
    {
      command: 'show interface fastethernet x/x switchport',
      description: 'Shows the configured and operating modes of a FastEthernet switchport.',
      example: 'Switch# show interface fastethernet 0/1 switchport'
    },
    {
      command: 'show version',
      description: 'Displays the IOS version, capabilities, memory, and configuration register information.',
      example: 'Router# show version'
    },
    {
      command: 'show run | begin interface',
      description: 'Lists configurations starting from the first occurrence of “interface.”',
      example: 'Router# show run | begin interface'
    },
    {
      command: 'show ip route connected',
      description: 'Shows routing table entries for directly connected networks.',
      example: 'Router# show ip route connected'
    },
    {
      command: 'show ip route static',
      description: 'Shows routing table entries for static routes.',
      example: 'Router# show ip route static'
    },
    {
      command: 'show ip route ospf',
      description: 'Displays routing table entries learned through OSPF.',
      example: 'Router# show ip route ospf'
    },
    {
      command: 'show ip route eigrp',
      description: 'Displays routing table entries learned through EIGRP.',
      example: 'Router# show ip route eigrp'
    },
    {
      command: 'show mac-address-table',
      description: 'Displays the MAC address table (command may vary with different IOS versions).',
      example: 'Switch# show mac-address-table'
    },
    {
      command: 'show flash',
      description: 'Displays filenames and directories in Flash memory.',
      example: 'Router# show flash'
    },
    {
      command: 'show clock',
      description: 'Shows the current date and time on the device.',
      example: 'Router# show clock'
    },
    {
      command: 'show ipv6 ???',
      description: 'Executes the IPv6 version of many IPv4 commands.',
      example: 'Router# show ipv6 route'
    },
    {
      command: 'show processes',
      description: 'Lists active processes running on the router.',
      example: 'Router# show processes'
    },
    {
      command: 'show process cpu',
      description: 'Displays CPU statistics.',
      example: 'Router# show process cpu'
    },
    {
      command: 'show memory',
      description: 'Shows memory allocation details.',
      example: 'Router# show memory'
    },
    {
      command: 'show users',
      description: 'Displays information about users currently logged in via Telnet.',
      example: 'Router# show users'
    },
    {
      command: 'show standby',
      description: 'Indicates if HSRP (Hot Standby Router Protocol) is active.',
      example: 'Router# show standby'
    },
    {
      command: 'ping X.X.X.X',
      description: 'Attempts to reach the destination host at X.X.X.X.',
      example: 'Router# ping 192.168.1.1'
    },
    {
      command: 'trace X.X.X.X',
      description: 'Shows the path taken to reach the destination host at X.X.X.X.',
      example: 'Router# trace 192.168.1.1'
    },
    {
        command: 'do show ???',
        description: 'Executes show commands from configuration mode.',
        example: 'R1(config)# do show ip interface brief'
      },
      {
        command: 'debug ???',
        description: 'Provides real-time reporting about processes related to almost any function.',
        example: 'R1# debug ip packet'
      },
      {
        command: 'debug all',
        description: 'Enables all debugging features, which can significantly impact router performance and should be used with caution.',
        example: 'R1# debug all'
      },
      {
        command: 'undebug all',
        description: 'Turns off all debugging commands, useful for stopping debugging when the router is busy.',
        example: 'R1# undebug all'
      }
          // More commands...
        ],
      },
      {
        section: "Line Editing Commands",
        commands:[
            {
                command: 'ctrl-a',
      description: 'Moves the cursor to the beginning of the current line.',
      example: 'Press ctrl-a'
    },
    {
      command: 'ctrl-e',
      description: 'Moves the cursor to the end of the current line.',
      example: 'Press ctrl-e'
    },
    {
      command: 'ctrl-p or up-arrow',
      description: 'Repeats up to 10 previous commands in the current mode.',
      example: 'Press ctrl-p or up-arrow'
    },
    {
      command: 'ctrl-n or dn-arrow',
      description: 'Moves forward in command history if you have gone back.',
      example: 'Press ctrl-n or dn-arrow'
    },
    {
      command: 'backspace-key',
      description: 'Erases the character to the left of the current cursor position.',
      example: 'Press backspace-key'
    },
    {
      command: 'ctrl-z or end',
      description: 'Exits to privilege mode from configuration mode.',
      example: 'Press ctrl-z or end'
    },
    {
      command: 'exit',
      description: 'Moves back one level in the hierarchical command structure.',
      example: 'Press exit'
    },
    {
      command: 'ctrl-c',
      description: 'Cancels the current command or exits Setup mode if accidentally entered.',
      example: 'Press ctrl-c'
    },
    {
      command: 'ctrl-shift-6',
      description: 'Stops ongoing ping or trace commands.',
      example: 'Press ctrl-shift-6'
    },
    {
      command: 'terminal length 0',
      description: 'Turns off paging, making output continuous without breaks.',
      example: 'Router# terminal length 0'
    },
    {
      command: 'terminal length 24',
      description: 'Sets normal page breaks in output (24 lines per page).',
      example: 'Router# terminal length 24'
    },
    {
      command: 'wr',
      description: 'Shortcut for `copy running-config startup-config` to save the current configuration.',
      example: 'Router# wr'
    }
        ]
      },

      {
        section:"Port Numbers and Protocols",
        commands:[
    {
        command: 'FTP Control',
        description: 'File Transfer Protocol for control commands using TCP.',
        example: 'TCP Port 21'
    },
    {
      command: 'FTP Data',
      description: 'File Transfer Protocol for data transfer using TCP.',
      example: 'TCP Port 20'
    },
    {
      command: 'SSH',
      description: 'Secure Shell for secure remote login and other secure network services using TCP.',
      example: 'TCP Port 22'
    },
    {
      command: 'Telnet',
      description: 'Telnet protocol for remote login using TCP.',
      example: 'TCP Port 23'
    },
    {
      command: 'SMTP',
      description: 'Simple Mail Transfer Protocol for email transmission using TCP.',
      example: 'TCP Port 25'
    },
    {
      command: 'DNS',
      description: 'Domain Name System for resolving domain names to IP addresses using TCP/UDP.',
      example: 'TCP/UDP Port 53'
    },
    {
      command: 'DHCP BOOTPS',
      description: 'Dynamic Host Configuration Protocol for client requests to the server using UDP.',
      example: 'UDP Port 67'
    },
    {
      command: 'DHCP BOOTPC',
      description: 'Dynamic Host Configuration Protocol for server replies to clients using UDP.',
      example: 'UDP Port 68'
    },
    {
      command: 'HTTP',
      description: 'Hypertext Transfer Protocol for web traffic using TCP.',
      example: 'TCP Port 80'
    },
    {
      command: 'POP',
      description: 'Post Office Protocol for retrieving incoming mail using TCP.',
      example: 'TCP Port 110'
    },
    {
      command: 'NTP',
      description: 'Network Time Protocol for time synchronization using UDP.',
      example: 'UDP Port 123'
    },
    {
      command: 'SNMP',
      description: 'Simple Network Management Protocol for network management using UDP.',
      example: 'UDP Port 161'
    },
    {
      command: 'HTTPS',
      description: 'Secure Hypertext Transfer Protocol for secure web traffic using TCP.',
      example: 'TCP Port 443'
    }
            
        ],
    },

    {
        section: "Common Router and Switching",
        commands: [
            
                {
                    command: 'delete vlan.dat',
                    description: 'Restores switch to default configuration by deleting VLAN data (only for switches).',
                    example: 'S1# delete vlan.dat'
                  },
                  {
                    command: 'erase startup-config',
                    description: 'Erases the startup configuration to reset the router or switch to default settings.',
                    example: 'S1# erase startup-config'
                  },
                  {
                    command: 'reload',
                    description: 'Reloads the device and prompts whether to save the current configuration (answer ‘no’ to discard).',
                    example: 'S1# reload'
                  },
                  {
                    command: 'configure terminal',
                    description: 'Enters global configuration mode.',
                    example: 'R1# configure terminal'
                  },
                  {
                    command: 'hostname NAME',
                    description: 'Sets the device hostname to NAME.',
                    example: 'R1(config)# hostname R1'
                  },
                  {
                    command: 'security passwords min-length 5',
                    description: 'Sets the minimum password length to 5 characters.',
                    example: 'R1(config)# security passwords min-length 5'
                  },
                  {
                    command: 'service password-encryption',
                    description: 'Encrypts all passwords except the enable secret.',
                    example: 'R1(config)# service password-encryption'
                  },
                  {
                    command: 'login block-for 60 attempts 3 within 30',
                    description: 'Blocks login attempts for 1 minute if 3 failed attempts occur within 30 seconds.',
                    example: 'R1(config)# login block-for 60 attempts 3 within 30'
                  },
                  {
                    command: 'enable secret PASSWORD',
                    description: 'Sets the enable secret password to PASSWORD.',
                    example: 'R1(config)# enable secret PASSWORD'
                  },
                  {
                    command: 'no ip domain-lookup',
                    description: 'Disables DNS lookup to prevent delays for mistyped commands.',
                    example: 'R1(config)# no ip domain-lookup'
                  },
                  {
                    command: 'banner motd MESSAGE',
                    description: 'Displays MESSAGE as a banner when logging in.',
                    example: 'R1(config)# banner motd Welcome to R1!'
                  },
                  {
                    command: 'line console 0',
                    description: 'Enters configuration mode for the console line.',
                    example: 'R1(config)# line console 0'
                  },
                  {
                    command: 'password PASSWORD',
                    description: 'Sets the console line password to PASSWORD.',
                    example: 'R1(config-line)# password PASSWORD'
                  },
                  {
                    command: 'login',
                    description: 'Configures the console line to prompt for a password.',
                    example: 'R1(config-line)# login'
                  },
                  {
                    command: 'logging synchronous',
                    description: 'Prevents logging messages from interrupting command entry.',
                    example: 'R1(config-line)# logging synchronous'
                  },
                  {
                    command: 'exec-timeout 0 0',
                    description: 'Sets no timeout for the console line session.',
                    example: 'R1(config-line)# exec-timeout 0 0'
                  },
                  {
                    command: 'line vty 0 4',
                    description: 'Configures the same options for VTY lines 0 through 4.',
                    example: 'R1(config)# line vty 0 4'
                  },
                  {
                    command: 'line vty 0 15',
                    description: 'Configures the same options for VTY lines 0 through 15 on a switch.',
                    example: 'S1(config)# line vty 0 15'
                  },
                  {
                    command: 'copy running-config startup-config',
                    description: 'Saves the current running configuration to the startup configuration file.',
                    example: 'R1# copy running-config startup-config'
                  },
                  {
                    command: 'wr',
                    description: 'Legacy command for saving the current configuration to startup configuration.',
                    example: 'R1# wr'
                  },
                  {
                    command: '!',
                    description: 'Adds a remark or comment in the configuration (no configuration changes).',
                    example: 'R1(config)# ! This is a comment'
                  },
                  {
                    command: 'interface vlan 1',
                    description: 'Configures the VLAN 1 interface on a switch.',
                    example: 'S1(config)# interface vlan 1'
                  },
                  {
                    command: 'description Management interface for this switch',
                    description: 'Adds an optional description for the management interface.',
                    example: 'S1(config-if)# description Management interface for this switch'
                  },
                  {
                    command: 'ip address 192.168.100.50 255.255.255.0',
                    description: 'Assigns an IP address and subnet mask to the management interface.',
                    example: 'S1(config-if)# ip address 192.168.100.50 255.255.255.0'
                  },
                  {
                    command: 'no shut',
                    description: 'Activates the interface (must be done to enable it).',
                    example: 'S1(config-if)# no shut'
                  },
                  {
                    command: 'exit',
                    description: 'Exits from the interface configuration mode and returns to global configuration mode.',
                    example: 'S1(config-if)# exit'
                  },
                  {
                    command: 'ip default-gateway 192.168.100.1',
                    description: 'Sets the default gateway for the switch management interface.',
                    example: 'S1(config)# ip default-gateway 192.168.100.1'
                  },
                  {
                    command: 'enable secret class',
                    description: 'Sets an enable password for remote configuration access.',
                    example: 'S1(config)# enable secret class'
                  },
                  {
                    command: 'line vty 0 15',
                    description: 'Configures VTY lines 0 through 15 for remote access on the switch.',
                    example: 'S1(config)# line vty 0 15'
                  },
                  {
                    command: 'password cisco',
                    description: 'Sets the login password for remote access via Telnet.',
                    example: 'S1(config-line)# password cisco'
                  },
                  {
                    command: 'login',
                    description: 'Configures VTY lines to require a password for remote access.',
                    example: 'S1(config-line)# login'
                  },
                  {
                    command: 'transport input telnet',
                    description: 'Allows Telnet access for remote configuration; default setting.',
                    example: 'S1(config-line)# transport input telnet'
                  },
                  {
                    command: 'interface INTERFACE-TYPE',
                    description: 'Enters configuration mode for a specific interface type.',
                    example: 'R1(config)# interface GigabitEthernet0/1'
                  },
                  {
                    command: 'ip address ADDRESS SNM',
                    description: 'Assigns an IP address and subnet mask to an interface.',
                    example: 'R1(config-if)# ip address 192.168.1.1 255.255.255.0'
                  },
                  {
                    command: 'description WORDS',
                    description: 'Adds a description to the interface for documentation purposes.',
                    example: 'R1(config-if)# description Connection to main office'
                  },
                  {
                    command: 'clock rate CLOCK',
                    description: 'Sets the clock rate on a serial DCE interface.',
                    example: 'R1(config-if)# clock rate 64000'
                  },
                  {
                    command: 'bandwidth VALUE',
                    description: 'Sets the bandwidth value used by routing protocols for the interface.',
                    example: 'R1(config-if)# bandwidth 1000'
                  },
                  {
                    command: 'no shutdown',
                    description: 'Activates the interface.',
                    example: 'R1(config-if)# no shutdown'
                  },
                  {
                    command: 'shutdown',
                    description: 'Deactivates the interface.',
                    example: 'R1(config-if)# shutdown'
                  },
                  {
                    command: 'ipv6 unicast-routing',
                    description: 'Enables IPv6 routing on the router.',
                    example: 'R1(config)# ipv6 unicast-routing'
                  },
                  {
                    command: 'interface Gi1/1',
                    description: 'Enters configuration mode for the GigabitEthernet1/1 interface.',
                    example: 'R1(config)# interface Gi1/1'
                  },
                  {
                    command: 'ipv6 enable',
                    description: 'Activates IPv6 on the specified interface.',
                    example: 'R1(config-if)# ipv6 enable'
                  },
                  {
                    command: 'ipv6 address 3ffe:b00:c18:1::3 /64',
                    description: 'Assigns a static IPv6 address to an interface.',
                    example: 'R1(config-if)# ipv6 address 3ffe:b00:c18:1::3 /64'
                  },
                  {
                    command: 'ipv6 address 3ffe:b00:c18:1:: /64 eui-64',
                    description: 'Configures an IPv6 address with automatic host portion.',
                    example: 'R1(config-if)# ipv6 address 3ffe:b00:c18:1:: /64 eui-64'
                  },
                  {
                    command: 'ipv6 address fe80::4 link-local',
                    description: 'Configures a link-local IPv6 address for the interface.',
                    example: 'R1(config-if)# ipv6 address fe80::4 link-local' 
            }
        ],
    },
    {
        section:"Layer-3 Switch Commands",
        commands:[
            {
                command: 'ip routing',
                description: 'Activates IPv4 routing within the switch.',
                example: 'S1(config)# ip routing'
              },
              {
                command: 'ipv6 routing',
                description: 'Activates IPv6 routing within the switch.',
                example: 'S1(config)# ipv6 routing'
              },
              {
                command: 'no switchport',
                description: 'Designates the interface as a routed port rather than a switchport.',
                example: 'S1(config-if)# no switchport'
              },
              {
                command: 'switchport trunk encapsulation dot1q',
                description: 'Configures the trunking encapsulation for 802.1Q (dot1q) on the interface.',
                example: 'S1(config-if)# switchport trunk encapsulation dot1q'
            }
        ]
    },

    {
        section:"VLAN Creation & Port Assignment",
        commands: [
            {
                command: 'vlan 10',
                description: 'Creates VLAN 10 in the VLAN.DAT database.',
                example: 'S1(config)# vlan 10'
              },
              {
                command: 'name Management',
                description: 'Optionally assigns a name to the VLAN.',
                example: 'S1(config-vlan)# name Management'
              },
              {
                command: 'interface fa0/12',
                description: 'Selects a specific port (FastEthernet 0/12) on the switch for configuration.',
                example: 'S1(config)# interface fa0/12'
              },
              {
                command: 'interface range fa0/12 – 20',
                description: 'Selects a range of ports (FastEthernet 0/12 to 0/20) for configuration.',
                example: 'S1(config)# interface range fa0/12 – 20'
              },
              {
                command: 'switchport mode access',
                description: 'Sets the selected port(s) to Access mode.',
                example: 'S1(config-if)# switchport mode access'
              },
              {
                command: 'switchport access vlan 10',
                description: 'Assigns the selected port(s) to VLAN 10.',
                example: 'S1(config-if)# switchport access vlan 10'
              }
        ]
    },
    {
        section: "Trunk Creation",
        commands: [
            {
                command: 'interface gi1/1',
                description: 'Selects the GigabitEthernet 1/1 port for trunking configuration.',
                example: 'S1(config)# interface gi1/1'
              },
              {
                command: 'switchport trunk encapsulation dot1q',
                description: 'Configures the trunking encapsulation to 802.1Q (dot1q) on the interface. (Note: applicable on Layer 3 switches only)',
                example: 'S1(config-if)# switchport trunk encapsulation dot1q'
              },
              {
                command: 'switchport mode trunk',
                description: 'Sets the port to operate in trunk mode.',
                example: 'S1(config-if)# switchport mode trunk'
              },
              {
                command: 'switchport trunk native vlan 99',
                description: 'Sets VLAN 99 as the native VLAN for carrying untagged traffic on the trunk.',
                example: 'S1(config-if)# switchport trunk native vlan 99'
              },
              {
                command: 'switchport trunk allowed vlan 1,10,20,99',
                description: 'Configures which VLANs are allowed to traverse the trunk. (Include VLAN 1 and the native VLAN if necessary)',
                example: 'S1(config-if)# switchport trunk allowed vlan 1,10,20,99'
              }
        ]
    },

    {
        section: "Router on a Stick Configuration",
        commands: [
            {
                command: 'interface Fa0/0',
                description: 'Selects the main interface (FastEthernet 0/0) for configuration.',
                example: 'R1(config)# interface Fa0/0'
              },
              {
                command: 'no ip address',
                description: 'Removes any IP address from the main interface, leaving it unassigned.',
                example: 'R1(config-if)# no ip address'
              },
              {
                command: 'interface Fa0/0.10',
                description: 'Creates a sub-interface (FastEthernet 0/0.10) for VLAN 10.',
                example: 'R1(config)# interface Fa0/0.10'
              },
              {
                command: 'encapsulation dot1q 10',
                description: 'Configures 802.1Q trunking on the sub-interface and assigns it to VLAN 10.',
                example: 'R1(config-if)# encapsulation dot1q 10'
              },
              {
                command: 'ip address 172.16.10.1 255.255.255.255',
                description: 'Assigns the IP address 172.16.10.1 with a subnet mask of 255.255.255.255 to the sub-interface.',
                example: 'R1(config-if)# ip address 172.16.10.1 255.255.255.255'
              },
              {
                command: 'interface Fa0/0.99',
                description: 'Creates another sub-interface (FastEthernet 0/0.99) for native VLAN 99 traffic.',
                example: 'R1(config)# interface Fa0/0.99'
              },
              {
                command: 'encapsulation dot1q 99 native',
                description: 'Configures 802.1Q trunking on the sub-interface for VLAN 99 as the native VLAN.',
                example: 'R1(config-if)# encapsulation dot1q 99 native'
              },
              {
                command: 'ip classless',
                description: 'Enables classless routing behavior, which is the default in IOS 11.3 and later.',
                example: 'R1(config)# ip classless'
              },
              {
                command: 'no ip classless',
                description: 'Enables classful routing behavior, where routing decisions are based on classful boundaries.',
                example: 'R1(config)# no ip classless'
              }
        ]
    },

    {
        section: "VLAN Trunking Protocol (VTP) Configuration",
        commands:[
            {
              command: 'vtp mode server',
              description: 'Configures the switch to operate in VTP server mode.',
              example: 'S1(config)# vtp mode server'
            },
            {
              command: 'vtp mode client',
              description: 'Configures the switch to operate in VTP client mode.',
              example: 'S1(config)# vtp mode client'
            },
            {
              command: 'vtp mode transparent',
              description: 'Configures the switch to operate in VTP transparent mode (recommended for most scenarios).',
              example: 'S1(config)# vtp mode transparent'
            },
            {
              command: 'vtp domain NAME',
              description: 'Changes the VTP domain name of the switch to NAME.',
              example: 'S1(config)# vtp domain NAME'
            },
            {
              command: 'vtp password PASSWORD',
              description: 'Sets the VTP password for the switch to PASSWORD.',
              example: 'S1(config)# vtp password PASSWORD'
            },
            {
              command: 'vtp pruning',
              description: 'Activates VTP pruning to reduce unnecessary VLAN traffic (not supported in Packet Tracer).',
              example: 'S1(config)# vtp pruning'
            },
            {
              command: 'vtp version 2',
              description: 'Changes the VTP version to 2 for enhanced features and compatibility.',
              example: 'S1(config)# vtp version 2'
            },
            {
              command: 'show vtp status',
              description: 'Displays VTP mode, revision number, version, domain name, pruning mode, and other related information.',
              example: 'S1# show vtp status'
            },
            {
              command: 'show vtp password',
              description: 'Displays the VTP password for the switch (the only way to view it, does not appear in the status output).',
              example: 'S1# show vtp password'
            }
        ]        
    },

    {
        section: "Etherchannel (PortChannel) Layer 2 Trucking",
        commands: [
        {
          command: 'interface range fa0/1 – 4',
          description: 'Selects a group of physical interfaces (FastEthernet 0/1 to 0/4) for configuration.',
          example: 'S1(config)# interface range fa0/1 – 4'
        },
        {
          command: 'switchport trunk encapsulation dot1q',
          description: 'Configures 802.1Q trunking on the interface. (Note: applicable on Layer 3 switches only)',
          example: 'S1(config-if)# switchport trunk encapsulation dot1q'
        },
        {
          command: 'switchport mode trunk',
          description: 'Sets the interface to operate in trunk mode.',
          example: 'S1(config-if)# switchport mode trunk'
        },
        {
          command: 'switchport trunk native vlan 777',
          description: 'Sets VLAN 777 as the native VLAN for untagged traffic on the trunk.',
          example: 'S1(config-if)# switchport trunk native vlan 777'
        },
        {
          command: 'channel-protocol lacp',
          description: 'Configures the interface to use LACP (Link Aggregation Control Protocol) for EtherChannel.',
          example: 'S1(config-if)# channel-protocol lacp'
        },
        {
          command: 'channel-protocol pagp',
          description: 'Configures the interface to use PAgP (Port Aggregation Protocol) for EtherChannel.',
          example: 'S1(config-if)# channel-protocol pagp'
        },
        {
          command: 'channel-group 3 mode passive',
          description: 'Configures the interface to use LACP, enabling it only if a LACP device is detected.',
          example: 'S1(config-if)# channel-group 3 mode passive'
        },
        {
          command: 'channel-group 3 mode active',
          description: 'Configures the interface to use LACP unconditionally, regardless of whether a LACP device is detected.',
          example: 'S1(config-if)# channel-group 3 mode active'
        },
        {
          command: 'channel-group 3 mode auto',
          description: 'Configures the interface to use PAgP, enabling it only if a PAgP device is detected.',
          example: 'S1(config-if)# channel-group 3 mode auto'
        },
        {
          command: 'channel-group 3 mode desirable',
          description: 'Configures the interface to use PAgP unconditionally, regardless of whether a PAgP device is detected.',
          example: 'S1(config-if)# channel-group 3 mode desirable'
        },
        {
          command: 'channel-group 3 mode on',
          description: 'Enables EtherChannel without any negotiation protocol.',
          example: 'S1(config-if)# channel-group 3 mode on'
        },
        {
          command: 'interface port-channel 3',
          description: 'Configures the virtual interface for the EtherChannel group 3.',
          example: 'S1(config)# interface port-channel 3'
        },
        {
          command: 'switchport mode trunk',
          description: 'Sets the virtual interface to operate in trunk mode.',
          example: 'S1(config-if)# switchport mode trunk'
        },
        {
          command: 'switchport trunk native vlan 777',
          description: 'Sets the native VLAN for the virtual interface to VLAN 777, matching the physical interfaces.',
          example: 'S1(config-if)# switchport trunk native vlan 777'
        },
        {
          command: 'no shutdown',
          description: 'Turns on the virtual interface.',
          example: 'S1(config-if)# no shutdown'
        }
    ]
    },

    {
        section: "Etherchannel Layer 3",
        commands: [
            [
                {
                  command: 'interface range fa0/1 – 2',
                  description: 'Selects a range of physical interfaces (FastEthernet 0/1 to 0/2) for configuration.',
                  example: 'SW1(config)# interface range fa0/1 – 2'
                },
                {
                  command: 'no switchport',
                  description: 'Configures the interface as a routed port, disabling Layer 2 switching features.',
                  example: 'SW1(config-if)# no switchport'
                },
                {
                  command: 'channel-group 1 mode {active, passive, on}',
                  description: 'Adds the interface to an EtherChannel group with the specified mode: active (LACP), passive (LACP), or on (no negotiation).',
                  example: 'SW1(config-if)# channel-group 1 mode active'
                },
                {
                  command: 'interface port-channel 1',
                  description: 'Configures the virtual interface for EtherChannel group 1.',
                  example: 'SW1(config)# interface port-channel 1'
                },
                {
                  command: 'no switchport',
                  description: 'Configures the virtual interface as a routed port, disabling Layer 2 switching features.',
                  example: 'SW1(config-if)# no switchport'
                },
                {
                  command: 'ip address x.x.x.x m.m.m.m',
                  description: 'Assigns an IP address and subnet mask to the port-channel interface. The IP configuration on the other end of the link should match.',
                  example: 'SW1(config-if)# ip address 192.168.1.1 255.255.255.0'
                },
                {
                  command: 'port-channel load-balance TYPE',
                  description: 'Configures the load-balancing algorithm for EtherChannel. TYPE can be src-ip, dst-ip, src-dst-ip, src-mac, dst-mac, src-dst-mac, src-port, dst-port, or src-dst-port.',
                  example: 'SW1(config)# port-channel load-balance src-ip'
                }
            ]
            
        ]
    },
    {
        section: "Spanning Tree Protocols",
        commands: [
            
                {
                  command: 'spanning-tree mode pvst',
                  description: 'Configures the switch for Per VLAN Spanning Tree (PVST), which is the default mode.',
                  example: 'S1(config)# spanning-tree mode pvst'
                },
                {
                  command: 'spanning-tree mode rapid-pvst',
                  description: 'Configures the switch for Rapid Per VLAN Spanning Tree (Rapid PVST) for faster convergence.',
                  example: 'S1(config)# spanning-tree mode rapid-pvst'
                },
                {
                  command: 'spanning-tree vlan 10,20 root primary',
                  description: 'Designates this switch as the root bridge for VLANs 10 and 20.',
                  example: 'S1(config)# spanning-tree vlan 10,20 root primary'
                },
                {
                  command: 'spanning-tree vlan 10 root secondary',
                  description: 'Designates this switch as the secondary root bridge for VLAN 10.',
                  example: 'S1(config)# spanning-tree vlan 10 root secondary'
                },
                {
                  command: 'spanning-tree vlan 10 priority 8192',
                  description: 'Sets the Bridge ID (BID) priority to 8192 for VLAN 10, influencing the root bridge election.',
                  example: 'S1(config)# spanning-tree vlan 10 priority 8192'
                },
                {
                  command: 'spanning-tree portfast default',
                  description: 'Enables Portfast on all interfaces in the switch by default, reducing STP convergence time.',
                  example: 'S1(config)# spanning-tree portfast default'
                },
                {
                  command: 'interface range fa0/10 – 20',
                  description: 'Selects a range of interfaces (FastEthernet 0/10 to 0/20) for configuration.',
                  example: 'S1(config)# interface range fa0/10 – 20'
                },
                {
                  command: 'spanning-tree portfast',
                  description: 'Enables Portfast on the selected interfaces, bypassing STP processing for faster transition to forwarding state.',
                  example: 'S1(config-if)# spanning-tree portfast'
                },
                {
                  command: 'spanning-tree bpduguard enable',
                  description: 'Enables BPDU Guard on the interface, disabling it if a Bridge Protocol Data Unit (BPDU) is received.',
                  example: 'S1(config-if)# spanning-tree bpduguard enable'
                },
                {
                  command: 'interface fa0/1',
                  description: 'Selects FastEthernet 0/1 for additional configuration.',
                  example: 'S1(config)# interface fa0/1'
                },
                {
                  command: 'spanning-tree vlan 10 port-priority 16',
                  description: 'Sets the port priority to 16 for VLAN 10 on the selected interface, affecting STP decisions.',
                  example: 'S1(config-if)# spanning-tree vlan 10 port-priority 16'
                },
                {
                  command: 'show spanning-tree',
                  description: 'Displays the spanning-tree status for all VLANs on the switch.',
                  example: 'S1# show spanning-tree'
                },
                {
                  command: 'show spanning-tree vlan 10',
                  description: 'Displays detailed spanning-tree information for VLAN 10.',
                  example: 'S1# show spanning-tree vlan 10'
                },
                {
                  command: 'show spanning-tree summary',
                  description: 'Shows a summary of spanning-tree information, including if the switch is the root bridge.',
                  example: 'S1# show spanning-tree summary'
                },
                {
                  command: 'show spanning-tree blockedports',
                  description: 'Displays ports that are in a blocked state due to spanning-tree protocols.',
                  example: 'S1# show spanning-tree blockedports'
                },
                {
                  command: 'show spanning-tree root',
                  description: 'Displays the Bridge ID (BID) of the root bridge for each VLAN.',
                  example: 'S1# show spanning-tree root'
                },
                {
                  command: 'interface fastethernet 0/1',
                  description: 'Selects FastEthernet 0/1 for HSRP configuration.',
                  example: 'R1(config)# interface fastethernet 0/1'
                },
                {
                  command: 'standby version 2',
                  description: 'Configures HSRP to use version 2, which must match on both ends of the configuration.',
                  example: 'R1(config-if)# standby version 2'
                },
                {
                  command: 'standby [optional group#] ip [optional IP-ADDRESS] [optional secondary]',
                  description: 'Sets the virtual IP address for HSRP. The configuration should be identical on the other end.',
                  example: 'R1(config-if)# standby 1 ip 192.168.1.1'
                },
                {
                  command: 'standby [optional group#] priority NUMBER [optional preempt]',
                  description: 'Sets the HSRP priority to NUMBER to influence the active router selection. Preempt allows this router to become active if it was previously down.',
                  example: 'R1(config-if)# standby 1 priority 110 preempt'
                },
                {
                  command: 'standby GROUP# ipv6 autoconfig',
                  description: 'Configures HSRP for IPv6 with automatic configuration of a virtual Link-Local address.',
                  example: 'R1(config-if)# standby 1 ipv6 autoconfig'
                },
                {
                  command: 'standby GROUP# ipv6 2001:CAFE:ACAD:4::1/64',
                  description: 'Sets the virtual shared IPv6 address for HSRP. The configuration should be identical on the other end.',
                  example: 'R1(config-if)# standby 1 ipv6 2001:CAFE:ACAD:4::1/64'
                },
                {
                  command: 'show standby',
                  description: 'Displays the current HSRP configuration and status.',
                  example: 'R1# show standby'
                },
            ]
    },

    {
        section: "Security",
        commands: [
            {
                command: 'service password-encryption',
                description: 'Encrypts all passwords in the configuration (except for those set with the "secret" command).',
                example: 'R1(config)# service password-encryption'
              },
              {
                command: 'security password min-length 8',
                description: 'Sets the minimum length for user passwords to 8 characters.',
                example: 'R1(config)# security password min-length 8'
              },
              {
                command: 'login block-for 120 attempts 3 within 60',
                description: 'Blocks login attempts for 2 minutes if more than 3 failed logins occur within 60 seconds.',
                example: 'R1(config)# login block-for 120 attempts 3 within 60'
              }
              
        ]
    },

    {
        section: "Port Security Switch",
        commands: [
            {
                command: 'interface fa0/1 or interface range fa0/1 – 15, gi1/1',
                description: 'Accesses configuration mode for a specific interface (e.g., fa0/1) or a range of interfaces (e.g., fa0/1 – 15, gi1/1).',
                example: 'S1(config)# interface fa0/1'
              },
              {
                command: 'switchport mode access',
                description: 'Configures the switch port to operate in access mode, overriding the default dynamic mode.',
                example: 'S1(config-if)# switchport mode access'
              },
              {
                command: 'switchport port-security',
                description: 'Enables port security features on the switch port.',
                example: 'S1(config-if)# switchport port-security'
              },
              {
                command: 'switchport port-security maximum 25',
                description: 'Allows a maximum of 25 MAC addresses to be learned on the switch port.',
                example: 'S1(config-if)# switchport port-security maximum 25'
              },
              {
                command: 'switchport port-security mac-address sticky',
                description: 'Configures the switch to dynamically learn and save MAC addresses on the port.',
                example: 'S1(config-if)# switchport port-security mac-address sticky'
              },
              {
                command: 'switchport port-security violation restrict',
                description: 'Sets the port to send SNMP alerts when a security violation occurs, while allowing existing connections to continue.',
                example: 'S1(config-if)# switchport port-security violation restrict'
              },
              {
                command: 'switchport port-security violation protect',
                description: 'Configures the port to only drop traffic from unauthorized MAC addresses, without generating SNMP alerts.',
                example: 'S1(config-if)# switchport port-security violation protect'
              },
              {
                command: 'switchport port-security violation shutdown',
                description: 'Sets the port to shut down automatically if a security violation occurs; this is the default behavior.',
                example: 'S1(config-if)# switchport port-security violation shutdown'
              },
              {
                command: 'switchport protected',
                description: 'Prevents traffic from being exchanged between ports that are also protected, isolating them from each other.',
                example: 'S1(config-if)# switchport protected'
              },
              {
                command: 'spanning-tree bpduguard enable',
                description: 'Enables BPDU Guard on the port, which disables the port if a Bridge Protocol Data Unit (BPDU) is detected.',
                example: 'S1(config-if)# spanning-tree bpduguard enable'
              },
              {
                command: 'shutdown then no shutdown',
                description: 'Shuts down the port and then brings it back up, useful for restoring the port if it was previously shut down.',
                example: 'S1(config-if)# shutdown then no shutdown'
              },
              {
                command: 'errdisable recovery cause psecure_violation',
                description: 'Configures the switch to automatically recover from port security violations by re-enabling the port after 5 minutes.',
                example: 'S1# errdisable recovery cause psecure_violation'
              },
              {
                command: 'show port-security interface fa0/12',
                description: 'Displays the port security configuration and status for the specified interface.',
                example: 'S1# show port-security interface fa0/12'
              }
                           
              
        ]
    },

    {
        section: "Enable/Disable Cisco Discovery Protocol (CDP)",
        commands: [
            {
                command: 'cdp run',
                description: 'Enables Cisco Discovery Protocol (CDP) globally on the router; this is enabled by default.',
                example: 'R1(config)# cdp run'
              },
              {
                command: 'no cdp run',
                description: 'Disables Cisco Discovery Protocol (CDP) globally on the router.',
                example: 'R1(config)# no cdp run'
              },
              {
                command: 'no cdp enable',
                description: 'Disables Cisco Discovery Protocol (CDP) updates on the specific interface.',
                example: 'R1(config-if)# no cdp enable'
              }                          
              
        ]
    },
      
    {
        section: "IP DHCP Snooping",
        commands: [
            {
                command: 'ip dhcp snooping',
                description: 'Enables DHCP snooping globally on the router to protect against unauthorized DHCP servers.',
                example: 'R1(config)# ip dhcp snooping'
              },
              {
                command: 'ip dhcp snooping trust',
                description: 'Designates the interface as trusted, allowing it to receive DHCP messages from DHCP servers.',
                example: 'R1(config-if)# ip dhcp snooping trust'
              }                                       
              
        ]
    },

    {
        section: "Static Routes",
        commands: [
            {
                command: 'ip route 0.0.0.0 0.0.0.0 serial0/0',
                description: 'Configures a default route that directs all traffic to the interface serial0/0.',
                example: 'R1(config)# ip route 0.0.0.0 0.0.0.0 serial0/0'
              },
              {
                command: 'ip route 0.0.0.0 0.0.0.0 50.77.4.13',
                description: 'Configures a default route that directs all traffic to the next-hop IP address 50.77.4.13.',
                example: 'R1(config)# ip route 0.0.0.0 0.0.0.0 50.77.4.13'
              },
              {
                command: 'ip route 0.0.0.0 0.0.0.0 serial0/0 150',
                description: 'Configures a default route that directs all traffic to the interface serial0/0, with an administrative distance of 150.',
                example: 'R1(config)# ip route 0.0.0.0 0.0.0.0 serial0/0 150'
              },
              {
                command: 'ip route 47.151.2.0 255.255.255.0 172.24.2.11',
                description: 'Configures a static route to network 47.151.2.0/24, specifying the next-hop IP address 172.24.2.11.',
                example: 'R1(config)# ip route 47.151.2.0 255.255.255.0 172.24.2.11'
              },
              {
                command: 'ip route 47.151.2.0 255.255.255.0 serial0/1',
                description: 'Configures a static route to network 47.151.2.0/24, specifying that traffic should be sent out interface serial0/1.',
                example: 'R1(config)# ip route 47.151.2.0 255.255.255.0 serial0/1'
              },
              {
                command: 'ip route 47.151.2.0 255.255.255.0 192.168.12.2 fastethernet0/0',
                description: 'Configures a static route to network 47.151.2.0/24, directing traffic to the next-hop IP address 192.168.12.2 via interface FastEthernet0/0.',
                example: 'R1(config)# ip route 47.151.2.0 255.255.255.0 192.168.12.2 fastethernet0/0'
              }
              
        ],
      },

      {
        section: "RIP (IPv4)",
        commands: [
            
            {
                command: 'no router rip',
                description: 'Removes all RIP (Routing Information Protocol) configurations and routing table entries from the router.',
                example: 'R1(config)# no router rip'
              },
              {
                command: 'router rip',
                description: 'Enters RIP configuration mode, allowing for the configuration of RIP routing settings.',
                example: 'R1(config)# router rip'
              },
              {
                command: 'network 192.168.10.0',
                description: 'Specifies which directly connected network(s) should be included in RIP updates. The configuration is classful and does not require a subnet mask.',
                example: 'R1(config-router)# network 192.168.10.0'
              },
              {
                command: 'passive-interface fastethernet0/0',
                description: 'Prevents RIP updates from being sent out of the specified interface (FastEthernet0/0), while still receiving updates on that interface.',
                example: 'R1(config-router)# passive-interface fastethernet0/0'
              },
              {
                command: 'default-information originate',
                description: 'Configures RIP to advertise default routes to other routers. This feature is disabled by default and should be enabled only on the router with the default route.',
                example: 'R1(config-router)# default-information originate'
              },
              {
                command: 'redistribute static',
                description: 'Configures RIP to include static routes in its updates to other routers. This is disabled by default and should be enabled only if static routes are configured.',
                example: 'R1(config-router)# redistribute static'
              },
              {
                command: 'debug ip rip',
                description: 'Enables real-time examination of RIP updates and routing information for troubleshooting purposes.',
                example: 'R1# debug ip rip'
              }              
        ],
      },

      {
        section: "RIP Version 2",
        commands: [
            {
                command: 'version 2',
                description: 'Configures RIP to use version 2, which supports classless routing and additional features not available in RIP version 1.',
                example: 'R1(config-router)# version 2'
              },
              {
                command: 'no auto-summary',
                description: 'Disables automatic classful summarization of RIP routes, allowing for classless routing and more precise route advertisements. This is recommended for most configurations.',
                example: 'R1(config-router)# no auto-summary'
              }              
                         
        ],
      },

      {
        section: "RIPng (IPv6)",
        commands: [
            {
            command: 'ipv6 route ::/0 S0/0/1',
            description: 'Configures a default IPv6 route that directs all traffic to the interface S0/0/1.',
            example: 'R1(config)# ipv6 route ::/0 S0/0/1'
            },
            {
            command: 'ipv6 router rip NAME',
            description: 'Starts the RIPng (Routing Information Protocol for IPv6) instance with the specified name.',
            example: 'R1(config)# ipv6 router rip NAME'
            },
            {
            command: 'interface fa0/1',
            description: 'Enters configuration mode for the interface FastEthernet0/1.',
            example: 'R1(config)# interface fa0/1'
            },
            {
            command: 'ipv6 rip NAME enable',
            description: 'Enables RIPng on the specified interface, allowing it to participate in RIPng routing processes.',
            example: 'R1(config-if)# ipv6 rip NAME enable'
            },
            {
            command: 'ipv6 rip NAME default-information originate',
            description: 'Configures RIPng to advertise the default route to other routers using the specified RIPng instance.',
            example: 'R1(config-if)# ipv6 rip NAME default-information originate'
            }
                                       
        ],
    },

    {
        section: " IPv4 EIGRP",
        commands: [
            {
                command: 'no router eigrp 100',
                description: 'Removes the EIGRP (Enhanced Interior Gateway Routing Protocol) configuration and routing information for the specified Autonomous System (AS) 100.',
                example: 'R1(config)# no router eigrp 100'
              },
              {
                command: 'router eigrp 100',
                description: 'Enters EIGRP configuration mode for the Autonomous System (AS) with process ID 100.',
                example: 'R1(config)# router eigrp 100'
              },
              {
                command: 'eigrp router-id 5.5.5.5',
                description: 'Sets the router ID for EIGRP to 5.5.5.5, used to identify this router in EIGRP routing updates.',
                example: 'R1(config)# eigrp router-id 5.5.5.5'
              },
              {
                command: 'no auto-summary',
                description: 'Disables automatic summarization of routes to classful boundaries, allowing for classless routing.',
                example: 'R1(config-router)# no auto-summary'
              },
              {
                command: 'network 172.16.0.0',
                description: 'Specifies which directly connected network(s) to include in EIGRP routing updates. No subnet or wildcard mask is needed for classful networks.',
                example: 'R1(config-router)# network 172.16.0.0'
              },
              {
                command: 'network 172.16.25.0 0.0.0.255',
                description: 'Includes the network 172.16.25.0/24 in EIGRP routing updates using a wildcard mask of 0.0.0.255.',
                example: 'R1(config-router)# network 172.16.25.0 0.0.0.255'
              },
              {
                command: 'passive-interface default',
                description: 'Configures all interfaces to not send EIGRP routing updates, while still receiving updates.',
                example: 'R1(config-router)# passive-interface default'
              },
              {
                command: 'no passive-interface fastethernet 0/1',
                description: 'Allows EIGRP routing updates to be sent out of the FastEthernet0/1 interface, overriding the default passive setting.',
                example: 'R1(config-router)# no passive-interface fastethernet 0/1'
              },
              {
                command: 'passive-interface fastethernet 0/0',
                description: 'Prevents EIGRP routing updates from being sent out of the FastEthernet0/0 interface, while still receiving updates.',
                example: 'R1(config-router)# passive-interface fastethernet 0/0'
              },
              {
                command: 'redistribute static',
                description: 'Configures EIGRP to include static routes in its routing updates, including default routes.',
                example: 'R1(config-router)# redistribute static'
              },
              {
                command: 'maximum paths 2',
                description: 'Sets the maximum number of equal-cost paths for load balancing to 2. The default is 4 paths; 1 path disables load balancing.',
                example: 'R1(config-if)# maximum paths 2'
              },
              {
                command: 'metric weights 0 k1 k2 k3 k4 k5',
                description: 'Modifies the metric weightings used by EIGRP to calculate route metrics. The weights k1 through k5 are adjusted as needed.',
                example: 'R1(config-router)# metric weights 0 k1 k2 k3 k4 k5'
              },
              {
                command: 'bandwidth 768',
                description: 'Sets the interface bandwidth to 768 kbps for routing protocol calculations, reflecting the speed of the serial link.',
                example: 'R1(config-if)# bandwidth 768'
              },
              {
                command: 'ip summary-address eigrp 100 172.16.24.0 255.255.252.0',
                description: 'Configures a manual summary route for network 172.16.24.0/22, which is advertised through EIGRP on the outbound interface.',
                example: 'R1(config-if)# ip summary-address eigrp 100 172.16.24.0 255.255.252.0'
              },
              {
                command: 'ip bandwidth-percent eigrp 100 40',
                description: 'Limits EIGRP updates for Autonomous System 100 to a maximum of 40% of the interface bandwidth.',
                example: 'R1(config-if)# ip bandwidth-percent eigrp 100 40'
              },
              {
                command: 'ip hello-interval eigrp 100 30',
                description: 'Sets the EIGRP hello interval to 30 seconds for Autonomous System 100 on this interface.',
                example: 'R1(config-if)# ip hello-interval eigrp 100 30'
              },
              {
                command: 'ip hold-time eigrp 100 90',
                description: 'Sets the EIGRP hold time to 90 seconds for Autonomous System 100 on this interface.',
                example: 'R1(config-if)# ip hold-time eigrp 100 90'
              },
              {
                command: 'key chain MYCHAIN',
                description: 'Creates a key chain named MYCHAIN for use in authentication.',
                example: 'R1(config)# key chain MYCHAIN'
              },
              {
                command: 'key 1',
                description: 'Defines a key within the key chain, using the key number 1.',
                example: 'R1(config-keychain)# key 1'
              },
              {
                command: 'key-string securetraffic',
                description: 'Sets the passphrase for the key to "securetraffic" in the key chain configuration.',
                example: 'R1(config-keychain-key)# key-string securetraffic'
              },
              {
                command: 'interface serial 0/1',
                description: 'Enters configuration mode for the Serial 0/1 interface.',
                example: 'R1(config)# interface serial 0/1'
              },
              {
                command: 'ip authentication mode eigrp 10 md5',
                description: 'Enables MD5 authentication for EIGRP on this interface for Autonomous System 10.',
                example: 'R1(config-subif)# ip authentication mode eigrp 10 md5'
              },
              {
                command: 'ip authentication key-chain eigrp 10 MYCHAIN',
                description: 'Assigns the key chain MYCHAIN for EIGRP authentication on this interface for Autonomous System 10.',
                example: 'R1(config-subif)# ip authentication key-chain eigrp 10 MYCHAIN'
              },
              {
                command: 'show ip eigrp neighbors',
                description: 'Displays information about EIGRP neighbors and their status.',
                example: 'R1# show ip eigrp neighbors'
              },
              {
                command: 'show ip eigrp topology',
                description: 'Shows the EIGRP topology table, including all routes learned by EIGRP.',
                example: 'R1# show ip eigrp topology'
              },
              {
                command: 'debug eigrp fsm',
                description: 'Enables debugging of EIGRP Finite State Machine (FSM) processes to monitor changes in route states.',
                example: 'R1# debug eigrp fsm'
              }
              
                                       
        ],
    },

    {
        section: "IPv4 OSPF (v2)",
        commands: [
            {
                command: 'interface loopback 10',
                description: 'Creates a virtual loopback interface with the number 10, often used to assign a stable OSPF Router ID.',
                example: 'R1(config)# interface loopback 10'
              },
              {
                command: 'router ospf 1',
                description: 'Enters OSPF (Open Shortest Path First) routing process configuration mode with process ID 1.',
                example: 'R1(config)# router ospf 1'
              },
              {
                command: 'router-id 2.2.2.2',
                description: 'Optionally sets the OSPF Router ID to 2.2.2.2, which is used to uniquely identify this OSPF router.',
                example: 'R1(config-router)# router-id 2.2.2.2'
              },
              {
                command: 'network 172.16.45.0 0.0.0.255 area 0',
                description: 'Includes the directly connected network 172.16.45.0/24 in OSPF area 0.',
                example: 'R1(config-router)# network 172.16.45.0 0.0.0.255 area 0'
              },
              {
                command: 'default-information originate',
                description: 'Advertises the default route (0.0.0.0/0) into OSPF, allowing other routers to learn the default route.',
                example: 'R1(config-router)# default-information originate'
              },
              {
                command: 'redistribute static',
                description: 'Redistributes classful static routes into OSPF, making them available to other OSPF routers.',
                example: 'R1(config-router)# redistribute static'
              },
              {
                command: 'redistribute static subnets',
                description: 'Redistributes classless static routes into OSPF, making them available to other OSPF routers with their subnet information.',
                example: 'R1(config-router)# redistribute static subnets'
              },
              {
                command: 'passive-interface default',
                description: 'Configures all interfaces to be passive, preventing OSPF updates from being sent out of any interface.',
                example: 'R1(config-router)# passive-interface default'
              },
              {
                command: 'no passive-interface fastethernet 0/1',
                description: 'Allows OSPF routing updates to be sent out of the FastEthernet0/1 interface, overriding the default passive setting.',
                example: 'R1(config-router)# no passive-interface fastethernet 0/1'
              },
              {
                command: 'passive-interface fastethernet 0/1',
                description: 'Prevents OSPF routing updates from being sent out of the FastEthernet0/1 interface, while still receiving updates.',
                example: 'R1(config-router)# passive-interface fastethernet 0/1'
              },
              {
                command: 'area 7 range 172.16.8.0 255.255.248.0',
                description: 'Configures address summarization on an Area Border Router (ABR) for area 7, summarizing the network 172.16.8.0/21.',
                example: 'R1(config-router)# area 7 range 172.16.8.0 255.255.248.0'
              },
              {
                command: 'summary address 172.16.8.0 255.255.248.0',
                description: 'Configures summary routes for non-OSPF routes imported into OSPF on an ASBR (Autonomous System Boundary Router).',
                example: 'R1(config-router)# summary address 172.16.8.0 255.255.248.0'
              },
              {
                command: 'auto-cost reference-bandwidth ?',
                description: 'Sets the reference bandwidth used for OSPF cost calculations. This value must be consistent across all OSPF routers.',
                example: 'R1(config-router)# auto-cost reference-bandwidth ?'
              },
              {
                command: 'area AREA-ID authentication message-digest',
                description: 'Enables MD5 authentication for OSPF in the specified area, enhancing security by validating routing updates.',
                example: 'R1(config-router)# area AREA-ID authentication message-digest'
              },
              {
                command: 'ip ospf message-digest-key 1 md5 PASSWORD',
                description: 'Configures the MD5 authentication key for OSPF with key ID 1 and the passphrase "PASSWORD".',
                example: 'R1(config-if)# ip ospf message-digest-key 1 md5 PASSWORD'
              },
              {
                command: 'ip ospf authentication message-digest',
                description: 'Enables OSPF message-digest authentication on the interface, which must be configured with a key.',
                example: 'R1(config-if)# ip ospf authentication message-digest'
              },
              {
                command: 'ip ospf cost 1562',
                description: 'Sets an absolute OSPF cost of 1562 for the interface, affecting the path selection process.',
                example: 'R1(config-if)# ip ospf cost 1562'
              },
              {
                command: 'ip ospf hello-interval seconds',
                description: 'Changes the OSPF hello interval timer on the interface from the default 10 seconds to a specified value.',
                example: 'R1(config-if)# ip ospf hello-interval seconds'
              },
              {
                command: 'ip ospf dead-interval seconds',
                description: 'Modifies the OSPF dead interval timer on the interface from the default 40 seconds to a specified value.',
                example: 'R1(config-if)# ip ospf dead-interval seconds'
              },
              {
                command: 'ip ospf priority {0 - 255}',
                description: 'Sets the OSPF priority for the interface, which is used in the election of the Designated Router (DR) and Backup Designated Router (BDR). Default is 1; 0 makes the router ineligible.',
                example: 'R1(config-if)# ip ospf priority {0 - 255}'
              },
              {
                command: 'show ip ospf neighbor',
                description: 'Displays the status of OSPF neighbor adjacencies, including their state (e.g., FULL, 2WAY).',
                example: 'R1# show ip ospf neighbor'
              },
              {
                command: 'show ip protocols',
                description: 'Shows information about the routing protocols configured on the router, including the OSPF Router ID.',
                example: 'R1# show ip protocols'
              },
              {
                command: 'clear ip ospf process',
                description: 'Clears the OSPF process, causing the router to re-calculate OSPF Router ID and re-establish OSPF adjacencies.',
                example: 'R1# clear ip ospf process'
              },
              {
                command: 'show ip ospf',
                description: 'Displays details about the OSPF process, including the router ID and OSPF area information.',
                example: 'R1# show ip ospf'
              },
              {
                command: 'show ip ospf interface serial 0/0/0',
                description: 'Shows OSPF interface details for Serial 0/0/0, including DR/BDR information, hello, and dead intervals.',
                example: 'R1# show ip ospf interface serial 0/0/0'
              }
              
        ],
      },

      {
        section: "IPv6 OSPF(v3)",
        commands: [
            {
                command: 'ipv6 unicast-routing',
                description: 'Enables IPv6 routing on the router, allowing it to route IPv6 packets.',
                example: 'R1(config)# ipv6 unicast-routing'
              },
              {
                command: 'no ipv6 router ospf 55',
                description: 'Removes the OSPFv3 routing process with process ID 55 from the router.',
                example: 'R1(config)# no ipv6 router ospf 55'
              },
              {
                command: 'ipv6 router ospf 100',
                description: 'Creates an OSPFv3 routing process with process ID 100 on the router.',
                example: 'R1(config)# ipv6 router ospf 100'
              },
              {
                command: 'router-id 5.5.5.5',
                description: 'Assigns a unique Router ID of 5.5.5.5 for the OSPFv3 process, used to identify the router in OSPF operations.',
                example: 'R1(config-rtr)# router-id 5.5.5.5'
              },
              {
                command: 'default-information originate',
                description: 'Advertises the default route (0.0.0.0/0) to other OSPFv3 routers, allowing them to learn about the default route.',
                example: 'R1(config-rtr)# default-information originate'
              },
              {
                command: 'redistribute static',
                description: 'Redistributes classful static routes, including the default route, into the OSPFv3 process.',
                example: 'R1(config-rtr)# redistribute static'
              },
              {
                command: 'redistribute static subnets',
                description: 'Redistributes classless static routes into the OSPFv3 process, including subnet information.',
                example: 'R1(config-rtr)# redistribute static subnets'
              },
              {
                command: 'passive-interface default',
                description: 'Configures all interfaces as passive, preventing OSPFv3 updates from being sent out of any interface.',
                example: 'R1(config-rtr)# passive-interface default'
              },
              {
                command: 'no passive-interface gi 1/0',
                description: 'Allows OSPFv3 updates to be sent out of the GigabitEthernet 1/0 interface, overriding the default passive setting.',
                example: 'R1(config-rtr)# no passive-interface gi 1/0'
              },
              {
                command: 'passive-interface gi 1/1',
                description: 'Prevents OSPFv3 routing updates from being sent out of the GigabitEthernet 1/1 interface, while still receiving updates.',
                example: 'R1(config-rtr)# passive-interface gi 1/1'
              },
              {
                command: 'no shutdown',
                description: 'Activates the interface, allowing it to participate in routing and other network activities.',
                example: 'R1(config)# no shutdown'
              },
              {
                command: 'interface gi 1/1',
                description: 'Selects the GigabitEthernet 1/1 interface for configuration, where network settings and other parameters are applied.',
                example: 'R1(config)# interface gi 1/1'
              },
              {
                command: 'ipv6 enable',
                description: 'Enables IPv6 on the selected interface, allowing it to handle IPv6 traffic.',
                example: 'R1(config-if)# ipv6 enable'
              },
              {
                command: 'ipv6 ospf 100 area 0',
                description: 'Associates the GigabitEthernet 1/1 interface with OSPFv3 process ID 100 and assigns it to area 0 for IPv6 routing.',
                example: 'R1(config-if)# ipv6 ospf 100 area 0'
              }
              
        ],
      },

      {
        section: "IPV6 EIGRP",
        commands: [
            {
                command: 'ipv6 unicast-routing',
                description: 'Enables IPv6 routing on the router, allowing it to handle IPv6 traffic.',
                example: 'R1(config)# ipv6 unicast-routing'
              },
              {
                command: 'no ipv6 router eigrp 100',
                description: 'Removes the EIGRP (Enhanced Interior Gateway Routing Protocol) process with AS number 100 from the router.',
                example: 'R1(config)# no ipv6 router eigrp 100'
              },
              {
                command: 'ipv6 router eigrp 100',
                description: 'Creates an EIGRP routing process with Autonomous System (AS) number 100 on the router.',
                example: 'R1(config)# ipv6 router eigrp 100'
              },
              {
                command: 'eigrp router-id 5.5.5.5',
                description: 'Sets the EIGRP Router ID to 5.5.5.5, used to uniquely identify this router in EIGRP communications.',
                example: 'R1(config-rtr)# eigrp router-id 5.5.5.5'
              },
              {
                command: 'redistribute static',
                description: 'Redistributes static routes, including default routes, into the EIGRP process, making them available to other EIGRP routers.',
                example: 'R1(config-rtr)# redistribute static'
              },
              {
                command: 'passive-interface default',
                description: 'Configures all interfaces as passive, preventing EIGRP updates from being sent out of any interface.',
                example: 'R1(config-rtr)# passive-interface default'
              },
              {
                command: 'no passive-interface gi 1/0',
                description: 'Allows EIGRP routing updates to be sent out of the GigabitEthernet 1/0 interface, overriding the default passive setting.',
                example: 'R1(config-rtr)# no passive-interface gi 1/0'
              },
              {
                command: 'passive-interface gi 1/1',
                description: 'Prevents EIGRP routing updates from being sent out of the GigabitEthernet 1/1 interface, while still receiving updates.',
                example: 'R1(config-rtr)# passive-interface gi 1/1'
              },
              {
                command: 'no shutdown',
                description: 'Activates the EIGRP process on the router, allowing it to start routing operations.',
                example: 'R1(config-rtr)# no shutdown'
              },
              {
                command: 'interface gi 1/1',
                description: 'Selects the GigabitEthernet 1/1 interface for configuration, where settings related to network operation will be applied.',
                example: 'R1(config)# interface gi 1/1'
              },
              {
                command: 'ipv6 enable',
                description: 'Enables IPv6 on the selected interface, allowing it to handle IPv6 traffic.',
                example: 'R1(config-if)# ipv6 enable'
              },
              {
                command: 'ipv6 eigrp 100',
                description: 'Associates the GigabitEthernet 1/1 interface with the EIGRP process ID 100 for IPv6 routing.',
                example: 'R1(config-if)# ipv6 eigrp 100'
              },
              {
                command: 'ipv6 summary-address eigrp 100 2001:123A:AAA0::/60',
                description: 'Configures a summary IPv6 address for EIGRP process ID 100, aggregating multiple routes into a single summary route.',
                example: 'R1(config-if)# ipv6 summary-address eigrp 100 2001:123A:AAA0::/60'
              },
              {
                command: 'ipv6 bandwidth-percent eigrp 100 40',
                description: 'Limits EIGRP updates for AS 100 to use a maximum of 40% of the available link bandwidth.',
                example: 'R1(config-if)# ipv6 bandwidth-percent eigrp 100 40'
              },
              {
                command: 'key chain MYCHAIN',
                description: 'Creates a key chain named MYCHAIN for authentication purposes in routing protocols.',
                example: 'R1(config)# key chain MYCHAIN'
              },
              {
                command: 'key 1',
                description: 'Defines a key with ID 1 in the key chain, used for authentication in routing protocols.',
                example: 'R1(config-keychain)# key 1'
              },
              {
                command: 'key-string securetraffic',
                description: 'Sets the passphrase for the key with ID 1 to "securetraffic" in the key chain.',
                example: 'R1(config-keychain-key)# key-string securetraffic'
              },
              {
                command: 'interface serial 0/1',
                description: 'Selects the Serial 0/1 interface for configuration, typically used for wide-area network (WAN) connections.',
                example: 'R1(config)# interface serial 0/1'
              },
              {
                command: 'ipv6 authentication mode eigrp 10 md5',
                description: 'Enables MD5 authentication for EIGRP process ID 10 on the interface, enhancing security by verifying routing updates.',
                example: 'R1(config-subif)# ipv6 authentication mode eigrp 10 md5'
              },
              {
                command: 'ipv6 authentication key-chain eigrp 10 MYCHAIN',
                description: 'Applies the key chain MYCHAIN to EIGRP process ID 10 for IPv6 authentication on the interface.',
                example: 'R1(config-subif)# ipv6 authentication key-chain eigrp 10 MYCHAIN'
              }
              
        ],
      },

      {
        section: "Configuring PPP with Authentication",
        commands: [
            {
                command: 'username R-2 password PASSWORD',
                description: 'Configures a local username and password for PPP authentication. For PAP, ensure the username and password match those sent from the other router. For CHAP, the username should be the hostname of the other router and the passwords must be consistent across both routers.',
                example: 'R1(config)# username R-2 password PASSWORD'
              },
              {
                command: 'interface serial 0/0/0',
                description: 'Selects the Serial 0/0/0 interface for PPP configuration.',
                example: 'R1(config)# interface serial 0/0/0'
              },
              {
                command: 'encapsulation ppp',
                description: 'Sets the encapsulation type of the interface to PPP (Point-to-Point Protocol).',
                example: 'R1(config-if)# encapsulation ppp'
              },
              {
                command: 'compress [predictor / stac]',
                description: 'Optionally configures data compression on the PPP link. Choose between Predictor or Stac compression methods to reduce the amount of data sent over the link.',
                example: 'R1(config-if)# compress predictor'
              },
              {
                command: 'ppp quality [percentage]',
                description: 'Optionally sets a threshold percentage of throughput. If the PPP link drops below this threshold, it will reset to try to improve the connection quality.',
                example: 'R1(config-if)# ppp quality 80'
              },
              {
                command: 'ppp authentication pap',
                description: 'Optionally configures the interface to use PAP (Password Authentication Protocol) for PPP authentication.',
                example: 'R1(config-if)# ppp authentication pap'
              },
              {
                command: 'ppp pap sent-username R-1 password PASSWORD',
                description: 'Configures the PAP authentication credentials for the PPP link. This is required when using PAP, where the sent username and password must match those configured on the other router.',
                example: 'R1(config-if)# ppp pap sent-username R-1 password PASSWORD'
              },
              {
                command: 'ppp authentication chap',
                description: 'Optionally configures the interface to use CHAP (Challenge Handshake Authentication Protocol) for PPP authentication.',
                example: 'R1(config-if)# ppp authentication chap'
              },
              {
                command: 'ppp multilink',
                description: 'Optionally enables PPP Multilink to combine multiple PPP links into a single logical link, increasing bandwidth and redundancy.',
                example: 'R1(config-if)# ppp multilink'
              },
              {
                command: 'encapsulation hdlc',
                description: 'Resets the encapsulation type of the interface to HDLC (High-Level Data Link Control), the default for serial interfaces.',
                example: 'R1(config-if)# encapsulation hdlc'
              }
              
        ],
      },

      {
        section: "FR Point-to-Point no sub-interface",
        commands: [
            {
                command: 'interface serial 0/0/0',
                description: 'Selects the Serial 0/0/0 interface for configuration.',
                example: 'R1(config)# interface serial 0/0/0'
              },
              {
                command: 'ip address 192.168.5.1 255.255.255.252',
                description: 'Assigns an IP address and subnet mask to the Serial 0/0/0 interface. A /30 subnet mask is typical for point-to-point links.',
                example: 'R1(config-if)# ip address 192.168.5.1 255.255.255.252'
              },
              {
                command: 'encapsulation frame-relay [ietf, cisco]',
                description: 'Sets the encapsulation type for the Frame Relay interface. "ietf" specifies the IETF Frame Relay encapsulation, which is optional, while "cisco" is the default encapsulation type.',
                example: 'R1(config-if)# encapsulation frame-relay cisco'
              },
              {
                command: 'frame-relay lmi-type [ansi, q933a, cisco]',
                description: 'Configures the LMI (Local Management Interface) type for the Frame Relay interface. Options include "ansi", "q933a", and "cisco", with "cisco" as the default.',
                example: 'R1(config-if)# frame-relay lmi-type cisco'
              },
              {
                command: 'frame-relay map ip 192.168.5.1 752',
                description: 'Maps the IP address 192.168.5.1 to the DLCI (Data Link Connection Identifier) 752. This configuration allows local pings to this IP address, where 192.168.5.1 is the local interface IP and DLCI 752 is a valid identifier for this interface.',
                example: 'R1(config-if)# frame-relay map ip 192.168.5.1 752'
              },
              {
                command: 'frame-relay map ip 192.168.5.2 752 broadcast [ietf, cisco]',
                description: 'Maps the IP address 192.168.5.2 to the DLCI 752, with the option to include broadcast if needed. This configuration allows communication to the next hop with IP 192.168.5.2, where DLCI 752 is used, and the encapsulation type ("ietf" or "cisco") is optional, with "cisco" as the default.',
                example: 'R1(config-if)# frame-relay map ip 192.168.5.2 752 broadcast'
              }
              
        ],
      },

      {
        section: "FR Point-to-Point with sub-interface",
        commands: [
            {
                command: 'interface serial 0/0/0',
                description: 'Selects the Serial 0/0/0 interface for configuration.',
                example: 'R1(config)# interface serial 0/0/0'
              },
              {
                command: 'no ip address',
                description: 'Removes the IP address from the main interface, which is typical when configuring Frame Relay sub-interfaces.',
                example: 'R1(config-if)# no ip address'
              },
              {
                command: 'encapsulation frame-relay [ietf, cisco]',
                description: 'Sets the encapsulation type for the Frame Relay interface. The "ietf" option specifies IETF Frame Relay encapsulation, while "cisco" is the default encapsulation type.',
                example: 'R1(config-if)# encapsulation frame-relay cisco'
              },
              {
                command: 'frame-relay lmi-type [ansi, q933a, cisco]',
                description: 'Configures the LMI (Local Management Interface) type for the Frame Relay interface. Choices include "ansi", "q933a", and "cisco", with "cisco" being the default.',
                example: 'R1(config-if)# frame-relay lmi-type cisco'
              },
              {
                command: 'interface serial 0/0/0.752 point-to-point',
                description: 'Creates a Frame Relay sub-interface with the specified DLCI (Data Link Connection Identifier) 752. This sub-interface is used for point-to-point communication.',
                example: 'R1(config)# interface serial 0/0/0.752 point-to-point'
              },
              {
                command: 'ip address 192.168.5.1 255.255.255.252',
                description: 'Assigns an IP address and subnet mask to the Frame Relay sub-interface. A /30 subnet mask is typically used for point-to-point links.',
                example: 'R1(config-subif)# ip address 192.168.5.1 255.255.255.252'
              },
              {
                command: 'frame-relay interface-dlci 752',
                description: 'Maps the DLCI (Data Link Connection Identifier) 752 to the Frame Relay sub-interface. This allows the sub-interface to communicate using the specified DLCI, with next hop and broadcast settings dynamically assigned.',
                example: 'R1(config-subif)# frame-relay interface-dlci 752'
              }
              
              
        ],
      },

      {
        section: "Multi-Point no sub-interface",
        commands: [
            {
                command: 'interface serial 0/0/0',
                description: 'Selects the Serial 0/0/0 interface for configuration.',
                example: 'R1(config)# interface serial 0/0/0'
              },
              {
                command: 'ip address 192.168.5.1 255.255.255.248',
                description: 'Assigns an IP address and subnet mask to the Serial 0/0/0 interface. The /29 subnet mask is used for a multi-point Frame Relay configuration, allowing multiple connections within the same IP subnet.',
                example: 'R1(config-if)# ip address 192.168.5.1 255.255.255.248'
              },
              {
                command: 'encapsulation frame-relay',
                description: 'Sets the encapsulation type for the interface to Frame Relay.',
                example: 'R1(config-if)# encapsulation frame-relay'
              },
              {
                command: 'frame-relay lmi-type [ansi, q933a, cisco]',
                description: 'Configures the LMI (Local Management Interface) type for the Frame Relay interface. Options include "ansi", "q933a", and "cisco", with "cisco" as the default.',
                example: 'R1(config-if)# frame-relay lmi-type cisco'
              },
              {
                command: 'frame-relay map ip 192.168.5.1 752',
                description: 'Maps the IP address 192.168.5.1 to DLCI (Data Link Connection Identifier) 752 on the Frame Relay interface. This configuration allows local pings to this IP address, where DLCI 752 is a valid identifier for this interface.',
                example: 'R1(config-if)# frame-relay map ip 192.168.5.1 752'
              },
              {
                command: 'frame-relay map ip 192.168.5.2 752 broadcast [ietf, cisco]',
                description: 'Maps the IP address 192.168.5.2 to DLCI 752. This configuration allows communication to the next hop with IP 192.168.5.2. The "broadcast" option is optional and the encapsulation type ("ietf" or "cisco") is optional, with "cisco" being the default.',
                example: 'R1(config-if)# frame-relay map ip 192.168.5.2 752 broadcast'
              },
              {
                command: 'frame-relay map ip 192.168.5.3 339 broadcast [ietf, cisco]',
                description: 'Maps the IP address 192.168.5.3 to DLCI 339. This configuration allows communication to the next hop with IP 192.168.5.3. The "broadcast" option is optional and the encapsulation type ("ietf" or "cisco") is optional, with "cisco" being the default.',
                example: 'R1(config-if)# frame-relay map ip 192.168.5.3 339 broadcast'
              }
              
        ],
      },

      {
        section: "Multi-Point with sub-interface",
        commands: [
            {
                command: 'interface serial 0/0/0',
                description: 'Selects the Serial 0/0/0 interface for configuration.',
                example: 'R1(config)# interface serial 0/0/0'
              },
              {
                command: 'no ip address',
                description: 'Removes the IP address configuration from the Serial 0/0/0 interface, typically used when configuring Frame Relay sub-interfaces.',
                example: 'R1(config-if)# no ip address'
              },
              {
                command: 'encapsulation frame-relay',
                description: 'Sets the encapsulation type for the interface to Frame Relay. This is configured on the main interface, not on sub-interfaces.',
                example: 'R1(config-if)# encapsulation frame-relay'
              },
              {
                command: 'frame-relay lmi-type [ansi, q933a, cisco]',
                description: 'Configures the LMI (Local Management Interface) type for Frame Relay. Options include "ansi", "q933a", and "cisco", with "cisco" being the default setting.',
                example: 'R1(config-if)# frame-relay lmi-type cisco'
              },
              {
                command: 'interface serial 0/0/0.752 multipoint',
                description: 'Creates a Frame Relay sub-interface on Serial 0/0/0 with DLCI 752 for multipoint configuration. The sub-interface number usually matches the DLCI.',
                example: 'R1(config)# interface serial 0/0/0.752 multipoint'
              },
              {
                command: 'ip address 192.168.5.1 255.255.255.248',
                description: 'Assigns an IP address and subnet mask to the Frame Relay sub-interface. The /29 subnet mask is used for multi-point configurations, accommodating multiple connections.',
                example: 'R1(config-subif)# ip address 192.168.5.1 255.255.255.248'
              },
              {
                command: 'frame-relay map ip 192.168.5.1 752',
                description: 'Maps the IP address 192.168.5.1 to DLCI 752 on the Frame Relay sub-interface. This allows local pings to the IP address 192.168.5.1 with DLCI 752.',
                example: 'R1(config-subif)# frame-relay map ip 192.168.5.1 752'
              },
              {
                command: 'frame-relay map ip 192.168.5.2 752 broadcast [ietf, cisco]',
                description: 'Maps the IP address 192.168.5.2 to DLCI 752, enabling communication to the next hop. The "broadcast" option is optional, and "ietf" or "cisco" encapsulation types can be used, with "cisco" being the default.',
                example: 'R1(config-subif)# frame-relay map ip 192.168.5.2 752 broadcast'
              },
              {
                command: 'frame-relay map ip 192.168.5.3 339 broadcast [ietf, cisco]',
                description: 'Maps the IP address 192.168.5.3 to DLCI 339, allowing communication to the next hop. The "broadcast" option is optional, and "ietf" or "cisco" encapsulation types can be used, with "cisco" as the default.',
                example: 'R1(config-subif)# frame-relay map ip 192.168.5.3 339 broadcast'
              },
              {
                command: 'show frame-relay map',
                description: 'Displays the mapping of IP addresses to DLCIs on the Frame Relay interface. Static mappings are defined by "frame-relay map" commands, while dynamic mappings are created through inverse-ARP.',
                example: 'R1# show frame-relay map'
              },
              {
                command: 'show frame-relay lmi',
                description: 'Shows the status of the local LMI link to the Frame Relay cloud, including information about LMI messages and the status of the Frame Relay interface.',
                example: 'R1# show frame-relay lmi'
              },
              {
                command: 'show frame-relay pvc',
                description: 'Displays the status of the Frame Relay PVCs (Permanent Virtual Circuits). "Active" indicates a fully connected PVC, "Inactive" means the PVC is connected to the Frame Relay switch but not seen at the other end, and "Delete" indicates no communication with the Frame Relay switch.',
                example: 'R1# show frame-relay pvc'
              }              
              
        ],
      },

      {
        section: "Standard Access Lists",
        commands: [
            {
                command: "ip access-list standard NAME",
                description: "Creates a named standard access list and enters its configuration mode.",
                example: "R-1(config)# ip access-list standard MY_LIST"
              },
              {
                command: "deny host 192.168.20.5 log",
                description: "Denies traffic from the specific host 192.168.20.5 and logs the matches.",
                example: "R-1(config-std-nacl)# deny host 192.168.20.5 log"
              },
              {
                command: "permit 192.168.20.0 0.0.0.255",
                description: "Permits traffic from the subnet 192.168.20.0/24.",
                example: "R-1(config-std-nacl)# permit 192.168.20.0 0.0.0.255"
              },
              {
                command: "deny any",
                description: "Denies all other IP addresses not explicitly permitted by previous rules.",
                example: "R-1(config-std-nacl)# deny any"
              },
              {
                command: "access-list 25 deny host 192.168.20.5",
                description: "Denies traffic from the specific host 192.168.20.5 using numbered access list 25.",
                example: "R-1(config)# access-list 25 deny host 192.168.20.5"
              },
              {
                command: "access-list 25 permit 192.168.20.0 0.0.0.255",
                description: "Permits traffic from the subnet 192.168.20.0/24 using numbered access list 25.",
                example: "R-1(config)# access-list 25 permit 192.168.20.0 0.0.0.255"
              },
              {
                command: "access-list 25 deny any",
                description: "Denies all other IP addresses not explicitly permitted by previous rules in access list 25.",
                example: "R-1(config)# access-list 25 deny any"
              }
              
        ],
      },

      {
        section: "Extended Access Lists",
        commands: [
            {
                "command": "ip access-list extended NAME",
                "description": "Creates a named extended access list and enters its configuration mode.",
                "example": "R-1(config)# ip access-list extended MY_EXT_LIST"
              },
              {
                "command": "deny tcp host 192.168.20.10 172.16.0.0 0.0.255.255 eq 23 log",
                "description": "Denies TCP traffic from a specific host (192.168.20.10) to a subnet (172.16.0.0/16) on port 23 (Telnet) and logs the matches.",
                "example": "R-1(config-ext-nacl)# deny tcp host 192.168.20.10 172.16.0.0 0.0.255.255 eq 23 log"
              },
              {
                "command": "permit ip 192.168.20.0 0.0.0.255 any",
                "description": "Permits all IP traffic from the subnet 192.168.20.0/24 to any destination.",
                "example": "R-1(config-ext-nacl)# permit ip 192.168.20.0 0.0.0.255 any"
              },
              {
                "command": "deny ip any any",
                "description": "Denies all IP traffic not explicitly permitted by previous rules. This rule is applied by default if no other rules are configured.",
                "example": "R-1(config-ext-nacl)# deny ip any any"
              },
              {
                "command": "remark UDP any lt 443",
                "description": "Adds a remark for UDP traffic with destination port less than 443 (HTTPS). This is an optional comment for documentation purposes.",
                "example": "R-1(config-ext-nacl)# remark UDP any lt 443"
              },
              {
                "command": "remark ICMP host X.X.X.X neq echo-reply",
                "description": "Adds a remark for ICMP traffic where the destination is a specific host and the type is not echo-reply (ping). This is an optional comment for documentation purposes.",
                "example": "R-1(config-ext-nacl)# remark ICMP host X.X.X.X neq echo-reply"
              }
              
        ],
      },

      {
        section: "Applying Access Lists",
        commands: [
            {
                command: 'interface fastethernet 0/0',
                description: 'Selects the FastEthernet 0/0 interface for configuration.',
                example: 'R-1(config)# interface fastethernet 0/0'
              },
              {
                command: 'ip access-group NAME in',
                description: 'Applies the named access list to evaluate packets coming into the router on the selected interface.',
                example: 'R-1(config-if)# ip access-group NAME in'
              },
              {
                command: 'ip access-group NAME out',
                description: 'Applies the named access list to evaluate packets leaving the router on the selected interface.',
                example: 'R-1(config-if)# ip access-group NAME out'
              },
              {
                command: 'line vty 0 4',
                description: 'Enters configuration mode for the virtual terminal lines 0 through 4, which are used for Telnet or SSH access.',
                example: 'R-1(config)# line vty 0 4'
              },
              {
                command: 'access-class NAME in',
                description: 'Applies the named access class to evaluate incoming packets for Telnet or SSH connections on the virtual terminal lines.',
                example: 'R-1(config-line)# access-class NAME in'
              }
              
        ],
      },

      {
        section: "Dynamic Access List (Stateful-Firewall)",
        commands: [
            {
                command: 'ip access-list extended OUTBOUND-TRAFFIC',
                description: 'Creates or accesses an extended access list named OUTBOUND-TRAFFIC.',
                example: 'R1(config)# ip access-list extended OUTBOUND-TRAFFIC'
              },
              {
                command: 'permit tcp any any reflect TCP-TRAFFIC',
                description: 'Permits TCP traffic from any source to any destination and reflects it using the TCP-TRAFFIC access list.',
                example: 'R1(config-ext-nacl)# permit tcp any any reflect TCP-TRAFFIC'
              },
              {
                command: 'permit udp any any reflect UDP-TRAFFIC',
                description: 'Permits UDP traffic from any source to any destination and reflects it using the UDP-TRAFFIC access list.',
                example: 'R1(config-ext-nacl)# permit udp any any reflect UDP-TRAFFIC'
              },
              {
                command: 'permit icmp any any reflect ICMP-TRAFFIC',
                description: 'Permits ICMP traffic from any source to any destination and reflects it using the ICMP-TRAFFIC access list.',
                example: 'R1(config-ext-nacl)# permit icmp any any reflect ICMP-TRAFFIC'
              },
              {
                command: 'deny ip any any',
                description: 'Denies all IP traffic that is not explicitly permitted by previous rules in the access list.',
                example: 'R1(config-ext-nacl)# deny ip any any'
              },
              {
                command: 'ip access-list extended EVALUATE-INBOUND',
                description: 'Creates or accesses an extended access list named EVALUATE-INBOUND.',
                example: 'R1(config)# ip access-list extended EVALUATE-INBOUND'
              },
              {
                command: 'evaluate TCP-TRAFFIC',
                description: 'Evaluates traffic against the TCP-TRAFFIC access list for the EVALUATE-INBOUND access list.',
                example: 'R1(config-ext-nacl)# evaluate TCP-TRAFFIC'
              },
              {
                command: 'evaluate UDP-TRAFFIC',
                description: 'Evaluates traffic against the UDP-TRAFFIC access list for the EVALUATE-INBOUND access list.',
                example: 'R1(config-ext-nacl)# evaluate UDP-TRAFFIC'
              },
              {
                command: 'evaluate ICMP-TRAFFIC',
                description: 'Evaluates traffic against the ICMP-TRAFFIC access list for the EVALUATE-INBOUND access list.',
                example: 'R1(config-ext-nacl)# evaluate ICMP-TRAFFIC'
              },
              {
                command: 'interface serial 0/0/0',
                description: 'Selects the Serial 0/0/0 interface for configuration.',
                example: 'R1(config)# interface serial 0/0/0'
              },
              {
                command: 'ip access-group OUTBOUND-TRAFFIC out',
                description: 'Applies the OUTBOUND-TRAFFIC access list to packets leaving the Serial 0/0/0 interface.',
                example: 'R1(config-if)# ip access-group OUTBOUND-TRAFFIC out'
              },
              {
                command: 'ip access-group EVALUATE-INBOUND in',
                description: 'Applies the EVALUATE-INBOUND access list to packets entering the Serial 0/0/0 interface.',
                example: 'R1(config-if)# ip access-group EVALUATE-INBOUND in'
              }              
        ],
      },

      {
        section: "Time-Based ACL",
        commands: [
            {
                command: 'time-range MON-WED-FRI',
                description: 'Creates or accesses a time range named MON-WED-FRI for scheduling.',
                example: 'R-1(config)# time-range MON-WED-FRI'
              },
              {
                command: 'periodic Monday Wednesday Friday 8:00 to 17:00',
                description: 'Defines the time range as occurring on Monday, Wednesday, and Friday from 8:00 to 17:00.',
                example: 'R-1(config-time-range)# periodic Monday Wednesday Friday 8:00 to 17:00'
              },
              {
                command: 'access-list 133 permit tcp 192.168.20.0 0.0.0.255 any eq telnet time-range MON-WED-FRI',
                description: 'Permits TCP traffic from the 192.168.20.0 subnet to any destination on port 23 (telnet) during the MON-WED-FRI time range.',
                example: 'R-1(config)# access-list 133 permit tcp 192.168.20.0 0.0.0.255 any eq telnet time-range MON-WED-FRI'
              },
              {
                command: 'show access-list',
                description: 'Displays all access lists configured on the router, including the number of matches per line.',
                example: 'R-1# show access-list'
              },
              {
                command: 'show access-list NAME',
                description: 'Displays details of a specific access list, including the number of matches per line.',
                example: 'R-1# show access-list NAME'
              }
              
        ],
      },

      {
        section: "DHCP for IPv4",
        commands: [
            {
                command: 'ip dhcp excluded 172.16.2.1 172.16.2.7',
                description: 'Excludes the IP range 172.16.2.1 to 172.16.2.7 from being assigned by the DHCP server.',
                example: 'R-1(config)# ip dhcp excluded 172.16.2.1 172.16.2.7'
              },
              {
                command: 'ip dhcp pool LAN-2',
                description: 'Creates or accesses a DHCP pool named LAN-2.',
                example: 'R-1(config)# ip dhcp pool LAN-2'
              },
              {
                command: 'network 172.16.2.0 255.255.255.128',
                description: 'Defines the network range for the DHCP pool as 172.16.2.0 with a subnet mask of 255.255.255.128.',
                example: 'R-1(dhcp-config)# network 172.16.2.0 255.255.255.128'
              },
              {
                command: 'default-router 172.16.2.1',
                description: 'Sets the default gateway for DHCP clients to 172.16.2.1.',
                example: 'R-1(dhcp-config)# default-router 172.16.2.1'
              },
              {
                command: 'dns-server 140.198.8.14',
                description: 'Specifies the DNS server for DHCP clients, with a maximum of 4 addresses allowed.',
                example: 'R-1(dhcp-config)# dns-server 140.198.8.14'
              },
              {
                command: 'domain-name MCC.COM',
                description: 'Optional configuration to set the domain name for DHCP clients as MCC.COM.',
                example: 'R-1(dhcp-config)# domain-name MCC.COM'
              },
              {
                command: 'lease-time 5',
                description: 'Sets the DHCP lease time to 5 days (default is 1 day).',
                example: 'R-1(dhcp-config)# lease-time 5'
              },
              {
                command: 'interface fastethernet 0/1',
                description: 'Selects the FastEthernet 0/1 interface for configuration.',
                example: 'R-3(config)# interface fastethernet 0/1'
              },
              {
                command: 'ip helper-address 192.168.15.2',
                description: 'Configures the interface to forward DHCP requests to the DHCP server at 192.168.15.2.',
                example: 'R-3(config-if)# ip helper-address 192.168.15.2'
              },
              {
                command: 'show ip dhcp binding',
                description: 'Displays a list of IP addresses currently assigned by the DHCP server along with their MAC addresses.',
                example: 'R-1# show ip dhcp binding'
              },
              {
                command: 'ipconfig /release',
                description: 'Releases the current IP address assigned by the DHCP server on a PC.',
                example: 'DOS-PROMPT> ipconfig /release'
              },
              {
                command: 'ipconfig /renew',
                description: 'Requests a new IP address from the DHCP server on a PC.',
                example: 'DOS-PROMPT> ipconfig /renew'
              }
              
        ],
      },

      {
        section: "DHCP for IPv6 Stateless Address Auto-Configuration (SLAAC)",
        commands: [
            {
                command: 'ipv6 unicast-routing',
                description: 'Activates IPv6 routing on the router.',
                example: 'R1(config)# ipv6 unicast-routing'
              },
              {
                command: 'ipv6 dhcp pool LAN-10-STATELESS',
                description: 'Creates a DHCPv6 pool named LAN-10-STATELESS for stateless address autoconfiguration and DNS information.',
                example: 'R1(config)# ipv6 dhcp pool LAN-10-STATELESS'
              },
              {
                command: 'dns-server 2001:345:ACAD:F::5',
                description: 'Specifies the IPv6 DNS server address for the DHCPv6 pool.',
                example: 'R1(dhcpv6-config)# dns-server 2001:345:ACAD:F::5'
              },
              {
                command: 'domain-name cisco.com',
                description: 'Optionally sets the domain name for the DHCPv6 pool as cisco.com.',
                example: 'R1(dhcpv6-config)# domain-name cisco.com'
              },
              {
                command: 'interface g1/1',
                description: 'Selects the GigabitEthernet 1/1 interface for configuration.',
                example: 'R1(config)# interface g1/1'
              },
              {
                command: 'ipv6 address 2001:A1B5:C13:10::1/64',
                description: 'Assigns an IPv6 address of 2001:A1B5:C13:10::1 with a /64 prefix to the interface.',
                example: 'R1(config-if)# ipv6 address 2001:A1B5:C13:10::1/64'
              },
              {
                command: 'ipv6 dhcp server LAN-10-STATELESS',
                description: 'Configures the interface to use the LAN-10-STATELESS DHCPv6 pool for stateless address configuration.',
                example: 'R1(config-if)# ipv6 dhcp server LAN-10-STATELESS'
              },
              {
                command: 'ipv6 nd other-config-flag',
                description: 'Enables the Other Configuration flag in IPv6 Neighbor Discovery to signal that additional configuration information (like DNS) is available via DHCPv6.',
                example: 'R1(config-if)# ipv6 nd other-config-flag'
              }
              
        ],
      },

      {
        section: "DHCP for IPv6 Stateful Address Auto-configuration",
        commands: [
            {
                command: 'ipv6 unicast-routing',
                description: 'Activates IPv6 routing on the router.',
                example: 'R1(config)# ipv6 unicast-routing'
              },
              {
                command: 'ipv6 dhcp pool LAN-10-STATEFUL',
                description: 'Creates a DHCPv6 pool named LAN-10-STATEFUL for stateful address configuration and DNS information.',
                example: 'R1(config)# ipv6 dhcp pool LAN-10-STATEFUL'
              },
              {
                command: 'address prefix 2001:D7B:CAFÉ:10::/64 lifetime infinite infinite',
                description: 'Configures the IPv6 address prefix for the DHCPv6 pool with an infinite lifetime.',
                example: 'R1(dhcpv6-config)# address prefix 2001:D7B:CAFÉ:10::/64 lifetime infinite infinite'
              },
              {
                command: 'dns-server 2001:345:ACAD:F::5',
                description: 'Specifies the IPv6 DNS server address for the DHCPv6 pool.',
                example: 'R1(dhcpv6-config)# dns-server 2001:345:ACAD:F::5'
              },
              {
                command: 'domain-name cisco.com',
                description: 'Optionally sets the domain name for the DHCPv6 pool as cisco.com.',
                example: 'R1(dhcpv6-config)# domain-name cisco.com'
              },
              {
                command: 'interface g1/1',
                description: 'Selects the GigabitEthernet 1/1 interface for configuration.',
                example: 'R1(config)# interface g1/1'
              },
              {
                command: 'ipv6 address 2001:D7B:CAFE:10::1/64',
                description: 'Assigns an IPv6 address of 2001:D7B:CAFE:10::1 with a /64 prefix to the interface.',
                example: 'R1(config-if)# ipv6 address 2001:D7B:CAFE:10::1/64'
              },
              {
                command: 'ipv6 dhcp server LAN-10-STATEFUL',
                description: 'Configures the interface to use the LAN-10-STATEFUL DHCPv6 pool for stateful address configuration.',
                example: 'R1(config-if)# ipv6 dhcp server LAN-10-STATEFUL'
              },
              {
                command: 'ipv6 nd managed-config-flag',
                description: 'Enables the Managed Configuration flag in IPv6 Neighbor Discovery to signal that stateful configuration information is available via DHCPv6.',
                example: 'R1(config-if)# ipv6 nd managed-config-flag'
              },
              {
                command: 'interface fastethernet 0/1',
                description: 'Selects the FastEthernet 0/1 interface for configuration.',
                example: 'R-3(config)# interface fastethernet 0/1'
              },
              {
                command: 'ip dhcp relay destination 2001:A123:7CA1::15',
                description: 'Configures the interface to relay DHCP requests to the specified IPv6 DHCP server address.',
                example: 'R-3(config-if)# ip dhcp relay destination 2001:A123:7CA1::15'
              },
              {
                command: 'show ipv6 dhcp pool',
                description: 'Displays information about the DHCPv6 pools configured on the router.',
                example: 'R1# show ipv6 dhcp pool'
              },
              {
                command: 'show ipv6 dhcp binding',
                description: 'Shows the current bindings of IPv6 addresses to clients, including lease information.',
                example: 'R1# show ipv6 dhcp binding'
              }
              
        ],
      },

      {
        section: "NAT for IPv4",
        commands: [
            {
                command: 'interface fa0/0',
                description: 'Selects the FastEthernet 0/0 interface for configuration.',
                example: 'R-1(config)# interface fa0/0'
              },
              {
                command: 'ip nat inside',
                description: 'Designates the interface as an inside NAT interface.',
                example: 'R-1(config-if)# ip nat inside'
              },
              {
                command: 'interface serial 0/0/0',
                description: 'Selects the Serial 0/0/0 interface for configuration.',
                example: 'R-1(config)# interface serial 0/0/0'
              },
              {
                command: 'ip nat outside',
                description: 'Designates the interface as an outside NAT interface.',
                example: 'R-1(config-if)# ip nat outside'
              },
              {
                command: 'ip nat inside source static 192.168.10.22 73.2.34.137',
                description: 'Configures a static NAT translation from the inside IP 192.168.10.22 to the outside IP 73.2.34.137.',
                example: 'R-1(config)# ip nat inside source static 192.168.10.22 73.2.34.137'
              },
              {
                command: 'ip nat pool POOL-NAME 73.2.34.138 73.2.34.143 netmask 255.255.255.248',
                description: 'Creates a NAT pool named POOL-NAME with the specified range and netmask for dynamic NAT.',
                example: 'R-1(config)# ip nat pool POOL-NAME 73.2.34.138 73.2.34.143 netmask 255.255.255.248'
              },
              {
                command: 'ip nat pool POOL-NAME 73.2.34.138 73.2.34.143 prefix-length 29',
                description: 'Creates a NAT pool named POOL-NAME with the specified range and prefix length for dynamic NAT.',
                example: 'R-1(config)# ip nat pool POOL-NAME 73.2.34.138 73.2.34.143 prefix-length 29'
              },
              {
                command: 'ip access-list standard NAT-ELIGIBLE',
                description: 'Creates a standard ACL named NAT-ELIGIBLE to define which internal addresses can be NATted.',
                example: 'R-1(config)# ip access-list standard NAT-ELIGIBLE'
              },
              {
                command: 'permit 192.168.10.0 0.0.0.255',
                description: 'Allows all addresses in the 192.168.10.0/24 subnet to be NATted.',
                example: 'R-1(config-std-nacl)# permit 192.168.10.0 0.0.0.255'
              },
              {
                command: 'deny any',
                description: 'Denies all other addresses from being NATted.',
                example: 'R-1(config-std-nacl)# deny any'
              },
              {
                command: 'ip nat inside source list NAT-ELIGIBLE pool POOL-NAME',
                description: 'Configures dynamic NAT using the NAT-ELIGIBLE ACL and the POOL-NAME pool.',
                example: 'R-1(config)# ip nat inside source list NAT-ELIGIBLE pool POOL-NAME'
              },
              {
                command: 'ip nat inside source list NAT-ELIGIBLE pool POOL-NAME overload',
                description: 'Configures dynamic NAT with PAT (overload) using the NAT-ELIGIBLE ACL and the POOL-NAME pool.',
                example: 'R-1(config)# ip nat inside source list NAT-ELIGIBLE pool POOL-NAME overload'
              },
              {
                command: 'ip nat inside source list NAT-ELIGIBLE interface serial 0/0/0 overload',
                description: 'Configures dynamic NAT with PAT (overload) using the NAT-ELIGIBLE ACL and the specified exit interface.',
                example: 'R-1(config)# ip nat inside source list NAT-ELIGIBLE interface serial 0/0/0 overload'
              },
              {
                command: 'show ip nat translations',
                description: 'Displays current NAT translations, including both dynamic and static entries.',
                example: 'R-1# show ip nat translations'
              },
              {
                command: 'show ip nat statistics',
                description: 'Displays NAT statistics, including the number of active translations and the role of NAT interfaces.',
                example: 'R-1# show ip nat statistics'
              }
              
        ],
      },

      {
        section: "Hot Standby Routing Protocol (HSRP) for IPv4",
        commands: [
            {
                command: 'interface fastethernet 0/1',
                description: 'Selects the FastEthernet 0/1 interface for HSRP configuration.',
                example: 'R1(config)# interface fastethernet 0/1'
              },
              {
                command: 'standby version 2',
                description: 'Configures the HSRP version to 2. Ensure the same version is used on all participating routers.',
                example: 'R1(config-if)# standby version 2'
              },
              {
                command: 'standby [optional group#] ip [optional IP-ADDRESS] [optional secondary]',
                description: 'Configures the virtual IP address for HSRP. The optional group number and secondary IP address can be specified.',
                example: 'R1(config-if)# standby 1 ip 192.168.1.1'
              },
              {
                command: 'standby [optional group#] priority NUMBER [optional preempt]',
                description: 'Sets the HSRP priority. A higher priority (default is 100) makes this router the primary HSRP router. The optional preempt setting will make this router the active one if it had been down and comes back up.',
                example: 'R1(config-if)# standby 1 priority 120 preempt'
              },
              {
                command: 'standby GROUP# ipv6 autoconfig',
                description: 'Configures HSRP for IPv6 to use autoconfiguration for the virtual link-local address.',
                example: 'R1(config-if)# standby 1 ipv6 autoconfig'
              },
              {
                command: 'standby GROUP# ipv6 2001:CAFE:ACAD:4::1/64',
                description: 'Sets the virtual IPv6 address for HSRP.',
                example: 'R1(config-if)# standby 1 ipv6 2001:CAFE:ACAD:4::1/64'
              },
              {
                command: 'standby GROUP# priority NUMBER [optional preempt]',
                description: 'Sets the HSRP priority for IPv6. A higher priority (default is 100) makes this router the primary HSRP router. The optional preempt setting will make this router the active one if it had been down and comes back up.',
                example: 'R1(config-if)# standby 1 priority 120 preempt'
              },
              {
                command: 'show standby',
                description: 'Displays the HSRP status and configuration to verify the setup.',
                example: 'R1# show standby'
              }
              
        ],
      },

      {
        section: "SSH",
        commands: [
            {
                command: 'hostname R1',
                description: 'Changes the hostname of the device from the default to R1.',
                example: 'Router(config)# hostname R1'
              },
              {
                command: 'username Chan-Chan password Alpha@1',
                description: 'Configures a local user named Chan-Chan with the password "Alpha@1".',
                example: 'R1(config)# username Chan-Chan password Alpha@1'
              },
              {
                command: 'ip domain-name ANYTHING.COM',
                description: 'Sets the domain name for the device, which is required for generating crypto keys.',
                example: 'R1(config)# ip domain-name ANYTHING.COM'
              },
              {
                command: 'crypto key generate rsa',
                description: 'Generates an RSA encryption key. The default key size is 512 bits, but 1024 bits is selected for stronger encryption.',
                example: 'R1(config)# crypto key generate rsa'
              },
              {
                command: 'ip ssh version 2',
                description: 'Configures the device to use SSH version 2 for secure remote access.',
                example: 'R1(config)# ip ssh version 2'
              },
              {
                command: 'line vty 0 15',
                description: 'Enters the configuration mode for virtual terminal lines 0 through 15, which are used for remote access.',
                example: 'R1(config)# line vty 0 15'
              },
              {
                command: 'login local',
                description: 'Configures the VTY lines to authenticate users against the local database of usernames and passwords.',
                example: 'R1(config-line)# login local'
              },
              {
                command: 'transport input ssh',
                description: 'Specifies that only SSH is allowed for remote management on the VTY lines.',
                example: 'R1(config-line)# transport input ssh'
              }
              
        ],
      },

   /*   
   Test 
   {
        section: "VLAN Commands",
        commands: [
          
        ],
      },*/
      // Additional sections...
    ];
    // Add more commands as needed
  